import { NavigationItem } from "./types";

export const NAVIGATION: NavigationItem[] = [
  {
    title: "Dashboard",
    icon: "dashboard",
    iconActive: "dashboardFill",
    path: "dashboard",
  },
  {
    title: "Transactions",
    icon: "transactions",
    iconActive: "transactionsFill",
    path: "card-transactions",
  },
  {
    title: "Income",
    icon: "income",
    iconActive: "incomeFill",
    path: "income",
  },
  {
    title: "Account",
    icon: "account",
    iconActive: "accountFill",
    path: "account",
  },
];
