import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "utils/theme";
import styled, { css } from "styled-components";
import { ICON, ICON_SIZE } from "./constants";

export type IconSize = keyof typeof ICON_SIZE;

export type IconName = keyof typeof ICON;

export const getHeight = (size: IconSize) => ICON_SIZE[size] || ICON_SIZE.md;

const getIcon = (name: IconName) => ICON[name] || ICON.imageSlash;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{
  $height: number;
  $primaryColor: string;
  $secondaryColor?: string;
}>`
  --fa-secondary-opacity: 1;

  ${(props) => css`
    height: ${props.$height}px;
    color: ${props.$primaryColor};
    --fa-primary-color: ${props.$primaryColor};
    --fa-secondary-color: ${props.$secondaryColor};
  `}
`;

interface Props {
  name: IconName;
  size?: IconSize;
  height?: number;
  color?: string;
  colorSecondary?: string;
}

const Icon: React.FC<Props> = ({
  name,
  size = "sm",
  height,
  color,
  colorSecondary,
  ...props
}) => {
  const iconHeight = height || getHeight(size);
  const primaryColor = colors[color] || color;
  const secondaryColor = colors[colorSecondary] || colorSecondary;
  return (
    <StyledFontAwesomeIcon
      icon={getIcon(name)}
      height={iconHeight}
      $height={iconHeight}
      $primaryColor={primaryColor}
      $secondaryColor={secondaryColor}
      {...props}
    />
  );
};

export default Icon;
