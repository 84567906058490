import React from "react";
import Input, { InputProps } from "../ui/Input";
import FieldContainer from "../FieldContainer";

interface Props extends InputProps {
  label?: React.ReactNode;
}

const InputField: React.FC<Props> = ({ name, label, ...props }) => (
  <FieldContainer name={name} label={label}>
    {({ inputProps, error }) => (
      <Input name={name} hasError={!!error} {...inputProps} {...props} />
    )}
  </FieldContainer>
);

export default InputField;
