import { BlockType } from "types/cms";
import Split from "./Split";
import Tile from "./Tile";
import Wysiwyg from "./Wysiwyg";
import People from "./People";
import Toggle from "./Toggle";
import Logo from "./Logo";

export default {
  [BlockType.split]: Split,
  [BlockType.tile]: Tile,
  [BlockType.wysiwyg]: Wysiwyg,
  [BlockType.people]: People,
  [BlockType.toggle]: Toggle,
  [BlockType.logo]: Logo,
};
