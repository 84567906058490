import LayoutContext, { LayoutName } from "components/context/LayoutContext";
import React, { useContext } from "react";
import styled from "styled-components";
import { breakpoints, generateMediaQueries } from "utils/theme";

export const mediaQueries = generateMediaQueries([
  `
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  `,
  `
    max-width: ${breakpoints.md}px;
    padding-left: 24px;
    padding-right: 24px;
  `,
  `
    max-width: ${breakpoints.lg}px;
    padding-left: 24px;
    padding-right: 24px;
  `,
  `
    max-width: ${breakpoints.xl + 256}px;
    padding-left: 152px;
    padding-right: 152px;
  `,
]);

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  ${mediaQueries}
`;

export const mediaQueriesForLayoutWithSidebar = generateMediaQueries([
  `
    max-width: 100%;
  `,
  `
    max-width: ${breakpoints.md}px;
  `,
  `
    max-width: ${breakpoints.lg}px;
  `,
  `
    max-width: ${breakpoints.xl + 256}px;
  `,
]);

const WrapperForLayoutWithSidebar = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
  ${mediaQueriesForLayoutWithSidebar}
`;

const ContentLayout = (props: any) => {
  const layout = useContext(LayoutContext);
  return layout.name === LayoutName.withSidebar ? (
    <WrapperForLayoutWithSidebar {...props} />
  ) : (
    <Wrapper {...props} />
  );
};

export default ContentLayout;
