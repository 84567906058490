import React from "react";
import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";
import App from "App";
import { getSessionPartner } from "utils/partners";
import Publications from "components/shared/Publications";
import Header from "./components/Header";
import UnlockYourPaycheck from "./components/UnlockYourPaycheck";
import AppHighlight from "./components/AppHighlight";
import Features from "./components/Features";
import Footer from "./components/Footer";
import FinancialInstitutions from "./components/FinancialInstitutions";

const Wrapper = styled.div`
  background: linear-gradient(75deg, ${colors.heliotrope}, ${colors.azure});
`;

const PublicationsWrapper = styled.div`
  padding: 0 0 64px;
  margin-top: -24px;

  ${mediaMax("lg")} {
    padding: 24px 0 96px;
    background: ${colors.azure};
  }
`;

const Home = () => {
  const partner = getSessionPartner();

  const hasProbationaryPeriod = !partner || partner.enableProbationaryPeriod;
  const hasInvitation = !!partner;

  return (
    <App>
      <Wrapper>
        <Header hasInvitation={hasInvitation} />

        <PublicationsWrapper>
          <Publications reverse />
        </PublicationsWrapper>

        <UnlockYourPaycheck />

        <AppHighlight hasProbationaryPeriod={hasProbationaryPeriod} />

        <Features />

        <FinancialInstitutions />

        <Footer />
      </Wrapper>
    </App>
  );
};

export default Home;
