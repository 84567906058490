import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  usePostQualifierMutation,
  usePostUserMutation,
} from "store/api/apiSlice";
import { toHash } from "utils/string";
import { EventType, trackEvent } from "utils/analytics";
import { ApiDisclosure } from "utils/api/disclosures";
import { SignupSelections } from "types/general";
import { Eligibility } from "types/signup";
import Fields from "./Fields";
import Success from "../Success";

const DEFAULT_ELIGIBILITY = {
  payroll: Eligibility.indeterminate,
  bank: Eligibility.indeterminate,
};

interface ApiError {
  data: [string, string | string[]];
}

interface Props {
  initialParams: SignupSelections;
  openDrawer: (body: React.ReactNode) => void;
  closeDrawer: () => void;
  messagingTerms?: ApiDisclosure;
}

const Form: React.FC<Props> = ({
  initialParams,
  openDrawer,
  closeDrawer,
  messagingTerms,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_postSignup, postSignupContext] = usePostUserMutation({
    fixedCacheKey: "signup",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_postWaitlist, postWaitlistContext] = usePostQualifierMutation({
    fixedCacheKey: "waitlist",
  });

  const [eligibility, setEligibility] = useState<{
    payroll: Eligibility;
    bank: Eligibility;
  }>(DEFAULT_ELIGIBILITY);
  const [hasError, setHasError] = useState({
    bank: false,
    payroll: false,
  });
  const [submissionCount, setSubmissionCount] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const form = useFormContext();

  const updateEligibility = (key: "bank" | "payroll") => (value: Eligibility) =>
    setEligibility((s) => ({ ...s, [key]: value }));

  const updateHasError = (key: "bank" | "payroll") => (value: boolean) =>
    setHasError((s) => ({ ...s, [key]: value }));

  const resetForm = () => {
    postSignupContext.reset();
    postWaitlistContext.reset();
    setIsSubmitted(false);
    setEligibility(() => ({ ...DEFAULT_ELIGIBILITY }));
    form.setValue("questionnaire.incomeType", null);
  };

  const updateValidation = (accountEligibility) => {
    setHasError(
      Object.entries(accountEligibility).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === Eligibility.indeterminate,
        }),
        { bank: false, payroll: false }
      )
    );
  };

  const scrollToErrorByName = (name) => {
    const errorEl = document.querySelector(`[name="${name}"]`);
    const rect = errorEl?.getBoundingClientRect();
    if (rect?.top) {
      window.scrollTo({
        top: window.scrollY + rect.top - 108,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleValidationErrors = (error: ApiError) => {
    Object.entries(error.data || {}).forEach(([name, message]) =>
      form.setError(
        `user.${name}`,
        {
          type: "custom",
          message: message instanceof Array ? message.join(" ") : message,
        },
        { shouldFocus: true }
      )
    );
  };

  const showSuccess = async () => {
    setSubmissionCount((c) => c + 1);
    const userId = await toHash(form.getValues("user.email"));
    trackEvent(EventType.userSignupSubmitted, { user_id: userId });
  };

  useEffect(() => {
    form.setValue("eligibility", eligibility);
  }, [eligibility]);

  useEffect(() => {
    setIsSubmitted(form.formState.isSubmitted);
  }, [form.formState.isSubmitted, form.formState.submitCount]);

  useEffect(() => {
    setHasError({ payroll: false, bank: false });
    if (isSubmitted) {
      updateValidation(eligibility);
    }
  }, [eligibility, isSubmitted, form.formState.submitCount]);

  useEffect(() => {
    if (postSignupContext.isError && postSignupContext.error) {
      handleValidationErrors(postSignupContext.error as ApiError);
    }
  }, [postSignupContext.isError, postSignupContext.error]);

  useEffect(() => {
    if (hasError.payroll || hasError.bank) {
      scrollToErrorByName(hasError.bank ? "bankName" : "payrollName");
    }
  }, [hasError]);

  useEffect(() => {
    if (postSignupContext.isSuccess || postWaitlistContext.isSuccess) {
      showSuccess();
    }
  }, [postSignupContext.isSuccess, postWaitlistContext.isSuccess]);

  return postSignupContext.isSuccess || postWaitlistContext.isSuccess ? (
    <Success eligibility={eligibility} resetForm={resetForm} />
  ) : (
    <Fields
      isSubmitting={form.formState.isSubmitting}
      hasError={hasError}
      initialParams={initialParams}
      updateEligibility={updateEligibility}
      updateHasError={updateHasError}
      eligibility={eligibility}
      openDrawer={openDrawer}
      closeDrawer={closeDrawer}
      messagingTerms={messagingTerms}
      submissionCount={submissionCount}
    />
  );
};

export default Form;
