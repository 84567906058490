import React, { ReactNode, useEffect, useState } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import * as process from "process";
import Loading from "components/ui/Loading";
import { getUserProfile } from "store/api/apiSlice";

(window as any).process = process;

const getCustomLogger = () => {
  const levels = ["none", "error", "warn", "debug"];
  const logLevel = levels.indexOf(process.env.LOG_LEVEL || "warn");

  return {
    debug: (msg) => {
      // eslint-disable-next-line no-console
      if (logLevel >= 3) console.log(msg);
    },
    info: (msg) => {
      // eslint-disable-next-line no-console
      if (logLevel >= 3) console.log(msg);
    },
    warn: (msg) => {
      // eslint-disable-next-line no-console
      if (logLevel >= 2) console.warn(msg);
    },
    error: (msg) => {
      // eslint-disable-next-line no-console
      if (logLevel >= 1) console.error(msg);
    },
  };
};

// Client Side IDs are public and safe to commit to the repo
const clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID;

type LDProvider = ({ children }: { children: ReactNode }) => React.JSX.Element;

interface Props {
  children: React.ReactNode;
  withAuth?: boolean;
}

export const FeatureFlagContextProvider: React.FC<Props> = ({
  children,
  withAuth,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { data: userProfile, isLoading: isUserProfileLoading } = getUserProfile(
    { skip: !withAuth }
  );
  const [AppLDProvider, setLDProvider] = useState<LDProvider>();

  const loadLaunchDarkly = async () => {
    setIsLoading(true);

    const loadedProviderComponent = await asyncWithLDProvider({
      clientSideID: clientSideId,

      context: {
        // Start off with anonymous user
        key: userProfile ? `user-${userProfile.id}` : "user-anonymous",
        kind: "user",
      },
      options: {
        logger: getCustomLogger(),
      },
      reactOptions: {
        // Don't automatically convert feature flag keys to camel case. There are confusing issues when we do this. Also makes
        // searching easier if we keep the flags consistent with the backend.
        //
        // https://docs.launchdarkly.com/sdk/client-side/react/react-web#flag-keys-in-the-react-sdk
        useCamelCaseFlagKeys: false,
      },
    });

    // useState accepts a function as a state setting mechanic. Our loadedProviderComponent is a functional component, so we need
    // to wrap it in a function so that it is properly set as the state.
    setLDProvider(() => loadedProviderComponent);
    setIsLoading(false);
  };

  useEffect(() => {
    if (userProfile || !withAuth) {
      loadLaunchDarkly();
    }
  }, [userProfile?.id, withAuth]);

  if (isLoading || (withAuth && isUserProfileLoading))
    return <Loading fullscreen fadeIn />;

  return <AppLDProvider>{children}</AppLDProvider>;
};

export default FeatureFlagContextProvider;
