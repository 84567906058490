import styled from "styled-components";
import {
  colors,
  mediaMax,
  mediaMin,
  shadows,
  transitions,
  zIndex,
} from "utils/theme";

interface Props {
  $isOpen: boolean;
}

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const SidebarInner = styled.div`
  position: relative;

  width: var(--sidebar-width);
  padding: 48px var(--padding-x);

  background: white;
  box-shadow: ${shadows.large};

  ${mediaMax("xl")} {
    --sidebar-width: 280px;
  }
`;

export const Sidebar = styled.div<Props>`
  ${mediaMin("lg")} {
    --sidebar-width: 400px;
    --padding-x: 48px;

    position: sticky;
    top: 0;

    align-self: self-start;
    height: 100vh;
    display: grid;
    width: ${(props) => (props.$isOpen ? "var(--sidebar-width)" : "0px")};
    overflow: hidden;
    flex: 0 0 auto;

    box-shadow: ${shadows.large};

    transition: width ${transitions.default};
    will-change: width;
  }

  @media screen and (max-width: 1440px) {
    --sidebar-width: 320px;
  }

  ${mediaMax("xl")} {
    --padding-x: 24px;
    --sidebar-width: 280px;
  }

  ${mediaMax("lg")} {
    ${SidebarInner} {
      position: fixed;
      bottom: 0;
      left: 0;
      transform: translateY(${(props) => (props.$isOpen ? 0 : "100%")});
      z-index: ${zIndex.modal};

      width: 100%;
      height: 50%;
      overflow: auto;

      transition: transform ${transitions.default};
      will-change: transform;
    }

    &::before {
      content: "";

      position: fixed;
      top: 0;
      left: 0;
      z-index: ${zIndex.modal};

      width: 100%;
      height: 100%;

      background: ${colors.midnight}80;

      transition: transform ${transitions.default};
      will-change: transform;

      pointer-events: none;

      ${(props) =>
        props.$isOpen
          ? ""
          : `
        opacity: 0;
        visibility: hidden;
      `}
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;

  padding: 0;
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;

  appearance: none;
  background: none;
  border: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;
