import UILabel from "components/ui/UILabel";
import React from "react";
import styled, { css } from "styled-components";
import { colors, mediaMin } from "utils/theme";

export const Wrapper = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;

  ${(props) =>
    props.$isMobile &&
    css`
      ${mediaMin("md")} {
        display: none;
      }
    `}
`;

const Detail: React.FC<{
  label: string;
  value: React.ReactNode;
  isMobile?: boolean;
  size?: "lg" | "xl";
}> = ({ label, value, isMobile, size = "lg" }) => (
  <Wrapper $isMobile={isMobile}>
    <UILabel.Micro
      bold
      caps
      color={`color-mix(in srgb, ${colors.midnight} 66%, transparent)`}
    >
      {label}
    </UILabel.Micro>
    {typeof value === "string" ? (
      <UILabel.Base size={size}>{value}</UILabel.Base>
    ) : (
      value
    )}
  </Wrapper>
);

export default Detail;
