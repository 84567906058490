import React from "react";
import App from "App";
import { OnboardingTask } from "types/onboarding";
import Flow from "./Flow";

interface Props {
  tasks: OnboardingTask[];
}

const OnboardingView: React.FC<Props> = ({ tasks }) => (
  <App withAuth>
    <Flow tasks={tasks} />
  </App>
);

export default OnboardingView;
