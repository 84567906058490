import React, { useEffect, useState } from "react";
import Loading from "components/ui/Loading";
import { getUserProfile, usePostVerifyCodeMutation } from "store/api/apiSlice";
import FormContainer from "components/form";
import Main from "./Main";
import Verify from "./Verify";

const Security: React.FC = () => {
  const [
    verifyCode,
    { data: verificationResponse, isLoading: verificationIsLoading },
  ] = usePostVerifyCodeMutation({ fixedCacheKey: "verifyCode" });
  const [isVerifying, setIsVerifying] = useState(false);
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    isFetching: isUserProfileFetching,
    refetch: refetchUserProfile,
  } = getUserProfile();
  const isMfaEnabled = userProfile?.mfaEnabled;

  const onClickToggle = () => {
    setIsVerifying(true);
  };

  const onClickCancel = () => {
    setIsVerifying(false);
  };

  const onSubmitCode = async (values) => {
    await verifyCode(values);
  };

  const updateMfa = async () => {
    await refetchUserProfile();
    setIsVerifying(false);
  };

  useEffect(() => {
    if (!verificationIsLoading && verificationResponse) {
      if (verificationResponse.success) {
        updateMfa();
      }
    }
  }, [verificationResponse, verificationIsLoading]);

  if (isUserProfileLoading) return <Loading />;

  if (isVerifying)
    return (
      <FormContainer
        onSubmit={onSubmitCode}
        defaultValues={{
          otpAttempt: "",
          type: isMfaEnabled ? "disable_mfa" : "enable_mfa",
        }}
      >
        <Verify
          isMfaEnabled={isMfaEnabled}
          onClickCancel={onClickCancel}
          phoneNumber={userProfile?.phoneNumber}
          userId={userProfile?.id}
          isLoading={isUserProfileLoading || isUserProfileFetching}
        />
      </FormContainer>
    );

  return <Main isMfaEnabled={isMfaEnabled} onClickToggle={onClickToggle} />;
};

export default Security;
