import React from "react";
import { BodyLarge, Eyebrow, Heading } from "components/ui/Text";
import appImage from "assets/images/home/dashboard-phone.png";
import appImageMobile from "assets/images/home/dashboard-phone-mobile.png";
import { ButtonLevel, ButtonLink } from "components/ui/Button";
import Amount from "components/shared/AnimatedAmounts";
import * as Styled from "./styled";

interface Props {
  eyebrow: string;
  heading: React.ReactNode;
  body: React.ReactNode;
}

const GetStartedSection: React.FC<Props> = ({ eyebrow, heading, body }) => (
    <Styled.Wrapper>
      <Styled.HeaderContent>
        <Styled.ImageWrapper>
          <Styled.AppImageWrapper>
            <Styled.AppImage src={appImage} />
            <Styled.AppImageMobile src={appImageMobile} />

            <Styled.Total>
              <Amount amounts={[475.25, 545.0, 612.98, 729.5]} />
            </Styled.Total>
            <Styled.LineItems>
              <Amount amounts={[350.44, 395.24, 565.67, 810.55]} />
              <Amount amounts={[315.2, 315.2, 315.2, 315.2]} />
            </Styled.LineItems>
          </Styled.AppImageWrapper>
        </Styled.ImageWrapper>

        <Styled.HeaderText>
          <Eyebrow color="flax">{eyebrow}</Eyebrow>
          <Heading.H1 tag="h2">{heading}</Heading.H1>
          <BodyLarge color="ice">{body}</BodyLarge>

          <Styled.Cta>
            <ButtonLink href="/users/sign_up" level={ButtonLevel.cta}>
              Sign Up
            </ButtonLink>
          </Styled.Cta>
        </Styled.HeaderText>
      </Styled.HeaderContent>
    </Styled.Wrapper>
  );

export default GetStartedSection;
