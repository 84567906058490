import React, { useState } from "react";
import styled from "styled-components";
import {
  getUserProfile,
  usePostInternalMessageMutation,
} from "store/api/apiSlice";
import UILayout from "components/layout/UILayout";
import Notification, { NotificationLevel } from "components/ui/Notification";
import FormContainer from "components/form";
import Loading from "components/ui/Loading";
import { Heading } from "components/ui/Text";
import { capitalize } from "utils/string";
import { mediaMax } from "utils/theme";
import { AccountAction } from "./types";
import Form from "./Form";
import { getActionNoun } from "./utils";

const Wrapper = styled.div`
  display: grid;
  gap: 36px;

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;

const ManageAccount = () => {
  const [action, setAction] = useState<AccountAction | null>(null);
  const [postInternalMessage] = usePostInternalMessageMutation();
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) return <Loading />;

  const onSubmit = async (values) => {
    await postInternalMessage({
      type: `${capitalize(values.action)} Account`,
      body: `Please ${values.action} my account.`,
    });

    setAction(values.action);
  };

  const hasCard = !!userProfile.currentCardAccount;

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Manage your account</Heading.H3>

        {action ? (
          <Notification level={NotificationLevel.success}>
            We received your {getActionNoun(action)} request. Please allow 1-3
            business days for changes to take effect.
          </Notification>
        ) : (
          <FormContainer onSubmit={onSubmit} defaultValues={{ action: null }}>
            <Form
              hasCard={hasCard}
              repaymentMethod={userProfile.repaymentMethod}
            />
          </FormContainer>
        )}
      </Wrapper>
    </UILayout>
  );
};

export default ManageAccount;
