import styled from "styled-components";
import { colors, zIndex } from "utils/theme";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.modal};

  width: 100%;
  height: 100%;

  background: ${colors.midnight};
  opacity: 0.5;
`;

export default ModalOverlay;
