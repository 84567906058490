import React from "react";
import { InfoType } from "./types";

const INFO: {
  [key in InfoType]: { heading?: React.ReactNode; children: React.ReactNode };
} = {
  [InfoType.phone]: {
    heading: "I don't have a US phone number",
    children:
      "Reset currently requires a US phone number for verification purposes.",
  },
  [InfoType.address]: {
    heading: "I don't have a US home address",
    children:
      "Reset currently requires a US home address to verify your identity and mail your Reset card.",
  },
  [InfoType.patriotAct]: {
    heading: "Patriot Act Disclosure",
    children: (
      <>
        <p>
          To help the government fight the funding of terrorism and money
          laundering activities, federal law requires all financial institutions
          to obtain, verify, and record information that identifies each person
          who opens an account.
        </p>
        <p>
          What this means for you: When you open an account, we will ask for
          your name, address, date of birth, and other information that will
          allow us to identify you. We may also ask to see your driver&apos;s
          license or other identifying documents.
        </p>
      </>
    ),
  },
};

export const getInfo = (infoType: InfoType) => INFO[infoType];
