import styled, { css } from "styled-components";
import { Eyebrow as BaseEyebrow } from "components/ui/Text";
import {
  borderRadius,
  colors,
  fontSizes,
  fontSizesMobile,
  fontWeights,
  mediaMax,
  shadows,
} from "utils/theme";
import listCheckImage from "assets/images/icon/list-check.svg";
import leftQuoteImage from "assets/images/icon/left-quote.svg";

export const CtaWrapper = styled.div`
  display: flex;
  padding-top: 24px;

  a,
  button {
    width: auto;
    min-width: 320px;
    max-width: 100%;
  }
`;

export const BodyWrapper = styled.div`
  color: color-mix(in srgb, var(--color) 85%, transparent);

  * {
    color: inherit;
  }

  em,
  strong,
  b,
  i,
  u {
    color: var(--color);

    font-weight: ${fontWeights.bold};
  }

  h3,
  h4,
  h5,
  h6 {
    color: var(--color);

    em,
    strong,
    b,
    i,
    u {
      font-weight: inherit;

      color: var(--color-highlight);
    }
  }

  ul {
    li {
      position: relative;

      padding-left: 40px;

      &::before {
        content: "";

        position: absolute;
        top: -2px;
        left: 0;

        width: 32px;
        height: 32px;

        background-image: url("${listCheckImage}");
        background-size: contain;
      }

      ${mediaMax("lg")} {
        max-width: 360px;
        width: 100%;
        margin: 0 auto;

        text-align: left;
      }
    }
  }

  blockquote {
    position: relative;

    padding: 36px 72px 36px;
    margin: 24px 0;

    color: ${colors.violet};
    box-shadow: ${shadows.tile};
    border-radius: ${borderRadius.small}px;
    background: white;

    font-size: ${fontSizes.sm}px;

    * {
      color: inherit;
    }

    &::before,
    &::after {
      content: "";

      position: absolute;

      width: 24px;
      height: 24px;

      background-image: url("${leftQuoteImage}");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &::before {
      top: 32px;
      left: 36px;
    }

    &::after {
      bottom: 32px;
      right: 36px;
      transform: rotate(180deg);
    }

    & > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${mediaMax("lg")} {
      padding: 36px 0 0;

      background: none;
      box-shadow: none;

      font-size: ${fontSizesMobile.sm}px;

      &::after {
        content: none;
      }

      &::before {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;

export const Eyebrow = styled(BaseEyebrow)`
  color: var(--color-eyebrow);
`;

interface WrapperProps {
  $isCentered?: boolean;
  $isLight?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.$isLight
      ? `
  --color: ${colors.white};
  --color-highlight: ${colors.heliotrope};
  --color-eyebrow: rgba(255, 255, 255, 0.7);`
      : `
  --color: ${colors.midnight};
  --color-highlight: ${colors.violet};
  --color-eyebrow: ${colors.azure};
  `}

  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--color);

  & > * {
    max-width: 768px;
  }

  ${({ $isCentered }) =>
    $isCentered &&
    css`
      align-items: center;
      text-align: center;

      & > * {
        max-width: 768px;
        margin: 0 auto;
      }
    `};

  ${mediaMax("lg")} {
    ${CtaWrapper} {
      justify-content: center;
    }
  }
`;
