import React from "react";
import * as Styled from "./styled";

interface Props {
  children: React.ReactNode;
  sidebar: React.ReactNode;
}

const SidebarLayout: React.FC<Props> = ({ children, sidebar }) => (
  <Styled.Wrapper>
    <Styled.Sidebar>{sidebar}</Styled.Sidebar>
    <Styled.Main>{children}</Styled.Main>
  </Styled.Wrapper>
);

export default SidebarLayout;
