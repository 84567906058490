import styled from "styled-components";
import Tile from "components/ui/Tile";

export const Section = styled(Tile)`
  display: grid;
  gap: 12px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const ItemLabel = styled.div`
  display: grid;
  gap: 4px;
`;

export const ItemAction = styled.div`
  flex: 0 0 auto;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;
