import React from "react";
import { WysiwygBlock } from "types/cms";
import * as Styled from "./styled";
import CtaButton from "../../CtaButton";
import Header from "../../Header";

const Wysiwyg: React.FC<WysiwygBlock> = ({
  isHero,
  isReverse,
  data: { eyebrow, heading, body, textSize, cta },
}) => (
  <Styled.Wrapper>
    {(eyebrow || heading || body) && (
      <Header
        eyebrow={eyebrow}
        heading={heading}
        body={body}
        isHero={isHero}
        textSize={textSize}
        isLight={isReverse}
      />
    )}
    {cta && (
      <Styled.CtaWrapper>
        <CtaButton {...cta} />
      </Styled.CtaWrapper>
    )}
  </Styled.Wrapper>
);

export default Wysiwyg;
