import React from "react";
import SearchInput, { SearchInputProps } from "components/shared/SearchInput";
import FieldContainer from "../FieldContainer";

interface Props extends SearchInputProps {
  label?: React.ReactNode;
}

const InputField: React.FC<Props> = ({ name, label, ...props }) => (
  <FieldContainer name={name} label={label}>
    {({ inputProps, error, setError, clearErrors }) => (
      <SearchInput
        name={name}
        hasError={!!error}
        setError={setError}
        clearErrors={clearErrors}
        {...inputProps}
        {...props}
      />
    )}
  </FieldContainer>
);

export default InputField;
