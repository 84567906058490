import React from "react";
import styled from "styled-components";
import { mediaMax } from "utils/theme";

interface Props {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 576px;
  margin: 0 auto;
  padding: 0 16px;

  ${mediaMax("lg")} {
    padding: 12px;
  }
`;

const UILayout: React.FC<Props> = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);

export default UILayout;
