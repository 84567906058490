import React from "react";
import styled from "styled-components";
import { colors, fontWeights } from "utils/theme";
import UILabel from "components/ui/UILabel";

const Wrapper = styled.div`
  padding: 12px 16px;

  border-radius: 8px;
  color: ${colors.violet}aa;
  background: ${colors.azure}11;

  &:not(:first-child) {
    margin-top: 24px;
  }

  a {
    color: ${colors.midnight};

    font-weight: ${fontWeights.bold};
  }
`;

interface Props {
  children: React.ReactNode;
}

const HelpMessage: React.FC<Props> = ({ children, ...props }) => (
  <Wrapper {...props}>
    <UILabel.Medium>{children}</UILabel.Medium>
  </Wrapper>
);

export default HelpMessage;
