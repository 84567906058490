import React from "react";
import UILabel from "components/ui/UILabel";
import MenuItem from "./MenuItem";
import * as Styled from "./styled";
import { MenuSection } from "./types";

interface Props {
  sections: MenuSection[];
}

const Menu: React.FC<Props> = ({ sections }) => {
  if (!sections.length) return null;

  return (
    <Styled.Menu>
      {sections.map((section) => (
        <Styled.MenuSection key={section.title}>
          {section.title && <UILabel.Large bold>{section.title}</UILabel.Large>}

          <Styled.MenuSectionItems>
            {section.items.map((item) => (
              <Styled.MenuItemWrapper key={item.children.toString()}>
                <MenuItem {...item} />
              </Styled.MenuItemWrapper>
            ))}
          </Styled.MenuSectionItems>
        </Styled.MenuSection>
      ))}
    </Styled.Menu>
  );
};

export default Menu;
