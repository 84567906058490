import React, { useRef } from "react";
import { Heading } from "components/ui/Text";
import { ApiAdminUser } from "utils/api/admin/user";
import { formatDateString } from "utils/date";
import {
  getAdminUserUnresolvedIntegrationErrors,
  usePostAdminIntegrationErrorResolveMutation,
} from "store/admin/api/apiSlice";
import { snakeCaseToTitleCase } from "utils/string";
import Loading from "components/ui/Loading";
import ConfirmActionWithValidationModal from "components/modal/ConfirmActionWithValidationModal";
import { ModalApi } from "components/modal/Modal";
import { ApiAdminIntegrationError } from "utils/api/admin/integrationError";
import { ButtonLevel } from "components/ui/Button";
import Table from "../../components/Table";
import * as Styled from "./styled";

const HEADINGS = ["Date", "Error Type", "Source"];

interface Props {
  user: ApiAdminUser;
}

const UnresolvedIntegrationErrors: React.FC<Props> = ({ user }) => {
  const resolveIntegrationErrorModalRef = useRef<ModalApi>(null);
  const [postAdminIntegrationErrorResolve] =
    usePostAdminIntegrationErrorResolveMutation();
  const { data, isLoading } = getAdminUserUnresolvedIntegrationErrors(
    user.id,
    {}
  );

  let integrationErrorToResolve: ApiAdminIntegrationError | null = null;

  const onClickRow = (integrationError) => {
    integrationErrorToResolve = integrationError;
    resolveIntegrationErrorModalRef.current?.show();
  };

  const resolveIntegrationError = async () => {
    await postAdminIntegrationErrorResolve(integrationErrorToResolve.id);
  };

  if (isLoading) return <Loading />;

  const { integrationErrors } = data || {};

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Unresolved Integration Errors</Heading.H4>

      <Table>
        <thead>
          <tr>
            {HEADINGS.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {integrationErrors?.map((integrationError, index) => (
            <tr
              key={integrationError.id}
              data-heading={HEADINGS[index]}
              onClick={() => onClickRow(integrationError)}
            >
              <td>
                {formatDateString(
                  integrationError.createdAt,
                  "numericShortWithTime"
                )}
              </td>
              <td>{snakeCaseToTitleCase(integrationError.errorType)}</td>
              <td>{snakeCaseToTitleCase(integrationError.source)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ConfirmActionWithValidationModal
        ref={resolveIntegrationErrorModalRef}
        title="Manually Resolve Integration Error"
        action={resolveIntegrationError}
        actionLabel={`Resolve`}
        validationString={`resolve`}
        buttonLevel={ButtonLevel.danger}
      />
    </Styled.SectionTile>
  );
};

export default UnresolvedIntegrationErrors;
