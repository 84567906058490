import React from "react";
import UILabel from "components/ui/UILabel";
import { ButtonLink, ButtonRouterLink, ButtonSize } from "components/ui/Button";
import { colors } from "utils/theme";
import { ApiUserProfile } from "utils/api/user";
import SliderTile from "components/ui/SliderTile";
import LightbulbIcon from "components/ui/Icon/LightbulbIcon";
import * as Styled from "./styled";

const IntroSlide = () => (
  <UILabel.Medium color="violet">
    The spending limit you see here is the same spending limit you will have on
    the Reset card.
  </UILabel.Medium>
);

const ProbationSlide = () => (
  <UILabel.Medium color="violet">
    After we receive your first direct deposit, your spending limit unlocks and
    will adjust daily based on your income, expenses, and other factors.
  </UILabel.Medium>
);

interface Props {
  userProfile?: ApiUserProfile;
  toggleMaxDslDisplay: (showMax: boolean) => void;
}

const DslInfo: React.FC<Props> = ({ userProfile, toggleMaxDslDisplay }) => {
  const cta = userProfile?.eligibleForCard ? (
    <ButtonLink size={ButtonSize.md} href="/apply?skip_intro=true">
      Apply Now
    </ButtonLink>
  ) : (
    <ButtonRouterLink size={ButtonSize.md} to="/card-transactions">
      About the Reset Card
    </ButtonRouterLink>
  );

  const slides = [<IntroSlide />];
  if (userProfile?.enableProbationaryLimit) {
    slides.push(<ProbationSlide />);
  }

  const onChangeSlide = (slide: number) => {
    toggleMaxDslDisplay(slide === 1);
  };

  return (
    <Styled.Wrapper>
      <Styled.TileWrapper>
        <SliderTile
          header={
            <Styled.Header>
              <LightbulbIcon />

              <UILabel.Small bold wideCaps color={`${colors.midnight}CC`}>
                How it works
              </UILabel.Small>
            </Styled.Header>
          }
          slides={slides}
          buttonText="Learn More"
          cta={cta}
          onChange={onChangeSlide}
        />
      </Styled.TileWrapper>
    </Styled.Wrapper>
  );
};

export default DslInfo;
