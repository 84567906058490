import React from "react";
import { Heading } from "components/ui/Text";
import appImage from "assets/images/home/dashboard-phone.png";
import appImageMobile from "assets/images/home/dashboard-phone-mobile.png";
import { ButtonLevel, ButtonLink } from "components/ui/Button";
import Amount from "components/shared/AnimatedAmounts";
import * as Styled from "./styled";
import { APP_HIGHLIGHT } from "../../constants";

interface Props {
  hasProbationaryPeriod: boolean;
}

const Features: React.FC<Props> = ({ hasProbationaryPeriod }) => {
  const { heading, body, disclaimer } = APP_HIGHLIGHT;

  return (
    <Styled.Wrapper>
      <Styled.HeaderContent>
        <Styled.HeaderText>
          <Heading.H1 tag="h2">{heading}</Heading.H1>
          <Styled.Body>{body}</Styled.Body>

          <Styled.Cta>
            <ButtonLink href="/users/sign_up" level={ButtonLevel.cta}>
              Get Started
            </ButtonLink>
          </Styled.Cta>

          {hasProbationaryPeriod && (
            <Styled.BodySmall>{disclaimer}</Styled.BodySmall>
          )}
        </Styled.HeaderText>

        <Styled.ImageWrapper>
          <Styled.AppImageWrapper>
            <Styled.AppImage src={appImage} />
            <Styled.AppImageMobile src={appImageMobile} />

            <Styled.Total>
              <Amount amounts={[475.25, 545.0, 612.98, 729.5]} />
            </Styled.Total>
            <Styled.LineItems>
              <Amount amounts={[350.44, 395.24, 565.67, 810.55]} />
              <Amount amounts={[315.2, 315.2, 315.2, 315.2]} />
            </Styled.LineItems>
          </Styled.AppImageWrapper>
        </Styled.ImageWrapper>
      </Styled.HeaderContent>
    </Styled.Wrapper>
  );
};

export default Features;
