import React from "react";
import styled from "styled-components";
import { colors, fontSizes, fontWeights } from "utils/theme";

const StyledLabel = styled.label`
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.xs}px;

  a {
    font-weight: ${fontWeights.bold};
    text-decoration: underline;

    &,
    &:visited {
      color: ${colors.midnight};
    }
  }
`;

interface Props {
  htmlFor?: string;
  children: React.ReactNode;
}

const Label: React.FC<Props> = ({ htmlFor, children, ...props }) => (
  <StyledLabel htmlFor={htmlFor} {...props}>
    {children}
  </StyledLabel>
);

export default Label;
