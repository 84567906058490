import { useEffect } from "react";
import storeSignupParams from "./storeSignupParams";
import storePartnerParam from "./storePartnerParam";
import cleanupUrlParams from "./cleanupUrlParams";

const Scripts = () => {
  useEffect(() => {
    // each storage function should return an array of keys it ingested
    const signupKeys = storeSignupParams();
    const partnerKeys = storePartnerParam();
    // ingested params are removed from the URL query string
    cleanupUrlParams([...signupKeys, ...partnerKeys]);
  }, []);

  return null;
};

export default Scripts;
