import React from "react";
import styled from "styled-components";
import Loading from "components/ui/Loading";
import { getStatements } from "store/api/apiSlice";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import DocumentList from "components/shared/DocumentList";
import {
  ResetDocument,
  ResetDocumentType,
} from "components/shared/DocumentList/types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const List: React.FC = () => {
  const { data: statements, isLoading } = getStatements();

  if (isLoading) return <Loading />;
  if (!statements) return null;

  const statementList = statements.map((statement) => ({
    title: new Date(statement.issueDate).toLocaleDateString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }),
    type: ResetDocumentType.Statement,
    data: { id: statement.id },
  })) as ResetDocument[];

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Deposit account statements</Heading.H3>

        <DocumentList documents={statementList} />
      </Wrapper>
    </UILayout>
  );
};

export default List;
