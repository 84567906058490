import styled from "styled-components";
import Tile from "components/ui/Tile";
import { borderRadius, colors, mediaMax } from "utils/theme";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  padding-top: 24px;
  padding-bottom: 24px;

  ${mediaMax("lg")} {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  ${mediaMax("md")} {
    padding: 8px 16px;

    border-radius: ${borderRadius.small}px;
  }
`;

export const LineItemWrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.midnight}11;
  }
`;
