import React from "react";
import { openWindow } from "utils/window";
import { LabelNode, LinkNode, NodeType } from "./types";

const Link: React.FC<LinkNode> = ({ text, data }) => {
  const onClick = (e) => {
    e.preventDefault();
    openWindow(e.target.href);
  };

  return (
    <a href={data.href} target="_blank" onClick={onClick}>
      {text}
    </a>
  );
};

const Node = (node: LabelNode) => {
  switch (node.type) {
    case NodeType.text:
      return <>{node.text}</>;
    case NodeType.link:
      return <Link {...node} />;
    default:
      return null;
  }
};

const Label: React.FC<{ nodes: LabelNode[] }> = ({ nodes }) => (
  <div>
    {nodes.map((node) => (
      <Node key={node.text} {...node} />
    ))}
  </div>
);

export default Label;
