import React, { useEffect, useState } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import Tabs from "components/ui/Tabs";
import * as Styled from "./styled";

const VARIANTS = {
  enter: (direction: number) => ({
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    x: `${direction * 100}%`,
  }),
  center: {
    position: "relative",
    opacity: 1,
    x: 0,
  },
  exit: (direction: number) => ({
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    x: `${-direction * 100}%`,
  }),
} as Variants;

const SWIPE_CONFIDENCE_THRESHOLD = 10000; // combine offset and velocity to determine intention to swipe
const swipePower = (offset: number, velocity: number) =>
  Math.abs(offset) * velocity;

export interface Tab {
  id: string;
  label: React.ReactNode;
  component: React.ReactNode;
}

interface Props {
  tabs: Tab[];
  onChange?: (tab: Tab) => void;
  activeId?: string;
  tabWidth?: number;
}

const TabbedCarousel: React.FC<Props> = ({
  tabs,
  activeId,
  onChange,
  tabWidth,
}) => {
  const [currentTab, setCurrentTab] = useState(
    tabs.find((tab) => tab.id === activeId) || tabs[0]
  );
  const [transitionDirection, setTransitionDirection] = useState(-1);
  const [disabled, setDisabled] = useState(false);

  const updateTab = async (tab) => {
    if (tab.id === currentTab.id) return;
    const tabVector = tabs.indexOf(tab) - tabs.indexOf(currentTab);
    setTransitionDirection(tabVector / Math.abs(tabVector));
    await setTimeout(() => {}, 0); // allow exit animation to use updated direction
    setCurrentTab(tab);
    if (onChange) {
      onChange(tab);
    }
  };

  const onClickTab = (id: string) => {
    let newTab;
    if (currentTab.id === id) {
      if (tabs.length === 2) {
        // toggle between tabs when only two
        newTab = tabs.find((t) => t.id !== id);
      } else return;
    } else {
      newTab = tabs.find((t) => t.id === id);
    }

    updateTab(newTab);
  };

  const navigate = (direction: number) => {
    const currentIndex = tabs.findIndex((tab) => tab.id === currentTab.id);
    if (currentIndex === 0 && direction === -1) return;
    if (currentIndex === tabs.length - 1 && direction === 1) return;
    const newTabIndex = (currentIndex + direction) % tabs.length;
    updateTab(tabs[newTabIndex]);
  };

  const onDragEnd = (_, { offset, velocity }) => {
    const swipe = swipePower(offset.x, velocity.x);

    if (swipe < -SWIPE_CONFIDENCE_THRESHOLD) {
      navigate(1);
    } else if (swipe > SWIPE_CONFIDENCE_THRESHOLD) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (activeId) {
      const newTab = tabs.find((tab) => tab.id === activeId);
      updateTab(newTab);
    }
  }, [activeId]);

  return (
    <Styled.Wrapper>
      <Styled.TabsWrapper>
        <Tabs
          tabs={tabs}
          onClick={onClickTab}
          activeId={currentTab.id}
          tabWidth={tabWidth}
          disabled={disabled}
        />
      </Styled.TabsWrapper>

      <Styled.CarouselWrapper>
        <AnimatePresence initial={false}>
          <motion.div
            key={currentTab.id}
            custom={transitionDirection}
            variants={VARIANTS}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              duration: 0.2,
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={onDragEnd}
            onAnimationStart={() => setDisabled(true)}
            onAnimationComplete={() => setDisabled(false)}
          >
            {currentTab.component}
          </motion.div>
        </AnimatePresence>
      </Styled.CarouselWrapper>
    </Styled.Wrapper>
  );
};

export default TabbedCarousel;
