import styled from "styled-components";
import Icon from "components/ui/Icon";
import { breakpoints, colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  padding: 88px 0 140px;
  min-height: 100vh;
  overflow: hidden;

  background: ${colors.midnight};

  ${mediaMin("md")} {
    padding: 96px 0 140px;
  }

  ${mediaMin("lg")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 152px 0 200px;
  }
`;

export const Navigation = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  margin: 0 auto;

  @media screen and (min-device-aspect-ratio: 2/3) {
    flex-direction: column-reverse;
  }

  ${mediaMin("md")} {
    padding: 0 24px;
    gap: 36px;
    max-width: ${breakpoints.md}px;
  }

  ${mediaMin("lg")} {
    display: grid;
    max-width: ${breakpoints.lg}px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 64px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  color: white;
`;

export const GetStartedButton = styled.div`
  button {
    position: relative;

    width: 100%;

    background: ${colors.azure};
  }

  ${mediaMin("lg")} {
    padding-top: 24px;
  }
`;

export const ButtonIcon = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  * {
    stroke-width: 20px;
    stroke: currentColor;
  }
`;

export const ImageWrapper = styled.div`
  ${mediaMin("lg")} {
    padding-right: 24px;
  }
`;

export const Image = styled.div`
  position: relative;

  padding-bottom: 75%;

  img {
    position: absolute;

    height: 100%;
    width: 100%;

    border-radius: 16px;
    object-fit: cover;
    object-position: center;
  }

  ${mediaMin("lg")} {
    padding-bottom: 100%;

    img {
      border-radius: 9999px;
    }
  }
`;

export const Shape = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top;
  }

  ${mediaMax("lg")} {
    display: none;
  }
`;
