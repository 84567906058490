import React from "react";
import styled from "styled-components";
import { Address } from "utils/api/address";
import Line from "./Line";

const Wrapper = styled.div`
  display: grid;
  gap: 24px;
`;

const LINES = [
  "name",
  "email",
  "phoneNumber",
  "dateOfBirth",
  "address",
  "currentAddress",
  "ssn",
];

const getLineItems = (info: { [key: string]: any }) =>
  LINES.filter((name) => info[name]).map((name) => ({
    name,
    value: info[name],
  }));

export interface Info {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  dateOfBirth?: string;
  address?: Address;
  currentAddress?: Address;
  ssn?: string;
}

interface Props {
  info: Info;
}

const PersonalInfo: React.FC<Props> = ({
  info: { firstName, lastName, ...otherInfo },
}) => {
  const lines = getLineItems({
    ...otherInfo,
    name: firstName || lastName ? `${firstName} ${lastName}` : "n/a",
  });
  return (
    <Wrapper>
      {lines.map((line, index) => (
        <Line key={index} {...line} />
      ))}
    </Wrapper>
  );
};

export default PersonalInfo;
