import styled, { css } from "styled-components";
import { borderRadius, colors, mediaMax } from "utils/theme";
import UILabel from "components/ui/UILabel";
import Tile from "components/ui/Tile";

const DAY_STYLES = css`
  width: 44px;
  flex: 0 0 auto;

  ${mediaMax("md")} {
    width: 32px;
  }
`;

const AMOUNT_STYLES = css`
  width: 88px;
  flex: 0 0 auto;

  text-align: right;

  ${mediaMax("md")} {
    width: 72px;
  }
`;

const SMALL_MEDIA = "@media screen and (max-width: 360px)";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${mediaMax("md")} {
    padding: 16px;

    border-radius: ${borderRadius.small}px;
  }
`;

export const Header = styled.header`
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  margin: -12px;

  background: ${colors.azure}19;
  border-radius: 9999px;
  color: ${colors.violet}BB;

  ${mediaMax("md")} {
    margin: -16px -8px;
    padding: 16px 8px;

    background: none;
    border-bottom: 1px solid ${colors.midnight}11;
    border-radius: ${borderRadius.small}px ${borderRadius.small}px 0 0;
  }
`;

export const HeaderLabel = styled(UILabel.Medium).attrs(() => ({
  bold: true,
}))`
  &:not(:first-child) {
    position: relative;

    &::before {
      content: "";

      position: absolute;
      top: 0;
      left: -12px;

      width: 1.5px;
      height: 100%;

      background: ${colors.azure}20;
    }
  }

  ${mediaMax("md")} {
    font-size: 12px;
  }
`;

export const DescriptionLabel = styled(HeaderLabel)`
  flex: 1 1 auto;
  padding-left: 52px;

  ${SMALL_MEDIA} {
    padding-left: 0;
  }
`;

export const DayLabel = styled(HeaderLabel)`
  ${DAY_STYLES}
`;

export const AmountLabel = styled(HeaderLabel)`
  ${AMOUNT_STYLES}
`;

export const LineItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
  margin: 0 auto -26px;
  width: 100%;
  padding: 0;

  list-style-type: none;

  ${mediaMax("md")} {
    margin-bottom: -12px;
  }
`;

export const LineItem = styled.li`
  position: relative;

  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 12px;

  &:not(:last-child) {
    &::after {
      content: "";

      position: absolute;
      bottom: -1px;
      left: 0;

      width: 100%;
      height: 1px;

      background: ${colors.midnight}11;
    }
  }

  ${mediaMax("md")} {
    align-items: flex-start;
  }
`;

export const ItemMain = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 1 auto;

  ${mediaMax("md")} {
    align-items: flex-start;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  flex: 0 0 auto;

  border-radius: 50%;
  background: ${colors.azure}19;

  ${SMALL_MEDIA} {
    display: none;
  }
`;

export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ItemDay = styled(UILabel.Large)`
  ${DAY_STYLES};

  margin-top: -3px;

  sup {
    transform: translateY(2px);

    display: inline-block;

    * {
      display: inline;
    }
  }
`;

export const ItemAmount = styled(UILabel.Large).attrs(() => ({
  color: colors.violet,
}))`
  ${AMOUNT_STYLES};
`;
