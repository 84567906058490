import React from "react";
import logomark from "assets/images/general/logomark-reverse.svg";
import UILabel from "components/ui/UILabel";
import * as Styled from "./styled";

const Notification = () => (
  <Styled.NotificationTile>
    <Styled.NotificationIcon>
      <img src={logomark} />
    </Styled.NotificationIcon>

    <Styled.NotificationText>
      <UILabel.Large bold>Daily Earnings Update 🎉</UILabel.Large>
      <UILabel.Medium color="dust">
        Check out your new spending limit
      </UILabel.Medium>
    </Styled.NotificationText>
  </Styled.NotificationTile>
);

export default Notification;
