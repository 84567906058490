import styled from "styled-components";
import Tile from "components/ui/Tile";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const Banner = styled(Tile)`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 36px;
  max-width: 544px;
  margin: 0 auto;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Body = styled(UILabel.Medium)`
  opacity: 0.7;
`;

export const Footer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    color: ${colors.midnight};
  }
`;

const ShapesContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;

  height: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ShapesLeft = styled(ShapesContainer)`
  right: 100%;
`;

export const ShapesRight = styled(ShapesContainer)`
  left: 100%;
`;

const Shape = styled.div`
  left: 0;
  transform: translateX(-50%);
  position: absolute;

  border-radius: 9999px;

  &::after {
    content: "";

    display: block;
    padding-bottom: 100%;
  }
`;

export const Shape1 = styled(Shape)`
  top: -24px;

  width: 80px;

  background: linear-gradient(135deg, ${colors.heliotrope}, ${colors.azure});
`;

export const Shape2 = styled(Shape)`
  top: 72px;
  left: -72px;

  width: 40px;

  background: linear-gradient(
    135deg,
    ${colors.heliotrope} 20%,
    ${colors.azure} 200%
  );
`;

export const Shape3 = styled(Shape)`
  top: 64px;

  width: 56px;

  background: linear-gradient(
    90deg,
    ${colors.heliotrope} -50%,
    ${colors.azure} 150%
  );
`;

export const Shape4 = styled(Shape)`
  top: 80px;
  left: 72px;

  width: 16px;

  background: ${colors.heliotrope};
`;

export const Shape5 = styled(Shape)`
  top: 152px;
  left: 64px;

  width: 24px;

  background: linear-gradient(
    135deg,
    ${colors.heliotrope},
    ${colors.azure} 150%
  );
`;

export const Icon1 = styled.div`
  position: absolute;
  top: 144px;
  right: 36px;
`;

export const Icon2 = styled.div`
  position: absolute;
  top: 12px;
  left: 28px;
`;
