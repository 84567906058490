import React, { useEffect, useState } from "react";
import { AnimatePresence, Variants } from "framer-motion";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import SurveyProgressHeader from "components/ui/SurveyProgressHeader";
import { useFormContext } from "react-hook-form";
import Header from "../Header";
import { PROMPTS } from "./constants";
import * as Styled from "./styled";

const VARIANTS = {
  enter: (direction: number) => ({
    position: "absolute",
    zIndex: 1,
    x: `${direction * 100}%`,
    opacity: 0,
  }),
  center: {
    position: "relative",
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    position: "absolute",
    x: `${-direction * 100}%`,
    opacity: 0,
  }),
} as Variants;

const Questionnaire = () => {
  const [currentPromptIndex, setCurrentPromptIndex] = useState(0);
  const [transitionDirection, setTransitionDirection] = useState(-1);
  const [isCurrentPromptValid, setIsCurrentPromptValid] = useState(false);
  const { formState } = useFormContext();

  const { setValue } = useFormContext();

  const { title, icon, text, Form } = PROMPTS[currentPromptIndex];

  const updateTransitionDirection = async (direction: number) => {
    setTransitionDirection(direction);
    await setTimeout(() => {}, 0);
  };

  const updateValidation = (isValid: boolean) => {
    setIsCurrentPromptValid(isValid);
    setValue(`isValid.${currentPromptIndex}`, isValid);
  };

  const goToPrevious = async () => {
    if (currentPromptIndex === 0) return;

    setIsCurrentPromptValid(false);

    await updateTransitionDirection(-1);
    setCurrentPromptIndex((i) => i - 1);
  };

  const goToNext = async () => {
    setIsCurrentPromptValid(false);

    await updateTransitionDirection(1);

    setCurrentPromptIndex((i) => i + 1);
  };

  useEffect(() => {
    if (formState.isSubmitting && isCurrentPromptValid) {
      if (currentPromptIndex < PROMPTS.length - 1) {
        goToNext();
      }
    }
  }, [formState.isSubmitting]);

  return (
    <Styled.Wrapper>
      <Header />

      <Styled.ContentWrapper>
        <SurveyProgressHeader
          icon={icon}
          title={title}
          stepCount={PROMPTS.length}
          currentStep={currentPromptIndex}
        />

        <Styled.PromptCarousel>
          <AnimatePresence initial={false} mode="sync">
            <Styled.PromptWrapper
              custom={transitionDirection}
              variants={VARIANTS}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30,
                duration: 0.2,
              }}
              key={currentPromptIndex}
            >
              <Styled.Prompt>{text}</Styled.Prompt>

              <Form updateValidation={updateValidation} />
            </Styled.PromptWrapper>
          </AnimatePresence>
        </Styled.PromptCarousel>
      </Styled.ContentWrapper>

      <Styled.Footer>
        <Styled.FooterInner>
          {currentPromptIndex > 0 && (
            <Styled.BackButton
              type="button"
              level={ButtonLevel.ghost}
              onClick={goToPrevious}
              size={ButtonSize.xl}
            >
              <Styled.ButtonIcon name="arrowLeft" />
              Back
            </Styled.BackButton>
          )}

          {currentPromptIndex < PROMPTS.length - 1 ? (
            <Styled.NextButton
              type="button"
              level={ButtonLevel.cta}
              size={ButtonSize.xl}
              onClick={goToNext}
              disabled={!isCurrentPromptValid}
            >
              Next
              <Styled.ButtonIcon name="arrowRight" />
            </Styled.NextButton>
          ) : (
            <Styled.FinishButton
              size={ButtonSize.xl}
              disabled={!isCurrentPromptValid}
            >
              Finish
              <Styled.ButtonIcon name="arrowRight" />
            </Styled.FinishButton>
          )}
        </Styled.FooterInner>
      </Styled.Footer>
    </Styled.Wrapper>
  );
};

export default Questionnaire;
