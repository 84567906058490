import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";

export const BackgroundShape = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  height: 100%;
  overflow: hidden;

  pointer-events: none;

  svg {
    flex: 0 0 auto;
    width: 100%;
    min-width: 1440px;

    * {
      fill: var(--color-background);
    }
  }
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  max-width: 768px;
  padding: 72px 16px 200px;
  margin: 0 auto;

  color: var(--color);

  text-align: center;

  sup {
    color: color-mix(in srgb, var(--color) 50%, transparent);

    line-height: 1;
    font-size: 0.66em;
  }
`;

interface WrapperProps {
  $theme?: "default" | "light";
}

const getStyles = ({ $theme = "default" }: WrapperProps) => {
  const styles = [];
  switch ($theme) {
    case "light":
      styles.push(`
        background: color-mix(in srgb, ${colors.azure} 10%, ${colors.ice});
      `);
      break;
    case "default":
    default:
      styles.push(`
        background: linear-gradient(75deg, ${colors.heliotrope}, ${colors.azure});
      `);
  }
  return styles.join("");
};

export const Wrapper = styled.header<WrapperProps>`
  position: relative;

  ${(props) => getStyles(props)}

  & + * {
    margin-top: -64px !important;
  }

  ${mediaMax("lg")} {
    ${Content} {
      padding: 80px 16px 120px;
    }

    ${BackgroundShape} {
      svg {
        min-width: 920px;
      }
    }

    & + * {
      margin-top: -32px !important;
    }
  }

  ${mediaMax("md")} {
    ${Content} {
      padding: 64px 16px 80px;
    }

    ${BackgroundShape} {
      svg {
        min-width: 480px;
      }
    }
  }
`;
