import { Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import styled from "styled-components";
import {
  borderRadius,
  colors,
  fontSizesUI,
  fontWeights,
  mediaMax,
  mediaMin,
} from "utils/theme";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  font-size: ${fontSizesUI.md}px;

  ${mediaMax("lg")} {
    box-shadow: none;
    border-radius: 0;

    font-size: ${fontSizesUI.sm}px;
  }
`;

export const LogoWrapper = styled.div`
  height: 24px;

  svg {
    height: 100%;
  }
`;

export const InfoHeading = styled(Heading.H4)`
  grid-column: span 2;
  padding-bottom: 8px;
`;

export const SectionHeading = styled.div`
  font-size: ${fontSizesUI.lg}px;
  font-weight: ${fontWeights.bold};

  ${mediaMax("lg")} {
    font-size: ${fontSizesUI.md}px;
  }
`;

export const Header = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  align-items: start;

  ${mediaMin("md")} {
    grid-template-columns: 1fr 2fr;
  }

  ${InfoHeading} {
    border-bottom: 1px solid ${colors.azure}22;
  }
`;

export const InfoList = styled.div`
  display: grid;
  align-items: start;
  gap: 16px;
`;

export const Info = styled.div`
  display: grid;
  align-items: start;
  gap: 4px;
`;

export const StatementInfo = styled.div`
  display: grid;
  gap: 12px;
  align-items: start;
  width: 100%;
  padding: 24px;

  background: ${colors.azure}11;
  border-radius: ${borderRadius.small}px;

  ${mediaMin("lg")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Details = styled.div``;

export const Disclosure = styled.div`
  width: 100%;

  pre {
    font-family: inherit;
    white-space: wrap;
  }
`;
