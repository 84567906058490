import React from "react";
import { ButtonSize } from "components/ui/Button";
import Icon, { IconName } from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { IncomeType } from "types/signup";
import { colors } from "utils/theme";
import * as Styled from "./styled";

const INCOME_TYPES: { label: string; icon: IconName; value: IncomeType }[] = [
  {
    label: "Salaries",
    icon: "briefcase",
    value: IncomeType.salary,
  },
  {
    label: "Hourly wages",
    icon: "clock",
    value: IncomeType.hourly,
  },
  { label: "Gig work", icon: "car", value: IncomeType.gig },
  {
    label: "Government benefits",
    icon: "bank",
    value: IncomeType.benefits,
  },
];

const Body = () => (
  <Styled.BodyWrapper>
    <div>
      It&apos;s your money, just sooner and without the fees. Reset does not
      charge interest, monthly fees, instant transfer fees, or late fees.
    </div>

    <Styled.IncomeTypes>
      <UILabel.Small caps bold color={`${colors.flax} !important`}>
        Access income from
      </UILabel.Small>

      <Styled.IncomeTypeList>
        {INCOME_TYPES.map((incomeType) => (
          <Styled.IncomeTypeItem key={incomeType.label}>
            <Styled.IncomeTypeLink
              size={ButtonSize.lg}
              href={`/users/sign_up?incomeType=${incomeType.value}`}
            >
              <Styled.IncomeTypeLinkContent>
                <Icon size="md" name={incomeType.icon} />

                <span>{incomeType.label}</span>
              </Styled.IncomeTypeLinkContent>
            </Styled.IncomeTypeLink>
          </Styled.IncomeTypeItem>
        ))}
      </Styled.IncomeTypeList>
    </Styled.IncomeTypes>
  </Styled.BodyWrapper>
);

export default Body;
