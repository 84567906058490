import React from "react";
import UILayout from "components/layout/UILayout";
import ExpensesList from "./components/ExpensesList";

const UpcomingBillsView: React.FC = () => (
  <UILayout>
    <ExpensesList />
  </UILayout>
);

export default UpcomingBillsView;
