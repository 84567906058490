import React, { useRef } from "react";
import styled from "styled-components";
import PinwheelModal, {
  ModalApi as PinwheelModalApi,
} from "components/shared/PinwheelModal";
import PlaidModal, {
  ModalApi as PlaidModalApi,
} from "components/shared/PlaidModal";
import {
  ApiNotification,
  ApiNotificationErrorType,
  ApiNotificationSource,
  getIntegrationErrors,
} from "utils/api/notifications";
import NotificationsListItem from "./NotificationsListItem";

const Notifications = styled.ul`
  display: grid;
  gap: 8px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  & > li {
    display: block;
    width: 100%;
  }
`;

interface Props {
  notifications?: ApiNotification[];
  onSuccess?: () => void;
}

const NotificationsList: React.FC<Props> = ({ notifications, onSuccess }) => {
  const pinwheelModalRef = useRef<PinwheelModalApi>(null);
  const plaidModalRef = useRef<PlaidModalApi>(null);

  const pinwheelRelinkHandler = () => {
    if (pinwheelModalRef && pinwheelModalRef.current) {
      pinwheelModalRef.current.open();
    }
  };

  const plaidRelinkHandler = () => {
    if (plaidModalRef && plaidModalRef.current) {
      plaidModalRef.current.open();
    }
  };

  const mapNotificationToHandler = async (notification: ApiNotification) => {
    if (
      notification.errorType ===
      ApiNotificationErrorType.monitoring_status_unavailable
    ) {
      switch (notification.source) {
        case ApiNotificationSource.pinwheel: {
          pinwheelRelinkHandler();
          break;
        }
        case ApiNotificationSource.plaid: {
          plaidRelinkHandler();
          break;
        }
        default:
          break;
      }
    }
  };

  const onRelinkSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  // [RFH-762] Keep only notifications that currently have UI. Currently only integration errors have UI.
  const notificationsWithUi = getIntegrationErrors(notifications || []);

  return (
    <>
      {notificationsWithUi?.length > 0 ? (
        <Notifications>
          {notificationsWithUi.map((notification) => (
            <NotificationsListItem
              notification={notification}
              key={notification.id}
              onClick={(e) => {
                e.preventDefault();
                mapNotificationToHandler(notification);
              }}
            />
          ))}
        </Notifications>
      ) : null}

      <PinwheelModal ref={pinwheelModalRef} onSuccess={onRelinkSuccess} />

      <PlaidModal ref={plaidModalRef} onSuccess={onRelinkSuccess} />
    </>
  );
};

export default NotificationsList;
