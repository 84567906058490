import React from "react";
import { AnimatePresence } from "framer-motion";
import { formatDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import Detail from "components/ui/Detail";
import StatusIcon from "components/ui/StatusIcon";
import * as Styled from "./styled";

const LineItemMain = (props) =>
  props.onClick ? (
    <Styled.LineItemMainButton {...props} />
  ) : (
    <Styled.LineItemMain {...props} />
  );

export interface LineItemDetail {
  label: string;
  value: React.ReactNode;
  isMobile?: boolean;
}

interface Props {
  title?: string;
  date?: string;
  dateFormat?: string;
  status?: string;
  amount?: number;
  details?: LineItemDetail[];
  footer?: React.ReactNode;
  onClick?: () => void;
  isOpen?: boolean;
  isPending?: boolean;
  isInactive?: boolean;
  hideDateMobile?: boolean;
}

const LineItem: React.FC<Props> = ({
  title,
  date,
  dateFormat,
  status,
  amount,
  details,
  footer,
  onClick,
  isOpen,
  isPending,
  isInactive,
  hideDateMobile,
}) => {
  const onClickMain = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <Styled.LineItem
      onClick={onClick}
      $isOpen={isOpen}
      $isPending={isPending}
      $hideDateMobile={hideDateMobile}
    >
      <LineItemMain onClick={onClick && onClickMain}>
        {date && (
          <Styled.LineItemDate>
            {formatDateString(date, dateFormat)}
          </Styled.LineItemDate>
        )}
        {title && <Styled.LineItemInfo>{title}</Styled.LineItemInfo>}
        {status && (
          <Styled.LineItemStatus>
            <StatusIcon status={status} />
          </Styled.LineItemStatus>
        )}
        {amount && (
          <Styled.LineItemAmount
            $status={status}
            $isCredit={amount < 0}
            $isInactive={isInactive}
          >
            {formatCurrency(amount, true)}
          </Styled.LineItemAmount>
        )}
        {onClick && <Styled.LineItemArrow name="chevronRight" size="xs" />}
      </LineItemMain>

      <AnimatePresence>
        {isOpen && (details || footer) && (
          <Styled.LineItemDetailsWrapper
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
          >
            <Styled.LineItemDetails>
              {details?.map((detail) => (
                <Detail
                  key={detail.label}
                  label={detail.label}
                  value={detail.value}
                  isMobile={detail.isMobile}
                />
              ))}

              <Styled.LineItemFooter>{footer}</Styled.LineItemFooter>
            </Styled.LineItemDetails>
          </Styled.LineItemDetailsWrapper>
        )}
      </AnimatePresence>
    </Styled.LineItem>
  );
};

export default LineItem;
