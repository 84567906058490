import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";

export const Wrapper = styled(ContentLayout)`
  margin: 0 auto;
  padding-top: 48px;
  padding-bottom: 48px;
  max-width: 576px;
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 48px;
`;
