import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import FormContainer, {
  FieldContainer,
  InputField,
  RadioField,
  SubmitButton,
} from "components/form";
import { usePostMockCreateUserMutation } from "store/api/apiSlice";
import { toBase64 } from "utils/file";
import { RepaymentMethod } from "utils/api/user";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  onSuccess?: () => void;
}

const DemoCreateUserModal = (
  { onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postCreateUser] = usePostMockCreateUserMutation();

  const onSubmit = async ({
    logo: rawLogo,
    primaryBank,
    payroll,
    ...values
  }) => {
    let logo;
    if (rawLogo?.length) {
      const logoFile = rawLogo[0];
      const logoBlob = await toBase64(logoFile);
      logo = { data: logoBlob, type: logoFile.type };
    }

    const accounts = {
      primaryBank: primaryBank.length ? primaryBank : undefined,
      payroll: payroll.length ? payroll : undefined,
    };

    const userData = Object.entries(values).reduce((acc, [key, value]) => {
      if (value?.length === 0) {
        return { ...acc };
      }

      return { ...acc, [key]: value };
    }, {});

    await postCreateUser({
      logo,
      accounts,
      ...userData,
    });
    if (onSuccess) {
      onSuccess();
    }
    ref.current?.dismiss();
  };

  return (
    <Modal ref={ref}>
      <Wrapper>
        <FormContainer
          onSubmit={onSubmit}
          defaultValues={{ repaymentMethod: RepaymentMethod.achDebitFlow }}
        >
          <InputField name="firstName" label="First Name" />
          <InputField name="lastName" label="Last Name" />
          <InputField name="primaryBank" label="Financial Institution" />
          <InputField name="payroll" label="Payroll Provider" />
          <RadioField
            name="repaymentMethod"
            options={[
              { label: "ACH debit", value: RepaymentMethod.achDebitFlow },
              {
                label: "Direct deposit",
                value: RepaymentMethod.directDepositFlow,
              },
            ]}
          />
          <FieldContainer name="logo" label="Partner Logo">
            {({ inputProps }) => <input {...inputProps} type="file" />}
          </FieldContainer>

          <SubmitButton>Create User</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(DemoCreateUserModal);
