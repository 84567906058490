import React from "react";
import styled from "styled-components";
import UILabel from "components/ui/UILabel";
import { SpendingBucket } from "../types";
import { SPENDING_BUCKETS } from "../constants";

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const KeyItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const KeyItemDot = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 9999px;
`;

const KEY_ITEMS = [
  SpendingBucket.needs,
  SpendingBucket.wants,
  SpendingBucket.savings,
].map((name) => SPENDING_BUCKETS[name]);

const Key = () => (
  <Wrapper>
    {KEY_ITEMS.map(({ label, color }) => (
      <KeyItem key={label}>
        <KeyItemDot style={{ background: color }} />
        <UILabel.Small bold color="midnight">
          {label}
        </UILabel.Small>
      </KeyItem>
    ))}
  </Wrapper>
);

export default Key;
