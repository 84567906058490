import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import Icon from "components/ui/Icon";
import useClickOutside from "utils/hooks/onClickOutside";
import * as Styled from "./styled";
import { SITE_NAVIGATION } from "../constants";

const Item: React.FC<{ title: string; path: string }> = ({ title, path }) => (
  <Styled.MenuItem>
    <Styled.MenuLink href={`/${path}`} data-turbo="false">
      {title}
    </Styled.MenuLink>
  </Styled.MenuItem>
);

const MobileMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useClickOutside(() => setIsOpen(false));

  const onClickToggle = () => {
    setIsOpen((o) => !o);
  };

  return (
    <Styled.Wrapper ref={wrapperRef} $isOpen={isOpen}>
      <AnimatePresence>
        {isOpen && (
          <Styled.Menu
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: { x: "-100%" },
              closed: { x: 0 },
            }}
            transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
          >
            <Styled.MenuList>
              {SITE_NAVIGATION.map((item) => (
                <Item key={item.title} {...item} />
              ))}
            </Styled.MenuList>
          </Styled.Menu>
        )}
      </AnimatePresence>

      <Styled.ToggleButton onClick={onClickToggle}>
        <Icon name={isOpen ? "close" : "menu"} />
      </Styled.ToggleButton>
    </Styled.Wrapper>
  );
};

export default MobileMenu;
