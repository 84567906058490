import DisappearingTile from "components/ui/DisappearingTile";
import styled from "styled-components";
import { colors, fontWeights, mediaMax } from "utils/theme";

export const Main = styled(DisappearingTile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaMax("md")} {
    gap: 24px;
  }
`;

export const UserHeader = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 12px;
  align-items: center;
`;

export const Avatar = styled.div`
  position: relative;

  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;

  background: ${colors.azure}20;
  border-radius: 9999px;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(45deg, ${colors.heliotrope}, ${colors.azure});
    mix-blend-mode: lighten;
  }
`;

export const Initials = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: ${colors.midnight};

  font-size: 24px;
  font-weight: ${fontWeights.black};
  text-transform: uppercase;
`;

export const UserInfo = styled.div``;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;

  text-align: center;

  a,
  button {
    color: ${colors.midnight};
  }
`;
