import { BaseButton } from "components/ui/Button";
import styled, { css } from "styled-components";
import {
  borderRadius,
  colors,
  fontSizesUI,
  fontWeights,
  shadows,
} from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const CardWrapper = styled.div`
  --bg-color-1: ${colors.azure};
  --bg-color-2: ${colors.heliotrope};
  color: white;
`;

export const StepList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  counter-reset: step;
`;

export const Step = styled(BaseButton)<{ $isActive: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 24px 24px 24px 56px;

  border-radius: ${borderRadius.regular}px;

  text-align: left;

  counter-increment: step;

  &::before {
    content: counter(step);

    position: absolute;
    left: 22px;
    top: 22px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    border-radius: 9999px;

    font-weight: ${fontWeights.bold};
    font-size: ${fontSizesUI.sm}px;
  }

  ${(props) =>
    props.$isActive
      ? css`
          box-shadow: ${shadows.tile};
          background: white;

          &::before {
            background: ${colors.violet};
            color: white;
          }
        `
      : css`
          box-shadow: 0 0 0 1px ${colors.azure}80 inset;
          opacity: 0.7;

          &::before {
            box-shadow: 0 0 0 1px ${colors.azure};
            color: ${colors.violet};
            opacity: 0.8;
          }
        `}
`;

export const Footer = styled.div`
  padding-top: 24px;

  button {
    width: 100%;
  }
`;
