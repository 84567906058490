import styled, { css } from "styled-components";
import { RouterLink } from "components/ui/Link";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  display: flex;
  height: 44px;
  justify-content: space-between;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;

  ${mediaMax("lg")} {
    gap: 12px;
  }
`;

export const ActionLink = styled(RouterLink)`
  svg {
    color: ${colors.violet};
  }

  ${mediaMax("lg")} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
`;

export const ActionLabel = styled.div`
  ${mediaMax("lg")} {
    display: none;
  }
`;

const mobileChatButtonStyles = css`
  width: 24px;
  height: 24px;

  button {
    width: 24px;
    height: 24px;

    background: none;
    color: ${colors.violet};
  }
`;

export const ChatButton = styled.div<{ $isOpen: boolean }>`
  ${mediaMax("lg")} {
    ${mediaMin("md")} {
      ${mobileChatButtonStyles}
    }
  }

  ${mediaMax("md")} {
    ${(props) =>
      !props.$isOpen &&
      css`
        ${mobileChatButtonStyles}
      `}
  }
`;
