import React from "react";
import styled from "styled-components";
import Tile from "components/ui/Tile";
import PersonalInfoList from "components/ui/PersonalInfoList";
import { Heading } from "components/ui/Text";
import { ButtonLink, ButtonSize } from "components/ui/Button";
import { ApiUserProfile } from "utils/api/user";
import Tag from "components/ui/Tag";

const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
`;

const StyledTile = styled(Tile)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
`;

const InfoWrapper = styled.div<{ $isSuccess: boolean }>`
  opacity: ${({ $isSuccess }) => ($isSuccess ? 0.7 : 1)};
`;

interface Props {
  userProfile: ApiUserProfile;
  isSuccess: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

const Show: React.FC<Props> = ({ userProfile, isSuccess, setIsEditing }) => (
  <StyledTile size="xl">
    <Header>
      <Heading.H3 tag="h1">Your information</Heading.H3>
      <ButtonLink size={ButtonSize.md} onClick={() => setIsEditing(true)}>
        Edit
      </ButtonLink>
    </Header>
    {isSuccess && <Tag>Update pending</Tag>}
    <InfoWrapper $isSuccess={isSuccess}>
      <PersonalInfoList info={userProfile} />
    </InfoWrapper>
  </StyledTile>
);

export default Show;
