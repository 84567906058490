import React from "react";
import styled from "styled-components";
import cusgIcon from "assets/images/partners/cusg-icon.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2em;
`;

const Emoji = styled.span`
  transform: translateY(-0.1em);

  display: inline-block;
`;

const Icon = styled.img`
  transform: translateY(-0.075em);

  display: block;
  width: 1.75em;
  margin-right: 0.25em;
`;

const CusgBannerMessage = () => (
  <Wrapper>
    <Icon src={cusgIcon} />
    <span>
      Welcome CUSG Employees! <Emoji>🙌</Emoji>
    </span>
  </Wrapper>
);

export default CusgBannerMessage;
