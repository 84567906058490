import React from "react";
import { SURVEY_SCORE } from "../../constants";

interface Props {
  score: number;
  scrollToLearnMore: (e: React.MouseEvent<HTMLElement>) => void;
}

const ScoreMessage: React.FC<Props> = ({ score, scrollToLearnMore }) => {
  if (score < SURVEY_SCORE.coping) {
    return (
      <>
        Like many, you&apos;re not always able to save, and may be a bit short
        on cash when bills roll around. Reset can help.{" "}
        <a href="#" onClick={scrollToLearnMore}>
          Learn&nbsp;more
        </a>
      </>
    );
  }
  if (score < SURVEY_SCORE.healthy) {
    return (
      <>
        You&apos;ve got some positive habits! Let&apos;s keep building on them.
        Reset can help you better manage your financial future.{" "}
        <a href="#" onClick={scrollToLearnMore}>
          Learn&nbsp;more
        </a>
      </>
    );
  }
  return (
    <>
      We&apos;re impressed! You&apos;ve taken your financial health seriously
      and it shows. Reset can help you to continue thriving in your financial
      happy place.{" "}
      <a href="#" onClick={scrollToLearnMore}>
        Learn&nbsp;more
      </a>
    </>
  );
};

export default ScoreMessage;
