import Icon, { IconName } from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import React from "react";
import styled from "styled-components";
import { colors, mediaMax, shadows, transitions } from "utils/theme";

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  display: grid;
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  height: 100%;

  pointer-events: none;

  svg {
    display: block;
  }
`;

const IconWrapper = styled.div`
  align-self: flex-end;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-bottom: -8px;

  border-radius: 9999px;

  transition: all ${transitions.simple};

  ${mediaMax("md")} {
    width: 36px;
    height: 36px;
    margin-bottom: -4px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  text-align: center;
`;

const StyledCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  background: transparent;
  border: 1px solid ${colors.violet}40;
  border-radius: 8px;
  outline: 0;
  appearance: none;

  transition: all ${transitions.simple};

  &:checked {
    background: ${colors.azure};
    border-color: ${colors.azure};

    & + ${Content} {
      color: white;

      ${IconWrapper} {
        background: ${colors.ice};
      }
    }
  }

  &:focus {
    border-color: ${colors.midnight};
    box-shadow: ${shadows.tile};
  }
`;

const Wrapper = styled.div`
  position: relative;

  padding-bottom: 100%;
`;

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: IconName;
  label: string;
}

const Checkbox = (
  { name, icon, label, onChange, ...props }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>
) => (
  <Wrapper>
    <StyledCheckbox
      name={name}
      type="checkbox"
      onChange={onChange}
      ref={ref}
      {...props}
    />

    <Content>
      <IconWrapper>
        <Icon name={icon} size="lg" color="violet" />
      </IconWrapper>

      <Label>
        <UILabel.Small bold>{label}</UILabel.Small>
      </Label>
    </Content>
  </Wrapper>
);

export default React.forwardRef(Checkbox);
