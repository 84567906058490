import React from "react";
import styled from "styled-components";
import { ApiNotificationLevel as NotificationLevel } from "utils/api/notifications";
import { colors, fontWeights, mediaMax, shadows } from "utils/theme";
import Icon from "./Icon";

export { NotificationLevel };

const getColor = (level: NotificationLevel) => {
  switch (level) {
    case NotificationLevel.success:
    case NotificationLevel.info:
      return colors.azure;
    case NotificationLevel.error:
    default:
      return colors.cerise;
  }
};

const getIcon = (level: NotificationLevel) => {
  switch (level) {
    case NotificationLevel.success:
      return "checkCircle";
    case NotificationLevel.info:
      return "infoCircle";
    case NotificationLevel.error:
    default:
      return "warningTriangle";
  }
};

const Wrapper = styled.a<{
  $level?: NotificationLevel;
  $showIcon?: boolean;
}>`
  position: relative;

  display: flex;
  padding: 16px 16px 16px ${(props) => (props.$showIcon ? 16 : 24)}px;
  width: 100%;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  border-radius: 12px;
  background: white;
  box-shadow: ${shadows.tile};
  color: ${colors.midnight};
  cursor: pointer;

  font-weight: ${fontWeights.bold};
  text-decoration: none;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    height: 100%;
    width: 12px;

    background: ${(props) => getColor(props.$level)};
  }

  ${mediaMax("md")} {
    padding: 12px 16px 12px ${(props) => (props.$showIcon ? 16 : 24)}px;
  }
`;

const ArrowIcon = styled.div`
  opacity: 0.33;

  svg {
    display: block;
  }
`;

const LevelIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;

  border-radius: 9999px;

  svg {
    display: block;
  }
`;

const Message = styled.div`
  flex: 1 1 auto;
`;

interface Props {
  level?: NotificationLevel;
  showIcon?: boolean;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const Notification: React.FC<Props> = ({
  children,
  showIcon = true,
  level = NotificationLevel.info,
  onClick,
}) => (
  <Wrapper onClick={onClick} $level={level} $showIcon={showIcon}>
    {showIcon && (
      <LevelIcon>
        <Icon name={getIcon(level)} size="md" />
      </LevelIcon>
    )}

    <Message>{children}</Message>

    {onClick && (
      <ArrowIcon>
        <Icon name="caretRightSolid" size="sm" color={colors.violet} />
      </ArrowIcon>
    )}
  </Wrapper>
);

export default Notification;
