import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { RouterLink } from "./Link";
import UILabel from "./UILabel";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 auto;
`;

interface Props {
  current: number;
  total: number;
}

const Pagination: React.FC<Props> = ({ current, total }) => {
  const [params] = useSearchParams();
  let range = [];
  if (total > 5) {
    range = new Array(5).fill(0).map((_, i) => current + i - 2);
    range = range.filter((n) => n >= 1 && n <= total);
  } else {
    range = new Array(total).fill(0).map((_, i) => i + 1);
  }

  const getUrlWithPage = (page: number) => {
    const newParams = new URLSearchParams(params);
    newParams.set("page", page.toString());
    return `?${newParams.toString()}`;
  };

  return (
    <Wrapper>
      {range.map((page, index) => (
        <React.Fragment key={page}>
          {index === 0 && page > 1 && (
            <RouterLink to={getUrlWithPage(1)} size="lg" color="azure">
              1
            </RouterLink>
          )}
          {index === 0 && page > 2 && <span>...</span>}
          {current === page ? (
            <UILabel.Large>{page}</UILabel.Large>
          ) : (
            <RouterLink to={getUrlWithPage(page)} size="lg" color="azure">
              {page}
            </RouterLink>
          )}
          {index === range.length - 1 && page < total - 1 && <span>...</span>}
          {index === range.length - 1 && page < total && (
            <RouterLink to={getUrlWithPage(total)} size="lg" color="azure">
              {total}
            </RouterLink>
          )}
        </React.Fragment>
      ))}
    </Wrapper>
  );
};

export default Pagination;
