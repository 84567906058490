import { ApiDashboardAccounts } from "utils/api/dashboard";
import {
  ApiNotification,
  ApiNotificationLevel,
  hasDslUpdatingNotification,
} from "utils/api/notifications";
import {
  DashboardAccount,
  DashboardAccountAction,
  DashboardAccountStatus,
} from "./types";
import { API_BANK_NAME_TO_DISPLAY_NAME_MAP } from "./constants";

const hasErrorNotifications = (
  source: string,
  notifications: ApiNotification[] = []
) =>
  notifications.some(
    (notification: ApiNotification) =>
      notification.source === source &&
      notification.level === ApiNotificationLevel.error
  );

type AccountStatusGetter = (props: {
  notifications: ApiNotification[];
}) => DashboardAccountStatus;

const getPayrollAccountStatus: AccountStatusGetter = ({
  notifications = [],
}) => {
  if (hasErrorNotifications("pinwheel", notifications)) {
    return DashboardAccountStatus.inactive;
  }
  if (hasDslUpdatingNotification(notifications)) {
    return DashboardAccountStatus.pending;
  }
  return DashboardAccountStatus.active;
};

const getBankAccountStatus: AccountStatusGetter = ({ notifications = [] }) => {
  if (hasErrorNotifications("plaid", notifications)) {
    return DashboardAccountStatus.inactive;
  }
  if (hasDslUpdatingNotification(notifications)) {
    return DashboardAccountStatus.pending;
  }
  return DashboardAccountStatus.active;
};

const mapBankToDisplayName = (bankName: string) =>
  API_BANK_NAME_TO_DISPLAY_NAME_MAP[bankName] || bankName;

interface AccountProps {
  availableBankBalance: number;
  accruedNetEarnings: number;
  upcomingExpensesAmount: number;
  outstandingResetTransactions: number;
  hasCard: boolean;
  accounts: ApiDashboardAccounts;
  payrollAction: DashboardAccountAction;
  notifications: ApiNotification[];
}

const getPayrollAccount: (props: AccountProps) => DashboardAccount = ({
  accounts,
  accruedNetEarnings,
  notifications,
  payrollAction,
}) => ({
  name: "earnings",
  title: "Accrued Net Earnings",
  subtitle: accounts.payroll || "Your Income Source",
  amount: accruedNetEarnings,
  icon: "dollar",
  href: "/income",
  status: getPayrollAccountStatus({ notifications }),
  actions: [payrollAction],
});

const getBankAccount: (props: AccountProps) => DashboardAccount = ({
  accounts,
  availableBankBalance,
  notifications,
}) => ({
  name: "bank",
  title: "Available Balance",
  subtitle:
    mapBankToDisplayName(accounts.primaryBank) || "Your Checking Account",
  amount: availableBankBalance,
  icon: "piggyBank",
  href: "/recurring-expenses",
  status: getBankAccountStatus({ notifications }),
});

const getCardAccount: (props: AccountProps) => DashboardAccount = ({
  outstandingResetTransactions,
}) => ({
  name: "card",
  title: "Outstanding Transactions",
  subtitle: "Reset Card",
  amount: outstandingResetTransactions || 0,
  icon: "creditCard",
  href: "/card-transactions",
  isDebit: true,
});

export const getAccounts: (props: AccountProps) => DashboardAccount[] = (
  props
) => {
  const payrollAccount = getPayrollAccount(props);
  const bankAccount = getBankAccount(props);

  const items: DashboardAccount[] = [payrollAccount, bankAccount];

  if (props.hasCard) {
    const cardAccount = getCardAccount(props);
    items.push(cardAccount);
  }

  return items;
};
