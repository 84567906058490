import React from "react";
import { capitalize, formatCurrency } from "utils/string";
import UILabel from "components/ui/UILabel";
import cardImage from "assets/images/general/reset-card-small.webp";
import { formatDateString } from "utils/date";
import { ApiUserProfile } from "utils/api/user";
import * as Styled from "./styled";

// TODO: consider making this generic like line item when need headers elsewhere.
interface Props {
  outstandingAmount: number;
  safeToSpend: number;
  nextPayday: string;
  userProfile: ApiUserProfile;
}

const Header: React.FC<Props> = ({
  outstandingAmount,
  safeToSpend,
  nextPayday,
  userProfile,
}) => {
  const cards = userProfile?.currentCardAccount?.cards;

  return (
    <Styled.Header>
      <Styled.HeaderCardImage>
        <img src={cardImage} />
      </Styled.HeaderCardImage>

      <Styled.HeaderMain>
        <Styled.HeaderBalance>
          <UILabel.Medium>Outstanding amount</UILabel.Medium>
          <Styled.HeaderAmount color="violet" tag="div">
            {formatCurrency(outstandingAmount, true)}
          </Styled.HeaderAmount>
          {cards ? (
            <Styled.HeaderBalanceNotes>
              {cards.map((card) => (
                <UILabel.Micro key={card.lastFour} caps bold color="dust">
                  {capitalize(card.form)} card ...{card.lastFour}
                </UILabel.Micro>
              ))}
            </Styled.HeaderBalanceNotes>
          ) : (
            <UILabel.Small>Reset card</UILabel.Small>
          )}
        </Styled.HeaderBalance>

        <Styled.HeaderStats>
          <Styled.HeaderItem>
            <UILabel.Medium>Next repayment</UILabel.Medium>
            <UILabel.Large bold color="violet">
              {formatDateString(nextPayday, "numericShort")}
            </UILabel.Large>
          </Styled.HeaderItem>

          <Styled.HeaderItem>
            <UILabel.Medium>Spending limit</UILabel.Medium>
            <UILabel.Large bold color="violet">
              {formatCurrency(Math.max(safeToSpend, 0), true)}
            </UILabel.Large>
          </Styled.HeaderItem>
        </Styled.HeaderStats>
      </Styled.HeaderMain>
    </Styled.Header>
  );
};

export default Header;
