import React, { useState } from "react";
import { HelpMessage } from "components/form";
import UILabel from "components/ui/UILabel";
import LineItem from "components/ui/LineItem";
import DisappearingTile from "components/ui/DisappearingTile";
import { ApiEarnings } from "utils/api/income";
import * as Styled from "./styled";
import { getAmount, getDetails } from "./utils";

const ItemFooter = () => (
  <Styled.LineItemFooter>
    <HelpMessage>
      Your net earnings are estimated based on previous paystubs, and may not
      reflect the final amount we receive from your payroll provider.
    </HelpMessage>
  </Styled.LineItemFooter>
);

interface Props extends ApiEarnings {}

const Earnings: React.FC<Props> = ({
  shifts,
  netPayRatio,
  compensationUnit,
  compensationAmount,
}) => {
  const [openEarnings, setOpenEarnings] = useState<Set<string>>(new Set());

  const toggleEarning = (earningId: string) => {
    setOpenEarnings((o) => {
      const newOpenEarning = new Set(o);
      if (newOpenEarning.has(earningId)) {
        newOpenEarning.delete(earningId);
      } else {
        newOpenEarning.add(earningId);
      }
      return newOpenEarning;
    });
  };

  const hasShifts = shifts?.length > 0;
  const isHourly = compensationUnit === "hourly";
  if (!isHourly && !hasShifts) return null;

  return (
    <DisappearingTile size="xl">
      {hasShifts ? (
        <Styled.LineItems>
          {shifts.map((shift) =>
            shift.earnings.map((earning, earningIndex) => {
              const earningId = `${shift.id}.${earningIndex}`;
              const amount = getAmount({
                earning,
                compensationAmount,
                compensationUnit,
              });
              return (
                <Styled.LineItemWrapper key={earningId}>
                  <LineItem
                    title="Earnings"
                    date={shift.endDate}
                    amount={amount}
                    details={getDetails({ ...earning, amount }, netPayRatio)}
                    isOpen={openEarnings.has(earningId)}
                    onClick={() => toggleEarning(earningId)}
                    footer={<ItemFooter />}
                  />
                </Styled.LineItemWrapper>
              );
            })
          )}
        </Styled.LineItems>
      ) : (
        <Styled.EmptyMessage>
          <HelpMessage>
            <UILabel.Large>
              We haven't been notified of any earnings for this pay period.
            </UILabel.Large>
          </HelpMessage>
        </Styled.EmptyMessage>
      )}
    </DisappearingTile>
  );
};

export default Earnings;
