import React from "react";
import Timeline from "components/ui/Timeline";
import Loading from "components/ui/Loading";
import { getDashboardOverview, getUserProfile } from "store/api/apiSlice";
import { getEvents } from "./utils";

const PaydayTimelineStatic: React.FC = () => {
  const { data: dashboard, isLoading: isLoadingDashboard } =
    getDashboardOverview();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();

  if (isLoadingDashboard || isLoadingUserProfile) return <Loading />;

  const events = getEvents({ dashboard, userProfile });

  return <Timeline events={events} />;
};

export default PaydayTimelineStatic;
