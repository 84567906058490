import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import FormContainer, { RadioField, SubmitButton } from "components/form";
import { usePostIssuePhysicalCardMutation } from "store/admin/api/apiSlice";
import Modal, { ModalApi } from "./Modal";

const RADIO_OPTIONS = [
  { label: "Standard Shipping", value: false },
  { label: "Expedited Shipping", value: true },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  id: string;
  onSuccess: () => void;
}

const ShipCardModal = (
  { id, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postIssuePhysicalCard] = usePostIssuePhysicalCardMutation();

  const onSubmit = async (values: { expeditedFulfillment: boolean }) => {
    await postIssuePhysicalCard({ id, ...values });
    if (onSuccess) {
      onSuccess();
    }
    ref.current?.dismiss();
  };

  return (
    <Modal ref={ref}>
      <Wrapper>
        <FormContainer onSubmit={onSubmit}>
          <RadioField
            label="Shipping method"
            name="expedited_fulfillment"
            options={RADIO_OPTIONS}
            required
          />

          <SubmitButton>Ship Card</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(ShipCardModal);
