import React from "react";
import { Body } from "components/ui/Text";
import { BudgetPrompt } from "../types";
import NeedsSelection from "./NeedsSelection";
import WantsSelection from "./WantsSelection";
import Income from "./Income";
import NeedsAmounts from "./NeedsAmounts";
import WantsAmounts from "./WantsAmounts";

export const PROMPTS: BudgetPrompt[] = [
  {
    title: "Needs",
    icon: "cubes",
    text: (
      <>
        <p>Tell us about spending you can&apos;t avoid.</p>

        <Body>
          This is spending on things that are essential to survival, or payments
          you&apos;re legally required to make. Select all that apply.
        </Body>
      </>
    ),
    Form: NeedsSelection,
  },
  {
    title: "Wants",
    icon: "faceLaughBeam",
    text: (
      <>
        <p>Tell us about discretionary spending.</p>

        <Body>
          This is spending on things that are nice to have but you can live
          without&mdash;even if it would be hard!
        </Body>
      </>
    ),
    Form: WantsSelection,
  },
  {
    title: "Income",
    icon: "dollarBadge",
    text: (
      <>
        <p>Tell us about your household take-home income.</p>

        <Body>
          This is your gross income minus taxes. We&apos;ll handle things like
          healthcare and 401(k) contributions separately. If your earnings vary,
          a rough estimate is fine.
        </Body>
      </>
    ),
    Form: Income,
  },
  {
    title: "Spending",
    icon: "wallet",
    text: (
      <>
        <p>
          Let&apos;s add up what you&apos;re spending on <strong>needs</strong>{" "}
          each month.
        </p>

        <Body>
          If you don&apos;t have bills or statements handy, give us your best
          guess.
        </Body>
      </>
    ),
    Form: NeedsAmounts,
  },
  {
    title: "Spending",
    icon: "wallet",
    text: (
      <>
        <p>
          Let&apos;s see what you&apos;re spending on <strong>wants</strong> in
          a typical month.
        </p>

        <Body>
          Spending can fluctuate in these categories, so rough estimates are
          fine.
        </Body>
      </>
    ),
    Form: WantsAmounts,
  },
];
