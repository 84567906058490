import React from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { colors, fontWeights } from "utils/theme";
import AnimatedCounter from "components/ui/AnimatedCounter";

const OUTER_RADIUS = 90;
const STROKE_WIDTH = 32;
const CIRCUMFERENCE = 2 * Math.PI * (OUTER_RADIUS - STROKE_WIDTH / 2);

const Wrapper = styled.div`
  position: relative;

  width: ${OUTER_RADIUS * 2}px;
  height: ${OUTER_RADIUS}px;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Value = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  color: black;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      to right,
      ${colors.heliotrope},
      ${colors.azure}
    );
    mix-blend-mode: lighten;
  }
`;

const aFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Score = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  font-size: 43px;
  font-weight: ${fontWeights.black};
  letter-spacing: -0.02em;

  opacity: 0;

  animation: ${aFadeIn} 0.15s 0.35s ease-in forwards;
`;

interface Props {
  score: number;
}

const Chart: React.FC<Props> = ({ score }) => {
  const displayScore = Math.round(score);

  return (
    <Wrapper>
      <svg
        width={OUTER_RADIUS * 2}
        height={OUTER_RADIUS}
        viewBox={`0 0 ${OUTER_RADIUS * 2} ${OUTER_RADIUS}`}
      >
        <circle
          cx={OUTER_RADIUS}
          cy={OUTER_RADIUS}
          r={OUTER_RADIUS - STROKE_WIDTH / 2}
          fill="transparent"
          stroke="#e6e6e6"
          strokeWidth={STROKE_WIDTH}
        />
      </svg>

      <Value>
        <svg>
          <motion.circle
            cx={OUTER_RADIUS}
            cy={OUTER_RADIUS}
            r={OUTER_RADIUS - STROKE_WIDTH / 2}
            fill="transparent"
            stroke="black"
            strokeWidth={STROKE_WIDTH}
            strokeDasharray={CIRCUMFERENCE / 2}
            animate={{
              strokeDashoffset: CIRCUMFERENCE - (CIRCUMFERENCE * score) / 200,
            }}
            initial={{ strokeDashoffset: CIRCUMFERENCE }}
            transition={{
              duration: 0.5,
              delay: 0.5,
              type: "spring",
              damping: 15,
            }}
          />
        </svg>

        <Score>
          <AnimatedCounter
            value={displayScore}
            isCurrency={false}
            delay={0.35}
          />
        </Score>
      </Value>
    </Wrapper>
  );
};

export default Chart;
