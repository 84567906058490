import styled, { css } from "styled-components";
import { BaseButton } from "components/ui/Button";
import { colors, mediaMax, shadows, transitions, zIndex } from "utils/theme";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: ${zIndex.modal - 1};

  ${mediaMax("lg")} {
    bottom: 96px;
    right: 24px;
  }

  ${mediaMax("md")} {
    bottom: 88px;
    right: 16px;
  }
`;

export const Button = styled(BaseButton)<{
  $isEnabled: boolean;
  $isVisible: boolean;
}>`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  border-radius: 8px;
  background: white;
  color: black;
  overflow: hidden;
  --border-color: ${colors.violet};
  box-shadow:
    ${shadows.tile},
    0 0 0 1.5px var(--border-color) inset;

  transition: all ${transitions.simple};
  will-change: transform;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(75deg, ${colors.azure}, ${colors.heliotrope});
    mix-blend-mode: screen;
  }

  ${(props) =>
    props.$isEnabled &&
    css`
      background: black;
      color: white;
      --border-color: white;
    `}

  ${(props) =>
    !props.$isVisible &&
    css`
      transform: translateY(8px);

      opacity: 0;
      visibility: hidden;
    `}
`;
