import React from "react";
import { motion } from "framer-motion";
import { Heading } from "components/ui/Text";
import { ApiAdminUser } from "utils/api/admin/user";
import { formatCurrency } from "utils/string";
import { SafeToSpendComponents } from "utils/api/dashboard";
import { isDefined } from "utils/object";
import {
  getAdminUser,
  usePostAdminUserRefreshEligibilityMutation,
} from "store/admin/api/apiSlice";
import Link, { LinkButton } from "components/ui/Link";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";
import OnboardingStatusTag from "../../components/OnboardingStatusTag";
import StatusTag from "../../components/StatusTag";
import EligibilityCriteria from "./EligibilityCriteria";

interface Props {
  user: ApiAdminUser;
  safeToSpendComponents: SafeToSpendComponents;
}

const BasicInfo: React.FC<Props> = ({ user, safeToSpendComponents }) => {
  const [refreshEligibility, { isLoading: isRefreshingEligibility }] =
    usePostAdminUserRefreshEligibilityMutation();
  const { isLoading: isLoadingUser } = getAdminUser(user.id);

  const onClickRefreshEligibility = () => {
    refreshEligibility(user.id);
  };

  const isLoadingEligibility = isRefreshingEligibility || isLoadingUser;

  return (
    <Styled.SectionTile>
      <Heading.H3 tag="h1">{user.name}</Heading.H3>

      <Styled.Info>
        <Styled.InfoSection>
          <Styled.InfoItem label="Email" value={user.email} />
          <Styled.InfoItem
            label="Links"
            value={
              <Link
                href={user.hubspotContactUrl}
                target="_blank"
                size="lg"
                color="azure"
              >
                Open in Hubspot <Icon name="externalLink" />
              </Link>
            }
          />
          <Styled.InfoItem
            label="Status"
            value={
              <Styled.TagWrapper>
                <OnboardingStatusTag status={user.onboardingStatus} />
              </Styled.TagWrapper>
            }
          />
          <Styled.InfoItem
              label="Account Status"
              value={
                <Styled.TagWrapper>
                  <StatusTag status={ user.deactivated ? 'deactivated' : 'active'} />
                </Styled.TagWrapper>
              }
          />
          <Styled.InfoItem
            label="Bank"
            value={user.linkedBankAccountName || "n/a"}
          />
          <Styled.InfoItem
            label="Payroll Provider"
            value={user.payrollAccount?.institutionName || "n/a"}
          />
          <Styled.InfoItem
            label="Compensation Unit"
            value={user.payrollAccount?.compensationUnit || "n/a"}
          />
          <Styled.InfoItem
            label="Pay Frequency"
            value={user.payrollAccount?.payFrequency || "n/a"}
          />
          <Styled.InfoItem
            label="Compensation Amount"
            value={
              formatCurrency(
                (user.payrollAccount?.compensationAmountInt || 0) / 100.0,
                true
              ) || "n/a"
            }
          />
          <Styled.InfoItem
            label="Annualized Compensation Amount"
            value={
              formatCurrency(user.annualizedCompensation || 0, true) || "n/a"
            }
          />
        </Styled.InfoSection>

        {safeToSpendComponents && (
          <Styled.InfoSection>
            <Styled.InfoItem
              label="Dynamic Spending Limit"
              value={formatCurrency(safeToSpendComponents.safeToSpend, true)}
            />
            <Styled.InfoItem
              label="Outstanding Amount"
              value={formatCurrency(
                safeToSpendComponents.outstandingResetTransactions,
                true
              )}
            />
            <Styled.InfoItem
              label="Available Bank Balance"
              value={formatCurrency(
                safeToSpendComponents.availableBankBalance,
                true
              )}
            />
            <Styled.InfoItem
              label="Accrued Net Earnings"
              value={formatCurrency(
                safeToSpendComponents.accruedNetEarnings,
                true
              )}
            />
            <Styled.InfoItem
              label="Upcoming Expenses"
              value={formatCurrency(
                safeToSpendComponents.upcomingExpensesAmount,
                true
              )}
            />
            <Styled.InfoItem
              label="Total Expenses"
              value={formatCurrency(
                safeToSpendComponents.totalExpensesAmount,
                true
              )}
            />
            {isDefined(user.eligible) && (
              <Styled.InfoItem
                label="Eligible?"
                value={
                  <Styled.TagWrapper>
                    <StatusTag status={user.eligible.toString()} />

                    <LinkButton
                      onClick={onClickRefreshEligibility}
                      disabled={isLoadingEligibility}
                    >
                      {isLoadingEligibility ? (
                        <motion.div
                          animate={{ rotate: 360 }}
                          transition={{
                            repeat: Infinity,
                            duration: 2,
                            ease: "linear",
                          }}
                        >
                          <Icon name="refresh" height={16} />
                        </motion.div>
                      ) : (
                        <Icon name="refresh" height={16} />
                      )}
                      Refresh
                    </LinkButton>
                  </Styled.TagWrapper>
                }
              />
            )}
            {user.eligibilityCriteria && (
              <Styled.InfoItem
                label="Criteria"
                value={
                  <EligibilityCriteria criteria={user.eligibilityCriteria} />
                }
              />
            )}
          </Styled.InfoSection>
        )}
      </Styled.Info>
    </Styled.SectionTile>
  );
};

export default BasicInfo;
