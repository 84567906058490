import { ResetDocument, ResetDocumentType } from "./types";

export const getUrl = (document: ResetDocument) => {
  switch (document.type) {
    case ResetDocumentType.Statement:
      return `/account/statements/${document.data.id}`;
    default:
      return document.data.url;
  }
};
