import styled from "styled-components";
import UILabel from "components/ui/UILabel";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0;
  margin: 0;

  list-style-type: none;

  ${mediaMax("md")} {
    gap: 4px;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Main = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Amount = styled(UILabel.Large)`
  opacity: 0.7;

  ${mediaMax("md")} {
    font-size: 14px;
  }
`;
