import React from "react";
import styled from "styled-components";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { colors, mediaMin } from "utils/theme";
import { getUserProfile } from "store/api/apiSlice";
import UILabel from "components/ui/UILabel";
import Logo from "components/ui/Logo";
import { getResetConstant } from "utils/reset";

const ResetLogo = styled(Logo)`
  width: 80px;

  opacity: 0.25;

  * {
    fill: ${colors.azure};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const LogoWrapper = styled.div`
  ${mediaMin("lg")} {
    display: none;
  }
`;

const AppFooter = () => {
  const { data: userProfile } = getUserProfile();

  return (
    <Wrapper>
      {userProfile && (
        <LogoWrapper>
          {userProfile.partnerSlug ? (
            <PartnerLogoLockup partnerSlug={userProfile.partnerSlug} />
          ) : (
            <ResetLogo />
          )}
        </LogoWrapper>
      )}

      <UILabel.Small color={`${colors.midnight}80`}>
        {getResetConstant("disclosure")}
      </UILabel.Small>
    </Wrapper>
  );
};

export default AppFooter;
