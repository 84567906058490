import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const FormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 96px auto 0;

  ${mediaMax("lg")} {
    flex-direction: column-reverse;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ModalWrapper = styled.div`
  position: absolute;
`;
