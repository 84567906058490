import styled, { css } from "styled-components";
import Tile from "components/ui/Tile";
import { colors, mediaMax } from "utils/theme";
import Tag from "components/ui/Tag";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-x: auto;
  overflow-y: hidden;

  ${mediaMax("lg")} {
    padding: 16px;

    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;

const getStatusTagStyles = ({ $label }: { $label: string }) => {
  switch ($label) {
    case "Vulnerable":
      return css`
        background: ${colors.heliotrope};
        color: white;
      `;
    case "Coping":
      return css`
        background: ${colors.violet};
        color: white;
      `;
    case "Healthy":
    default:
      return css`
        background: ${colors.leaf};
      `;
  }
};

export const StatusTag = styled(Tag)<{ $label: string }>`
  width: fit-content;

  color: ${colors.midnight};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
