import React, { useState, createContext, useEffect, useRef } from "react";
import ModalOverlay from "components/modal/ModalOverlay";

const ModalContext = createContext<{
  id: string | null;
  setId: (id: string | null) => void;
  toggleCloseBehavior: (useDefaultBehavior: boolean) => void;
  portal: Element | null;
}>({
  id: null,
  setId: () => null,
  toggleCloseBehavior: () => null,
  portal: null,
});

export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [id, setId] = useState<string | null>(null);
  const [originalFocus, setOriginalFocus] = useState<Element | null>(null);
  const [useDefaultCloseBehavior, setUseDefaultCloseBehavior] = useState(true);
  const portalRef = useRef(null);

  const toggleCloseBehavior = (useDefault: boolean) => {
    setUseDefaultCloseBehavior(useDefault);
  };

  const onClickOverlay = () => {
    if (useDefaultCloseBehavior) {
      setId(null);
    }
  };

  useEffect(() => {
    if (!id && originalFocus) {
      (originalFocus as HTMLElement)?.focus();
    }
  }, [id]);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (useDefaultCloseBehavior) {
        if (e.key === "Escape") {
          setId(null);
        }
      }
    };
    window.addEventListener("keydown", keydownHandler);

    return () => {
      window.removeEventListener("keydown", keydownHandler);
    };
  });

  useEffect(() => {
    const focus = document.querySelector(":focus");
    setOriginalFocus(focus);

    if (portalRef.current && id) {
      const firstFocusableElement = (
        portalRef.current as HTMLElement
      )?.querySelector("a:not(.js-skip-focus), button:not(.js-skip-focus)");
      (firstFocusableElement as HTMLElement)?.focus();
    }
  }, [portalRef.current, id]);

  return (
    <ModalContext.Provider
      value={{
        id,
        setId,
        toggleCloseBehavior,
        portal: portalRef.current,
      }}
    >
      {id && <ModalOverlay onClick={onClickOverlay} />}

      <div ref={portalRef} id="modal-portal" />

      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
