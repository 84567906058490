import { v4 as uuid } from "uuid";

export const openWindow = (url: string) => {
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const width = Math.min(winWidth, 800);
  const height = Math.min(winHeight, 480);
  window.open(
    `${url}?hideNavigation=true`,
    uuid(),
    `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=${width},
     height=${height},
     left=${(winWidth - width) / 2},
     top=${(winHeight - height) / 2}`
  );
};
