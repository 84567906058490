import React from "react";
import { LogoBlock } from "types/cms";
import * as Styled from "./styled";

const Logo: React.FC<LogoBlock> = ({ data: { images } }) => (
  <Styled.Wrapper>
    <Styled.ImageWrapper>
      {images.map((image, index) => (
        <img key={index} src={image.url} alt={image.title || "logo"} />
      ))}
    </Styled.ImageWrapper>
  </Styled.Wrapper>
);

export default Logo;
