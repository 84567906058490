import React from "react";
import { useFormContext } from "react-hook-form";
import { FormSection, RadioField, SubmitButton } from "components/form";
import UILabel from "components/ui/UILabel";
import styled from "styled-components";
import Link from "components/ui/Link";
import { Heading } from "components/ui/Text";
import { CardAction } from "./types";

const LabelText = styled.div`
  display: grid;
  gap: 4px;
`;

const Body = styled(UILabel.Medium)`
  opacity: 0.7;
`;

const Footer = styled.div`
  display: grid;
  gap: 24px;
  width: 100%;
  justify-items: center;

  button {
    width: 100%;
  }
`;

const Form: React.FC = () => {
  const { watch } = useFormContext();
  const selectedAction = watch("action");

  return (
    <>
      <FormSection>
        <Heading.H4 tag="h1">
          Why do you need to replace your&nbsp;card?
        </Heading.H4>

        <RadioField
          name="action"
          options={[
            {
              value: CardAction.replace,
              label: (
                <LabelText>
                  <div>
                    My card is <strong>lost</strong> or <strong>stolen</strong>
                  </div>
                  {selectedAction === CardAction.replace && (
                    <Body>
                      Your Reset card will be canceled and you'll receive a
                      replacement card with a new number.
                    </Body>
                  )}
                </LabelText>
              ),
            },
            {
              value: CardAction.reissue,
              label: (
                <LabelText>
                  <div>
                    My card is <strong>damaged</strong>
                  </div>
                  {selectedAction === CardAction.reissue && (
                    <Body>
                      Your Reset card will be reissued with the same card
                      number. You can continue using your current card until
                      your new one arrives.
                    </Body>
                  )}
                </LabelText>
              ),
            },
          ]}
        />
      </FormSection>

      <Footer>
        <SubmitButton disabled={!selectedAction}>Submit</SubmitButton>

        <Link href="/account" size="lg" color="midnight">
          Cancel
        </Link>
      </Footer>
    </>
  );
};

export default Form;
