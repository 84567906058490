import React from "react";
import styled, { css } from "styled-components";
import Icon, { IconName } from "components/ui/Icon";
import { Label, MaskedField, MaskType } from "components/form";
import { colors } from "utils/theme";
import { BaseButton } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import { useFormContext } from "react-hook-form";

const InputRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > * {
    flex: 1 1 auto;
  }
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    padding-left: 60px;
    padding-right: 80px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 46px;

  border-radius: 7px 0 0 7px;
  border-right: 1px solid ${colors.midnight}40;
  background: ${colors.ice};
`;

const InputDenominator = styled(UILabel.Large)`
  position: absolute;
  top: 24px;
  right: 12px;
  transform: translateY(-50%);

  opacity: 0.5;
`;

const ActionButton = styled(BaseButton)`
  flex: 0 0 auto;
`;

const Wrapper = styled.div<{ $valid: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 6px;

  ${(props) =>
    props.$valid &&
    css`
      ${IconWrapper} {
        background: ${colors.leaf};
        border-color: ${colors.violet};
        color: white;

        svg {
          color: ${colors.midnight};
        }
      }

      input {
        border-color: ${colors.midnight};
      }
    `}
`;

interface Props {
  name: string;
  label: string;
  icon: IconName;
  removeRow: (name: string) => void;
}

const ExpenseRow: React.FC<Props> = ({ name, label, icon, removeRow }) => {
  const formPath = `expenses.${name}`;
  const { watch } = useFormContext();
  const value = watch(formPath);

  return (
    <Wrapper key={name} $valid={!!value}>
      <Label>{label}</Label>

      <InputRow>
        <InputWrapper>
          <MaskedField
            maskType={MaskType.currency}
            name={formPath}
            placeholder="$"
          />

          <IconWrapper>
            <Icon name={icon} color="violet" />
          </IconWrapper>

          <InputDenominator>/ month</InputDenominator>
        </InputWrapper>

        <ActionButton onClick={() => removeRow(name)} type="button">
          <Icon name="trash" />
        </ActionButton>
      </InputRow>
    </Wrapper>
  );
};

export default ExpenseRow;
