import React, { useEffect, useRef } from "react";
import { FieldError, useFormState } from "react-hook-form";
import styled from "styled-components";
import { getPropValue } from "utils/object";
import Notification from "components/ui/Notification";
import { ApiNotificationLevel } from "utils/api/notifications";
import { getErrorMessage } from "../utils";

const ErrorMessage = styled.div``;

export const GENERAL_ERROR = "generalError";

const GeneralError: React.FC = () => {
  const ref = useRef(null);
  const { errors } = useFormState();
  const error = getPropValue(errors, GENERAL_ERROR) as FieldError;
  const errorMessage = getErrorMessage(error);

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0 && ref?.current) {
      const rect = ref.current.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + rect.top - 16,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [errorMessage]);

  return errorMessage ? (
    <ErrorMessage ref={ref}>
      <Notification level={ApiNotificationLevel.error}>
        {errorMessage}
      </Notification>
    </ErrorMessage>
  ) : null;
};

export default GeneralError;
