import React from "react";
import { Body, Heading } from "components/ui/Text";
import FormContainer, { FormSection } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import Button from "components/ui/Button";
import {
  getBankAccountsLinked,
  getCardApplicationStatus,
  usePostCardApplicationConfirmRepaymentAccountMutation,
} from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import * as Styled from "../styled";
import BankAccount from "./bankAccount";
import SuccessMessage from "../SuccessMessage";
import CancelApplicationButton from "../CancelApplicationButton";

const ConfirmRepaymentAccount: React.FC = () => {
  const { data: linkedBankAccount, isLoading: isLoadingBankAccount } =
    getBankAccountsLinked();
  const [
    postCardApplicationConfirmRepaymentAccount,
    { isLoading: isConfirming, isSuccess: submittedConfirmation },
  ] = usePostCardApplicationConfirmRepaymentAccountMutation();
  const { data: cardApplicationStatus } = getCardApplicationStatus({
    pollingInterval: 2000,
  });

  const onSubmit = async () => {
    await postCardApplicationConfirmRepaymentAccount();
  };

  if (cardApplicationStatus?.complete) {
    return (
      <SuccessMessage isDashboardReady={cardApplicationStatus?.complete} />
    );
  }

  return (
    <FormContainer onSubmit={onSubmit}>
      <Styled.Header>
        <Heading.H2 tag="h1">Set up autopay.</Heading.H2>
        <Body>
          Please verify that the information below is correct before submitting.
          This is the checking account Reset will use for repayment.
        </Body>
      </Styled.Header>

      <FormSection>
        {isLoadingBankAccount ? (
          <Loading />
        ) : (
          <BankAccount bankAccount={linkedBankAccount} />
        )}
      </FormSection>

      <MobileFixedFooter>
        <Button isLoading={isConfirming || submittedConfirmation} type="submit">
          Confirm
        </Button>
      </MobileFixedFooter>

      <Styled.CancelButtonWrapper>
        <CancelApplicationButton />
      </Styled.CancelButtonWrapper>
    </FormContainer>
  );
};

export default ConfirmRepaymentAccount;
