import { motion } from "framer-motion";
import styled from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  background: white;

  ${mediaMin("md")} {
    padding: 0 16px;

    background: none;
  }

  ${mediaMin("lg")} {
    padding: 0 24px;
  }
`;

export const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaMax("md")} {
    padding: 24px 16px 80px;
    gap: 24px;

    text-align: center;
  }
`;

export const ResultsHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;

  ${mediaMax("md")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ChartWrapper = styled.div`
  flex: 0 0 auto;
`;

export const ResultsHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Categories = styled.div`
  display: grid;
  gap: 24px 48px;
  grid-template-columns: repeat(2, 1fr);

  text-align: left;

  ${mediaMax("md")} {
    gap: 24px 36px;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    padding-top: 24px;
  }
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  ${mediaMax("md")} {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const CategoryIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  flex: 0 0 auto;

  border-radius: 9999px;
  background: ${colors.ice};
`;

export const CategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1 1 auto;
`;

export const CategoryMeter = styled.div`
  width: 100%;
  height: 8px;
  overflow: hidden;

  background: #eee;
  border-radius: 9999px;
`;

export const CategoryScore = styled(motion.div)`
  height: 100%;
`;
