import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 400px;
  margin: 0 auto;

  ${mediaMax("md")} {
    max-width: 100%;
  }
`;

export const TimelineWrapper = styled.div`
  padding: 0 24px;
  width: 100%;

  @media screen and (max-width: 400px) {
    padding: 0 0 0 12px;
  }
`;

export const FaqsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 -16px;

  text-align: center;
`;
