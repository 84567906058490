import React from "react";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";
import FooterLinks from "./FooterLinks";
import { LinkButton } from "../Link";
import UILabel from "../UILabel";

interface Props {
  isAdmin: boolean;
  isImpersonating: boolean;
  stopImpersonatingUser: () => void;
  isOpen: boolean;
  closeDrawer: () => void;
}

const MobileFooterLinks: React.FC<Props> = ({
  isOpen,
  isAdmin,
  isImpersonating,
  stopImpersonatingUser,
  closeDrawer,
}) => (
  <Styled.MobileFooterLinks $isOpen={isOpen}>
    <FooterLinks
      isAdmin={isAdmin}
      isImpersonating={isImpersonating}
      stopImpersonatingUser={stopImpersonatingUser}
      closeMobileDrawer={closeDrawer}
    />

    <Styled.MobileFooterCloseButton>
      <LinkButton onClick={closeDrawer}>
        <Icon name="close" />
        <UILabel.Small bold>Close</UILabel.Small>
      </LinkButton>
    </Styled.MobileFooterCloseButton>
  </Styled.MobileFooterLinks>
);

export default MobileFooterLinks;
