import React from "react";
import UILabel from "components/ui/UILabel";
import { formatDateString } from "utils/date";
import { colors } from "utils/theme";
import {
  TimelineEvent,
  TimelineEventStatus,
  TimelineEventStepStatus,
} from "./types";
import * as Styled from "./styled";
import { getEventStatus } from "./utils";
import StepNode from "./StepNode";

export { TimelineEventStatus, TimelineEventStepStatus };

interface Props {
  events: TimelineEvent[];
}

const Timeline: React.FC<Props> = ({ events }) => (
  <Styled.Wrapper>
    {events.map((event, eventIndex) => {
      const status = getEventStatus({
        date: event.date,
        nextDate: events[eventIndex + 1]?.date,
        prevDate: events[eventIndex - 1]?.date,
        index: eventIndex,
      });
      const isToday = status === TimelineEventStatus.today;
      const data = event.data(status);
      return (
        <Styled.Event key={eventIndex} $status={status}>
          {eventIndex < events.length - 1 && (
            <Styled.EventConnector>
              <svg viewBox="0 0 6 10" preserveAspectRatio="none">
                <line
                  x1={3}
                  x2={3}
                  y1={0}
                  y2={10}
                  strokeWidth={data.steps?.length ? 2 : 6}
                />
              </svg>
            </Styled.EventConnector>
          )}

          <Styled.EventMain
            $hasSteps={data.steps?.length > 0}
            $stepStatus={data.steps?.[0].status}
          >
            <Styled.EventNode />

            <Styled.EventText>
              {event.date && (
                <Styled.EventDate bold={isToday} color={isToday && "azure"}>
                  {isToday
                    ? "Today"
                    : formatDateString(event.date, "textShortWithWeekday")}
                </Styled.EventDate>
              )}
              <UILabel.XLarge bold>{data.title}</UILabel.XLarge>
              {data.description && (
                <Styled.EventDescription color={`${colors.midnight}CC`}>
                  {data.description}
                </Styled.EventDescription>
              )}
            </Styled.EventText>
          </Styled.EventMain>

          {data.steps?.length && (
            <Styled.EventSteps>
              {data.steps.map((step, stepIndex) => (
                <Styled.EventStep
                  key={stepIndex}
                  $status={step.status}
                  $nextStatus={data.steps[stepIndex + 1]?.status}
                >
                  <Styled.EventStepMain>
                    <StepNode status={step.status}>{stepIndex + 1}</StepNode>

                    <Styled.EventStepText>
                      <UILabel.Medium bold>{step.title}</UILabel.Medium>
                      {step.subtitle && (
                        <UILabel.Micro color={`${colors.midnight}A0`} caps bold>
                          {step.subtitle}
                        </UILabel.Micro>
                      )}
                    </Styled.EventStepText>

                    {step.detail && (
                      <Styled.EventStepDetail>
                        <UILabel.Large bold color="violet">
                          {step.detail}
                        </UILabel.Large>
                      </Styled.EventStepDetail>
                    )}
                  </Styled.EventStepMain>
                </Styled.EventStep>
              ))}
            </Styled.EventSteps>
          )}
        </Styled.Event>
      );
    })}
  </Styled.Wrapper>
);

export default Timeline;
