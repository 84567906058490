import React from "react";
import { useSearchParams } from "react-router-dom";
import { getAdminCardTransactions } from "store/admin/api/apiSlice";
import Loading from "components/ui/Loading";
import { formatDateString } from "utils/date";
import Pagination from "components/ui/Pagination";
import Link, { RouterLink } from "components/ui/Link";
import { snakeCaseToTitleCase, formatCurrency } from "utils/string";
import { ApiAdminCardTransaction } from "utils/api/admin/cardTransaction";
import Icon from "components/ui/Icon";
import StatusTag from "components/ui/StatusTag";
import Table from "../../components/Table";
import * as Styled from "./styled";

const HEADINGS = [
  "Date",
  "User ID",
  "Amount",
  "Merchant",
  "Network State",
  "Customer State",
  "Links",
];

const isPosted = (transaction: ApiAdminCardTransaction) =>
  ["cleared", "settled"].includes(transaction.networkState) &&
  transaction.customerState === "posted";

const getTransactionUrl = (transaction: ApiAdminCardTransaction) => {
  const statusSlug = isPosted(transaction) ? "posted" : "pending";
  return `https://app.synctera.com/transactions/${statusSlug}/${transaction.syncteraTransactionId}`;
};

const getRow = (transaction: ApiAdminCardTransaction) => [
  formatDateString(transaction.createdAt, "numericShortWithTime"),
  <>
    <RouterLink to={`/admin/users/${transaction.userId}`} color="azure">
      {transaction.userId.slice(-4)}
    </RouterLink>
  </>,
  formatCurrency(transaction.amount, true),
  transaction.merchantName,
  <Link
    href={
      transaction.networkState
        ? getTransactionUrl(transaction)
        : `https://app.synctera.com/accounts/${transaction.syncteraAccountId}/transactions`
    }
    target="_blank"
    color="azure"
  >
    {transaction.networkState
      ? snakeCaseToTitleCase(transaction.networkState)
      : "N/A"}
    <Icon name="externalLink" size="sm" />
  </Link>,
  <StatusTag status={transaction.customerState} />,
  <Link
    href={`https://app.synctera.com/accounts/${transaction.syncteraAccountId}/transactions`}
    target="_blank"
    color="azure"
  >
    Transactions
    <Icon name="externalLink" size="sm" />
  </Link>,
];

const CardTransactions = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const { data, isLoading } = getAdminCardTransactions(page);

  if (isLoading) <Loading />;

  const { cardTransactions, totalPages } = data || {};

  if (!cardTransactions) return null;

  return (
    <Styled.Wrapper>
      <Table>
        <thead>
          <tr>
            {HEADINGS.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cardTransactions.map((transaction) => (
            <tr key={transaction.id}>
              {getRow(transaction).map((cell, index) => (
                <td key={index} data-heading={HEADINGS[index]}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default CardTransactions;
