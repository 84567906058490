import styled from "styled-components";
import BaseTile from "components/ui/Tile";
import {
  Heading,
  Body as BaseBody,
  BodySmall as BaseBodySmall,
} from "components/ui/Text";
import {
  borderRadius,
  colors,
  fontWeights,
  fonts,
  mediaMax,
} from "utils/theme";

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HeaderBody = styled(BaseBody)``;

export const ImageContainer = styled.div`
  position: relative;

  padding-bottom: 50%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
`;

export const Tile = styled(BaseTile)`
  overflow: hidden;
  padding: 0;
`;

export const TileContent = styled.div`
  position: relative;

  display: grid;
  gap: 8px;
  --padding: 36px;
  padding: 0 var(--padding) var(--padding);

  ${mediaMax("lg")} {
    --padding: 24px;
  }
`;

export const TileBody = styled(BaseBodySmall)`
  opacity: 0.5;
`;

export const Label = styled(Heading.H4)`
  margin-bottom: 12px;
`;

export const Alert = styled.button`
  padding: 16px 24px;

  appearance: none;
  border: 0;
  border-radius: ${borderRadius.small}px;
  background: ${colors.heliotrope}40;

  cursor: pointer;

  * {
    color: ${colors.violet};

    font-weight: ${fontWeights.bold};
    font-family: ${fonts.body};
    text-align: left;

    u {
      float: right;

      color: ${colors.midnight};
    }
  }
`;

export const CancelButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
`;
