import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import { colors } from "utils/theme";

const Path = styled.path<{ $gradientId: string }>`
  fill: url(#${(props) => props.$gradientId});
`;

const GradientStopOne = styled.stop`
  stop-color: ${colors.azure};
`;

const GradientStopTwo = styled.stop`
  stop-color: ${colors.heliotrope};
`;

const Logo = (props: any) => {
  const svgId = `logo_${uuid()}`;
  return (
    <svg viewBox="0 0 150 43" xmlns="http://www.w3.org/2000/svg" {...props}>
      <linearGradient
        id={svgId}
        gradientUnits="userSpaceOnUse"
        x1="148.4263"
        y1="43.7641"
        x2="7.1786"
        y2="-5.8795"
      >
        <GradientStopOne offset="0" />
        <GradientStopTwo offset="1" />
      </linearGradient>
      <Path
        $gradientId={svgId}
        d="M150,39.3l-0.3,0.2c-1.1,0.9-2.4,1.6-3.8,2.1c-1.4,0.5-2.9,0.7-4.4,0.7c-2.7,0-4.8-0.8-6.3-2.3
	c-1.5-1.5-2.2-3.7-2.2-6.4v-6.2h0v-19l8-8v1.3l0,1.6v10.4h7v5.1c0,1-0.8,1.7-1.7,1.7h-5.3v4.9h0v7.6c0,0.8,0.2,1.5,0.6,1.9
	c0.4,0.4,0.9,0.6,1.6,0.6c0.4,0,0.7,0,1-0.1c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.5-0.2,0.7-0.2
	c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.5,0.6l2.2,3.5L150,39.3z M22.8,3.3H3.9v16c0,1,0.8,1.7,1.7,1.7H11V10.4h11.8
	c3.9,0,7.1,3.2,7.1,7.1v0c0,3.9-3.2,7.1-7.1,7.1H11H0l11,11.4v-4.3h9.4c1.3,0,2.4,0.7,3.1,1.8l5.3,9.2h5.6c1.1,0,1.8-1.2,1.3-2.2
	l-6.1-10.5c4.4-2.4,7.3-7.1,7.3-12.4v0C36.9,9.7,30.5,3.3,22.8,3.3z M68.1,27.3c0.3-0.4,0.4-1.2,0.4-2.4c0-2.2-0.3-4.2-1-5.9
	c-0.7-1.7-1.6-3.2-2.8-4.4c-1.2-1.2-2.6-2.1-4.3-2.7c-1.6-0.6-3.4-0.9-5.4-0.9c-2.3,0-4.4,0.4-6.2,1.2c-1.8,0.8-3.4,1.9-4.7,3.3
	c-1.3,1.4-2.3,3-2.9,4.8c-0.7,1.8-1,3.8-1,5.9c0,2.7,0.4,5.1,1.2,7.1c0.8,2,1.9,3.8,3.2,5.2c1.4,1.4,3,2.5,4.8,3.2
	c1.8,0.7,3.8,1.1,6,1.1c1.1,0,2.2-0.1,3.3-0.2c1.1-0.2,2.3-0.4,3.4-0.8c1.1-0.4,2.2-0.9,3.2-1.6c1-0.7,2-1.5,2.8-2.4l0.2-0.3
	l-0.2-0.3l-2.1-2.6c-0.3-0.5-0.9-0.8-1.7-0.8c-0.5,0-1.1,0.2-1.6,0.5c-0.5,0.3-1,0.6-1.7,0.9c-0.6,0.3-1.3,0.6-2.2,0.9
	c-0.8,0.3-1.8,0.4-2.9,0.4c-1.1,0-2.1-0.2-3.1-0.5c-0.9-0.3-1.7-0.9-2.3-1.5c-0.7-0.7-1.2-1.6-1.6-2.6c-0.4-1-0.6-2.1-0.8-3.4h18.1
	C67.3,28.1,67.8,27.9,68.1,27.3z M48.5,22.9c0.4-1.9,1.1-3.3,2.1-4.4c1.1-1.1,2.7-1.7,4.6-1.7c1,0,1.9,0.2,2.7,0.5
	c0.7,0.3,1.4,0.8,1.9,1.4c0.5,0.6,0.9,1.3,1.1,2c0.2,0.7,0.3,1.4,0.4,2.1H48.5z M94.9,29c0.4,0.9,0.7,2,0.7,3.3
	c0,1.5-0.3,2.9-0.8,4.1c-0.5,1.3-1.3,2.4-2.4,3.3c-1,0.9-2.4,1.6-3.9,2.2c-1.5,0.5-3.3,0.8-5.3,0.8c-1.1,0-2.1-0.1-3.1-0.3
	c-1-0.2-2-0.5-2.9-0.8c-0.9-0.3-1.8-0.7-2.6-1.2c-0.8-0.5-1.5-1-2.1-1.5l-0.3-0.2l0.2-0.3l1.7-2.8c0.2-0.4,0.5-0.7,0.9-0.9
	c0.4-0.2,0.8-0.3,1.3-0.3c0.5,0,1,0.1,1.5,0.4c0.4,0.2,0.8,0.5,1.4,0.8c0.5,0.3,1.1,0.5,1.8,0.8c0.7,0.2,1.5,0.4,2.6,0.4
	c0.8,0,1.5-0.1,2.1-0.3c0.6-0.2,1-0.4,1.4-0.7c0.3-0.3,0.6-0.6,0.8-1C88,34.4,88,34,88,33.6c0-0.6-0.2-1.1-0.5-1.4
	c-0.4-0.4-0.9-0.8-1.5-1.1c-0.7-0.3-1.4-0.6-2.3-0.9c-0.9-0.3-1.8-0.6-2.7-0.9c-0.9-0.3-1.9-0.7-2.8-1.1c-0.9-0.4-1.8-1-2.5-1.7
	C75,25.9,74.4,25,73.9,24c-0.4-1-0.7-2.2-0.7-3.6c0-1.3,0.3-2.5,0.8-3.7c0.5-1.2,1.3-2.2,2.3-3.1c1-0.9,2.2-1.6,3.7-2.1
	c1.5-0.5,3.1-0.8,5-0.8c2.1,0,4.1,0.4,5.8,1.1c1.7,0.7,3.2,1.6,4.4,2.8l0.2,0.2l-0.2,0.3l-1.6,2.6c-0.2,0.4-0.5,0.6-0.8,0.8
	c-0.3,0.2-0.6,0.2-1,0.2c-0.4,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.9-0.4-1.4-0.7c-0.5-0.2-1.1-0.4-1.7-0.6C86.7,17.1,86,17,85.1,17
	c-1.3,0-2.4,0.3-3.1,0.8c-0.7,0.5-1,1.2-1,2c0,0.5,0.2,1,0.5,1.3c0.4,0.4,0.9,0.8,1.5,1.1c0.7,0.3,1.4,0.6,2.3,0.9
	c0.9,0.3,1.8,0.6,2.7,0.9c0.9,0.3,1.8,0.7,2.7,1.1c0.9,0.4,1.7,0.9,2.5,1.6C93.9,27.3,94.5,28.1,94.9,29z M127.7,27.3
	c0.3-0.4,0.4-1.2,0.4-2.4c0-2.2-0.3-4.2-1-5.9c-0.7-1.7-1.6-3.2-2.8-4.4c-1.2-1.2-2.6-2.1-4.3-2.7c-1.6-0.6-3.4-0.9-5.4-0.9
	c-2.3,0-4.4,0.4-6.2,1.2c-1.8,0.8-3.4,1.9-4.7,3.3c-1.3,1.4-2.3,3-2.9,4.8c-0.7,1.8-1,3.8-1,5.9c0,2.7,0.4,5.1,1.2,7.1
	c0.8,2,1.9,3.8,3.2,5.2c1.4,1.4,3,2.5,4.8,3.2c1.8,0.7,3.8,1.1,6,1.1c1.1,0,2.2-0.1,3.3-0.2c1.1-0.2,2.3-0.4,3.4-0.8
	c1.1-0.4,2.2-0.9,3.2-1.6c1-0.7,2-1.5,2.8-2.4l0.2-0.3l-0.2-0.3l-2.1-2.6c-0.3-0.5-0.9-0.8-1.7-0.8c-0.5,0-1.1,0.2-1.6,0.5
	c-0.5,0.3-1,0.6-1.7,0.9c-0.6,0.3-1.3,0.6-2.2,0.9c-0.8,0.3-1.8,0.4-2.9,0.4c-1.1,0-2.1-0.2-3.1-0.5c-0.9-0.3-1.7-0.9-2.3-1.5
	c-0.7-0.7-1.2-1.6-1.6-2.6c-0.4-1-0.6-2.1-0.8-3.4H126C126.8,28.1,127.3,27.9,127.7,27.3z M108,22.9c0.4-1.9,1.1-3.3,2.1-4.4
	c1.1-1.1,2.7-1.7,4.6-1.7c1,0,1.9,0.2,2.7,0.5c0.7,0.3,1.4,0.8,1.9,1.4c0.5,0.6,0.9,1.3,1.1,2c0.2,0.7,0.3,1.4,0.4,2.1H108z"
      />
    </svg>
  );
};

export default Logo;
