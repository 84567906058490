import React from "react";
import ClipboardInput from "components/ui/ClipboardInput";
import { ApiAdminUserPinwheelStatus } from "utils/api/admin/user";
import { formatDateString } from "utils/date";
import * as Styled from "./styled";
import StatusTag from "../../components/StatusTag";

interface Props extends ApiAdminUserPinwheelStatus {}

const Pinwheel: React.FC<Props> = ({
  connected,
  monitoringStatus,
  pinwheelAccountId,
  linkToken,
  dataRefreshedAt,
}) => (
  <>
    <Styled.InfoItem
      label="Connection Status"
      value={
        <Styled.TagWrapper>
          <StatusTag status={connected ? "connected" : "disconnected"} />
        </Styled.TagWrapper>
      }
    />

    {monitoringStatus && (
      <Styled.InfoItem
        label="Monitoring Status"
        value={
          <Styled.TagWrapper>
            <StatusTag status={monitoringStatus} />
          </Styled.TagWrapper>
        }
      />
    )}

    {dataRefreshedAt && (
      <>
        <Styled.InfoItem
          label="Direct Deposit Allocations Refreshed At"
          value={formatDateString(
            dataRefreshedAt.directDepositAllocations,
            "numericShortWithTime"
          )}
        />
        <Styled.InfoItem
          label="Income Refreshed At"
          value={formatDateString(
            dataRefreshedAt.income,
            "numericShortWithTime"
          )}
        />
        <Styled.InfoItem
          label="Employment Refreshed At"
          value={formatDateString(
            dataRefreshedAt.employment,
            "numericShortWithTime"
          )}
        />
        <Styled.InfoItem
          label="Identity Refreshed At"
          value={formatDateString(
            dataRefreshedAt.identity,
            "numericShortWithTime"
          )}
        />
        <Styled.InfoItem
          label="Shifts Refreshed At"
          value={formatDateString(
            dataRefreshedAt.shifts,
            "numericShortWithTime"
          )}
        />
        <Styled.InfoItem
          label="Paystubs Refreshed At"
          value={formatDateString(
            dataRefreshedAt.paystubs,
            "numericShortWithTime"
          )}
        />
      </>
    )}

    {pinwheelAccountId && (
      <Styled.InfoItem label="Pinwheel Account ID" value={pinwheelAccountId} />
    )}

    {linkToken && (
      <Styled.InfoItem
        label="Latest Pinwheel Link Token"
        value={<ClipboardInput>{linkToken}</ClipboardInput>}
      />
    )}
  </>
);

export default Pinwheel;
