import styled from "styled-components";
import { mediaMax, mediaMin, zIndex } from "utils/theme";

export const SidebarWrapper = styled.div`
  height: 100%;

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const MobileHeaderWrapper = styled.div`
  ${mediaMin("lg")} {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;

  ${mediaMax("lg")} {
    padding-bottom: 156px;
  }
`;

export const Content = styled.div`
  --padding-x: 16px;

  margin: 0 auto;
  max-width: 576px;
  width: 100%;
  flex: 1 1 auto;
  padding: 24px var(--padding-x);

  ${mediaMin("lg")} {
    padding: 48px var(--padding-x);
  }
`;

export const FormStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;

  ${mediaMin("lg")} {
    display: none;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const ContentFooter = styled.div`
  margin: 96px auto 0;
`;

export const ChatWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: ${zIndex.modal};

  ${mediaMax("lg")} {
    bottom: auto;
    top: 90px;
  }
`;
