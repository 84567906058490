import React from "react";
import Input, { InputProps } from "../../ui/Input";
import FieldContainer, { FieldApi } from "../../FieldContainer";
import { getMaskedValue, getPattern } from "./utils";
import { MaskType } from "./types";

interface Props extends InputProps {
  label?: React.ReactNode;
  maskType: MaskType;
}

const input =
  ({ inputProps, setValue }: FieldApi) =>
  ({ name, maskType, ...props }: Props) => {
    const onChange = (e) => {
      const value = getMaskedValue(maskType, e.target.value);

      setValue(name, value);
    };

    return (
      <Input
        name={name}
        pattern={getPattern(maskType)}
        {...inputProps}
        onChange={onChange}
        {...props}
      />
    );
  };

const MaskedField: React.FC<Props> = ({ name, label, maskType, ...props }) => (
  <FieldContainer name={name} label={label}>
    {(fieldProps) => input(fieldProps)({ name, maskType, ...props })}
  </FieldContainer>
);

export { MaskType };

export default MaskedField;
