import React, { useEffect, useState } from "react";
import { usePostFinancialHealthBudgetMutation } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import SlideLayout from "components/layout/SlideLayout";
import Step from "./Step";
import { BudgetStep, BudgetResults, BudgetFormData, BudgetData } from "./types";
import { getBudget, getInitialResults, isBudgetFormValid } from "./utils";
import * as Styled from "./styled";

const Flow = () => {
  const [currentStep, setCurrentStep] = useState<BudgetStep | null>(null);
  const [budgetData, setBudgetData] = useState<BudgetData | null>(null);
  const [budgetResults, setBudgetResults] = useState<BudgetResults | null>(
    null
  );
  const [postBudget, postBudgetResults] =
    usePostFinancialHealthBudgetMutation();

  const getStarted = async () => {
    setCurrentStep(BudgetStep.Questionnaire);
  };

  const onSubmitQuestionnaire = (budgetFormData: BudgetFormData) => {
    if (!isBudgetFormValid(budgetFormData)) return;

    const questionnaireResults = getBudget(budgetFormData);

    setBudgetData(questionnaireResults);
    setCurrentStep(BudgetStep.Signup);
  };

  const onSubmitSignup = async (user) => {
    await postBudget({ user, data: budgetData });
  };

  useEffect(() => {
    const initialResults = getInitialResults();
    setBudgetResults(initialResults);
    setCurrentStep(initialResults ? BudgetStep.Results : BudgetStep.Intro);
  }, []);

  if (!currentStep)
    return (
      <Styled.Wrapper>
        <Loading />
      </Styled.Wrapper>
    );

  return (
    <Styled.Wrapper>
      <SlideLayout currentId={currentStep}>
        <Step
          currentStep={currentStep}
          budgetResults={budgetResults}
          getStarted={getStarted}
          onSubmitQuestionnaire={onSubmitQuestionnaire}
          onSubmitSignup={onSubmitSignup}
          postBudgetResults={postBudgetResults}
        />
      </SlideLayout>
    </Styled.Wrapper>
  );
};

export default Flow;
