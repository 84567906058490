interface BaseDocument {
  title: string;
}

export enum ResetDocumentType {
  Web = "web",
  File = "file",
  Statement = "statement",
}

export interface WebDocument extends BaseDocument {
  type: ResetDocumentType.Web;
  data: {
    url: string;
  };
}

export interface FileDocument extends BaseDocument {
  type: ResetDocumentType.File;
  data: {
    url: string;
  };
}

export interface StatementDocument extends BaseDocument {
  type: ResetDocumentType.Statement;
  data: {
    id: string;
  };
}

export type ResetDocument = WebDocument | FileDocument | StatementDocument;
