import React from "react";
import styled from "styled-components";
import InputField from "./InputField";

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
`;

const Row = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 5fr 2fr 3fr;
`;

interface Props {
  name: string;
  required?: boolean;
  label?: React.ReactNode;
}

const AddressField: React.FC<Props> = ({ name, label, required }) => (
  <Wrapper>
    {label}
    <InputField
      name={`${name}.streetOne`}
      label="Street address"
      required={required}
      placeholder="123 Main St"
    />
    <InputField
      name={`${name}.streetTwo`}
      label="Apartment, suite, etc. (optional)"
      placeholder="Apt 4"
    />
    <Row>
      <InputField
        name={`${name}.city`}
        label="City"
        required={required}
        placeholder="Phoenix"
      />
      <InputField
        name={`${name}.stateAbbr`}
        label="State"
        pattern="\w{2}"
        maxLength={2}
        required={required}
        placeholder="AZ"
      />
      <InputField
        name={`${name}.zipCode`}
        label="Zip code"
        maxLength={5}
        pattern="\d{5}"
        required={required}
        placeholder="12345"
      />
    </Row>
  </Wrapper>
);

export default AddressField;
