import React from "react";
import {
  UseFormRegisterReturn,
  useFormContext,
  useFormState,
  FieldError,
  UseFormSetError,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
} from "react-hook-form";
import { getPropValue } from "utils/object";
import Field from "./ui/Field";
import { getErrorMessage } from "./utils";

interface InputProps extends UseFormRegisterReturn<string> {
  id: string;
}

export interface FieldApi {
  inputProps: InputProps;
  error: FieldError;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

interface Props {
  name: string;
  label?: React.ReactNode;
  children: (props: FieldApi) => React.ReactNode;
}

const FieldContainer: React.FC<Props> = ({ name, label, children }) => {
  const { register, setError, clearErrors, setValue } = useFormContext();
  const { errors } = useFormState();
  const inputProps = register(name);
  const error = getPropValue(errors, name) as FieldError;
  const errorMessage = getErrorMessage(error);

  return (
    <Field name={name} label={label} errorMessage={errorMessage}>
      {children({
        inputProps: { ...inputProps, id: name },
        error,
        setError,
        clearErrors,
        setValue,
      })}
    </Field>
  );
};

export default FieldContainer;
