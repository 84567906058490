import React from "react";
import { capitalize } from "utils/string";
import * as Styled from "./styled";

const mapOnboardingStatusToLabel = (status) => {
  switch (status) {
    case "confirmed_email":
      return "Confirmed Email";
    case "linked_income_account":
      return "Linked Income";
    case "linked_core_financial_institution_account":
      return "Linked Bank";
    case "verified_identity":
      return "ID Verified";
    case "switched_direct_deposit":
      return "Switched DD";
    case "issued_card":
      return "Card Issued";
    default:
      return capitalize(status.replace(/_/g, " "));
  }
};

interface Props {
  status: string;
}

const OnboardingStatusTag: React.FC<Props> = ({ status }) => (
  <Styled.Wrapper $status={status}>
    {mapOnboardingStatusToLabel(status)}
  </Styled.Wrapper>
);

export default OnboardingStatusTag;
