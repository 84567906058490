import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Dashboard from "components/views/Dashboard";
import CardTransactions from "components/views/CardTransactions";
import Income from "components/views/Income";
import Statements from "components/views/Account/Statements/Root";
import UpcomingBills from "components/views/UpcomingBills";
import Activate from "components/views/Activate";
import Account from "components/views/Account/Root";
import Documents from "components/views/Account/Documents";
import ManageAccount from "components/views/Account/ManageAccount";
import PersonalInfo from "components/views/Account/PersonalInfo";
import Security from "components/views/Account/Security";
import ReplaceCard from "components/views/Account/ReplaceCard";
import ContentfulPage from "components/shared/ContentfulPage";
import Statement from "components/views/Account/Statements/Statement";
import Root from "./Root";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/card-transactions", element: <CardTransactions /> },
      { path: "/income", element: <Income /> },
      {
        path: "/account",
        children: [
          { path: "", element: <Account /> },
          { path: "personal-info", element: <PersonalInfo /> },
          { path: "security", element: <Security /> },
          { path: "replace-card", element: <ReplaceCard /> },
          { path: "documents", element: <Documents /> },
          { path: "manage-account", element: <ManageAccount /> },
          {
            path: "statements",
            children: [
              {
                path: "",
                element: <Statements />,
              },
              {
                path: ":id",
                element: <Statement />,
              },
            ],
          },
          { path: ":slug", element: <ContentfulPage /> },
        ],
      },
      {
        path: "/recurring-expenses",
        element: <UpcomingBills />,
        handle: {
          parent: "dashboard",
        },
      },
      { path: "/activate", element: <Activate /> },
    ],
  },
]);

export default router;
