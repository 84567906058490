import React from "react";
import Icon from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { Body, Heading } from "components/ui/Text";
import { colors } from "utils/theme";
import { interpolateColor } from "utils/string";
import { CATEGORIES, RESULTS_CATEGORIES } from "../../constants";
import { getScoreLabel, getScoresByCategory } from "../../utils";
import { Tile } from "../../styled";
import Chart from "./Chart";
import * as Styled from "./styled";
import ScoreMessage from "./ScoreMessage";
import Header from "../../Header";

interface Props {
  score: number;
  subscores: number[];
  scrollToLearnMore: (e: React.MouseEvent<HTMLElement>) => void;
}

const ResultsSection: React.FC<Props> = ({
  score,
  subscores,
  scrollToLearnMore,
}) => {
  const scoresByCategory = getScoresByCategory(subscores);
  const scoreLabel = getScoreLabel(score);

  return (
    <Styled.Wrapper>
      <Header />
      <Tile>
        <Styled.ResultsContent>
          <Styled.ResultsHeader>
            <Styled.ChartWrapper>
              <Chart score={score} />
            </Styled.ChartWrapper>

            <Styled.ResultsHeaderText>
              <UILabel.Small color="azure" wideCaps bold>
                Your Financial Health
              </UILabel.Small>

              <Heading.H2 tag="h1">{scoreLabel}</Heading.H2>
            </Styled.ResultsHeaderText>
          </Styled.ResultsHeader>

          <Body>
            <ScoreMessage score={score} scrollToLearnMore={scrollToLearnMore} />
          </Body>

          <Styled.Categories>
            {RESULTS_CATEGORIES.map((categoryName, categoryIndex) => {
              const category = CATEGORIES[categoryName];
              const categoryScore = Math.round(scoresByCategory[categoryName]);
              const background =
                categoryScore > 70
                  ? interpolateColor(
                      colors.azure,
                      colors.violet,
                      (categoryScore - 70) / 30
                    )
                  : interpolateColor(
                      colors.flax,
                      colors.azure,
                      (categoryScore / 700) ** 2 * 100
                    );

              return (
                <Styled.Category key={categoryName}>
                  <Styled.CategoryIcon>
                    <Icon name={category.icon} size="md" color="violet" />
                  </Styled.CategoryIcon>

                  <Styled.CategoryContent>
                    <UILabel.Large bold>{category.title}</UILabel.Large>

                    <Styled.CategoryMeter>
                      <Styled.CategoryScore
                        animate={{ width: `${categoryScore}%` }}
                        initial={{ width: 0 }}
                        transition={{
                          duration: 0.5,
                          delay: 0.65 + categoryIndex * 0.2,
                          ease: [0.25, 1, 0.5, 1],
                        }}
                        style={{ background }}
                      />
                    </Styled.CategoryMeter>
                  </Styled.CategoryContent>
                </Styled.Category>
              );
            })}
          </Styled.Categories>
        </Styled.ResultsContent>
      </Tile>
    </Styled.Wrapper>
  );
};

export default ResultsSection;
