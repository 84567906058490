import React from "react";
import { Heading } from "components/ui/Text";

const Greeting = ({ name }: { name?: string }) => {
  const greeting = ["Welcome back"];
  if (name) greeting.push(name);
  const greetingMessage = `${greeting.join(", ")}!`;
  return <Heading.H5>{greetingMessage}</Heading.H5>;
};

export default Greeting;
