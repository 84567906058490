export interface ApiPaystub {
  payDate: string;
  grossPayAmount: number;
  netPayAmount: number;
}

export interface ApiShiftEarning {
  hours: number;
  amount: number;
}

export interface ApiShift {
  id: string;
  earnings: ApiShiftEarning[];
  endDate: string;
}

export type CompensationUnit =
  | "hourly"
  | "semi_monthly"
  | "monthly"
  | "annually"
  | "bi_weekly"
  | "weekly"
  | "variable";

export interface ApiEarnings {
  shifts: ApiShift[];
  netPayRatio: number;
  beginningOfPayPeriod: string;
  compensationUnit: CompensationUnit;
  compensationAmount: number;
}

export const isHourlyOrGig = (compensationUnit: CompensationUnit) =>
  !compensationUnit || compensationUnit === "hourly";
