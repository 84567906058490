import React, { useEffect } from "react";
import styled from "styled-components";
import Icon, { IconName, IconSize, getHeight } from "components/ui/Icon";
import { colors } from "utils/theme";

export const ImageContainer = styled.div<{ $size: IconSize; $color?: string }>`
  img,
  svg {
    display: block;
    height: ${(props) => getHeight(props.$size)}px;
  }

  svg {
    * {
      fill: ${(props) => colors[props.$color] || props.$color};
    }
  }
`;

interface Props {
  icon?: string;
  iconName?: IconName;
  size?: IconSize;
  color?: string;
}

const IconImage: React.FC<Props> = ({
  icon,
  iconName,
  color = colors.violet,
  size,
}) => {
  const [svg, setSvg] = React.useState<string | null>(null);

  const fetchIcon = async (url: string) => {
    const response = await fetch(url);
    const res = await response.text();
    setSvg(res);
  };

  useEffect(() => {
    if (icon) {
      fetchIcon(icon);
    }
  }, [icon]);

  if (iconName) {
    return <Icon name={iconName} color={color} size={size || "sm"} />;
  }
  if (icon) {
    return (
      <ImageContainer $size={size || "sm"} $color={color}>
        {svg ? (
          <div dangerouslySetInnerHTML={{ __html: svg }} />
        ) : (
          <img src={icon} />
        )}
      </ImageContainer>
    );
  }

  return <Icon name="questionCircle" color={color} size={size || "sm"} />;
};

export default IconImage;
