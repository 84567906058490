export const removeCookie = (name: string) => {
  document.cookie = `${name}=;expires=${new Date("1/1/1970")};path=/`;
};

const WHITELIST = ["_ga", "_gat", "_gid"];

export const removeAllCookies = () => {
  const cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    const name = cookie.split("=")[0];
    if (!WHITELIST.includes(name)) {
      removeCookie(name);
    }
  });
};
