import React, { useEffect, useMemo, useState } from "react";
import { usePostInternalMessageMutation } from "store/api/apiSlice";
import { ApiCardTransaction } from "utils/api/cardTransactions";
import { formatDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import { local } from "utils/storage";
import { LinkButton } from "components/ui/Link";
import UILabel from "components/ui/UILabel";
import { HelpMessage } from "components/form";
import ListSection from "./ListSection";
import { getTransactionsByDateAndStatus } from "./utils";
import * as Styled from "./styled";

interface Props {
  cardTransactions: ApiCardTransaction[];
  canDispute?: boolean;
}

const List: React.FC<Props> = ({ cardTransactions, canDispute }) => {
  const [openTransactions, setOpenTransactions] = useState<Set<string>>(
    new Set()
  );
  const [postInternalMessage] = usePostInternalMessageMutation();
  const [disputes, setDisputes] = useState<Set<string>>(new Set());
  const [showPastTransactions, setShowPastTransactions] = useState(false);

  useEffect(() => {
    const storedDisputes = local.getItem("disputes");
    if (storedDisputes) setDisputes(new Set(storedDisputes));
  }, []);

  const transactionsByDateAndStatus = useMemo(
    () => getTransactionsByDateAndStatus(cardTransactions),
    [cardTransactions]
  );

  const addDispute = (transactionId: string) => {
    setDisputes((d) => {
      const newDisputesArray = [...Array.from(d), transactionId];
      const newDisputes = new Set(newDisputesArray);
      local.setItem("disputes", newDisputesArray);
      return newDisputes;
    });
  };

  const disputeTransaction = async (transaction: ApiCardTransaction) => {
    await postInternalMessage({
      type: `Transaction Dispute`,
      body: [
        "I'd like to dispute a transaction:",
        `Reset ID: ${transaction.id}`,
        `Reference Number: ${transaction.transactionReferenceNumber}`,
        `Date: ${formatDateString(transaction.createdAt)}`,
        `Merchant: ${transaction.merchantName}`,
        `Amount: ${formatCurrency(transaction.amount, true)}`,
      ].join("\n"),
    });
    addDispute(transaction.id);
    return { success: true };
  };

  const toggleTransaction = (transactionId: string) => {
    setOpenTransactions((o) => {
      const newOpenTransactions = new Set(o);
      if (newOpenTransactions.has(transactionId)) {
        newOpenTransactions.delete(transactionId);
      } else {
        newOpenTransactions.add(transactionId);
      }
      return newOpenTransactions;
    });
  };

  const currentTransactions = transactionsByDateAndStatus?.current || [];
  const pastTransactions = transactionsByDateAndStatus?.past || [];

  return (
    <Styled.Wrapper>
      {currentTransactions.length === 0 ? (
        <Styled.EmptyMessage>
          <HelpMessage>
            <UILabel.Large>
              You don't have any outstanding transactions.
            </UILabel.Large>
          </HelpMessage>
        </Styled.EmptyMessage>
      ) : (
        <Styled.SectionList>
          {currentTransactions.map((section) => (
            <ListSection
              key={section.date}
              {...section}
              disputeTransaction={disputeTransaction}
              disputes={disputes}
              openTransactions={openTransactions}
              toggleTransaction={toggleTransaction}
              canDispute={canDispute}
            />
          ))}
        </Styled.SectionList>
      )}
      {showPastTransactions && (
        <Styled.SectionList>
          {pastTransactions.map((section) => (
            <ListSection
              key={section.date}
              {...section}
              disputeTransaction={disputeTransaction}
              disputes={disputes}
              openTransactions={openTransactions}
              toggleTransaction={toggleTransaction}
              canDispute={canDispute}
            />
          ))}
        </Styled.SectionList>
      )}
      {transactionsByDateAndStatus?.past?.length > 0 && (
        <Styled.SectionFooter>
          <LinkButton onClick={() => setShowPastTransactions((s) => !s)}>
            {showPastTransactions ? "Hide" : "Show"} Past Transactions
          </LinkButton>
        </Styled.SectionFooter>
      )}
    </Styled.Wrapper>
  );
};

export default List;
