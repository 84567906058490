import React, { useState } from "react";
import { Heading } from "components/ui/Text";
import { getAdminUserDirectDeposit } from "store/admin/api/apiSlice";
import Button from "components/ui/Button";
import * as Styled from "./styled";
import AllocationInfo from "./AllocationInfo";

interface Props {
  userId: string;
}

const DirectDeposit: React.FC<Props> = ({ userId }) => {
  const [showDirectDeposit, setShowDirectDeposit] = useState(false);
  const { data: directDeposit, isLoading: isLoadingDirectDeposit } =
    getAdminUserDirectDeposit(userId, { skip: !showDirectDeposit });

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Direct Deposit</Heading.H4>

      <Styled.InfoSection>
        {directDeposit ? (
          <>
            {directDeposit?.allocations?.map((allocation, index) => (
              <Styled.InfoItem
                key={allocation.accountName || index}
                label={`Account ${index + 1}`}
                value={<AllocationInfo {...allocation} />}
              />
            ))}
          </>
        ) : (
          <Styled.ButtonWrapper>
            <Button
              isLoading={isLoadingDirectDeposit}
              onClick={() => setShowDirectDeposit(true)}
            >
              Show Allocations
            </Button>
          </Styled.ButtonWrapper>
        )}
      </Styled.InfoSection>
    </Styled.SectionTile>
  );
};

export default DirectDeposit;
