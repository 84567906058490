import styled from "styled-components";
import InfoBox from "components/ui/InfoBox";
import { borderRadius, colors, mediaMax } from "utils/theme";

export const SocureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  visibility: hidden;
  opacity: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const WarningMessage = styled(InfoBox)`
  background: ${colors.cosmos} !important;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  padding: 24px 0;

  border: 1px solid ${colors.flax};
  border-radius: ${borderRadius.small}px;
  background: ${colors.ice};
`;

export const Overview = styled(Box)`
  ${mediaMax("md")} {
    display: none;
  }
`;

export const OverviewItems = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
`;

export const OverviewIcon = styled.div`
  position: relative;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    mix-blend-mode: lighten;
  }
`;

export const OverviewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  &:first-child {
    ${OverviewIcon} {
      &::after {
        background: linear-gradient(
          75deg,
          ${colors.azure},
          ${colors.heliotrope} 200%
        );
      }
    }
  }

  &:nth-child(2) {
    ${OverviewIcon} {
      &::after {
        background: linear-gradient(
          75deg,
          ${colors.azure} -100%,
          ${colors.heliotrope}
        );
      }
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SocureLogo = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  img {
    width: 69px;
  }
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: center;
  padding-top: 24px;
`;
