import React from "react";
import { BodyLarge, Eyebrow, Heading } from "components/ui/Text";
import cardImage from "assets/images/general/reset-card-angle.png";
import Icon, { IconName } from "components/ui/Icon";
import Notification from "./Notification";
import * as Styled from "./styled";

const BULLETS: { text: string; icon: IconName }[] = [
  {
    text: "Access your income daily as you earn it.",
    icon: "calendar",
  },
  {
    text: "No interest, monthly, transfer, or late fees.",
    icon: "moneyBillWave",
  },
  {
    text: "No credit check or security deposit.",
    icon: "loan",
  },
];

const CardSection = () => (
  <Styled.Wrapper>
    <Styled.HeaderContent>
      <Styled.HeaderText>
        <Eyebrow>The income-linked smart card</Eyebrow>
        <Heading.H1>
          Tap into financial wellness. You&nbsp;<em>earned</em>&nbsp;it.
        </Heading.H1>
        <Styled.Bullets>
          {BULLETS.map((bullet) => (
            <Styled.Bullet key={bullet.text}>
              <Styled.BulletIcon>
                <Icon name={bullet.icon} color="violet" size="md" />
              </Styled.BulletIcon>
              <BodyLarge>{bullet.text}</BodyLarge>
            </Styled.Bullet>
          ))}
        </Styled.Bullets>
      </Styled.HeaderText>

      <Styled.CardWrapper>
        <Styled.CardImage>
          <img src={cardImage} />
        </Styled.CardImage>

        <Notification />
      </Styled.CardWrapper>
    </Styled.HeaderContent>
  </Styled.Wrapper>
);

export default CardSection;
