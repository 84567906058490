import React from "react";
import Label from "../Label";
import * as Styled from "./styled";

interface Props {
  score: number;
  message: string[];
  label: string;
}

const PasswordStrength: React.FC<Props> = ({ score, message, label }) => (
  <Styled.Wrapper>
    <Label>
      Strength
      {score > 0 && (
        <>
          : <Styled.Strength>{label}</Styled.Strength>
        </>
      )}
    </Label>
    <Styled.Meter $score={score}>
      {new Array(3).fill(0).map((_, position) => (
        <Styled.Divider $position={position} key={position} />
      ))}
    </Styled.Meter>
    <Styled.Message>{message.length > 0 && message.join(" ")}</Styled.Message>
  </Styled.Wrapper>
);

export default PasswordStrength;
