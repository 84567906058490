import React, { useState } from "react";
import ReactDOM from "react-dom";
import Icon from "components/ui/Icon";
import { BaseButton } from "components/ui/Button";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { colors } from "utils/theme";
import { ApiUserProfile } from "utils/api/user";
import * as Styled from "./styled";
import { NAVIGATION } from "./constants";
import { pathMatchesRoute } from "./utils";
import { Match } from "./types";
import FooterLinks from "./FooterLinks";
import MobileFooterLinks from "./MobileFooterLinks";

interface Props {
  userProfile: ApiUserProfile;
  matches: Match[];
  stopImpersonatingUser: () => void;
}

const Navigation: React.FC<Props> = ({
  userProfile,
  matches,
  stopImpersonatingUser,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showMobileMenuButton = userProfile.admin || userProfile.impersonating;

  return (
    <>
      <Styled.Wrapper>
        <Styled.NavInner>
          <Styled.LogoWrapper>
            <PartnerLogoLockup
              partnerSlug={userProfile.partnerSlug}
              partnerLogo={userProfile.partnerLogo}
            />
          </Styled.LogoWrapper>

          <Styled.NavItems $showMobileMenuButton={showMobileMenuButton}>
            {NAVIGATION.map((item) => {
              const isActive = matches.some(pathMatchesRoute(item));

              return (
                <Styled.NavItem key={item.title}>
                  <Styled.NavLink
                    to={`/${item.path}`}
                    data-turbo="false"
                    $isActive={isActive}
                  >
                    <Icon
                      name={isActive ? item.iconActive : item.icon}
                      color={colors.violet}
                    />
                    <Styled.Label $isActive={isActive}>
                      {item.title}
                    </Styled.Label>
                  </Styled.NavLink>
                </Styled.NavItem>
              );
            })}

            {showMobileMenuButton && (
              <Styled.MobileMenuButton>
                <BaseButton onClick={() => setIsDrawerOpen(true)}>
                  <Icon name="ellipsisVertical" size="md" />
                </BaseButton>
              </Styled.MobileMenuButton>
            )}
          </Styled.NavItems>

          <Styled.FooterLinks>
            <FooterLinks
              isAdmin={userProfile.admin}
              isImpersonating={userProfile.impersonating}
              stopImpersonatingUser={stopImpersonatingUser}
            />
          </Styled.FooterLinks>
        </Styled.NavInner>
      </Styled.Wrapper>

      {ReactDOM.createPortal(
        <MobileFooterLinks
          isOpen={isDrawerOpen}
          isAdmin={userProfile.admin}
          isImpersonating={userProfile.impersonating}
          stopImpersonatingUser={stopImpersonatingUser}
          closeDrawer={() => setIsDrawerOpen(false)}
        />,
        document.body
      )}
    </>
  );
};

export default Navigation;
