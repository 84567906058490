import React, { useMemo, useRef } from "react";
import styled from "styled-components";
import FormContainer from "components/form";
import OnboardingFlow from "components/shared/OnboardingFlow";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import {
  getDisclosures,
  usePostQualifierMutation,
  usePostUserMutation,
} from "store/api/apiSlice";
import { mediaMin } from "utils/theme";
import { PartnerSlug } from "types/general";
import { Eligibility } from "types/signup";
import { DEFAULT_VALUES } from "./constants";
import { getInitialParams, getSignupBody } from "./utils";
import SignupForm from "./Form";

const LogoWrapper = styled.div`
  margin: 48px auto 0;

  ${mediaMin("lg")} {
    display: none;
  }
`;

const View: React.FC = () => {
  const initialParams = useRef(getInitialParams());
  const [postSignup] = usePostUserMutation({
    fixedCacheKey: "signup",
  });
  const [postWaitlist] = usePostQualifierMutation({
    fixedCacheKey: "waitlist",
  });
  const { data: disclosuresData } = getDisclosures([
    "messaging_terms_and_conditions",
  ]);
  const partnerSlug = sessionStorage.getItem("partner") as PartnerSlug;

  const messagingTerms = (disclosuresData?.disclosures || [])[0];

  const onSubmit = async ({ eligibility, ...values }) => {
    const isEligible = Object.values(eligibility).every(
      (v) => v === Eligibility.eligible
    );
    const isIncomplete = Object.values(eligibility).some(
      (v) => v === Eligibility.indeterminate
    );

    if (!isIncomplete) {
      const body = getSignupBody({
        values,
        isEligible,
        messagingTermsId: messagingTerms?.id,
      });

      if (isEligible) {
        await postSignup(body);
      } else {
        await postWaitlist(body);
      }
    }
  };

  const defaultValues = useMemo(
    () => ({
      ...DEFAULT_VALUES,
      questionnaire: {
        ...DEFAULT_VALUES.questionnaire,
        incomeType: initialParams.current.incomeType,
      },
    }),
    [initialParams]
  );

  return (
    <OnboardingFlow
      sidebarNavigation={[
        {
          text: "Log In",
          icon: "userCircle",
          href: "/users/sign_in",
        },
      ]}
      mobileNavigation={[{ text: "Log In", href: "/users/sign_in" }]}
      partnerSlug={partnerSlug}
    >
      {({ openDrawer, closeDrawer }) => (
        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues}>
          <SignupForm
            initialParams={initialParams.current}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
            messagingTerms={messagingTerms}
          />

          <LogoWrapper>
            <PartnerLogoLockup partnerSlug={partnerSlug} />
          </LogoWrapper>
        </FormContainer>
      )}
    </OnboardingFlow>
  );
};

export default View;
