interface ResetAddress {
  street_one: string;
  street_two: string;
  city: string;
  state_abbr: string;
  zip_code: string;
}

interface ResetConstants {
  address: ResetAddress;
  disclosure: string;
  eligibility: string;
}

export interface ResetApp {
  RESET: ResetConstants;
}

export const getResetConstant = (key: keyof ResetConstants) => {
  const app = window as unknown as ResetApp;
  return app.RESET[key];
};

export const getResetAddress = () =>
  getResetConstant("address") as ResetAddress;
