import React from "react";
import { ApiUserProfile } from "utils/api/user";
import ChatWidget from "components/ui/ChatWidget";
import { ButtonLevel } from "components/ui/Button";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";
import Greeting from "./components/Greeting";

interface Props {
  userProfile: ApiUserProfile;
}

const Toolbar: React.FC<Props> = ({ userProfile }) => (
  <Styled.Wrapper>
    <Greeting name={userProfile.firstName} />

    <Styled.Actions>
      <Styled.ActionLink to="/account/user-faqs">
        <Icon name="questionCircle" />
        <Styled.ActionLabel>Help</Styled.ActionLabel>
      </Styled.ActionLink>

      <ChatWidget
        isPrivate
        positionY="top"
        buttonProps={{ level: ButtonLevel.tertiary }}
      >
        {({ isOpen, defaultButton }) => (
          <Styled.ChatButton $isOpen={isOpen}>
            {defaultButton}
          </Styled.ChatButton>
        )}
      </ChatWidget>
    </Styled.Actions>
  </Styled.Wrapper>
);

export default Toolbar;
