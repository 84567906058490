import React from "react";
import styled from "styled-components";
import {
  Link as RouterLink,
  LinkProps as BaseRouterLinkProps,
} from "react-router-dom";
import { borderRadius, colors, mediaMax, shadows } from "utils/theme";

export type TileSize = "sm" | "md" | "lg" | "xl";

interface StyleProps {
  $size: TileSize;
  $isDisabled?: boolean;
}

const BASE_STYLES = `
  border: 0;

  text-decoration: none;
  text-align: left;
  font-family: inherit;

  &, &:visited {
    color: ${colors.midnight};
  }
`;

const getStyles = (props: StyleProps) => {
  let styles = "";
  if (props.$isDisabled) {
    styles += `
    background: color-mix(in srgb, ${colors.violet} 3%, transparent);
    `;
  } else {
    styles += `
      background: white;
    
      box-shadow: ${shadows.tile};
    `;
  }
  return styles;
};

const getSizeStyles = (props: StyleProps) => {
  switch (props.$size) {
    case "xl":
      return `
          padding: 48px;

          border-radius: ${borderRadius.large}px;

          ${mediaMax("lg")} {
            padding: 32px;
          }
        `;
    case "lg":
      return `
          padding: 24px;

          border-radius: ${borderRadius.large}px;

          ${mediaMax("lg")} {
            padding: 16px;
          }
        `;
    case "md":
      return `
          padding: 16px;

          border-radius: ${borderRadius.regular}px;
      `;
    case "sm":
      return `
          padding: 16px;

          border-radius: ${borderRadius.small}px;
      `;
    default:
      return "";
  }
};

const StyledTile = styled.div<StyleProps>`
  ${BASE_STYLES}
  ${getStyles}
  ${getSizeStyles}
`;

const StyledTileInteractive = styled.div<StyleProps>`
  ${BASE_STYLES}
  ${getStyles}
  ${getSizeStyles}

  ${(props) =>
    !props.$isDisabled &&
    `  
  &:not([disabled]) {
    cursor: pointer;
  }`}
`;

interface BaseProps {
  size?: TileSize;
  children?: React.ReactNode;
  isDisabled?: boolean;
}

const Tile: React.FC<BaseProps> = ({ size = "md", isDisabled, ...props }) => (
  <StyledTile $size={size} $isDisabled={isDisabled} {...props} />
);

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    BaseProps {}

export const TileLink: React.FC<LinkProps> = ({
  size = "md",
  isDisabled,
  href,
  ...props
}) => (
  <StyledTileInteractive
    as="a"
    $size={size}
    $isDisabled={isDisabled}
    data-turbo="false"
    {...props}
    href={isDisabled ? null : href}
  />
);

export interface RouterLinkProps extends BaseRouterLinkProps, BaseProps {}

export const TileRouterLink: React.FC<RouterLinkProps> = ({
  size = "md",
  isDisabled,
  to,
  ...props
}) => (
  <StyledTileInteractive
    as={RouterLink}
    $size={size}
    $isDisabled={isDisabled}
    data-turbo="false"
    {...props}
    to={isDisabled ? null : to}
  />
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseProps {}

export const TileButton: React.FC<ButtonProps> = ({
  size = "md",
  isDisabled,
  ...props
}) => (
  <StyledTileInteractive
    as="button"
    type="button"
    $size={size}
    $isDisabled={isDisabled}
    {...props}
  />
);

export default Tile;
