import React from "react";
import Notification from "components/ui/Notification";
import { ApiNotification } from "utils/api/notifications";

interface NotificationListItemProps {
  notification: ApiNotification;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const NotificationsListItem = (props: NotificationListItemProps) => {
  const { notification } = props;
  return (
    <li key={notification.id}>
      <Notification onClick={props.onClick} level={notification.level}>
        {notification.title}
      </Notification>
    </li>
  );
};

export default NotificationsListItem;
