import styled from "styled-components";
import { borderRadius, colors, mediaMax, shadows } from "utils/theme";

export const Wrapper = styled.div``;

export const EmptyMessage = styled.div`
  padding: 48px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LineItems = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1px;
  max-width: 576px;
  margin: 0 auto;
  width: 100%;
  padding: 0;

  list-style-type: none;

  ${mediaMax("md")} {
    gap: 16px;
  }
`;

export const LineItemWrapper = styled.li`
  position: relative;

  &:not(:last-child) {
    &::after {
      content: "";

      position: absolute;
      bottom: -1px;
      left: 0;

      width: 100%;
      height: 1px;

      background: ${colors.midnight}11;
    }
  }

  ${mediaMax("md")} {
    padding: 0 16px;

    background: white;
    box-shadow: ${shadows.tile};
    border-radius: ${borderRadius.small}px;
  }
`;

export const LineItemFooter = styled.div`
  width: 100%;
  grid-column: span 2;
`;
