import React from "react";
import { ApiNotification } from "utils/api/notifications";
import NotificationsList from "components/views/Dashboard/components/NotificationsList";
import { Heading } from "components/ui/Text";
import * as Styled from "./styled";

interface Props {
  notifications: ApiNotification[];
  refetch: () => void;
  setPollingInterval: (interval: number) => void;
}

const ErrorResolution: React.FC<Props> = ({
  notifications,
  refetch,
  setPollingInterval,
}) => {
  const onSuccess = () => {
    setPollingInterval(5_000);
    refetch();
  };

  return (
    <>
      <Styled.Header>
        <Heading.H3 tag="h1">
          Please resolve the following issues before proceeding with your
          application.
        </Heading.H3>
      </Styled.Header>

      <NotificationsList notifications={notifications} onSuccess={onSuccess} />
    </>
  );
};

export default ErrorResolution;
