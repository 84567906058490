import React, { useState } from "react";
import { AnimatePresence, motion, Variants } from "framer-motion";
import Icon from "components/ui/Icon";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import * as Styled from "./styled";

const VARIANTS = {
  enter: (direction: number) => ({
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    x: `${direction * 100}%`,
  }),
  center: {
    position: "relative",
    opacity: 1,
    x: 0,
  },
  exit: (direction: number) => ({
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    x: `${-direction * 100}%`,
  }),
} as Variants;

interface Props {
  header: React.ReactNode;
  slides: React.ReactNode[];
  buttonText: string | string[];
  cta: React.ReactNode;
  onChange: (slide: number) => void;
}

const SliderTile: React.FC<Props> = ({
  header,
  slides,
  buttonText,
  cta,
  onChange,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(1);

  const updateSlide = async (slide: number) => {
    onChange(slide);
    setDirection(slide > currentSlide ? 1 : -1);
    await setTimeout(() => {}, 0); // allow exit animation to use updated direction
    setCurrentSlide(slide);
  };

  const currentButtonText =
    buttonText instanceof Array ? buttonText[currentSlide] : buttonText;

  return (
    <Styled.Wrapper>
      {header}

      <Styled.Slides>
        <AnimatePresence initial={false}>
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={VARIANTS}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30,
              duration: 0.2,
            }}
          >
            <Styled.Slide>
              {slides[currentSlide]}

              {currentSlide === slides.length - 1 ? (
                cta
              ) : (
                <Styled.SlideButton
                  level={ButtonLevel.ghost}
                  size={ButtonSize.md}
                  onClick={() => updateSlide(1)}
                >
                  {currentButtonText}
                  <Styled.ButtonArrowRight>
                    <Icon name="arrowRight" />
                  </Styled.ButtonArrowRight>
                </Styled.SlideButton>
              )}
            </Styled.Slide>
          </motion.div>
        </AnimatePresence>
      </Styled.Slides>

      {slides.length > 1 && (
        <Styled.Footer>
          {currentSlide > 0 && (
            <Styled.FooterButtonPrev onClick={() => updateSlide(0)}>
              <Icon name="arrowLeft" />
            </Styled.FooterButtonPrev>
          )}

          {slides.map((_, index) => (
            <Styled.FooterDot key={index} $isActive={index === currentSlide} />
          ))}
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};

export default SliderTile;
