import {
  PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP,
  PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP,
} from "utils/constants/plaid";
import { sortByDateAscending, sortByDayAscending } from "./array";

export interface ApiExpense {
  id: string;
  lastOccurrenceDate: string;
  nextOccurrenceDate: string;
  merchantName?: string;
  personalFinanceCategoryPrimary: keyof typeof PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP;
  personalFinanceCategorySecondary: keyof typeof PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP;
  description: string;
  averageAmount: number;
}

export const sortExpensesByLastOccurenceAscending = (expenses: ApiExpense[]) =>
  sortByDateAscending(expenses, "lastOccurrenceDate");

export const sortExpensesByNextOccurenceAscending = (expenses: ApiExpense[]) =>
  sortByDateAscending(expenses, "nextOccurrenceDate");

export const sortExpensesByDayAscending = (expenses: ApiExpense[]) =>
  sortByDayAscending(expenses, "lastOccurrenceDate");

export const filterExpensesByNextPayday = (
  expenses: ApiExpense[],
  nextPayday
) => {
  if (!nextPayday) return [];

  return expenses.filter((expense) => {
    const expenseDate = new Date(expense.nextOccurrenceDate);
    const nextPaydayDate = new Date(nextPayday);
    return expenseDate <= nextPaydayDate;
  });
};
