import styled from "styled-components";
import Tile from "components/ui/Tile";
import { fontSizesUI, mediaMax, mediaMin, shadows } from "utils/theme";

export const TextWithTooltip = styled.div`
  display: flex;
  gap: 8px;

  svg {
    display: block;
  }

  &::after {
    content: attr(data-tooltip);

    display: block;
    margin-top: 4px;

    font-size: ${fontSizesUI.sm}px;
  }

  ${mediaMin("lg")} {
    &[data-tooltip] {
      text-decoration: underline;

      cursor: help;

      position: relative;

      &::after {
        position: absolute;
        left: 0;
        z-index: 1;
        bottom: calc(100% + 8px);

        width: 200px;
        padding: 12px;

        background: white;
        box-shadow: ${shadows.tile};
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        &::after {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  ${mediaMax("lg")} {
    flex-direction: column;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`;

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-x: auto;
  overflow-y: hidden;

  ${mediaMax("lg")} {
    padding: 16px;

    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }

  tr {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      ${TextWithTooltip} {
        &::after {
          top: calc(100% + 8px);
          bottom: auto;
        }
      }
    }
  }
`;
