import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  ${mediaMax("md")} {
    gap: 24px;
  }
`;

export const TabsWrapper = styled.div`
  position: relative;
  z-index: 1;

  margin: 0 auto;
  width: fit-content;
`;

export const CarouselWrapper = styled.div`
  position: relative;

  display: flex;
  width: 100%;

  & > * {
    width: 100%;
    flex: 0 0 auto;
  }
`;
