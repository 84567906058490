import React, { useRef } from "react";
import { LinkButton } from "components/ui/Link";
import { ModalApi } from "components/modal/Modal";
import ConfirmActionModal from "components/modal/ConfirmActionModal";
import { usePostInternalMessageMutation } from "store/api/apiSlice";

const CancelApplicationButton = () => {
  const modal = useRef<ModalApi>(null);
  const [postInternalMessage, { isLoading }] = usePostInternalMessageMutation();

  const submitRequest = async () => {
    await postInternalMessage({
      type: "Cancel card application",
      body: "Please cancel my Reset card application.",
    });

    window.location.href = "/application-cancelled";
  };

  const openModal = () => {
    modal.current?.show();
  };

  return (
    <div>
      <LinkButton onClick={openModal}>Cancel Application</LinkButton>

      <ConfirmActionModal
        ref={modal}
        title="Are you sure you want to cancel your card application?"
        body={
          <>
            <p>
              A member of the Reset team will follow up with you via email to
              confirm before any action is&nbsp;taken.
            </p>

            <p>
              If you need help completing your application, please visit our{" "}
              <a href="/faqs" target="_blank">
                FAQs
              </a>{" "}
              or reach out to{" "}
              <a href="mailto:hello@getreset.co">hello@getreset.co</a>.
            </p>
          </>
        }
        action={() => submitRequest()}
        actionLabel="Request Cancellation"
        cancelLabel="Go back"
        isLoading={isLoading}
      />
    </div>
  );
};

export default CancelApplicationButton;
