import React, { useState } from "react";
import { Heading } from "components/ui/Text";
import { ApiAdminUser } from "utils/api/admin/user";
import { formatDateString } from "utils/date";
import { formatCurrency, snakeCaseToTitleCase } from "utils/string";
import {
  getAdminUserPlaidStatus,
  getAdminUserPlaidTransactions,
} from "store/admin/api/apiSlice";
import Button from "components/ui/Button";
import * as Styled from "./styled";
import Table from "../../components/Table";

const HEADINGS = [
  "Date",
  "Amount",
  "Category",
  "Category ID",
  "Merchant",
  "Name",
  "Type",
];

interface Props {
  user: ApiAdminUser;
}

const LinkedBankAccount: React.FC<Props> = ({ user }) => {
  const { data: plaidStatus } = getAdminUserPlaidStatus(user.id);

  const [showTransactions, setShowTransactions] = useState(false);
  const { data } = getAdminUserPlaidTransactions(user.id, {
    skip: !showTransactions,
  });

  const { plaidTransactions: transactions } = data || {};

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Linked Bank Account</Heading.H4>
      {plaidStatus && (
        <Styled.InfoSection>
          <Styled.InfoItem label="Error" value={plaidStatus.error || "n/a"} />
          <Styled.InfoItem
            label="Balance Last Successful Update At"
            value={formatDateString(plaidStatus.balancesUpdatedAt || "n/a")}
          />
          <Styled.InfoItem
            label="Transactions Last Successful Update At"
            value={formatDateString(
              plaidStatus.transactionsSuccessfulUpdatedAt || "n/a"
            )}
          />
          <Styled.InfoItem
            label="Transactions Last Failed Update At"
            value={formatDateString(
              plaidStatus.transactionsFailUpdatedAt || "n/a"
            )}
          />
          <Styled.InfoItem
              label="Plaid Item ID"
              value={plaidStatus.plaidItemId || "n/a"}
          />
        </Styled.InfoSection>
      )}

      {showTransactions ? (
        <Table>
          <thead>
            <tr>
              {HEADINGS.map((heading) => (
                <th key={heading}>{heading}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {transactions?.map((transaction, index) => (
              <tr key={index} data-heading={HEADINGS[index]}>
                <td>
                  {formatDateString(transaction.date, "numericShortWithTime")}
                </td>
                <td>{formatCurrency(transaction.amount, true)}</td>
                <td>{transaction.category}</td>
                <td>{transaction.categoryId}</td>
                <td>{transaction.merchantName}</td>
                <td>{transaction.name}</td>
                <td>{snakeCaseToTitleCase(transaction.transactionType)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Button onClick={() => setShowTransactions(true)}>
          Show Transactions
        </Button>
      )}
    </Styled.SectionTile>
  );
};

export default LinkedBankAccount;
