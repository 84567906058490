import React from "react";
import msufcuLogo from "assets/images/partners/msufcu.svg";
import msufcuLogoReverse from "assets/images/partners/msufcu-reverse.svg";
import cusgLogo from "assets/images/partners/cusg.svg";
import cusgLogoReverse from "assets/images/partners/cusg-reverse.svg";
import { PartnerSlug } from "types/general";
import { Partner } from "./types";
import MsufcuBannerMessage from "./MsufcuBannerMessage";
import CusgBannerMessage from "./CusgBannerMessage";

export const PARTNERS: {
  [key in PartnerSlug]: Partner;
} = {
  msufcu: {
    slug: "msufcu",
    name: "MSUFCU",
    enableProbationaryPeriod: false,
    logo: { color: msufcuLogo, reverse: msufcuLogoReverse },
    banner: {
      message: <MsufcuBannerMessage />,
      theme: {
        color: "#FFFFFF",
        background: "#004F38",
      },
    },
    signup: {
      bank: { searchString: "michigan state", id: "ins_105483" },
    },
    faqs: {
      identityVerification: [
        {
          title: "Why does Reset need this information?",
          body: "The Reset card application requires a separate identity verification than the one for opening an MSUFCU account.",
        },
      ],
    },
  },
  cusg: {
    slug: "cusg",
    name: "CUSG",
    enableProbationaryPeriod: false,
    logo: { color: cusgLogo, reverse: cusgLogoReverse },
    banner: {
      message: <CusgBannerMessage />,
      theme: {
        color: "#FFFFFF",
        background: "#004d98",
      },
    },
    signup: {
      payroll: {
        searchString: "paylocity",
        id: "913170d1-393c-4f35-8c23-df3133ce7529",
      },
    },
  },
};
