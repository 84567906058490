import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaMax("md")} {
    gap: 36px;
  }
`;

export const Footer = styled.div`
  padding: 48px 0 24px;
  display: flex;
  justify-content: center;
`;
