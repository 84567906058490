import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Tile from "components/cms/components/block/Tile";
import backgroundShape from "assets/images/home/section-divider.svg";
import * as Styled from "./styled";
import { FEATURES } from "../../constants";

const Features = () => (
  <Styled.Wrapper>
    <Styled.BackgroundShape src={backgroundShape} />

    <ContentLayout>
      <Tile {...FEATURES} />
    </ContentLayout>
  </Styled.Wrapper>
);

export default Features;
