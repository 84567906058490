import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: grid;
  gap: 36px;

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;
