import { LineItemDetail } from "components/ui/LineItem";
import { ApiShiftEarning, CompensationUnit } from "utils/api/income";
import { formatCurrency } from "utils/string";

export const getAmount = ({
  earning,
  compensationAmount,
  compensationUnit,
}: {
  earning: ApiShiftEarning;
  compensationAmount: number;
  compensationUnit: CompensationUnit;
}) => {
  if (earning.amount) {
    return earning.amount / 100;
  }
  if (earning.hours && compensationUnit === "hourly") {
    return (earning.hours * compensationAmount) / 100;
  }
  return 0;
};

export const getDetails = (
  { amount, hours }: ApiShiftEarning,
  netPayRatio: number
) => {
  const details: LineItemDetail[] = [
    {
      label: "Net earnings",
      value: formatCurrency(amount * netPayRatio, true),
    },
  ];
  if (hours) {
    details.push({
      label: "Hours",
      value: hours,
    });
  }
  return details;
};
