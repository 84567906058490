import React from "react";
import styled from "styled-components";
import LightbulbFillSvg from "./LightbulbFill";
import Icon from "..";

const Wrapper = styled.div`
  position: relative;

  & > * {
    position: relative;

    display: block;
  }
`;

const LightbulbFill = styled(LightbulbFillSvg)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 12px;
`;

const LightbulbIcon = () => (
  <Wrapper>
    <LightbulbFill />
    <Icon name="lightbulbOn" />
  </Wrapper>
);

export default LightbulbIcon;
