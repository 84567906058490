import React, { useRef } from "react";
import TabbedCarousel from "components/shared/TabbedCarousel";
import AccountsOverview from "components/shared/AccountsOverview";
import { ApiUserProfile } from "utils/api/user";
import { EventType, trackEvent } from "utils/analytics";
import { useLocation } from "react-router-dom";
import environment from "utils/environment";
import { ModalApi } from "components/modal/Modal";
import PaydayCelebrationModal from "components/modal/PaydayCelebrationModal";
import * as Styled from "./styled";
import PaydayTab from "./PaydayTab";
import DashboardTile from "../DashboardTile";

interface Props {
  userProfile: ApiUserProfile;
}

const TABS = [
  {
    id: "overview",
    label: "Overview",
    component: (
      <DashboardTile>
        <AccountsOverview />
      </DashboardTile>
    ),
  },
  { id: "payday", label: "Payday", component: <PaydayTab /> },
];

const DashboardTabs: React.FC<Props> = ({ userProfile }) => {
  const { hash } = useLocation();
  const paydayModal = useRef<ModalApi>(null);

  const onChangeTab = (tab) => {
    switch (tab.id) {
      case "payday":
        if (environment.enableDemoTriggers) {
          paydayModal.current?.show();
        }
        break;
      default:
    }

    if (tab.id === "overview") {
      window.location.hash = "";
    } else {
      window.location.hash = tab.id;
    }

    trackEvent(EventType.dashboardTabChanged, { data: { tab: tab.id } });
  };

  const activeTab = TABS.find((tab) => tab.id === hash.slice(1));

  if (!userProfile.currentCardAccount)
    return (
      <Styled.LineItemsWrapper>
        <AccountsOverview />
      </Styled.LineItemsWrapper>
    );

  return (
    <Styled.Wrapper>
      <TabbedCarousel
        tabs={TABS}
        onChange={onChangeTab}
        activeId={activeTab?.id}
      />

      <PaydayCelebrationModal ref={paydayModal} />
    </Styled.Wrapper>
  );
};

export default DashboardTabs;
