import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { isDefined } from "utils/object";
import { colors, transitions } from "utils/theme";
import UILabel from "components/ui/UILabel";
import { BaseButton } from "components/ui/Button";

export const Wrapper = styled.div`
  display: flex;
  padding: 4px;

  border-radius: 9999px;
  overflow: hidden;
  background: ${colors.violet}20;
`;

export const Label = styled(UILabel.Micro).attrs(() => ({
  wideCaps: true,
  bold: true,
}))`
  position: relative;
  z-index: 1;
`;

export const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: 9999px;
  background: ${colors.white};

  transition: color ${transitions.default};
`;

export const Tab = styled(BaseButton)<{ $isActive: boolean; $width?: number }>`
  position: relative;

  padding: 4px 16px;
  height: 24px;

  ${(props) =>
    isDefined(props.$width)
      ? css`
          flex: 0 0 ${props.$width};
        `
      : css`
          flex: 1 1 auto;
        `}

  ${(props) =>
    props.$isActive
      ? css`
          color: ${colors.midnight};
        `
      : css`
          color: ${colors.midnight}A0;
        `}
`;
