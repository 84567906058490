import React from "react";
import styled from "styled-components";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";

const StyledField = styled.div`
  display: grid;
  gap: 6px;
`;

interface Props {
  name: string;
  label?: React.ReactNode;
  children: React.ReactNode;
  errorMessage?: string;
}

const Field: React.FC<Props> = ({ name, label, children, errorMessage }) => (
    <StyledField>
      {label && <Label htmlFor={name}>{label}</Label>}

      {children}

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </StyledField>
  );

export default Field;
