import React from "react";
import App from "App";
import ContentfulPage from "components/shared/ContentfulPage";

const CmsPage = () => (
    <App>
      <ContentfulPage />
    </App>
  );

export default CmsPage;
