import React from "react";
import styled from "styled-components";
import { IconName } from "components/ui/Icon";
import { mediaMax } from "utils/theme";
import TileCheckbox from "../ui/TileCheckbox";
import FieldContainer from "../FieldContainer";

const Wrapper = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);

  ${mediaMax("lg")} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaMax("md")} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export interface TileCheckboxOption {
  name: string;
  label: string;
  icon: IconName;
}

interface Props {
  name: string;
  options: TileCheckboxOption[];
}

const TileCheckboxGroupField: React.FC<Props> = ({
  name,
  options,
  ...props
}) => (
  <Wrapper {...props}>
    {options.map((option) => {
      const optionName = `${name}.${option.name}`;
      return (
        <FieldContainer name={optionName} key={optionName}>
          {({ inputProps }) => <TileCheckbox {...option} {...inputProps} />}
        </FieldContainer>
      );
    })}
  </Wrapper>
);

export default TileCheckboxGroupField;
