import React from "react";
import FormContainer from "components/form";
import Questionnaire from "./Questionnaire";
import { SurveyResults, SurveyStep } from "./types";
import Signup from "./Signup";
import Results from "./Results";
import Intro from "./Intro";

interface Props {
  currentStep: SurveyStep;
  results: SurveyResults;
  getStarted: () => void;
  onSubmitQuestionnaire: (values: any) => void;
  onSubmitSignup: (values: any) => void;
  postSurveyResults: any; // TODO: type this
}

const Step: React.FC<Props> = ({
  currentStep,
  results,
  getStarted,
  onSubmitQuestionnaire,
  onSubmitSignup,
  postSurveyResults,
}) => {
  switch (currentStep) {
    case SurveyStep.Signup:
      return (
        <FormContainer onSubmit={onSubmitSignup}>
          <Signup postSurveyResults={postSurveyResults} />
        </FormContainer>
      );
    case SurveyStep.Results:
      return results ? <Results {...results} /> : null;
    case SurveyStep.Questionnaire:
      return (
        <FormContainer onSubmit={onSubmitQuestionnaire}>
          <Questionnaire />
        </FormContainer>
      );
    default:
      return <Intro getStarted={getStarted} />;
  }
};

export default Step;
