import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { colors, mediaMax, mediaMin, zIndex } from "utils/theme";
import { BodyLarge } from "components/ui/Text";
import Button from "components/ui/Button";
import Icon from "components/ui/Icon";
import { SubmitButton } from "components/form";

export const Wrapper = styled.div`
  min-height: 100vh;
  padding-bottom: 120px;

  background: white;
`;

export const ContentWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${mediaMax("lg")} {
    padding: 24px;
  }

  ${mediaMax("md")} {
    padding: 16px;
  }
`;

export const PromptCarousel = styled.div`
  position: relative;
`;

export const PromptWrapper = styled(motion.div)`
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const Prompt = styled(BodyLarge).attrs(() => ({ tag: "div" }))`
  display: flex;
  flex-direction: column;
  gap: 12px;

  color: ${colors.midnight};

  * {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.nav};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92px;
  padding: 0;
  margin: 0;

  background: ${colors.midnight};
`;

export const FooterInner = styled.div`
  width: 100%;
  max-width: 656px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  & > * {
    flex: 0 0 auto;
    width: calc(50% - 8px);
  }

  ${mediaMax("lg")} {
    padding: 0 24px;
  }

  ${mediaMax("md")} {
    padding: 0 16px;
  }
`;

export const ButtonIcon = styled(Icon)`
  * {
    stroke-width: 20px;
    stroke: currentColor;
  }

  ${mediaMin("md")} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const BackButton = styled(Button)`
  position: relative;

  background: transparent;
  border: 1.5px solid ${colors.flax}40;
  color: white;

  ${ButtonIcon} {
    left: 16px;
  }

  ${mediaMax("md")} {
    width: auto;
    padding: 0 24px;
    border: 0;

    text-decoration: underline;

    ${ButtonIcon} {
      display: none;
    }
  }
`;

const nextButtonStyles = css`
  position: relative;

  ${ButtonIcon} {
    right: 16px;
  }

  ${mediaMax("md")} {
    flex: 1 1 auto;

    ${ButtonIcon} {
      margin-right: -16px;
    }
  }
`;

export const NextButton = styled(Button)`
  ${nextButtonStyles}

  background: ${colors.azure};
`;

export const FinishButton = styled(SubmitButton)`
  ${nextButtonStyles}

  background: ${colors.heliotrope};
`;
