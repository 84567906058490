import React from "react";

const CreditCard = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <rect
      x="2.49505"
      y="7.84158"
      width="19.0099"
      height="3.48515"
      fill="var(--color-accent)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.505 4.67328C20.6095 4.67328 21.505 5.56871 21.505 6.67328V7.84158H2.49505V6.67328C2.49505 5.56871 3.39048 4.67328 4.49505 4.67328H19.505ZM4.49505 19.3267C3.39048 19.3267 2.49505 18.4313 2.49505 17.3267V11.3267H21.505V17.3267C21.505 18.4313 20.6095 19.3267 19.5049 19.3267H4.49505Z"
      fill="var(--color-primary)"
    />
    <path
      d="M5.25 15.9375C5.25 15.6281 5.50312 15.375 5.8125 15.375H8.0625C8.37188 15.375 8.625 15.6281 8.625 15.9375C8.625 16.2469 8.37188 16.5 8.0625 16.5H5.8125C5.50312 16.5 5.25 16.2469 5.25 15.9375ZM9.75 15.9375C9.75 15.6281 10.0031 15.375 10.3125 15.375H14.8125C15.1219 15.375 15.375 15.6281 15.375 15.9375C15.375 16.2469 15.1219 16.5 14.8125 16.5H10.3125C10.0031 16.5 9.75 16.2469 9.75 15.9375ZM1.875 6.375C1.875 5.13223 2.88223 4.125 4.125 4.125H19.875C21.116 4.125 22.125 5.13223 22.125 6.375V17.625C22.125 18.866 21.116 19.875 19.875 19.875H4.125C2.88223 19.875 1.875 18.866 1.875 17.625V6.375ZM3 6.375V7.5H21V6.375C21 5.75379 20.4973 5.25 19.875 5.25H4.125C3.50379 5.25 3 5.75379 3 6.375ZM3 10.875H21V8.625H3V10.875ZM3 12V17.625C3 18.2473 3.50379 18.75 4.125 18.75H19.875C20.4973 18.75 21 18.2473 21 17.625V12H3Z"
      fill="var(--color-outline)"
    />
  </svg>
);

export default CreditCard;
