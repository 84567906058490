import React from "react";
import { IconName } from "components/ui/Icon";

export enum BudgetStep {
  Intro = "INTRO",
  Questionnaire = "QUESTIONNAIRE",
  Signup = "SIGNUP",
  Results = "RESULTS",
}

export enum IncomeFrequency {
  weekly = "WEEKLY",
  biweekly = "BIWEEKLY",
  semimonthly = "SEMIMONTHLY",
  monthly = "MONTHLY",
  annually = "ANNUALLY",
}

export interface BudgetFormData {
  isValid: boolean[];
  expenseSelection: { [key: string]: boolean };
  expenses: { [key: string]: string };
  income: string;
  incomeFrequency: IncomeFrequency;
}

export interface BudgetPrompt {
  title: string;
  icon: IconName;
  text: React.ReactNode;
  Form: React.FC<{ updateValidation: (isValid: boolean) => void }>;
}

export interface BudgetExpense {
  name: string;
  amount: number;
}

export interface BudgetData {
  version: number;
  income: number; // annual
  expenses: BudgetExpense[];
}

export interface BudgetResults {
  monthlyIncome: number;
  needs: number;
  wants: number;
}

export enum SpendingBucket {
  wants = "WANTS",
  needs = "NEEDS",
  savings = "SAVINGS",
}
