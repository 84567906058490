import React from "react";
import LineItem from "components/ui/LineItem";
import Loading from "components/ui/Loading";
import { ApiPaystub } from "utils/api/income";
import * as Styled from "./styled";

interface Props {
  isLoading: boolean;
  paystubs: ApiPaystub[];
}

const Paystubs: React.FC<Props> = ({ isLoading, paystubs }) => {
  if (isLoading) return <Loading />;

  if (!paystubs?.length) return null;

  return (
    <Styled.Wrapper>
      {paystubs.map((item, index) => (
        <Styled.LineItemWrapper
          key={`${item.grossPayAmount}.${item.payDate}.${index}`}
        >
          <LineItem
            date={item.payDate}
            title="Income"
            amount={item.netPayAmount}
          />
        </Styled.LineItemWrapper>
      ))}
    </Styled.Wrapper>
  );
};

export default Paystubs;
