import React from "react";
import { Outlet } from "react-router-dom";
import AdminLayout from "components/layout/AdminLayout";

const Root: React.FC = () => (
    <AdminLayout>
      <Outlet />
    </AdminLayout>
  );

export default Root;
