import styled from "styled-components";
import { motion } from "framer-motion";
import ContentLayout from "components/layout/ContentLayout";
import { colors, zIndex } from "utils/theme";

export const Wrapper = styled.div``;

export const Main = styled.div`
  position: fixed;
  top: 0;
  z-index: ${zIndex.nav};

  width: 100%;

  background: ${colors.midnight};
`;

export const MainContent = styled(ContentLayout)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 72px;

  color: ${colors.white};
`;

export const Title = styled.div`
  display: flex;
  gap: 8px;
`;

export const NavItems = styled.div`
  display: flex;
  gap: 12px;
`;

export const NavItem = styled.a`
  color: ${colors.flax};
`;

export const Progress = styled.div`
  width: 100%;
  height: 4px;
  background: ${colors.flax};
`;

export const ProgressBar = styled(motion.div)`
  height: 100%;

  background: ${colors.azure};
`;

export const StepInfoWrapper = styled.div``;

export const StepInfo = styled(ContentLayout)`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 24px;

  &:empty {
    margin-top: 76px;
    margin-bottom: 0;
  }
`;

export const CurrentStep = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
  background: ${colors.flax};
`;
