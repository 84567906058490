import styled from "styled-components";
import { borderRadius, colors, mediaMax } from "utils/theme";
import { ButtonLink } from "components/ui/Button";
import Link from "components/ui/Link";

export const Callout = styled.div`
  padding: 24px;
  margin: 144px 0 -48px;

  background: ${colors.white};
  border-radius: ${borderRadius.small}px;

  * {
    color: ${colors.midnight};
  }

  ${mediaMax("lg")} {
    margin: 64px 0 -24px;
  }
`;

export const CalloutMain = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaMax("lg")} {
    flex-direction: column;
  }
`;

export const CalloutContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CalloutLinkMobile = styled.a`
  display: none;

  ${mediaMax("lg")} {
    display: inline;
  }
`;

export const CalloutLinks = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  gap: 24px;

  ${mediaMax("lg")} {
    width: 100%;
    flex: 0 0 auto;
    margin-top: 16px;
  }
`;

export const CalloutLink = styled(Link)`
  &,
  &:visited,
  &:hover {
    color: ${colors.azure} !important;
  }

  ${mediaMax("lg")} {
    display: none;
  }
`;

export const CalloutButton = styled(ButtonLink)`
  * {
    color: white;
  }

  ${mediaMax("lg")} {
    width: 100%;
  }
`;

export const CalloutIcon = styled.div`
  flex: 0 0 auto;
  margin: -8px 0;

  img {
    display: block;
    width: 64px;
  }

  ${mediaMax("md")} {
    img {
      width: 40px;
    }
  }
`;
