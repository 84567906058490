import React from "react";
import Tag, { TagLevel } from "components/ui/Tag";
import { TransactionCustomerState } from "utils/api/cardTransactions";
import { capitalize } from "utils/string";
import styled from "styled-components";

const StyledTag = styled(Tag)`
  width: fit-content;
  margin-top: 2px;
`;

const mapStatusToLevel = (status: TransactionCustomerState) => {
  switch (status) {
    case TransactionCustomerState.pending:
      return TagLevel.pending;
    case TransactionCustomerState.declined:
      return TagLevel.warning;
    case TransactionCustomerState.repaid:
      return TagLevel.success;
    case TransactionCustomerState.expired:
    case TransactionCustomerState.manuallyExpired:
      return TagLevel.disabled;
    case TransactionCustomerState.posted:
    default:
      return TagLevel.info;
  }
};

const StatusTag: React.FC<{
  status: TransactionCustomerState;
}> = ({ status }) => {
  const level = mapStatusToLevel(status);
  return (
    <StyledTag level={level}>{capitalize(status.replace("_", " "))}</StyledTag>
  );
};

export default StatusTag;
