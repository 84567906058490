import styled from "styled-components";
import Tile from "components/ui/Tile";
import { mediaMax } from "utils/theme";

export const Wrapper = styled(Tile).attrs(() => ({ size: "xl" }))`
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-x: auto;

  ${mediaMax("lg")} {
    padding: 16px;

    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
`;
