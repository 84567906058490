import React from "react";
import styled from "styled-components";
import Icon from "components/ui/Icon";
import { colors, fontSizes, fonts, shadows, transitions } from "utils/theme";

const StyledSelect = styled.select<{ $hasError?: boolean }>`
  width: 100%;
  height: 48px;
  padding: 0 12px;

  background: white;
  border: 1px solid ${colors.midnight}40;
  border-radius: 8px;
  outline: 0;
  appearance: none;

  font-family: ${fonts.body};
  font-size: ${fontSizes.sm}px;

  transition: all ${transitions.simple};

  ${(props) =>
    props.$hasError
      ? `
  border-color: ${colors.cerise};
  `
      : ""}

  &:focus {
    border-color: ${colors.midnight};
    box-shadow: ${shadows.regular};
  }

  &:invalid {
    color: ${colors.midnight}80;
  }
`;

const Wrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    color: ${colors.midnight}80;
  }
`;

export interface SelectOption {
  name?: string;
  value: string;
  data?: any;
}

export interface SelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  value?: any;
  hasError?: boolean;
  options: SelectOption[];
}

const Select = (
  { name, onChange, options, hasError, ...props }: SelectProps,
  ref: React.Ref<HTMLSelectElement>
) => (
  <Wrapper>
    <StyledSelect
      name={name}
      onChange={onChange}
      ref={ref}
      $hasError={hasError}
      {...props}
    >
      <option value="" disabled>
        Select one
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name || option.value}
        </option>
      ))}
    </StyledSelect>

    <Icon name="chevronDown" size="sm" />
  </Wrapper>
);

export default React.forwardRef(Select);
