import styled, { css } from "styled-components";
import { BlockBackground } from "types/cms";
import { colors, mediaMax, mediaMin } from "utils/theme";

const getBackgroundColor = (color: BlockBackground) => {
  switch (color) {
    case BlockBackground.white:
      return colors.white;
    case BlockBackground.lightBlue:
      return `${colors.azure}18`;
    case BlockBackground.gradient:
      return `linear-gradient(to right, ${colors.heliotrope}, ${colors.azure})`;
    case BlockBackground.dark:
      return colors.midnight;
    default:
      return color;
  }
};

export const ContentBlocks = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    padding-top: 80px;

    ${mediaMax("lg")} {
      padding-top: 64px;
    }
  }

  &:last-child {
    padding-bottom: 120px;

    ${mediaMax("lg")} {
      padding-bottom: 80px;
    }
  }

  & > *:first-child {
    margin-top: -80px;

    ${mediaMax("lg")} {
      margin-top: -64px;
    }
  }
`;

export const ContentBlock = styled.div<{
  $background: BlockBackground;
  $hasNextBlockDifferentBg: boolean;
  $hasNextBlockDivider: boolean;
}>`
  position: relative;

  padding-top: 80px;

  ${mediaMax("lg")} {
    padding-top: 64px;
  }

  ${(props) =>
    props.$background &&
    css`
      background: ${getBackgroundColor(props.$background)};

      &:first-child {
        padding-top: 80px;
        margin-top: -80px;

        ${mediaMax("lg")} {
          padding-top: 64px;
          margin-top: -64px;
        }
      }

      &:last-child {
        padding-bottom: 80px;

        ${mediaMax("lg")} {
          padding-bottom: 64px;
        }
      }
    `}

  ${(props) =>
    props.$hasNextBlockDivider &&
    css`
      padding-bottom: 80px;

      ${mediaMax("lg")} {
        padding-bottom: 64px;
      }
    `}

  ${(props) =>
    props.$hasNextBlockDifferentBg &&
    css`
      padding-bottom: 80px;

      ${mediaMax("lg")} {
        padding-bottom: 64px;
      }
    `}
`;

export const DividerWrapper = styled.div<{
  $background: BlockBackground;
  $isMirrored: boolean;
  $previousBlockBackground?: BlockBackground;
}>`
  position: relative;

  width: 100%;

  pointer-events: none;

  ${(props) =>
    props.$previousBlockBackground &&
    css`
      background: ${getBackgroundColor(props.$previousBlockBackground)};
    `}

  ${(props) =>
    props.$isMirrored &&
    css`
      svg {
        transform: scaleX(-1);
      }
    `}

  &::after {
    content: "";

    display: block;
    width: 100%;
    height: 40px;
    margin-top: -1px;

    background: ${(props) => getBackgroundColor(props.$background)};
  }

  svg {
    display: block;
    width: 100%;

    ${(props) =>
      props.$background !== BlockBackground.gradient &&
      css`
        * {
          fill: ${getBackgroundColor(props.$background)};
        }
      `}
  }

  & + * {
    padding-top: 0;
  }
`;

export const Footer = styled.footer`
  padding-bottom: 96px;
`;

export const Wrapper = styled.div<{
  $withSidebar?: boolean;
  $noLayout?: boolean;
}>`
  --color-background: ${colors.ice};

  display: flex;
  flex-direction: column;
  gap: 80px;
  min-height: calc(100vh - 72px);

  background: var(--color-background);

  ${mediaMax("lg")} {
    gap: 60px;
  }

  ${mediaMax("md")} {
    gap: 40px;
  }

  ${(props) =>
    props.$withSidebar &&
    css`
      --color-background: color-mix(in srgb, ${colors.azure} 3%, white);

      ${mediaMin("lg")} {
        margin: -48px;
      }
    `}

  ${(props) =>
    props.$noLayout &&
    css`
      min-height: 0;
      margin: 0 !important;

      background: none;

      ${ContentBlocks} {
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    `}
`;
