import React from "react";
import { local } from "utils/storage";
import { LOCAL_KEY } from "utils/constants/general";
import styled from "styled-components";
import { ApiUserProfile } from "utils/api/user";
import NotificationsContainer from "components/container/NotificationsContainer";
import NotificationsList from "./NotificationsList";
import Toolbar from "./Toolbar";
import InterviewBanner from "./InterviewBanner";

const NotificationsWrapper = styled.div`
  position: relative;
  z-index: 1;

  margin: 0 auto;
  width: 100%;

  & > * {
    width: 100%;
  }

  &:empty {
    display: none;
  }
`;

const ToolbarWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

interface Props {
  userProfile: ApiUserProfile;
}

const Header: React.FC<Props> = ({ userProfile }) => {
  // Keeping the infrastructure in place if we ever want to show the banner again.
  // Was previously used by RF-5.
  const [, setIsBannerDismissed] = React.useState(
    local.getItem(LOCAL_KEY.HIDE_INTERVIEW_BANNER) as boolean
  );
  const showInterviewBanner = false;

  const dismissBanner = () => {
    local.setItem(LOCAL_KEY.HIDE_INTERVIEW_BANNER, true);
    setIsBannerDismissed(true);
  };

  return (
    <>
      {showInterviewBanner ? (
        <InterviewBanner
          userProfile={userProfile}
          dismissBanner={dismissBanner}
        />
      ) : (
        <ToolbarWrapper>
          <Toolbar userProfile={userProfile} />
        </ToolbarWrapper>
      )}

      <NotificationsWrapper>
        <NotificationsContainer>
          {({ notifications, onResolveIntegrationError }) => (
            <NotificationsList
              notifications={notifications}
              onSuccess={onResolveIntegrationError}
            />
          )}
        </NotificationsContainer>
      </NotificationsWrapper>
    </>
  );
};

export default Header;
