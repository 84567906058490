import React, { useEffect, useRef, useState } from "react";
import { useScript } from "@uidotdev/usehooks";
import { v4 as uuid } from "uuid";
import DisappearingTile from "components/ui/DisappearingTile";
import Loading from "components/ui/Loading";
import {
  getIdentityVerificationStatus,
  getUserProfile,
  usePostIdentityVerificationSubmitVerificationMutation,
} from "store/api/apiSlice";
import environment from "utils/environment";
import { API_URL } from "utils/api/common";
import { VerificationStatus } from "types/verification";
import * as Styled from "./styled";
import SendLink from "./SendLink";
import WaitingForUpload from "./WaitingForUpload";
import { ProgressStatus, SocureConfig, WindowWithSocure } from "./types";
import Message from "../Message";

const MAX_RETRIES = 3;
const CAPTURE_APP_WORKFLOW_ENUM = 1; // corresponds to document upload only workflow

const getCaptureAppConfig = (userId: string) => ({
  redirect: {
    method: "POST",
    url: `${API_URL}/identity_verifications/socure_redirect/${userId}`, // use something like ngrok to test locally
  },
});

const cleanupSocure = () => {
  const { Socure } = window as unknown as WindowWithSocure;
  if (Socure) {
    Socure.reset();
    Socure.unmount();
  }
  window.sessionStorage.removeItem("documentVerificationToken");
  window.sessionStorage.removeItem("publicApiKey");
  window.localStorage.removeItem("devicer_id");
};

const DocumentUpload = () => {
  const { data: userProfile } = getUserProfile();
  const { refetch: refetchVerificationStatus } =
    getIdentityVerificationStatus();
  const [submitDocumentId, submitDocumentIdResults] =
    usePostIdentityVerificationSubmitVerificationMutation();

  const lib = useRef(null);
  const containerId = useRef(`socure-container-${uuid()}`);
  const scriptStatus = useScript("https://websdk.socure.com/bundle.js", {
    removeOnUnmount: true,
  });
  const [isSocureReady, setIsSocureReady] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [progressStatus, setProgressStatus] = useState<ProgressStatus | null>(
    null
  );
  const [retryCount, setRetryCount] = useState(0);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleError = () => {
    window.location.reload();
  };

  const handleSuccess = () => {
    cleanupSocure();
    refetchVerificationStatus();
    setIsUploaded(true);
  };

  // Start Socure

  const onSocureProgress = (progress) => {
    setProgressStatus(progress?.status);
  };

  const onSocureError = () => {
    handleError();
  };

  const onSocureSuccess = (response) => {
    const documentId = response?.documentUuid;
    if (documentId) {
      submitDocumentId(documentId);
    } else {
      handleError();
    }
  };

  const config: SocureConfig = {
    onProgress: onSocureProgress,
    onSuccess: onSocureSuccess,
    onError: onSocureError,
    qrCodeNeeded: false,
  };

  const initSocure = async () => {
    const { SocureInitializer } = window as unknown as WindowWithSocure;
    lib.current = await SocureInitializer.init(environment.socureSdkKey);
    lib.current.init(
      environment.socureSdkKey,
      `#${containerId.current}`,
      config
    );
    setIsSocureReady(true);
  };

  useEffect(() => {
    if (scriptStatus === "ready") {
      initSocure();
    }
  }, [scriptStatus]);

  // End Socure

  useEffect(() => {
    if (submitDocumentIdResults.isSuccess) {
      handleSuccess();
    } else if (submitDocumentIdResults.isError) {
      if (retryCount < MAX_RETRIES) {
        // if we have document_id, try again so user may not have to reupload
        setRetryCount((c) => c + 1);
        const documentId = submitDocumentIdResults?.originalArgs;
        submitDocumentId(documentId);
      } else {
        handleError();
      }
    }
  }, [submitDocumentIdResults]);

  const onSubmitPhoneNumber = async (values) => {
    setPhoneNumber(values.phoneNumber);
    const rawPhoneNumber = values.phoneNumber.replace(/[^0-9]/g, "");
    const mobileNumber = `+1${rawPhoneNumber}`;
    const captureAppConfig = getCaptureAppConfig(userProfile.id);
    await lib.current.start(
      CAPTURE_APP_WORKFLOW_ENUM,
      mobileNumber,
      captureAppConfig
    );
  };

  if (!userProfile) return null;

  let content = null;
  switch (progressStatus) {
    case "WAITING_FOR_USER_TO_REDIRECT":
    case "WAITING_FOR_REDIRECT_METHOD":
    case "WAITING_FOR_UPLOAD":
      content = (
        <WaitingForUpload status={progressStatus} phoneNumber={phoneNumber} />
      );
      break;
    default:
      content = (
        <SendLink
          onSubmitPhoneNumber={onSubmitPhoneNumber}
          phoneNumber={userProfile.phoneNumber}
        />
      );
      break;
  }

  if (isUploaded) {
    return <Message status={VerificationStatus.submittedWithDocument} />;
  }

  return (
    <DisappearingTile size="xl">
      <Styled.SocureWrapper id={containerId.current}></Styled.SocureWrapper>

      {isSocureReady ? content : <Loading />}
    </DisappearingTile>
  );
};

export default DocumentUpload;
