import React from "react";
import styled from "styled-components";
import Button, { ButtonLink } from "components/ui/Button";
import { CtaType } from "types/cms";

const Container = styled.div`
  display: flex;

  a,
  button {
    width: auto;
    max-width: 100%;
  }
`;

const CtaButton = ({ type, data: { text, ...data } }) => (
  <Container>
    {type === CtaType.button ? (
      <Button {...data}>{text}</Button>
    ) : (
      <ButtonLink {...data}>{text}</ButtonLink>
    )}
  </Container>
);

export default CtaButton;
