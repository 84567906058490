import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;

  text-align: center;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const Amount = styled.div`
  display: flex;
`;
