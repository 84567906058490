import { HelpMessage } from "components/form";
import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";

export const ListItemAction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${mediaMax("md")} {
    width: 100%;

    text-align: center;

    & > * {
      width: 100%;
    }
  }
`;

export const DisputeMessage = styled(HelpMessage)`
  width: 100%;
  grid-column: span 2;

  background: ${colors.cosmos};
`;
