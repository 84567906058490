import { MaskType } from "components/form";
import { getMaskedValue } from "components/form/fields/MaskedField/utils";
import { ApiUserProfile } from "utils/api/user";

const REQUIRED = [
  // anything not on this can be blanked out (e.g. `streetTwo`)
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "streetOne",
  "city",
  "stateAbbr",
  "zipCode",
];

const SECTIONS = [
  {
    name: "name",
    title: "Name",
    fields: ["firstName", "lastName"],
  },
  {
    name: "contact",
    title: "Contact Information",
    fields: ["email", "phoneNumber"],
  },
  {
    name: "address",
    title: "Address",
    fields: ["currentAddress"],
  },
];

export const getSections = (userProfile: ApiUserProfile) =>
  SECTIONS.map((section) => ({
    ...section,
    fields: section.fields.filter((field) => userProfile[field]),
  })).filter((section) => section.fields.length > 0);

export const getDefaultValues = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  currentAddress,
}: ApiUserProfile) => ({
  firstName,
  lastName,
  email,
  phoneNumber: phoneNumber
    ? getMaskedValue(MaskType.phone, phoneNumber)
    : undefined,
  currentAddress,
});

function getValuesToUpdate(values, defaultValues) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    let newValue = value;
    if (value === undefined && REQUIRED.includes(key)) return acc;
    if (value instanceof Object) {
      newValue = getValuesToUpdate(value, defaultValues[key]);
      if (Object.entries(newValue).length === 0) return acc;
    } else if (typeof value === "string") {
      if (
        value === defaultValues[key] ||
        (value.length === 0 && REQUIRED.includes(key))
      )
        return acc;
    } else if (value instanceof Array) {
      if (
        value.every((v, index) => v === defaultValues[key][index]) ||
        value.length === 0
      )
        return acc;
    }
    return { ...acc, [key]: newValue };
  }, {});
}

export const getMessage = (values, defaultValues) => {
  const valuesToUpdate = getValuesToUpdate(
    values,
    defaultValues
  ) as ApiUserProfile;

  const { firstName, lastName, email, phoneNumber, currentAddress } =
    valuesToUpdate;
  const messages = [];
  if (firstName || lastName) {
    messages.push(`${values.firstName} ${values.lastName}`);
  }
  if (email) messages.push(email);
  if (phoneNumber) messages.push(phoneNumber);
  if (currentAddress) {
    const address = values.currentAddress;
    let formattedAddress = address.streetOne;
    if (address.streetTwo) formattedAddress += `\n${address.streetTwo}`;
    formattedAddress += `\n${address.city}, ${address.stateAbbr} ${address.zipCode}`;
    messages.push(formattedAddress);
  }
  return messages.join("\n");
};
