import styled from "styled-components";
import { colors, ease, fontWeights } from "utils/theme";
import { BodySmall } from "components/ui/Text";

export const COLOR = [
  `color-mix(in srgb, ${colors.azure} 10%, white)`,
  colors.cerise,
  colors.cerise,
  colors.cosmos,
  colors.azure,
];

export const getColor = (score: number) => COLOR[score] || COLOR[0];

export const Wrapper = styled.div`
  display: grid;
  gap: 4px;
  padding-top: 12px;
`;

export const Meter = styled.div<{ $score: number }>`
  position: relative;

  height: 6px;
  width: 100%;
  overflow: hidden;

  border-radius: 9999px;
  background: ${getColor(0)};

  ${(props) => `
    &::before {
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      transform: scaleX(${props.$score / 4});
      transform-origin: left center;

      width: 100%;
      height: 100%;

      background: ${getColor(props.$score)};

      transition: transform 0.3s ${ease.outQuart};
    }
  `};
`;

export const Divider = styled.div<{ $position: number }>`
  position: absolute;
  left: ${(props) => (props.$position + 1) * 25}%;
  top: 0;

  width: 6px;
  height: 100%;

  background: white;
`;

export const Strength = styled.span`
  font-weight: ${fontWeights.normal};
`;

export const Message = styled(BodySmall)`
  line-height: 1.33;
`;
