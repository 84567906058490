import React from "react";
import { BodySmall, Heading } from "components/ui/Text";
import { ButtonLevel, ButtonLink } from "components/ui/Button";
import { getResetConstant } from "utils/reset";
import * as Styled from "./styled";

const Features = () => (
  <Styled.Wrapper>
    <Styled.Main>
      <Heading.H1 tag="h2">Ready to Reset?</Heading.H1>

      <ButtonLink href="/users/sign_up" level={ButtonLevel.cta}>
        Get Started
      </ButtonLink>
    </Styled.Main>

    <Styled.Footnotes>
      <BodySmall>
        <sup>1</sup> {getResetConstant("eligibility") as string}
      </BodySmall>
    </Styled.Footnotes>
  </Styled.Wrapper>
);

export default Features;
