import React from "react";
import fiCardsImage from "assets/images/general/financial-institution-cards.svg";
import moneyStacks from "assets/images/general/money-stacks.png";
import moneyHand from "assets/images/general/money-hand.png";
import creditUnionPeople from "assets/images/general/credit-union-people.png";
import financialWellness from "assets/images/general/financial-wellness.png";
import financialJourney from "assets/images/general/financial-journey.png";
import teamMattImage from "assets/images/team/matt-dicou.jpg";
import teamWesImage from "assets/images/team/wes-rodriguez.jpg";
import teamBrianImage from "assets/images/team/brian-mascarenhas.jpg";
import teamWillImage from "assets/images/team/will-fifer.jpg";
import teamPjImage from "assets/images/team/pj-huynh.jpg";
import { PageContent, HeroType, BlockType, CtaType } from "types/cms";

const content: {
  [page: string]: PageContent;
} = {
  financial_institutions: {
    hero: {
      id: "hero",
      type: HeroType.card,
      data: {
        textSize: "small",
        heading:
          "Tap into the future of financial wellness for your credit union or bank community.",
        body: (
          <>
            <p>
              Drive <strong>account holder growth and loyalty</strong>, and{" "}
              <strong>increase deposits</strong>, by providing daily income
              smoothing and streamlined&nbsp;budgeting.
            </p>
            <p>
              Reset helps you{" "}
              <strong>
                empower your members and customers <em>responsibly</em>
              </strong>{" "}
              with daily access to income from employers and gig&nbsp;platforms.
              <sup>1</sup>
            </p>
          </>
        ),
        image: fiCardsImage,
        cta: {
          type: CtaType.link,
          data: {
            text: "Partner with Reset Today",
            href: "mailto:partner@getreset.co?subject=Interested%20in%20Partnering%20with%20Reset",
          },
        },
      },
    },
    blocks: [
      {
        id: "block_1",
        type: BlockType.split,
        data: {
          heading: "Foster higher engagement with embedded on-demand pay.",
          image: creditUnionPeople,
          imageOrientation: "right",
          body: (
            <>
              <p>
                Reset enables you to embed responsible on-demand pay into
                account offerings, while keeping your credit union or bank the
                primary financial home.
              </p>
              <p>
                Strengthen your commitment to financial wellness with Reset. The
                more concern you show for the financial health of your account
                holders, the higher the chance of increasing brand loyalty and
                lifetime value.<sup>2</sup>
              </p>
            </>
          ),
        },
      },
      {
        id: "block_2",
        type: BlockType.split,
        data: {
          heading: "Drive growth in deposits.",
          image: moneyStacks,
          body: (
            <>
              <p>
                Understand how your account holders are allocating direct
                deposits and provide incentives for moving over their{" "}
                <em>full&nbsp;deposit.</em>
              </p>
              <p>
                Protect existing deposits. Help your members and customers avoid
                costly fees from late bills and alternative financial services
                that eat away at savings.
              </p>
            </>
          ),
        },
      },
      {
        id: "block_3",
        type: BlockType.split,
        data: {
          heading: "Attract new members and customers.",
          image: moneyHand,
          imageOrientation: "right",
          body: (
            <>
              <p>
                Go beyond the 2-day early paycheck trend with responsible
                on-demand pay and provide an impactful solution that meets the
                needs of your account holders.
              </p>
            </>
          ),
        },
      },
      {
        id: "block_4",
        type: BlockType.split,
        data: {
          heading:
            "Reach younger members and customers earlier in their financial journey.",
          image: financialJourney,
          body: (
            <>
              <p>
                Younger generations are more wary of traditional credit and
                mindful of spending within their means.
              </p>
              <p>
                <strong>Safely</strong> and <strong>responsibly</strong> serve
                individuals who can't yet access traditional credit cards, or
                are simply looking for alternatives.
              </p>
              <p>
                Reset&apos;s dynamic spending limit inherently factors in the
                cardholder&apos;s ability to pay and repayment periods are
                risk-optimized with no revolving balances.
              </p>
            </>
          ),
        },
      },
      {
        id: "block_5",
        type: BlockType.split,
        data: {
          heading: (
            <>
              Reduce financial stress. Improve employee retention.
              <sup>1, 3</sup>
            </>
          ),
          image: financialWellness,
          imageOrientation: "right",
          body: (
            <>
              <p>
                Financial stress has real costs for your employees, their
                wellbeing, and your workplace. Make a difference with Reset.
              </p>
              <p>
                Differentiate your commercial client offerings with a next-gen,
                earned wage access solution.
              </p>
              <p>
                Credit unions can partner with Reset to augment offerings to
                Select Employee Groups (SEGs).
              </p>
            </>
          ),
        },
      },
      {
        id: "block_6",
        type: BlockType.wysiwyg,
        data: {
          heading: "Bring your financial institution into the future.",
          cta: {
            type: CtaType.link,
            data: {
              text: "Partner with Reset Today",
              href: "mailto:partner@getreset.co?subject=Interested%20in%20Partnering%20with%20Reset",
            },
          },
        },
      },
    ],
    footnotes: [
      <>
        <sup>1</sup> Reset coverage extends to paid U.S. workers and government
        benefit recipients via integrations with major payroll providers, large
        employers, gig platforms, and government benefit systems.
      </>,
      <>
        <sup>2</sup> &ldquo;Gallup found that 73% of members who felt their
        credit union cared for their financial well-being were
        &lsquo;engaged,&rsquo; meaning they had a rational, emotional, and
        psychological attachment to the brand.&rdquo; (The Financial Brand,
        &ldquo;Customer Engagement at Credit Unions&rdquo;)
      </>,
      <>
        <sup>3</sup> Studies on employee retention are discussed in the ADP
        whitepaper, &ldquo;Earned Wage Access: Tapping into the Potential of
        Flexible Pay for Today&apos;s World of Work.&rdquo;
      </>,
    ],
  },
  about: {
    hero: {
      id: "hero",
      type: HeroType.centered,
      data: {
        heading: "Our story",
      },
    },
    blocks: [
      {
        id: "block_1",
        type: BlockType.wysiwyg,
        data: {
          body: (
            <>
              <p>
                Reset is driven to alleviate the financial costs of poverty and
                transform the way people manage their money. Born out of the
                lived experiences of a team that comes from immigrant and
                blue-collar households, Reset is built for the many that are
                striving for more while making due with less. We care because we
                come from the communities we aim to serve.
              </p>
              <p>
                Today, more than 60% of Americans are living
                paycheck-to-paycheck and struggling to cover basic expenses
                given high inflation. Finances are even tougher for those with
                little in savings and limited access to credit. To make ends
                meet while waiting for their paycheck, many are forced to resort
                to expensive predatory loan alternatives, or continually get hit
                with overdraft and late fees on bills. Existing solutions have
                also fallen short given the fees they charge to workers, poor
                design for those with multiple jobs or company changes, and a
                lack of focus on holistic financial health.
              </p>
              <p>
                We started from a blank canvas and reimagined a financial
                wellness product that combines daily access to income with
                streamlined budgeting and financial planning. We got rid of all
                the senseless fees that other solutions charge and finally made
                a product that's employer portable (we go where you go) and
                works with existing checking accounts (we come to you).
              </p>
              <p>
                With Reset as a financial GPS, cardholders can confidently take
                control of their finances and avoid predatory financial services
                that trap millions of Americans into cycles of debt. We aim to
                swap financial stress with economic stability through tools that
                support long-term financial health. Reset is a movement rooted
                in empathy and equity, with the belief that everyone deserves a
                fair chance at financial freedom. Ready to Reset?
              </p>
            </>
          ),
        },
      },
      {
        id: "block_3",
        type: BlockType.people,
        data: {
          heading: (
            <>
              We knew there had to be a better way.{" "}
              <em>So&nbsp;we&nbsp;built&nbsp;it.</em>
            </>
          ),
          people: [
            {
              name: "Matt Dicou",
              title: "Co-Founder & CEO",
              image: teamMattImage,
              links: [
                {
                  url: "https://www.linkedin.com/in/mattdicou/",
                  label: "LinkedIn",
                  icon: "linkedin",
                },
              ],
            },
            {
              name: "Wes Rodriguez",
              title: "Co-Founder & COO/CFO",
              image: teamWesImage,
              links: [
                {
                  url: "https://www.linkedin.com/in/wes-rodriguez/",
                  label: "LinkedIn",
                  icon: "linkedin",
                },
              ],
            },
            {
              name: "Brian Mascarenhas",
              title: "Co-Founder & CTO",
              image: teamBrianImage,
              links: [
                {
                  url: "https://www.linkedin.com/in/bmascarenhas/",
                  label: "LinkedIn",
                  icon: "linkedin",
                },
              ],
            },
            {
              name: "Will Fifer",
              title: "Designer & Engineer",
              image: teamWillImage,
              links: [
                {
                  url: "https://www.linkedin.com/in/will-fifer/",
                  label: "LinkedIn",
                  icon: "linkedin",
                },
              ],
            },
            {
              name: "PJ Huynh",
              title: "Engineer",
              image: teamPjImage,
              links: [
                {
                  url: "https://www.linkedin.com/in/pj-huynh-0863bb51/",
                  label: "LinkedIn",
                  icon: "linkedin",
                },
              ],
            },
          ],
        },
      },
      {
        id: "block_4",
        type: BlockType.wysiwyg,
        data: {
          heading: "Ready to Reset?",
          cta: {
            type: CtaType.link,
            data: {
              text: "Get Started",
              href: "/get-started",
            },
          },
        },
      },
    ],
  },
};

export default content;
