import React, { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import MarketingPageContent from "components/shared/MarketingPageContent";
import ResultsSection from "./ResultsSection";
import { getContent } from "./utils";
import * as Styled from "./styled";

interface Props {
  score: number;
  subscores: number[];
}

const Results: React.FC<Props> = ({ score, subscores }) => {
  const learnMoreRef = useRef(null);

  const scrollToLearnMore = (e) => {
    e.preventDefault();

    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.hash = "#learn-more";
    }
  };

  const getStartedContent = getContent({ score, subscores });

  return (
    <div>
      <AnimatePresence initial>
        <ResultsSection
          score={score}
          subscores={subscores}
          scrollToLearnMore={scrollToLearnMore}
        />
      </AnimatePresence>

      <Styled.ContentWrapper ref={learnMoreRef} id="learn-more">
        <MarketingPageContent
          {...getStartedContent}
          eyebrow="How Reset can help"
        />
      </Styled.ContentWrapper>
    </div>
  );
};

export default Results;
