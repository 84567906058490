import UILayout from "components/layout/UILayout";
import Transactions from "components/shared/Transactions";
import Loading from "components/ui/Loading";
import React from "react";
import {
  getCardTransactions,
  getDashboardOverview,
  getUserProfile,
} from "store/api/apiSlice";
import * as Styled from "./styled";
import Header from "./Header";

const CardTransactions: React.FC = () => {
  const { data: cardTransactions, isLoading: areTransactionsLoading } =
    getCardTransactions();
  const { data: dashboard, isLoading: isDashboardLoading } =
    getDashboardOverview();
  const { data: userProfile, isLoading: isUserProfileLoading } =
    getUserProfile();

  if (areTransactionsLoading || isDashboardLoading || isUserProfileLoading)
    return <Loading />;

  const { outstandingResetTransactions, safeToSpend, nextPayday } =
    dashboard?.safeToSpendComponents || {};

  return (
    <UILayout>
      <Styled.Wrapper>
        <Header
          outstandingAmount={outstandingResetTransactions}
          nextPayday={nextPayday}
          safeToSpend={safeToSpend}
          userProfile={userProfile}
        />

        <Transactions cardTransactions={cardTransactions} canDispute />
      </Styled.Wrapper>
    </UILayout>
  );
};

export default CardTransactions;
