import React from "react";
import styled from "styled-components";
import { Body } from "components/ui/Text";
import { LinkButton } from "components/ui/Link";
import InfoBox from "components/ui/InfoBox";

const LinkWrapper = styled.div`
  text-align: center;
`;

interface Props {
  email: string;
  showSignupForm: () => void;
}

const SignupSuccess: React.FC<Props> = ({ email, showSignupForm }) => (
  <>
    <InfoBox>
      <Body>
        Email sent to <strong>{email}</strong>
      </Body>
    </InfoBox>

    <LinkWrapper>
      <LinkButton onClick={showSignupForm}>
        I didn't receive an email
      </LinkButton>
    </LinkWrapper>
  </>
);

export default SignupSuccess;
