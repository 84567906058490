import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import Icon, { IconName } from "components/ui/Icon";
import {
  getMockUsers,
  getUserProfile,
  usePostMockInitMutation,
  usePostMockResetMutation,
  usePostMockSetUserMutation,
  usePostSimulateEarningsMutation,
  usePostSimulateTransactionMutation,
} from "store/api/apiSlice";
import { MswApi } from "utils/hooks/useMsw";
import UILabel from "components/ui/UILabel";
import SwitchCheckbox from "components/form/ui/SwitchCheckbox";
import { LinkButton } from "components/ui/Link";
import { toTitleCase } from "utils/string";
import * as Styled from "./styled";
import { ModalApi } from "../Modal";

interface Action {
  name: string;
  icon: IconName;
  onClick: () => void;
  shortcut: string;
}

interface Props {
  msw: MswApi;
  openCreateUserModal: () => void;
  toggleControlPanel: () => Promise<void>;
}

const DemoTriggersModal = (
  { msw, openCreateUserModal, toggleControlPanel }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const { data: users } = getMockUsers();
  const { data: userProfile } = getUserProfile();
  const [postSimulateTransaction] = usePostSimulateTransactionMutation();
  const [postSimulateEarnings] = usePostSimulateEarningsMutation();
  const [postSetCurrentUser] = usePostMockSetUserMutation();
  const [postMockInit] = usePostMockInitMutation();
  const [postReset] = usePostMockResetMutation();

  const close = () => {
    ref.current?.dismiss();
  };

  const performAction = (action: () => Promise<any>) => async () => {
    await action();
    close();
  };

  const onToggleDemoMode = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;

    await msw.toggle(isChecked);
    await postMockInit();

    if (!isChecked) {
      close();
    }
  };

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.altKey) {
        const action = (() => {
          switch (event.code) {
            case "KeyE":
              return performAction(postSimulateEarnings);
            case "KeyT":
              return performAction(postSimulateTransaction);
            case "KeyH":
              return performAction(toggleControlPanel);
            default:
              return null;
          }
        })();

        if (action) {
          event.preventDefault();
          action();
        }
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const actions: Action[] = [
    {
      name: "earnings",
      icon: "dollar",
      onClick: performAction(postSimulateEarnings),
      shortcut: "E",
    },
    {
      name: "transaction",
      icon: "creditCard",
      onClick: performAction(postSimulateTransaction),
      shortcut: "T",
    },
  ];

  return (
    <Styled.Modal ref={ref}>
      <Styled.Wrapper>
        <Styled.Header>
          <Styled.SwitchWrapper>
            <SwitchCheckbox
              onChange={onToggleDemoMode}
              checked={msw.isEnabled}
            />
            <UILabel.Medium>Demo mode</UILabel.Medium>
          </Styled.SwitchWrapper>

          {msw.isEnabled && (
            <LinkButton onClick={() => postReset()}>
              <Icon name="dice" />
              Start From Scratch
            </LinkButton>
          )}
        </Styled.Header>

        {msw.isEnabled && (
          <>
            <Styled.Section>
              <UILabel.Small caps bold color="dust">
                Users
              </UILabel.Small>
              <Styled.UserList>
                {users?.map((user) => (
                  <Styled.UserButton
                    key={user.id}
                    onClick={() => postSetCurrentUser({ id: user.id })}
                    $isActive={user.id === userProfile?.id}
                    type="button"
                  >
                    {user.firstName} {user.lastName}
                  </Styled.UserButton>
                ))}

                <Styled.LinkButtonWrapper>
                  <LinkButton type="button" onClick={openCreateUserModal}>
                    + Create user
                  </LinkButton>
                </Styled.LinkButtonWrapper>
              </Styled.UserList>
            </Styled.Section>

            <Styled.Section>
              <UILabel.Small caps bold color="dust">
                Actions
              </UILabel.Small>
              <Styled.ActionList>
                {actions.map(({ name, icon, onClick }) => (
                  <Styled.ActionButton
                    onClick={onClick}
                    key={name}
                    type="button"
                  >
                    <Icon name={icon} color="violet" />
                    New {toTitleCase(name)}
                  </Styled.ActionButton>
                ))}
              </Styled.ActionList>
              <Styled.ShortcutList>
                {actions.map(({ name, shortcut }) => (
                  <Styled.Shortcut key={name}>
                    <Styled.ShortcutKey>&#x2387;</Styled.ShortcutKey>
                    <Styled.ShortcutKey>{shortcut}</Styled.ShortcutKey>
                    <Styled.ShortcutLabel>New {name}</Styled.ShortcutLabel>
                  </Styled.Shortcut>
                ))}

                <Styled.Shortcut>
                  <Styled.ShortcutKey>&#x2387;</Styled.ShortcutKey>
                  <Styled.ShortcutKey>H</Styled.ShortcutKey>
                  <Styled.ShortcutLabel>
                    Show/hide controls
                  </Styled.ShortcutLabel>
                </Styled.Shortcut>
              </Styled.ShortcutList>
            </Styled.Section>
          </>
        )}
      </Styled.Wrapper>
    </Styled.Modal>
  );
};

export default forwardRef(DemoTriggersModal);
