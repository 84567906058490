import styled from "styled-components";
import * as Text from "components/ui/Text";
import { colors, fontWeights, mediaMax } from "utils/theme";

export const Body = styled(Text.Body)`
  strong,
  em {
    color: ${colors.midnight};

    font-weight: ${fontWeights.bold};
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;

  a,
  button {
    width: auto;
    min-width: 320px;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 0 16px;
  margin: 0 auto;

  text-align: center;

  & > * {
    max-width: 768px;
  }

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;
