import styled from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  overflow: hidden;
  padding: 80px 0;

  background: ${colors.ice};

  pointer-events: none;

  ${mediaMin("lg")} {
    padding: 900px 0 120px;
    margin-top: -1048px;

    background: none;
  }

  @media screen and (min-width: 2200px) {
    padding-top: calc(900vw / 22);
    margin-top: calc(-1048vw / 22);
  }

  * {
    pointer-events: auto;
  }
`;

export const BackgroundShape = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  min-width: 2200px;

  pointer-events: none;

  ${mediaMax("lg")} {
    display: none;
  }
`;
