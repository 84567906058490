import React from "react";
import Icon from "components/ui/Icon";
import { LinkButton } from "components/ui/Link";
import { DashboardAccountAction } from "../types";

const Action = ({
  onClick,
  title,
  icon,
  disabled,
  toRender,
}: DashboardAccountAction) => (
  <div>
    <LinkButton
      size="sm"
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      {icon && <Icon name={icon} size="xs" />}
      {title}
    </LinkButton>

    {toRender}
  </div>
);

export default Action;
