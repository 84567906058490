import React from "react";
import App from "App";
import FinancialHealthSurvey from "components/shared/FinancialHealthSurvey";

const FinancialHealth = () => (
  <App>
    <FinancialHealthSurvey />
  </App>
);

export default FinancialHealth;
