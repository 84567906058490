import { IconName } from "components/ui/Icon";

export enum SurveyStep {
  Intro = "INTRO",
  Questionnaire = "QUESTIONNAIRE",
  Signup = "SIGNUP",
  Results = "RESULTS",
}

export interface QuestionnaireCategory {
  title: string;
  icon: IconName;
}

export enum QuestionnaireCategoryName {
  Spending = "SPENDING",
  Saving = "SAVING",
  Borrowing = "BORROWING",
  Planning = "PLANNING",
}

export interface QuestionOption {
  label: React.ReactNode;
  value: number;
}

export interface QuestionnaireQuestion {
  categoryName: QuestionnaireCategoryName;
  text: React.ReactNode;
  options: QuestionOption[];
}

export type SurveyResults = {
  score: number;
  subscores: number[];
};
