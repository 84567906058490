/* eslint no-restricted-syntax: 0 */

// There's no built-in way to persist database with msw/@data. A complete rebuild is supposedly coming, but could be a while; see thread: https://github.com/mswjs/data/pull/277
// This solution for persistence is maintained here: https://github.com/Kamahl19/react-starter/blob/main/src/mocks/persist.ts
// USAGE NOTE: Don't use manyOf('modelName'); store the relationship on the related model, e.g. userId on earnings model. This is because performing an update on an instance while also updating a relation ("colocated update") throws errors after a page reload, possibly due to the way the database is hydrated.
// CHANGE: Import debounce differently
// CHANGE: Use localStorage instead of sessionStorage

import debounce from "lodash.debounce";
import {
  DATABASE_INSTANCE,
  ENTITY_TYPE,
  PRIMARY_KEY,
  type FactoryAPI,
  type Entity,
  type ModelDictionary,
  type PrimaryKeyType,
} from "@mswjs/data/lib/glossary";
import {
  type SerializedEntity,
  SERIALIZED_INTERNAL_PROPERTIES_KEY,
} from "@mswjs/data/lib/db/Database";
import { inheritInternalProperties } from "@mswjs/data/lib/utils/inheritInternalProperties";

const STORAGE_KEY_PREFIX = "mswjs-data";

// Timout to persist state with some delay
const DEBOUNCE_PERSIST_TIME_MS = 10;

type Models<Dictionary extends ModelDictionary> = Record<
  keyof Dictionary,
  Map<PrimaryKeyType, Entity<Dictionary, any>> // eslint-disable-line @typescript-eslint/no-explicit-any
>;

type SerializedModels<Dictionary extends ModelDictionary> = Record<
  keyof Dictionary,
  Map<PrimaryKeyType, SerializedEntity>
>;

function deserializeEntity(entity: SerializedEntity) {
  const {
    [SERIALIZED_INTERNAL_PROPERTIES_KEY]: internalProperties,
    ...publicProperties
  } = entity;

  inheritInternalProperties(publicProperties, {
    [ENTITY_TYPE]: internalProperties.entityType,
    [PRIMARY_KEY]: internalProperties.primaryKey,
  });

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  return publicProperties as Entity<any, any>;
}

export default function persist<Dictionary extends ModelDictionary>(
  factory: FactoryAPI<Dictionary>
) {
  if (typeof window === "undefined" || typeof localStorage === "undefined") {
    return;
  }

  const db = factory[DATABASE_INSTANCE];

  const key = `${STORAGE_KEY_PREFIX}/${db.id}`;

  const persistState = debounce(() => {
    // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/consistent-type-assertions
    const models = db["models"] as Models<Dictionary>;
    // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/consistent-type-assertions
    const serializeEntity = db["serializeEntity"] as (
      entity: Entity<Dictionary, any> // eslint-disable-line @typescript-eslint/no-explicit-any
    ) => SerializedEntity;

    const json = Object.fromEntries(
      Object.entries(models).map(([modelName, entities]) => [
        modelName,
        Array.from(entities, ([, entity]) => serializeEntity(entity)),
      ])
    );

    localStorage.setItem(key, JSON.stringify(json));
  }, DEBOUNCE_PERSIST_TIME_MS);

  function hydrateState() {
    const initialState = localStorage.getItem(key);

    if (initialState) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const data = JSON.parse(initialState) as SerializedModels<Dictionary>;

      for (const [modelName, entities] of Object.entries(data)) {
        for (const entity of entities.values()) {
          db.create(modelName, deserializeEntity(entity));
        }
      }
    }

    // Add event listeners only after hydration
    db.events.on("create", persistState);
    db.events.on("update", persistState);
    db.events.on("delete", persistState);
  }

  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", hydrateState);
  } else {
    hydrateState();
  }
}
