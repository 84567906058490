import { PageBlock, PageContent } from "types/cms";
import { ApiContentfulPageResponse } from "utils/api/contentful";
import { PartnerSlug } from "types/general";
import { buildBlockByType, buildBlocks } from "./blocks";
import { buildHero } from "./hero";
import { buildFootnotes } from "./footnotes";

const mapPartnerToType = (partner: string) => {
  switch (partner) {
    case "MSUFCU":
      return PartnerSlug.msufcu;
    default:
      return undefined;
  }
};

const mapEntryToPage = (pageResponse: ApiContentfulPageResponse) => {
  const { page, allPages } = pageResponse;
  const { blocksCollection, hero: rawHero, footnotesCollection } = page;
  const pagesById = allPages.reduce((acc, p) => {
    acc[p.sys.id] = p;
    return acc;
  }, {});
  const blocks = buildBlocks(blocksCollection.items, pagesById).filter(
    (b) => b
  );
  const hero = rawHero ? buildHero(rawHero) : undefined;
  const footnotes = footnotesCollection?.items.length
    ? buildFootnotes(footnotesCollection.items, pagesById)
    : undefined;
  const partnerSlug = mapPartnerToType(page.partner);
  return {
    hero,
    blocks,
    footnotes,
    partnerSlug,
  } as PageContent;
};

export const transformRawBlock = (block) =>
  buildBlockByType(block) as PageBlock;

export default mapEntryToPage;
