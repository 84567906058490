import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import UILayout from "components/layout/UILayout";
import Loading from "components/ui/Loading";
import { LinkButton } from "components/ui/Link";
import UILabel from "components/ui/UILabel";
import Menu from "components/ui/Menu";
import { colors } from "utils/theme";
import { logout } from "utils/api/user";
import * as Styled from "./styled";
import { Wrapper } from "../styled";
import { getMenuSections } from "./utils";

const Account = () => {
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) return <Loading />;

  const menuSections = getMenuSections({ userProfile });

  return userProfile ? (
    <UILayout>
      <Wrapper>
        <Styled.Main>
          <Styled.UserHeader>
            <Styled.Avatar>
              <Styled.Initials>
                {userProfile.firstName?.slice(0, 1)}
                {userProfile.lastName?.slice(0, 1)}
              </Styled.Initials>
            </Styled.Avatar>

            <Styled.UserInfo>
              <UILabel.XLarge>
                {userProfile.firstName} {userProfile.lastName}
              </UILabel.XLarge>

              <UILabel.Medium color={`${colors.midnight}AA`}>
                Reset member since{" "}
                {new Date(userProfile.createdAt).toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </UILabel.Medium>
            </Styled.UserInfo>
          </Styled.UserHeader>
          <Menu sections={menuSections} />
        </Styled.Main>

        <Styled.Footer>
          <LinkButton size="lg" onClick={logout}>
            Log out
          </LinkButton>
        </Styled.Footer>
      </Wrapper>
    </UILayout>
  ) : null;
};

export default Account;
