import React from "react";
import styled from "styled-components";
import { AnimatePresence, Variants, motion } from "framer-motion";

const VARIANTS = {
  enter: (direction) => ({
    position: "absolute",
    zIndex: 1,
    x: `${direction * 100}%`,
    opacity: 0,
  }),
  center: {
    position: "relative",
    zIndex: 1,
    x: 0,
    opacity: 1,
    transitionEnd: {
      x: 0,
    },
  },
  exit: (direction) => ({
    position: "absolute",
    zIndex: 0,
    x: `${direction * -100}%`,
    opacity: 0,
  }),
} as Variants;

const Wrapper = styled.div`
  position: relative;
`;

const Slide = styled(motion.div)`
  left: 0;
  top: 0;

  min-height: 100vh;
  width: 100%;
`;

interface Props {
  currentId: number | string;
  direction?: number;
  children: React.ReactNode;
}

const SlideLayout: React.FC<Props> = ({
  currentId,
  direction = 1,
  children,
}) => (
  <Wrapper>
    <AnimatePresence initial={false}>
      <Slide
        custom={direction}
        variants={VARIANTS}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          type: "spring",
          stiffness: 300,
          damping: 30,
          duration: 0.2,
        }}
        key={currentId}
      >
        {children}
      </Slide>
    </AnimatePresence>
  </Wrapper>
);

export default SlideLayout;
