import React from "react";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";
import { getResetConstant } from "utils/reset";

const EligibilityFooter = () => (
  <UILabel.Small color={`${colors.midnight}80`}>
    {getResetConstant("eligibility")}
  </UILabel.Small>
);

export default EligibilityFooter;
