import React from "react";
import styled from "styled-components";
import { colors } from "utils/theme";

const Path = styled.path`
  fill: ${colors.ice};
`;

const HeaderDivider = (props) => (
  <svg viewBox="0 0 2244 634" {...props}>
    <Path
      d="M1441.1,524.9c-360.1,0-506.1-207.8-847.9-207.8c-354.1,0-519.2,178.8-593,300v243.3H2244V0
	C2240.9,30.3,2024,524.9,1441.1,524.9z"
    />
  </svg>
);

export default HeaderDivider;
