import React from "react";
import { Body, Heading } from "components/ui/Text";
import { ButtonLink } from "components/ui/Button";
import * as Styled from "./styled";

const NotFound = () => (
  <Styled.Wrapper>
    <Styled.ErrorCode>404</Styled.ErrorCode>
    <Heading.H2 tag="h1">We can't find that!</Heading.H2>
    <Body>The page you're looking for may have moved.</Body>
    <Styled.Footer>
      <ButtonLink href="/">Go Home</ButtonLink>
    </Styled.Footer>
  </Styled.Wrapper>
);

export default NotFound;
