import React from "react";
import { Eyebrow } from "components/ui/Text";
import styled from "styled-components";
import { colors, fontSizes } from "utils/theme";
import Icon, { IconName } from "components/ui/Icon";
import { ApiBankAccount } from "utils/api/bank_accounts";

const Item = styled.div`
  display: flex;
  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;

  border-radius: 9999px;
  background: ${colors.azure}2A;
`;

const ItemContent = styled.div`
  display: grid;
  gap: 2px;
`;

const ItemHeading = styled(Eyebrow)`
  color: ${colors.violet};
  opacity: 0.5;

  font-size: ${fontSizes.xxs}px;
`;

const ItemBody = styled.div`
  display: grid;
  gap: 4px;
`;

interface Props {
  bankAccount: ApiBankAccount;
}

const BANK_ACCOUNT_FIELDS: {
  title: string;
  icon: IconName;
  valueKey: string;
}[] = [
  {
    title: "Financial Institution",
    icon: "bank",
    valueKey: "institutionName",
  },
  {
    title: "Routing Number",
    icon: "arrowRight",
    valueKey: "routingNumber",
  },
  {
    title: "Account Number",
    icon: "account",
    valueKey: "accountNumber",
  },
];

const BankAccount: React.FC<Props> = ({ bankAccount }) =>
  BANK_ACCOUNT_FIELDS.map((field) => (
    <Item key={field.valueKey}>
      <IconWrapper>
        <Icon name={field.icon} color={colors.violet} />
      </IconWrapper>
      <ItemContent>
        <ItemHeading>{field.title}</ItemHeading>
        <ItemBody>{bankAccount[field.valueKey]}</ItemBody>
      </ItemContent>
    </Item>
  ));
export default BankAccount;
