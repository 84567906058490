import styled, { css } from "styled-components";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { borderRadius, colors, fonts, shadows, transitions } from "utils/theme";
import { motion } from "framer-motion";

export const DropdownMenu = styled.div`
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  gap: 12px;

  border-radius: ${borderRadius.small}px;

  white-space: nowrap;
`;

export const DropdownButton = styled(Button).attrs(() => ({
  size: ButtonSize.md,
  level: ButtonLevel.tertiary,
}))`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 16px 4px 20px;

  white-space: nowrap;
`;

export const DropdownList = styled(motion.ul)`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;

  display: flex;
  flex-direction: column;
  padding: 8px;
  min-width: 180px;

  background: white;
  box-shadow: ${shadows.tile};
  border-radius: ${borderRadius.small}px;
`;

export const DropdownItem = styled.li`
  display: flex;
  align-items: center;

  list-style-type: none;
`;

export const DropdownLink = styled.a`
  display: flex;
  width: 100%;
  padding: 8px;
  gap: 6px;
  align-items: center;

  color: ${colors.midnight};
  border-radius: 6px;

  font-family: ${fonts.body};
  text-decoration: none;
  text-align: left;

  transition: all ${transitions.simple};

  &:visited {
    color: ${colors.midnight};
  }

  @media (hover: hover) {
    &:hover {
      background: ${colors.ice};
    }
  }
`;

export const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
`;

export const DropdownDivider = styled.div`
  height: 1px;
  margin: 8px -8px;

  background: ${colors.violet};
  opacity: 0.1;
`;

export const Wrapper = styled.div<{ $isOpen: boolean }>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 44px;

  ${(props) => props.$isOpen && css``}
`;
