import React from "react";
import SidebarLayout from "components/layout/SidebarLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import FormProgressSidebar from "components/ui/FormProgressSidebar";
import FormProgressHeader from "components/ui/FormProgressHeader";
import Loading from "components/ui/Loading";
import AppFooter from "components/shared/AppFooter";
import { FormTaskStatus } from "types/task";
import { getCardApplicationTasks, getUserProfile } from "store/api/apiSlice";
import ChatWidget from "components/ui/ChatWidget";
import { ButtonLevel } from "components/ui/Button";
import { OnboardingTaskName } from "types/onboarding";
import CardApplicationStep from "./components/CardApplicationStep";
import * as Styled from "./styled";
import { mapTasksToSteps } from "./utils";

const CardApplication: React.FC = () => {
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();
  const { data: tasks, isLoading: isLoadingTasks } = getCardApplicationTasks();

  if (isLoadingUserProfile || isLoadingTasks) {
    return (
      <Styled.LoadingWrapper>
        <Loading />
      </Styled.LoadingWrapper>
    );
  }

  const steps = mapTasksToSteps(tasks);

  const currentStepIndex = steps.findIndex(
    (task) => task.status === FormTaskStatus.ready
  );
  const currentTask = tasks.find((task) => task.state === FormTaskStatus.ready);

  const partnerSlug = userProfile?.partnerSlug;

  return (
    <SidebarLayout
      sidebar={
        <Styled.SidebarWrapper>
          <FormProgressSidebar
            title="Card Application"
            steps={steps}
            navigation={
              userProfile?.eligibleForCard
                ? [
                    {
                      text: "Save & Exit",
                      href: "/dashboard",
                      icon: "arrowLeft",
                    },
                  ]
                : []
            }
            partnerSlug={partnerSlug}
          />
        </Styled.SidebarWrapper>
      }
    >
      <Styled.MobileHeaderWrapper>
        <FormProgressHeader
          currentStepIndex={currentStepIndex}
          steps={steps}
          icon="creditCard"
          title="Reset Card Application"
          navigation={
            userProfile?.eligibleForCard
              ? [
                  {
                    text: "Save & Exit",
                    href: "/dashboard",
                  },
                ]
              : []
          }
        />
      </Styled.MobileHeaderWrapper>

      <DrawerLayout>
        {(drawer) => (
          <Styled.ContentWrapper>
            <Styled.Content>
              <Styled.FormStep>
                <CardApplicationStep
                  id={currentTask.name as OnboardingTaskName}
                  drawer={drawer}
                />
              </Styled.FormStep>

              <Styled.ContentFooter>
                <AppFooter />
              </Styled.ContentFooter>
            </Styled.Content>
          </Styled.ContentWrapper>
        )}
      </DrawerLayout>

      <Styled.ChatWrapper>
        <ChatWidget
          isPrivate
          buttonProps={{ level: ButtonLevel.tertiary }}
          label="Help"
          icon="commentQuestion"
          positionMobileY="top"
        />
      </Styled.ChatWrapper>
    </SidebarLayout>
  );
};

export default CardApplication;
