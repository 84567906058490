import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import useClickOutside from "utils/hooks/onClickOutside";
import { DROPDOWN_SECTIONS } from "./constants";
import * as Styled from "./styled";

export interface UserProfile {
  admin: boolean;
  firstName: string;
  lastName: string;
}

interface Props {
  userProfile: UserProfile;
}

const UserDropdown: React.FC<Props> = ({ userProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useClickOutside(() => setIsOpen(false));

  const toggleDropdown = () => {
    setIsOpen((o) => !o);
  };

  const sections = DROPDOWN_SECTIONS.map((section) =>
    section.filter((item) => !item.adminOnly || userProfile.admin)
  );

  return (
    <Styled.Wrapper $isOpen={isOpen} ref={wrapperRef}>
      <Styled.DropdownMenu>
        <Styled.DropdownButton onClick={toggleDropdown}>
          Hi, {userProfile.firstName}!
          <Icon name="userCircle" />
        </Styled.DropdownButton>

        <AnimatePresence>
          {isOpen && (
            <Styled.DropdownList
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, translateY: 0 },
                collapsed: { opacity: 0, translateY: 12 },
              }}
              transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
            >
              {sections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  {sectionIndex > 0 && <Styled.DropdownDivider />}
                  {section.map((item) => (
                    <Styled.DropdownItem key={item.title}>
                      <Styled.DropdownLink href={item.href} data-turbo="false">
                        <Styled.DropdownIcon>
                          <Icon name={item.icon} color="violet" />
                        </Styled.DropdownIcon>
                        <UILabel.Medium>{item.title}</UILabel.Medium>
                      </Styled.DropdownLink>
                    </Styled.DropdownItem>
                  ))}
                </React.Fragment>
              ))}
            </Styled.DropdownList>
          )}
        </AnimatePresence>
      </Styled.DropdownMenu>
    </Styled.Wrapper>
  );
};

export default UserDropdown;
