import React from "react";
import Timeline from "components/ui/Timeline";
import Loading from "components/ui/Loading";
import {
  getDashboardOverview,
  getDashboardPayday,
  getUserProfile,
} from "store/api/apiSlice";
import { getEvents } from "./utils";

const PaydayTimeline: React.FC = () => {
  const { data: payday, isLoading: isLoadingPayday } = getDashboardPayday();
  const { data: dashboard, isLoading: isLoadingDashboard } =
    getDashboardOverview();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    getUserProfile();

  if (isLoadingPayday || isLoadingDashboard || isLoadingUserProfile)
    return <Loading />;

  const events = getEvents({ dashboard, payday, userProfile });

  return <Timeline events={events} />;
};

export default PaydayTimeline;
