import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  overflow: hidden;
  padding: 80px 0;

  background: ${colors.flax};

  ${mediaMax("lg")} {
    padding: 64px 0;
  }
`;
