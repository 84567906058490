import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Split from "components/cms/components/block/Split";
import { getSessionPartner } from "utils/partners";
import { PartnerSlug } from "types/general";
import { BlockType, SplitBlock } from "types/cms";
import groceries from "assets/images/home/groceries.webp";
import * as Styled from "./styled";
import SocialSecurity from "./SocialSecurity";
import Body from "./Body";

const UNLOCK_YOUR_PAYCHECK: SplitBlock = {
  id: "unlock-your-paycheck",
  type: BlockType.split,
  data: {
    heading: <>Unlock your paycheck sooner. Lose&nbsp;the&nbsp;fees.</>,
    body: <Body />,
    textSize: "large",
    image: groceries,
  },
};

const UnlockYourPaycheck = () => {
  const partner = getSessionPartner();

  return (
    <Styled.Wrapper>
      <ContentLayout>
        <Split {...UNLOCK_YOUR_PAYCHECK} />
      </ContentLayout>

      {partner?.slug === PartnerSlug.msufcu && <SocialSecurity />}
    </Styled.Wrapper>
  );
};

export default UnlockYourPaycheck;
