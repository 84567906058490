import React from "react";
import Page from "components/cms/Page";
import content from "./content";

const GeneralPage: React.FC<{ page: string }> = ({ page }) => {
  const pageContent = content[page];

  return <Page content={pageContent} />;
};

export default GeneralPage;
