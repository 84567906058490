import React from "react";
import ColorIcon from "components/ui/ColorIcon";
import { formatCurrency } from "utils/string";
import Icon from "components/ui/Icon";
import { DashboardAccount, DashboardAccountStatus } from "../types";
import Status from "./Status";
import Action from "./Action";
import * as Styled from "./styled";

interface Props extends DashboardAccount {}

const LineItem: React.FC<Props> = ({
  title,
  amount,
  subtitle,
  icon,
  isDebit,
  href,
  status,
  actions,
}) => {
  const showAsDebit = (isDebit && amount >= 0) || (!isDebit && amount < 0);
  const isActive = !status || status === DashboardAccountStatus.active;

  return (
    <Styled.LineItemWrapper>
      <Styled.LineItem $isInteractive={!!href}>
        {href && (
          <Styled.LineItemLink
            to={href}
            data-turbo="false"
          ></Styled.LineItemLink>
        )}

        <Styled.IconWrapper $isDebit={isDebit} $isActive={isActive}>
          <ColorIcon name={icon} />
        </Styled.IconWrapper>

        <Styled.Main>
          <Styled.Left>
            <Styled.Title $isActive={isActive}>
              <span>{title}</span>

              {href && (
                <Styled.TitleIcon>
                  <Icon name="arrowRight" size="xs" />
                </Styled.TitleIcon>
              )}
            </Styled.Title>

            {(subtitle || status) && (
              <Styled.Info>
                {subtitle && (
                  <div>
                    <Styled.Subtitle>{subtitle}</Styled.Subtitle>
                  </div>
                )}
              </Styled.Info>
            )}
          </Styled.Left>

          <Styled.Right>
            <Styled.Amount $isDebit={showAsDebit} $isActive={isActive}>
              {showAsDebit && "-"}${formatCurrency(Math.abs(amount))}
            </Styled.Amount>
          </Styled.Right>
        </Styled.Main>
      </Styled.LineItem>

      {(actions || status) && (
        <Styled.Actions>
          {status && (
            <Styled.ActionItem>
              <Status status={status} />
            </Styled.ActionItem>
          )}

          {actions?.map((action) => (
            <Styled.ActionItem key={action.icon}>
              <Action {...action} />
            </Styled.ActionItem>
          ))}
        </Styled.Actions>
      )}
    </Styled.LineItemWrapper>
  );
};

export default LineItem;
