import React from "react";
import UILabel from "components/ui/UILabel";
import { BodySmall, Heading } from "components/ui/Text";
import AnimatedCounter from "components/ui/AnimatedCounter";
import Chart from "./Chart";
import * as Styled from "./styled";
import Key from "./Key";
import { getBudgetProfile } from "./utils";

interface Props {
  needs: number;
  wants: number;
  savings: number;
  monthlyIncome: number;
  isOverBudget: boolean;
  scrollToLearnMore: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const MainResults: React.FC<Props> = ({
  needs,
  wants,
  savings,
  monthlyIncome,
  isOverBudget,
  scrollToLearnMore,
}) => {
  const { heading, body } = getBudgetProfile({
    monthlyIncome,
    needs,
    wants,
    savings,
    isOverBudget,
  });

  return (
    <Styled.Tile>
      <Styled.ResultsContent>
        <Styled.ResultsHeader>
          <Styled.ChartWrapper>
            <Chart needs={needs} wants={wants} savings={savings} isAnimated />

            {!isOverBudget && (
              <Styled.SavingsAmount>
                <UILabel.Micro wideCaps bold color="midnight">
                  Savings
                </UILabel.Micro>
                <Styled.Amount>
                  $
                  <AnimatedCounter
                    value={savings}
                    isCurrency={false}
                    delay={0.35}
                  />
                </Styled.Amount>
              </Styled.SavingsAmount>
            )}

            <Key />
          </Styled.ChartWrapper>

          <Styled.ResultsHeaderText>
            <UILabel.Small color="azure" wideCaps bold>
              Your Spending Profile
            </UILabel.Small>

            <Heading.H2 tag="h1">{heading}</Heading.H2>

            <BodySmall tag="div">
              {body}{" "}
              <a href="#" onClick={scrollToLearnMore}>
                Learn&nbsp;more
              </a>
            </BodySmall>
          </Styled.ResultsHeaderText>
        </Styled.ResultsHeader>
      </Styled.ResultsContent>
    </Styled.Tile>
  );
};

export default MainResults;
