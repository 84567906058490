import React from "react";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { colors } from "utils/theme";
import * as Styled from "./styled";
import { Wrapper } from "../styled";

interface Props {
  isMfaEnabled?: boolean;
  onClickToggle: () => void;
}

const Main: React.FC<Props> = ({ isMfaEnabled, onClickToggle }) => (
  <UILayout>
    <Wrapper>
      <Heading.H3 tag="h1">Login &amp; security</Heading.H3>

      <Styled.Section size="xl">
        <Styled.Item>
          <Styled.ItemLabel>
            <UILabel.Large>
              2-Factor Authentication is{" "}
              <strong
                style={{ color: isMfaEnabled ? colors.azure : colors.cerise }}
              >
                <u>{isMfaEnabled ? "on" : "off"}</u>
              </strong>
            </UILabel.Large>
            <UILabel.Medium light>
              {isMfaEnabled
                ? "When you log in, we'll ask for a password and send a code to your phone."
                : "Turn on 2-Factor Authentication to protect your account in the event that your password is stolen."}
            </UILabel.Medium>
          </Styled.ItemLabel>

          <Styled.ItemAction>
            <Button
              onClick={onClickToggle}
              size={ButtonSize.md}
              level={isMfaEnabled ? ButtonLevel.ghost : ButtonLevel.primary}
            >
              Turn {isMfaEnabled ? "off" : "on"}
            </Button>
          </Styled.ItemAction>
        </Styled.Item>
      </Styled.Section>
    </Wrapper>
  </UILayout>
);

export default Main;
