import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import React from "react";
import { getDocumentToHtmlStringOptions } from "./common";

const Text = ({ data, pagesById }) => {
  const html = documentToHtmlString(
    data,
    getDocumentToHtmlStringOptions(pagesById)
  );
  const sanitizedHtml = html.replace(/<\/?p>/g, "");
  return <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export const buildFootnotes = (items, pagesById) => {
  let count = 0;
  return items.map(({ text, superscriptOverride }, index) => {
    let superscript: string | number;
    if (superscriptOverride) {
      superscript = superscriptOverride;
    } else {
      count += 1;
      superscript = count;
    }
    return (
      <React.Fragment key={index}>
        <sup>{superscript}</sup> <Text data={text.json} pagesById={pagesById} />
      </React.Fragment>
    );
  });
};
