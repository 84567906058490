import styled from "styled-components";
import Button, { BaseButton } from "components/ui/Button";
import { borderRadius, colors, shadows } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  padding: 24px;

  border-radius: ${borderRadius.regular}px;
  box-shadow: ${shadows.tile};
  background: white;
`;

export const Slides = styled.div`
  position: relative;

  width: 100%;
`;

export const Slide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 12px;
`;

export const SlideButton = styled(Button)`
  position: relative;

  width: 100%;
  display: flex;
`;

export const ButtonArrowRight = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    * {
      stroke: currentColor;
      stroke-width: 8px;
    }
  }
`;

export const Footer = styled.div`
  position: relative;

  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const FooterDot = styled.div<{ $isActive?: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 9999px;
  background-color: ${colors.violet};
  opacity: ${(props) => (props.$isActive ? 1 : 0.33)};
`;

export const FooterButton = styled(BaseButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const FooterButtonPrev = styled(FooterButton)`
  left: 0;
`;
