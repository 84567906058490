import React, { useEffect } from "react";
import { TileCheckboxGroupField } from "components/form";
import { NEEDS_OPTIONS } from "../constants";

interface Props {
  updateValidation: (isValid: boolean) => void;
}

const NeedsSelection: React.FC<Props> = ({ updateValidation }) => {
  useEffect(() => {
    updateValidation(true);
  }, []);

  return (
    <TileCheckboxGroupField name="expenseSelection" options={NEEDS_OPTIONS} />
  );
};

export default NeedsSelection;
