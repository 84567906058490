import React from "react";
import Menu from "components/ui/Menu";
import DisappearingTile from "components/ui/DisappearingTile";
import UILayout from "components/layout/UILayout";
import { MENU } from "./constants";

const Home = () => (
  <UILayout>
    <DisappearingTile size="xl">
      <Menu sections={MENU} />
    </DisappearingTile>
  </UILayout>
);

export default Home;
