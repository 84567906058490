import React from "react";
import { useForm, FormProvider, UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import { mediaMax } from "utils/theme";
import GeneralError from "./fields/GeneralError";

const Form = styled.form`
  display: grid;
  width: 100%;
  gap: 36px;

  ${mediaMax("lg")} {
    gap: 24px;
  }
`;

interface Props {
  children: React.ReactNode;
  onSubmit: (
    values: { [key: string]: any },
    methods: UseFormReturn<
      {
        [key: string]: any;
      },
      any,
      undefined
    >
  ) => void;
  defaultValues?: { [key: string]: any };
}

const FormContainer: React.FC<Props> = ({
  children,
  onSubmit,
  defaultValues,
}) => {
  const methods = useForm({ defaultValues });

  const submitHandler = (e) => onSubmit(e, methods);

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={(e) => {
          methods.clearErrors();
          methods.handleSubmit(submitHandler)(e);
        }}
      >
        <GeneralError />
        {children}
      </Form>
    </FormProvider>
  );
};

export default FormContainer;
