import UILabel from "components/ui/UILabel";
import React from "react";
import styled from "styled-components";
import { ApiAdminUserDirectDepositAllocation } from "utils/api/admin/user";
import { ordinalize } from "utils/number";
import { isDefined } from "utils/object";
import { capitalize, formatCurrency } from "utils/string";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

interface Props extends ApiAdminUserDirectDepositAllocation {}

const AllocationInfo: React.FC<Props> = ({
  accountName,
  allocationType,
  amount,
  percentage,
  priority,
  routingNumber,
  maskedAccountNumber
}) => (
  <Wrapper>
    <UILabel.XLarge>{accountName}</UILabel.XLarge>
    <UILabel.Medium>
      Allocation Type: {capitalize(allocationType)}
    </UILabel.Medium>
    {isDefined(amount) && (
      <UILabel.Medium>
        Amount: {formatCurrency(amount / 100, true)}
      </UILabel.Medium>
    )}
    {isDefined(percentage) && (
      <UILabel.Medium>Amount: {percentage}%</UILabel.Medium>
    )}
    {isDefined(routingNumber) && (
      <UILabel.Medium>Routing Number: {routingNumber}</UILabel.Medium>
    )}
    {isDefined(maskedAccountNumber) && (
      <UILabel.Medium>Account Number: {maskedAccountNumber}</UILabel.Medium>
    )}
    {isDefined(priority) && (
      <UILabel.Medium>Priority: {ordinalize(priority)}</UILabel.Medium>
    )}

  </Wrapper>
);

export default AllocationInfo;
