import styled from "styled-components";
import UILabel from "components/ui/UILabel";
import { borderRadius, colors, mediaMax, mediaMin, shadows } from "utils/theme";
import DisappearingTile from "components/ui/DisappearingTile";

const BORDER_COLOR = `${colors.midnight}11`;

export const Wrapper = styled(DisappearingTile).attrs(() => ({
  size: "xl",
}))`
  padding-top: 24px;
  padding-bottom: 24px;

  ${mediaMax("lg")} {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  ${mediaMax("md")} {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0;
  margin: 0;

  list-style-type: none;
`;

export const ListItemWrapper = styled.li`
  margin: 0 -8px;

  border-top: 1px solid ${BORDER_COLOR};
`;

export const EmptyMessage = styled.div`
  padding: 48px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionList = styled(List)`
  ${mediaMin("md")} {
    &:not(:first-child) {
      border-top: 1px solid ${BORDER_COLOR};
    }
  }

  ${mediaMax("md")} {
    gap: 16px;
  }
`;

export const Section = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${mediaMin("md")} {
    &:first-child {
      ${ListItemWrapper}:first-child {
        border-top: none;
      }
    }
  }

  ${mediaMax("md")} {
    padding: 16px 16px 8px 16px;

    background: white;
    box-shadow: ${shadows.tile};
    border-radius: ${borderRadius.small}px;
  }
`;

export const SectionHeading = styled(UILabel.Medium).attrs(() => ({
  bold: true,
}))`
  color: ${colors.violet};

  ${mediaMin("md")} {
    display: none;
  }
`;

export const SectionFooter = styled.div`
  padding: 48px 0 24px;
  display: flex;
  justify-content: center;

  ${mediaMin("md")} {
    border-top: 1px solid ${BORDER_COLOR};
  }

  ${mediaMax("md")} {
    padding: 24px 0 0;
  }
`;
