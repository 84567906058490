import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import Button, { ButtonLevel } from "components/ui/Button";
import { Heading } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import { Input } from "components/form";
import { LinkButton } from "components/ui/Link";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

interface Props {
  action: () => void;
  actionLabel: string;
  validationString: string;
  title: string;
  buttonLevel?: ButtonLevel;
}

const ConfirmActionWithValidationModal = (
  { action, actionLabel, validationString, title, buttonLevel }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const stringToMatch = validationString.toUpperCase();

  const confirmAction = () => {
    if (inputRef.current?.value === stringToMatch) {
      action();
      ref.current?.dismiss();
    }
  };

  return (
    <Modal ref={ref}>
      <form>
        <Wrapper>
          <Heading.H4 tag="h2">{title}</Heading.H4>
          <UILabel.Large>Please type {stringToMatch} to confirm</UILabel.Large>

          <Input ref={inputRef} required pattern={stringToMatch} />

          <Footer>
            <Button
              onClick={confirmAction}
              type="submit"
              fullWidth
              level={buttonLevel}
            >
              {actionLabel}
            </Button>
            <LinkButton size="lg" onClick={() => ref.current?.dismiss()}>
              Cancel
            </LinkButton>
          </Footer>
        </Wrapper>
      </form>
    </Modal>
  );
};

export default forwardRef(ConfirmActionWithValidationModal);
