import React from "react";
import styled from "styled-components";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";
import * as Styled from "./styled";
import { TimelineEventStepStatus } from "./types";
import Icon from "../Icon";

const SuccessNode = styled(Styled.EventStepNode)`
  background: ${colors.leaf};

  svg {
    height: 10px;

    * {
      stroke-width: 64px;
      stroke: currentColor;
    }
  }
`;

const PendingNode = styled(Styled.EventStepNode)`
  background: ${colors.azure};
  color: white;

  svg {
    * {
      stroke-width: 16px;
      stroke: currentColor;
    }
  }
`;

interface Props {
  children?: React.ReactNode;
  status: TimelineEventStepStatus;
}

const StepNode: React.FC<Props> = ({ children, status }) => {
  switch (status) {
    case TimelineEventStepStatus.complete:
      return (
        <SuccessNode>
          <Icon name="checkmark" />
        </SuccessNode>
      );
    case TimelineEventStepStatus.pending:
      return (
        <PendingNode>
          <Icon name="ellipsis" size="sm" />
        </PendingNode>
      );
    case TimelineEventStepStatus.incomplete:
    default:
      return (
        <Styled.EventStepNode
          style={{ boxShadow: `0 0 0 2px ${colors.sky} inset` }}
        >
          <UILabel.Micro bold color="violet">
            {children}
          </UILabel.Micro>
        </Styled.EventStepNode>
      );
  }
};

export default StepNode;
