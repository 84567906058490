import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div``;

export const ImageWrapper = styled.div`
  display: flex;
  gap: 48px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  img {
    width: 180px;
    max-height: 108px;
    object-fit: contain;
    object-position: center;
  }

  ${mediaMax("md")} {
    img {
      width: 120px;
      max-height: 72px;
    }
  }
`;
