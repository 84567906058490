import React from "react";
import { capitalize } from "utils/string";
import * as Styled from "./styled";

const mapStatusToLabel = (status) => {
  switch (status) {
    default:
      return capitalize(status.replace(/_/g, ""));
  }
};

interface Props {
  status: string;
}

const StatusTag: React.FC<Props> = ({ status }) => (
  <Styled.Wrapper $status={status}>{mapStatusToLabel(status)}</Styled.Wrapper>
);

export default StatusTag;
