import React from "react";
import Icon from "components/ui/Icon";
import DisappearingTile from "components/ui/DisappearingTile";
import { openWindow } from "utils/window";
import { createSimplePdf } from "utils/pdf";
import * as Styled from "./styled";
import { ResetDocument, ResetDocumentType } from "./types";
import { getUrl } from "./utils";
import DownloadStatementButton from "./DownloadStatementButton";

interface Props {
  documents: ResetDocument[];
}

const onClickOpen = (url) => (e) => {
  e.preventDefault();
  openWindow(url);
};

const onClickDownload = (document) => async (e) => {
  if (document.type === ResetDocumentType.Web) {
    e.preventDefault();
    let filename = document.title;
    if (filename.indexOf("Reset") < 0) filename = `Reset ${filename}`;
    await createSimplePdf(`${document.data.url}?hideNavigation=true`, {
      filename,
      remove: ["#table-of-contents", ".alert-group"],
    });
  }
};

const canPopUp = (type: ResetDocumentType) =>
  [ResetDocumentType.Web, ResetDocumentType.Statement].includes(type);

const canDownloadSimple = (type: ResetDocumentType) =>
  [ResetDocumentType.Web, ResetDocumentType.File].includes(type);

const DocumentList: React.FC<Props> = ({ documents }) => (
  <DisappearingTile size="xl">
    <Styled.Wrapper>
      {documents.map((document) => (
        <Styled.Document key={document.title}>
          <Styled.Label>
            <Styled.IconWrapper>
              <Icon name="document" color="violet" />
            </Styled.IconWrapper>
            {document.title}
          </Styled.Label>

          <Styled.Actions>
            {canPopUp(document.type) && (
              <a
                href={getUrl(document)}
                target="_blank"
                onClick={onClickOpen(getUrl(document))}
              >
                <Icon name="openWindow" color="azure" />
              </a>
            )}
            {canDownloadSimple(document.type) && (
              <a
                href={getUrl(document)}
                target="_blank"
                onClick={onClickDownload(document)}
              >
                <Icon name="download" color="azure" />
              </a>
            )}
            {document.type === ResetDocumentType.Statement && (
              <DownloadStatementButton document={document} />
            )}
          </Styled.Actions>
        </Styled.Document>
      ))}
    </Styled.Wrapper>
  </DisappearingTile>
);

export default DocumentList;
