import React from "react";
import styled from "styled-components";
import { colors, fontSizesUI, fontWeights } from "utils/theme";

type Size = "xs" | "sm" | "md" | "lg" | "xl";

const StyledBase = styled.div<{
  $size: Size;
  $color?: string;
  $isBold?: boolean;
  $isCaps?: boolean;
  $isWideCaps?: boolean;
  $isLight?: boolean;
}>`
  line-height: 1.25;
  font-size: ${(props) => fontSizesUI[props.$size]}px;
  ${(props) =>
    props.$color
      ? `
    color: ${colors[props.$color] || props.$color};
  `
      : ""}
  ${(props) =>
    props.$isLight
      ? `
    color: ${colors[props.$color] || props.$color || colors.midnight}B3;
  `
      : ""}
  ${(props) =>
    props.$isBold &&
    `
    font-weight: ${fontWeights.bold};
  `}
  ${(props) =>
    (props.$isCaps || props.$isWideCaps) &&
    `
    text-transform: uppercase;
  `}
  ${(props) =>
    props.$isCaps &&
    `
    letter-spacing: 0.11em;
  `}
  ${(props) =>
    props.$isWideCaps &&
    `
    letter-spacing: 0.20em;
  `}
  
  a {
    color: ${colors.azure};

    font-weight: ${fontWeights.bold};
    text-decoration: underline;
  }
`;

interface Props {
  size: Size;
  color?: string;
  bold?: boolean;
  caps?: boolean;
  wideCaps?: boolean;
  light?: boolean;
  children: React.ReactNode;
}

const Base: React.FC<Props> = ({
  color,
  size,
  bold,
  caps,
  wideCaps,
  light,
  ...props
}) => (
  <StyledBase
    $size={size}
    $color={color}
    $isBold={bold}
    $isCaps={caps}
    $isWideCaps={wideCaps}
    $isLight={light}
    {...props}
  />
);

const XLarge = (props) => <Base {...props} size="xl" />;
const Large = (props) => <Base {...props} size="lg" />;
const Medium = (props) => <Base {...props} size="md" />;
const Small = (props) => <Base {...props} size="sm" />;
const Micro = (props) => <Base {...props} size="xs" />;

export default { Micro, Small, Medium, Large, XLarge, Base };
