import React, { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { TileCheckboxOption } from "components/form/fields/TileCheckboxGroupField";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import ExpenseRow from "./ExpenseRow";
import * as Styled from "./styled";

interface Props {
  updateValidation: (isValid: boolean) => void;
  options: TileCheckboxOption[];
}

const Amounts: React.FC<Props> = ({ options, updateValidation }) => {
  const { watch, setValue } = useFormContext();
  const expenseSelection = watch("expenseSelection");
  const expenses = watch("expenses");

  const removeRow = (name: string) => {
    setValue(`expenseSelection.${name}`, false);
    setValue(`expenses.${name}`, undefined);
  };

  const addRow = (name: string) => () => {
    setValue(`expenseSelection.${name}`, true);
  };

  const rows = useMemo(
    () => options.filter(({ name }) => expenseSelection[name]),
    [Object.values(expenseSelection)]
  );
  const unselectedOptions = useMemo(
    () => options.filter(({ name }) => !expenseSelection[name]),
    [Object.values(expenseSelection)]
  );

  useEffect(() => {
    if (expenses) {
      const areFilledAllRows = rows.every((row) => !!expenses[row.name]);
      updateValidation(areFilledAllRows);
    }
  }, [expenses, rows]);

  return (
    <Styled.Wrapper>
      <Styled.ExpenseList>
        {rows.map((row) => (
          <ExpenseRow key={row.name} {...row} removeRow={removeRow} />
        ))}
      </Styled.ExpenseList>

      {unselectedOptions.length > 0 && (
        <Styled.Footer>
          <UILabel.Medium bold>Add spending</UILabel.Medium>

          <Styled.AddRowButtons>
            {unselectedOptions.map((option) => (
              <Button
                size={ButtonSize.sm}
                level={ButtonLevel.ghost}
                key={option.name}
                onClick={addRow(option.name)}
                type="button"
              >
                <Icon name={option.icon} color="violet" />
                <UILabel.Small>{option.label}</UILabel.Small>
              </Button>
            ))}
          </Styled.AddRowButtons>
        </Styled.Footer>
      )}
    </Styled.Wrapper>
  );
};

export default Amounts;
