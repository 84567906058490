import { IconName } from "components/ui/Icon";

export const PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP: {
  [key: string]: { title: string; icon: IconName };
} = {
  INCOME: {
    title: "Income",
    icon: "income",
  },
  TRANSFER_IN: {
    title: "Transfer (in)",
    icon: "transfer",
  },
  TRANSFER_OUT: {
    title: "Transfer (out)",
    icon: "transfer",
  },
  LOAN_PAYMENTS: {
    title: "Loan payment",
    icon: "loan",
  },
  BANK_FEES: {
    title: "Bank fee",
    icon: "bank",
  },
  ENTERTAINMENT: {
    title: "Entertainment",
    icon: "popcorn",
  },
  FOOD_AND_DRINK: {
    title: "Food & drink",
    icon: "food",
  },
  GENERAL_MERCHANDISE: {
    title: "Merchandise",
    icon: "shoppingBag",
  },
  HOME_IMPROVEMENT: {
    title: "Home improvement",
    icon: "tools",
  },
  MEDICAL: {
    title: "Medical",
    icon: "medical",
  },
  PERSONAL_CARE: {
    title: "Personal care",
    icon: "spa",
  },
  GENERAL_SERVICES: {
    title: "Service",
    icon: "services",
  },
  GOVERNMENT_AND_NON_PROFIT: {
    title: "Government & non-profit",
    icon: "landmark",
  },
  TRANSPORTATION: {
    title: "Transportation",
    icon: "car",
  },
  TRAVEL: {
    title: "Travel",
    icon: "plane",
  },
  RENT_AND_UTILITIES: {
    title: "Rent & utilities",
    icon: "utilities",
  },
  OTHER: {
    title: "Other",
    icon: "utilities",
  },
};

export const PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP: {
  [key: string]: {
    parentCategory: keyof typeof PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP;
    description: string;
    icon?: IconName;
  };
} = {
  INCOME_DIVIDENDS: {
    parentCategory: "INCOME",
    description: "Dividends from investment accounts",
  },
  INCOME_INTEREST_EARNED: {
    parentCategory: "INCOME",
    description: "Income from interest on savings accounts",
  },
  INCOME_RETIREMENT_PENSION: {
    parentCategory: "INCOME",
    description: "Income from pension payments",
  },
  INCOME_TAX_REFUND: {
    parentCategory: "INCOME",
    description: "Income from tax refunds",
  },
  INCOME_UNEMPLOYMENT: {
    parentCategory: "INCOME",
    description:
      "Income from unemployment benefits, including unemployment insurance and healthcare",
  },
  INCOME_WAGES: {
    parentCategory: "INCOME",
    description: "Income from salaries, gig-economy work, and tips earned",
  },
  INCOME_OTHER_INCOME: {
    parentCategory: "INCOME",
    description:
      "Other miscellaneous income, including alimony, social security, child support, and rental",
  },
  TRANSFER_IN_CASH_ADVANCES_AND_LOANS: {
    parentCategory: "TRANSFER_IN",
    description: "Loans and cash advances deposited into a bank account",
  },
  TRANSFER_IN_DEPOSIT: {
    parentCategory: "TRANSFER_IN",
    description: "Cash, checks, and ATM deposits into a bank account",
  },
  TRANSFER_IN_INVESTMENT_AND_RETIREMENT_FUNDS: {
    parentCategory: "TRANSFER_IN",
    description: "Inbound transfers to an investment or retirement account",
  },
  TRANSFER_IN_SAVINGS: {
    parentCategory: "TRANSFER_IN",
    description: "Inbound transfers to a savings account",
  },
  TRANSFER_IN_ACCOUNT_TRANSFER: {
    parentCategory: "TRANSFER_IN",
    description: "General inbound transfers from another account",
  },
  TRANSFER_IN_OTHER_TRANSFER_IN: {
    parentCategory: "TRANSFER_IN",
    description: "Other miscellaneous inbound transactions",
  },
  TRANSFER_OUT_INVESTMENT_AND_RETIREMENT_FUNDS: {
    parentCategory: "TRANSFER_OUT",
    description:
      "Transfers to an investment or retirement account, including investment apps such as Acorns, Betterment",
  },
  TRANSFER_OUT_SAVINGS: {
    parentCategory: "TRANSFER_OUT",
    description: "Outbound transfers to savings accounts",
  },
  TRANSFER_OUT_WITHDRAWAL: {
    parentCategory: "TRANSFER_OUT",
    description: "Withdrawals from a bank account",
  },
  TRANSFER_OUT_ACCOUNT_TRANSFER: {
    parentCategory: "TRANSFER_OUT",
    description: "General outbound transfers to another account",
  },
  TRANSFER_OUT_OTHER_TRANSFER_OUT: {
    parentCategory: "TRANSFER_OUT",
    description: "Other miscellaneous outbound transactions",
  },
  LOAN_PAYMENTS_CAR_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description: "Car loans and leases",
    icon: "car",
  },
  LOAN_PAYMENTS_CREDIT_CARD_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description:
      "Payments to a credit card. These are positive amounts for credit card subtypes and negative for depository subtypes",
    icon: "creditCard",
  },
  LOAN_PAYMENTS_PERSONAL_LOAN_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description:
      "Personal loans, including cash advances and buy now pay later repayments",
    icon: "handshake",
  },
  LOAN_PAYMENTS_MORTGAGE_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description: "Payments on mortgages",
    icon: "house",
  },
  LOAN_PAYMENTS_STUDENT_LOAN_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description:
      'Payments on student loans. For college tuition, refer to "General Services - Education',
    icon: "student",
  },
  LOAN_PAYMENTS_OTHER_PAYMENT: {
    parentCategory: "LOAN_PAYMENTS",
    description: "Other miscellaneous debt payments",
  },
  BANK_FEES_ATM_FEES: {
    parentCategory: "BANK_FEES",
    description: "Fees incurred for out-of-network ATMs",
    icon: "atm",
  },
  BANK_FEES_FOREIGN_TRANSACTION_FEES: {
    parentCategory: "BANK_FEES",
    description: "Fees incurred on non-domestic transactions",
    icon: "globe",
  },
  BANK_FEES_INSUFFICIENT_FUNDS: {
    parentCategory: "BANK_FEES",
    description: "Fees relating to insufficient funds",
    icon: "chartDown",
  },
  BANK_FEES_INTEREST_CHARGE: {
    parentCategory: "BANK_FEES",
    description:
      "Fees incurred for interest on purchases, including not-paid-in-full or interest on cash advances",
    icon: "percentage",
  },
  BANK_FEES_OVERDRAFT_FEES: {
    parentCategory: "BANK_FEES",
    description: "Fees incurred when an account is in overdraft",
    icon: "invoice",
  },
  BANK_FEES_OTHER_BANK_FEES: {
    parentCategory: "BANK_FEES",
    description: "Other miscellaneous bank fees",
    icon: "fee",
  },
  ENTERTAINMENT_CASINOS_AND_GAMBLING: {
    parentCategory: "ENTERTAINMENT",
    description: "Gambling, casinos, and sports betting",
    icon: "playingCards",
  },
  ENTERTAINMENT_MUSIC_AND_AUDIO: {
    parentCategory: "ENTERTAINMENT",
    description:
      "Digital and in-person music purchases, including music streaming services",
    icon: "music",
  },
  ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS: {
    parentCategory: "ENTERTAINMENT",
    description:
      "Purchases made at sporting events, music venues, concerts, museums, and amusement parks",
    icon: "rollerCoaster",
  },
  ENTERTAINMENT_TV_AND_MOVIES: {
    parentCategory: "ENTERTAINMENT",
    description: "In home movie streaming services and movie theaters",
    icon: "popcorn",
  },
  ENTERTAINMENT_VIDEO_GAMES: {
    parentCategory: "ENTERTAINMENT",
    description: "Digital and in-person video game purchases",
    icon: "videoGame",
  },
  ENTERTAINMENT_OTHER_ENTERTAINMENT: {
    parentCategory: "ENTERTAINMENT",
    description:
      "Other miscellaneous entertainment purchases, including night life and adult entertainment",
    icon: "party",
  },
  FOOD_AND_DRINK_BEER_WINE_AND_LIQUOR: {
    parentCategory: "FOOD_AND_DRINK",
    description: "Beer, Wine & Liquor Stores",
    icon: "wine",
  },
  FOOD_AND_DRINK_COFFEE: {
    parentCategory: "FOOD_AND_DRINK",
    description: "Purchases at coffee shops or cafes",
    icon: "coffee",
  },
  FOOD_AND_DRINK_FAST_FOOD: {
    parentCategory: "FOOD_AND_DRINK",
    description: "Dining expenses for fast food chains",
    icon: "hamburger",
  },
  FOOD_AND_DRINK_GROCERIES: {
    parentCategory: "FOOD_AND_DRINK",
    description:
      "Purchases for fresh produce and groceries, including farmers' markets",
    icon: "grocery",
  },
  FOOD_AND_DRINK_RESTAURANT: {
    parentCategory: "FOOD_AND_DRINK",
    description:
      "Dining expenses for restaurants, bars, gastropubs, and diners",
    icon: "restaurant",
  },
  FOOD_AND_DRINK_VENDING_MACHINES: {
    parentCategory: "FOOD_AND_DRINK",
    description: "Purchases made at vending machine operators",
    icon: "candyBar",
  },
  FOOD_AND_DRINK_OTHER_FOOD_AND_DRINK: {
    parentCategory: "FOOD_AND_DRINK",
    description:
      "Other miscellaneous food and drink, including desserts, juice bars, and delis",
  },
  GENERAL_MERCHANDISE_BOOKSTORES_AND_NEWSSTANDS: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Books, magazines, and news",
    icon: "book",
  },
  GENERAL_MERCHANDISE_CLOTHING_AND_ACCESSORIES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Apparel, shoes, and jewelry",
    icon: "shirt",
  },
  GENERAL_MERCHANDISE_CONVENIENCE_STORES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Purchases at convenience stores",
    icon: "cashRegister",
  },
  GENERAL_MERCHANDISE_DEPARTMENT_STORES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description:
      "Retail stores with wide ranges of consumer goods, typically specializing in clothing and home goods",
    icon: "store",
  },
  GENERAL_MERCHANDISE_DISCOUNT_STORES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Stores selling goods at a discounted price",
    icon: "discount",
  },
  GENERAL_MERCHANDISE_ELECTRONICS: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Electronics stores and websites",
    icon: "gadget",
  },
  GENERAL_MERCHANDISE_GIFTS_AND_NOVELTIES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Photo, gifts, cards, and floral stores",
    icon: "gift",
  },
  GENERAL_MERCHANDISE_OFFICE_SUPPLIES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Stores that specialize in office goods",
    icon: "stapler",
  },
  GENERAL_MERCHANDISE_ONLINE_MARKETPLACES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description:
      "Multi-purpose e-commerce platforms such as Etsy, Ebay and Amazon",
    icon: "box",
  },
  GENERAL_MERCHANDISE_PET_SUPPLIES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Pet supplies and pet food",
    icon: "dogLeashed",
  },
  GENERAL_MERCHANDISE_SPORTING_GOODS: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Sporting goods, camping gear, and outdoor equipment",
    icon: "baseball",
  },
  GENERAL_MERCHANDISE_SUPERSTORES: {
    parentCategory: "GENERAL_MERCHANDISE",
    description:
      "Superstores such as Target and Walmart, selling both groceries and general merchandise",
    icon: "store",
  },
  GENERAL_MERCHANDISE_TOBACCO_AND_VAPE: {
    parentCategory: "GENERAL_MERCHANDISE",
    description: "Purchases for tobacco and vaping products",
    icon: "cigarette",
  },
  GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE: {
    parentCategory: "GENERAL_MERCHANDISE",
    description:
      "Other miscellaneous merchandise, including toys, hobbies, and arts and crafts",
    icon: "palette",
  },
  HOME_IMPROVEMENT_FURNITURE: {
    parentCategory: "HOME_IMPROVEMENT",
    description: "Furniture, bedding, and home accessories",
    icon: "couch",
  },
  HOME_IMPROVEMENT_HARDWARE: {
    parentCategory: "HOME_IMPROVEMENT",
    description: "Building materials, hardware stores, paint, and wallpaper",
  },
  HOME_IMPROVEMENT_REPAIR_AND_MAINTENANCE: {
    parentCategory: "HOME_IMPROVEMENT",
    description: "Plumbing, lighting, gardening, and roofing",
    icon: "lightbulb",
  },
  HOME_IMPROVEMENT_SECURITY: {
    parentCategory: "HOME_IMPROVEMENT",
    description: "Home security system purchases",
    icon: "security",
  },
  HOME_IMPROVEMENT_OTHER_HOME_IMPROVEMENT: {
    parentCategory: "HOME_IMPROVEMENT",
    description:
      "Other miscellaneous home purchases, including pool installation and pest control",
    icon: "pool",
  },
  MEDICAL_DENTAL_CARE: {
    parentCategory: "MEDICAL",
    description: "Dentists and general dental care",
    icon: "tooth",
  },
  MEDICAL_EYE_CARE: {
    parentCategory: "MEDICAL",
    description: "Optometrists, contacts, and glasses stores",
    icon: "glasses",
  },
  MEDICAL_NURSING_CARE: {
    parentCategory: "MEDICAL",
    description: "Nursing care and facilities",
    icon: "nurse",
  },
  MEDICAL_PHARMACIES_AND_SUPPLEMENTS: {
    parentCategory: "MEDICAL",
    description: "Pharmacies and nutrition shops",
    icon: "pharmacy",
  },
  MEDICAL_parentCategory_CARE: {
    parentCategory: "MEDICAL",
    description: "Doctors and physicians",
    icon: "doctor",
  },
  MEDICAL_VETERINARY_SERVICES: {
    parentCategory: "MEDICAL",
    description: "Prevention and care procedures for animals",
    icon: "veteranarian",
  },
  MEDICAL_OTHER_MEDICAL: {
    parentCategory: "MEDICAL",
    description:
      "Other miscellaneous medical, including blood work, hospitals, and ambulances",
    icon: "ambulance",
  },
  PERSONAL_CARE_GYMS_AND_FITNESS_CENTERS: {
    parentCategory: "PERSONAL_CARE",
    description: "Gyms, fitness centers, and workout classes",
    icon: "dumbbell",
  },
  PERSONAL_CARE_HAIR_AND_BEAUTY: {
    parentCategory: "PERSONAL_CARE",
    description:
      "Manicures, haircuts, waxing, spa/massages, and bath and beauty products",
    icon: "scissors",
  },
  PERSONAL_CARE_LAUNDRY_AND_DRY_CLEANING: {
    parentCategory: "PERSONAL_CARE",
    description: "Wash and fold, and dry cleaning expenses",
    icon: "washer",
  },
  PERSONAL_CARE_OTHER_PERSONAL_CARE: {
    parentCategory: "PERSONAL_CARE",
    description:
      "Other miscellaneous personal care, including mental health apps and services",
  },
  GENERAL_SERVICES_ACCOUNTING_AND_FINANCIAL_PLANNING: {
    parentCategory: "GENERAL_SERVICES",
    description: "Financial planning, and tax and accounting services",
    icon: "dollarChart",
  },
  GENERAL_SERVICES_AUTOMOTIVE: {
    parentCategory: "GENERAL_SERVICES",
    description: "Oil changes, car washes, repairs, and towing",
    icon: "carWash",
  },
  GENERAL_SERVICES_CHILDCARE: {
    parentCategory: "GENERAL_SERVICES",
    description: "Babysitters and daycare",
    icon: "baby",
  },
  GENERAL_SERVICES_CONSULTING_AND_LEGAL: {
    parentCategory: "GENERAL_SERVICES",
    description: "Consulting and legal services",
    icon: "scale",
  },
  GENERAL_SERVICES_EDUCATION: {
    parentCategory: "GENERAL_SERVICES",
    description:
      "Elementary, high school, professional schools, and college tuition",
    icon: "chalkboard",
  },
  GENERAL_SERVICES_INSURANCE: {
    parentCategory: "GENERAL_SERVICES",
    description: "Insurance for auto, home, and healthcare",
    icon: "accident",
  },
  GENERAL_SERVICES_POSTAGE_AND_SHIPPING: {
    parentCategory: "GENERAL_SERVICES",
    description: "Mail, packaging, and shipping services",
    icon: "mailbox",
  },
  GENERAL_SERVICES_STORAGE: {
    parentCategory: "GENERAL_SERVICES",
    description: "Storage services and facilities",
    icon: "storage",
  },
  GENERAL_SERVICES_OTHER_GENERAL_SERVICES: {
    parentCategory: "GENERAL_SERVICES",
    description:
      "Other miscellaneous services, including advertising and cloud storage",
  },
  GOVERNMENT_AND_NON_PROFIT_DONATIONS: {
    parentCategory: "GOVERNMENT_AND_NON_PROFIT",
    description: "Charitable, political, and religious donations",
    icon: "charity",
  },
  GOVERNMENT_AND_NON_PROFIT_GOVERNMENT_DEPARTMENTS_AND_AGENCIES: {
    parentCategory: "GOVERNMENT_AND_NON_PROFIT",
    description:
      "Government departments and agencies, such as driving licences, and passport renewal",
    icon: "idCard",
  },
  GOVERNMENT_AND_NON_PROFIT_TAX_PAYMENT: {
    parentCategory: "GOVERNMENT_AND_NON_PROFIT",
    description: "Tax payments, including income and property taxes",
    icon: "moneyBag",
  },
  GOVERNMENT_AND_NON_PROFIT_OTHER_GOVERNMENT_AND_NON_PROFIT: {
    parentCategory: "GOVERNMENT_AND_NON_PROFIT",
    description: "Other miscellaneous government and non-profit agencies",
  },
  TRANSPORTATION_BIKES_AND_SCOOTERS: {
    parentCategory: "TRANSPORTATION",
    description: "Bike and scooter rentals",
    icon: "bicycle",
  },
  TRANSPORTATION_GAS: {
    parentCategory: "TRANSPORTATION",
    description: "Purchases at a gas station",
    icon: "gasoline",
  },
  TRANSPORTATION_PARKING: {
    parentCategory: "TRANSPORTATION",
    description: "Parking fees and expenses",
    icon: "parking",
  },
  TRANSPORTATION_PUBLIC_TRANSIT: {
    parentCategory: "TRANSPORTATION",
    description:
      "Public transportation, including rail and train, buses, and metro",
    icon: "train",
  },
  TRANSPORTATION_TAXIS_AND_RIDE_SHARES: {
    parentCategory: "TRANSPORTATION",
    description: "Taxi and ride share services",
    icon: "taxi",
  },
  TRANSPORTATION_TOLLS: {
    parentCategory: "TRANSPORTATION",
    description: "Toll expenses",
    icon: "bridge",
  },
  TRANSPORTATION_OTHER_TRANSPORTATION: {
    parentCategory: "TRANSPORTATION",
    description: "Other miscellaneous transportation expenses",
    icon: "carBus",
  },
  TRAVEL_FLIGHTS: {
    parentCategory: "TRAVEL",
    description: "Airline expenses",
  },
  TRAVEL_LODGING: {
    parentCategory: "TRAVEL",
    description: "Hotels, motels, and hosted accommodation such as Airbnb",
    icon: "bed",
  },
  TRAVEL_RENTAL_CARS: {
    parentCategory: "TRAVEL",
    description: "Rental cars, charter buses, and trucks",
    icon: "rental",
  },
  TRAVEL_OTHER_TRAVEL: {
    parentCategory: "TRAVEL",
    description: "Other miscellaneous travel expenses",
    icon: "luggage",
  },
  RENT_AND_UTILITIES_GAS_AND_ELECTRICITY: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Gas and electricity bills",
    icon: "electric",
  },
  RENT_AND_UTILITIES_INTERNET_AND_CABLE: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Internet and cable bills",
    icon: "wifi",
  },
  RENT_AND_UTILITIES_RENT: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Rent payment",
    icon: "building",
  },
  RENT_AND_UTILITIES_SEWAGE_AND_WASTE_MANAGEMENT: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Sewage and garbage disposal bills",
    icon: "trash",
  },
  RENT_AND_UTILITIES_TELEPHONE: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Cell phone bills",
    icon: "phone",
  },
  RENT_AND_UTILITIES_WATER: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Water bills",
    icon: "faucet",
  },
  RENT_AND_UTILITIES_OTHER_UTILITIES: {
    parentCategory: "RENT_AND_UTILITIES",
    description: "Other miscellaneous utility bills",
  },
  OTHER_OTHER: {
    parentCategory: "OTHER",
    description: "Unknown category",
  },
};
