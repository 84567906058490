import React from "react";
import styled from "styled-components";
import { colors } from "utils/theme";
import UILabel from "components/ui/UILabel";
import { DashboardAccountStatus } from "../types";

const mapStatusToLabel = (status: DashboardAccountStatus) => {
  switch (status) {
    case DashboardAccountStatus.active:
      return "Connected";
    case DashboardAccountStatus.inactive:
      return "Offline";
    case DashboardAccountStatus.pending:
      return "Connecting";
    default:
      return "";
  }
};

const mapStatusToColor = (status: DashboardAccountStatus) => {
  switch (status) {
    case DashboardAccountStatus.active:
      return colors.leaf;
    case DashboardAccountStatus.inactive:
      return colors.cosmos;
    case DashboardAccountStatus.pending:
      return colors.flax;
    default:
      return "transparent";
  }
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Text = styled(UILabel.Small).attrs(() => ({ caps: false }))`
  opacity: 0.7;

  line-height: 1;
`;

const Badge = styled.div<{ $status: DashboardAccountStatus }>`
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background: ${(props) => mapStatusToColor(props.$status)};
  border: 1px solid ${colors.midnight};
`;

interface Props {
  status: DashboardAccountStatus;
}

const Status: React.FC<Props> = ({ status }) => (
  <Wrapper>
    <Badge $status={status} />
    <Text>{mapStatusToLabel(status)}</Text>
  </Wrapper>
);

export default Status;
