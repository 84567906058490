import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ApiStatement } from "utils/api/statements";
import { formatDateString } from "utils/date";
import { ApiUserProfile } from "utils/api/user";
import {
  DEFAULT_STATEMENT_STYLE,
  DEFAULT_STYLE,
  FONTS,
  STATEMENT_STYLES,
  STATEMENT_TABLE_LAYOUTS,
  STYLES,
} from "./constants";
import { buildItems } from "./simple";
import { buildStatement } from "./statements";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface PdfOptions {
  filename?: string;
  remove?: string[];
}

// create pdf from url with fetched html
// appropriate for text-based documents in a single column and/or tables
// see ~/app/views/static_pages/privacy_policy.html.erb for example
export const createSimplePdf = async (
  url: string,
  options: PdfOptions = {}
) => {
  const res = await fetch(url);
  const html = await res.text();
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  if (options.remove) {
    options.remove.forEach((selector) => {
      const elements = doc.querySelectorAll(selector);
      elements.forEach((el) => el.remove());
    });
  }
  const main = doc.body.querySelector("main") || doc.body;
  const ids = [];
  const content = Array.from(main.childNodes)
    .reduce((acc, el) => {
      const items = buildItems(el, ids);
      return [...acc, ...items];
    }, [])
    .filter((item) => item);
  pdfMake
    .createPdf(
      { content, styles: STYLES, defaultStyle: DEFAULT_STYLE },
      null,
      FONTS
    )
    .download(`${options.filename || "document"}.pdf`);
};

export const createStatementPdf = async ({
  statement,
  userProfile,
}: {
  statement: ApiStatement;
  userProfile: ApiUserProfile;
}) => {
  const content = buildStatement({ statement, userProfile });
  await pdfMake
    .createPdf(
      {
        content,
        styles: STATEMENT_STYLES,
        defaultStyle: DEFAULT_STATEMENT_STYLE,
      },
      STATEMENT_TABLE_LAYOUTS,
      FONTS
    )
    .download(
      `Reset Deposit Account Statement ${formatDateString(
        statement.issueDate,
        "text"
      )}.pdf`
    );
};
