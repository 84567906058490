import UILabel from "components/ui/UILabel";
import styled from "styled-components";
import { colors, mediaMin } from "utils/theme";

export const Wrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  list-style-type: none;
`;

export const Document = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.midnight}11;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const Label = styled(UILabel.Medium)`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  gap: 8px;

  ${mediaMin("md")} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  align-items: center;
`;
