export const getOrdinal = (n: number | string) => {
  const s = ["th", "st", "nd", "rd"];
  const v = (typeof n === "string" ? parseInt(n, 10) : n) % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const ordinalize = (n: number | string) => `${n}${getOrdinal(n)}`;

export const round = (i, places = 0) =>
  Math.round((i + Number.EPSILON) * 10 ** places) / 10 ** places;
