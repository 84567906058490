import React from "react";
import styled from "styled-components";
import { colors, fontSizes, fonts, shadows, transitions } from "utils/theme";

const StyledInput = styled.input<{ $hasError?: boolean }>`
  width: 100%;
  height: 48px;
  padding: 0 12px;

  background: white;
  border: 1px solid ${colors.midnight}40;
  border-radius: 8px;
  outline: 0;
  appearance: none;

  font-family: ${fonts.body};
  font-size: ${fontSizes.sm}px;

  transition: all ${transitions.simple};

  ${(props) =>
    props.$hasError
      ? `
  border-color: ${colors.cerise};
  `
      : ""}

  &:focus {
    border-color: ${colors.midnight};
    box-shadow: ${shadows.regular};
  }

  &::placeholder {
    color: ${colors.midnight}55;
  }

  &[disabled] {
    background: ${colors.violet}10;
    color: ${colors.midnight}AA;
  }
`;

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: any;
  hasError?: boolean;
}

const Input = (
  { name, onChange, hasError, ...props }: InputProps,
  ref: React.Ref<HTMLInputElement>
) => (
  <StyledInput
    name={name}
    onChange={onChange}
    ref={ref}
    $hasError={hasError}
    {...props}
  />
);

export default React.forwardRef(Input);
