import { useEffect, useState } from "react";
import environment from "utils/environment";
import { local } from "utils/storage";

interface AppWindow {
  HubSpotConversations: any;
  hsConversationsSettings: { [key: string]: any };
  hsConversationsOnReady: Function[];
  RESET: {
    hubspot: {
      chatContainerId: string;
    };
  };
}

interface Props {
  isPrivate?: boolean;
}

const useHubspotChat = ({ isPrivate }: Props) => {
  const [isReady, setIsReady] = useState(
    environment.resetEnv !== "production" && !environment.enableDemoTriggers
  );
  const [isOpen, setIsOpen] = useState(false);

  const app = window as unknown as AppWindow;
  const chat = app.HubSpotConversations;
  const id = app.RESET?.hubspot?.chatContainerId;

  const toggle = (open = !isOpen) => {
    if (open) {
      chat.widget.load();
      chat.widget.open();
    } else {
      chat.widget.remove();
    }
    setIsOpen((o) => !o);
  };

  const onWidgetLoaded = () => {
    setIsReady(true);
  };

  const initializeChat = () => {
    chat.on("widgetLoaded", onWidgetLoaded);

    chat.widget.load();
  };

  useEffect(() => {
    // check if the widget can load before showing it
    if (!isReady) {
      if (chat) {
        initializeChat();
      } else {
        // give HubSpot script a chance to load
        setTimeout(() => {
          initializeChat();
        }, 5000);
      }
    } else if (chat) {
      // prevent chat from popping up automatically
      chat.widget.remove();
    }
  }, [isReady]);

  useEffect(() => {
    // on public pages, hubspot cookies will be removed on page load when this is true, and this localStorage item will be removed
    local.setItem("markHubspotChatForDeletion", isPrivate);
  }, [isPrivate]);

  return { isReady, isOpen, toggle, id };
};

export default useHubspotChat;
