import React from "react";
import { colors } from "utils/theme";

const LightbulbFill = (props) => (
  <svg viewBox="0 0 11 13" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14044 9.46006C10.1934 8.51892 10.8562 7.15034 10.8562 5.62695C10.8562 2.78854 8.55521 0.487549 5.7168 0.487549C2.87838 0.487549 0.577393 2.78854 0.577393 5.62695C0.577393 7.15072 1.24053 8.5196 2.29394 9.46076C2.58596 9.8387 2.78858 10.116 3.13156 10.709C3.30169 11.0032 3.55059 11.8003 3.62043 12.1931H5.68332H5.75052H7.81341C7.88324 11.8003 8.13215 11.0032 8.30228 10.709C8.64547 10.1156 8.84812 9.83836 9.14044 9.46006Z"
      fill={colors.cosmos}
    />
  </svg>
);

export default LightbulbFill;
