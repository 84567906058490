import { http, HttpResponse, passthrough } from "msw";
import { drop as dropDb } from "@mswjs/data";
import dayjs from "dayjs";
import { local } from "utils/storage";
import db from "./db";
import seed, { createCardTransactions, createShifts, seedUser } from "./seed";
import {
  apiPath,
  formatDate,
  getCardTransactions,
  getPaystubs,
  getRecurringExpenses,
  getSafeToSpendComponents,
  getShifts,
} from "./utils";
import persist from "./persist";
import { COMPENSATION_AMOUNT, NET_PAY_RATIO } from "./constants";

persist(db);

if (db.user.getAll().length === 0) {
  seed(db);
}

const drop = dropDb as (database: any) => void; // ignore typescript "excessively deep and infinite" warning

let currentUserId =
  local.getItem("mockCurrentUserId") || db.user.getAll()[0].id;

const getCurrentUser = () =>
  db.user.findFirst({ where: { id: { equals: currentUserId } } });

const setCurrentUserId = (id) => {
  local.setItem("mockCurrentUserId", id);

  currentUserId = id;
};

const buildQuery = (path, request) =>
  http.get(apiPath(path), () => {
    const currentUser = getCurrentUser();

    if (!currentUser) return passthrough();

    return request(currentUser);
  });

const buildMutation = (path, request) =>
  http.post(apiPath(path), () => {
    const currentUser = getCurrentUser();

    if (!currentUser) return passthrough();

    return request(currentUser);
  });

export const handlers = [
  // mock-only
  http.post(apiPath("mock/init"), () => new HttpResponse()),
  http.post(apiPath("mock/reset"), () => {
    drop(db);
    seed(db);
    setCurrentUserId(db.user.getAll()[0].id);

    return new HttpResponse();
  }),
  http.get(apiPath("mock/users"), async () => {
    const users = db.user.getAll();
    return HttpResponse.json(users);
  }),
  http.post(apiPath("mock/users/set_current"), async ({ request }) => {
    const params = await request.json();
    const { id } = params as { id: string };
    setCurrentUserId(id);

    return new HttpResponse();
  }),
  http.post(apiPath("mock/users/create"), async ({ request }) => {
    const { firstName, lastName, ...userParams } = (await request.json()) as {
      firstName: string;
      lastName: string;
    };
    const email =
      firstName && lastName
        ? `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`.replace(
            /\s+/g,
            "."
          )
        : undefined;
    const user = seedUser(db, {
      ...userParams,
      firstName,
      lastName,
      email,
    });
    setCurrentUserId(user.id);
    return HttpResponse.json(user);
  }),

  // overrides
  buildQuery("users/profile", (currentUser) => HttpResponse.json(currentUser)),
  buildQuery("dashboard/overview", (currentUser) => {
    const safeToSpendComponents = getSafeToSpendComponents(db, currentUser);

    return HttpResponse.json({
      accounts: currentUser?.accounts,
      safeToSpendComponents,
    });
  }),
  buildQuery("dashboard/payday", (currentUser) => {
    const paystub = getPaystubs(db, currentUser)[0];
    const paycheckAmount = paystub.netPayAmount;
    const repaymentAmount = paycheckAmount * 0.4;
    const remainderAmount = paycheckAmount - repaymentAmount;

    return HttpResponse.json({
      currentPayPeriodEnd: formatDate(dayjs().subtract(3, "days")),
      currentPayday: formatDate(dayjs()),
      paycheckAmount,
      repaymentAmount,
      remainderAmount,
      paycheckReceived: true,
    });
  }),
  buildQuery(
    "recurring_transactions/active_recurring_outflows",
    (currentUser) => {
      const recurringExpenses = getRecurringExpenses(db, currentUser);

      return HttpResponse.json(recurringExpenses);
    }
  ),
  buildQuery("card_accounts/current/card_transactions", (currentUser) => {
    const cardTransactions = getCardTransactions(db, currentUser);

    return HttpResponse.json(cardTransactions);
  }),
  buildQuery("income/earnings", (currentUser) => {
    const shifts = getShifts(db, currentUser);

    return HttpResponse.json({
      shifts,
      netPayRatio: NET_PAY_RATIO,
      beginningOfPayPeriod: formatDate(dayjs().subtract(2, "days")),
      compensationUnit: "hourly",
      compensationAmount: COMPENSATION_AMOUNT,
    });
  }),
  buildQuery("income/paystubs", (currentUser) => {
    const paystubs = getPaystubs(db, currentUser);

    return HttpResponse.json({
      data: paystubs,
    });
  }),
  buildQuery("notifications", () => HttpResponse.json([])),
  buildMutation("simulate/earnings", (currentUser) => {
    createShifts(db, currentUser.id);

    return new HttpResponse();
  }),
  buildMutation("simulate/transaction", (currentUser) => {
    createCardTransactions(db, currentUser.id);

    return new HttpResponse();
  }),
];
