import { BlockType, CardType, TileBlock } from "types/cms";

const tileBlock: TileBlock = {
  id: "block_1",
  type: BlockType.tile,
  data: {
    tiles: [
      {
        type: CardType.icon,
        body: "Access a portion of your income as it is earned",
        iconName: "paycheck",
      },
      {
        type: CardType.icon,
        body: "Only spend what you can afford with a smart spending limit",
        iconName: "dollarChart",
      },
      {
        type: CardType.icon,
        body: "We do not ask for tips or charge interest, monthly, or late fees",
        iconName: "handshake",
      },
    ],
  },
};

export default { tileBlock };
