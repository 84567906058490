import React from "react";
import { ApiAdminWaitlistEligibility } from "utils/api/admin/waitlist";
import * as Styled from "./styled";

const mapEligibilityToLabel = (status) => {
  switch (status) {
    case "green":
      return "Eligible";
    case "red":
      return "Ineligible";
    case "yellow":
    default:
      return "Indeterminate";
  }
};

interface Props {
  status: ApiAdminWaitlistEligibility;
}

const EligibilityTag: React.FC<Props> = ({ status }) => (
  <Styled.Wrapper $status={status}>
    {mapEligibilityToLabel(status)}
  </Styled.Wrapper>
);

export default EligibilityTag;
