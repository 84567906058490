import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import FormContainer, { InputField, SubmitButton } from "components/form";
import { usePostAdminUserOverrideIncomeMutation } from "store/admin/api/apiSlice";
import Modal, { ModalApi } from "./Modal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

interface Props {
  id: string;
  onSuccess: () => void;
}

const OverrideIncomeModal = (
  { id, onSuccess }: Props,
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);
  const [postOverrideIncome] = usePostAdminUserOverrideIncomeMutation();

  const onSubmit = async (values: { compensationAmount: string }) => {
    const compensationAmountInt = Math.round(
      parseInt(values.compensationAmount, 10) * 100
    );

    await postOverrideIncome({
      id,
      compensation_amount_int: compensationAmountInt,
    });
    if (onSuccess) {
      onSuccess();
    }
    ref.current?.dismiss();
  };

  return (
    <Modal ref={ref}>
      <Wrapper>
        <FormContainer onSubmit={onSubmit}>
          <InputField
            name="compensationAmount"
            label="Annualized Compensation Amount (USD)"
            type="number"
          />

          <SubmitButton>Override Income</SubmitButton>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(OverrideIncomeModal);
