import React from "react";
import { ButtonProps, ButtonSize } from "components/ui/Button";
import Icon, { IconName } from "components/ui/Icon";
import useHubspotChat from "utils/hooks/useHubspotChat";
import * as Styled from "./styled";
import { PositionX, PositionY } from "./types";

interface RenderProps {
  isOpen: boolean;
  defaultButton: React.ReactNode;
}

interface Props {
  buttonProps?: ButtonProps;
  icon?: IconName;
  label?: string;
  isPrivate?: boolean;
  positionX?: PositionX;
  positionY?: PositionY;
  positionMobileX?: PositionX;
  positionMobileY?: PositionY;
  children?: (props: RenderProps) => React.ReactNode;
}

const ChatWidget: React.FC<Props> = ({
  isPrivate,
  positionX = "right",
  positionY = "bottom",
  positionMobileX,
  positionMobileY,
  buttonProps,
  icon = "chat",
  label = "Talk to Us",
  children,
}) => {
  const chatWidget = useHubspotChat({ isPrivate });

  const defaultButton = (
    <Styled.Wrapper
      $isOpen={chatWidget.isOpen}
      $isReady={chatWidget.isReady}
      $positionX={positionX}
      $positionY={positionY}
      $positionMobileX={positionMobileX}
      $positionMobileY={positionMobileY}
    >
      <Styled.ChatButton
        size={ButtonSize.md}
        onClick={() => chatWidget.toggle()}
        {...buttonProps}
      >
        <Icon name={chatWidget.isOpen ? "close" : icon} />
        <Styled.ButtonLabel>
          {chatWidget.isOpen ? "Close Chat" : label}
        </Styled.ButtonLabel>
      </Styled.ChatButton>

      <Styled.ChatWrapper>
        <div id={chatWidget.id} />
      </Styled.ChatWrapper>
    </Styled.Wrapper>
  );

  if (!children) {
    return defaultButton;
  }

  return children({ isOpen: chatWidget.isOpen, defaultButton });
};

export default ChatWidget;
