import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;

  ${mediaMax("lg")} {
    margin-bottom: 8px;
  }

  ${mediaMax("md")} {
    margin-bottom: 24px;
  }
`;

export const TileWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  margin-top: -25%;
`;

export const Header = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
