import React from "react";
import Toggle from "components/cms/components/block/Toggle";
import Loading from "components/ui/Loading";
import { ToggleBlock } from "types/cms";
import { getContentfulToggleBlock } from "store/api/apiSlice";
import { transformRawBlock } from "utils/contentful/mapEntryToPage";
import { TOGGLE_BLOCK_ID } from "utils/constants/contentful";
import { substituteIn } from "utils/template";

interface Props {
  bankName: string;
}

const Faqs: React.FC<Props> = ({ bankName }) => {
  const { data, isLoading } = getContentfulToggleBlock({
    id: TOGGLE_BLOCK_ID.directDepositSwitch,
  });

  if (isLoading) return <Loading />;

  const rawToggleBlock = data?.toggleBlock;

  if (!rawToggleBlock) return null;

  const toggleBlockWithSubstitutions = substituteIn(rawToggleBlock, {
    bank: bankName,
  });
  const faqs = transformRawBlock(toggleBlockWithSubstitutions) as ToggleBlock;

  const faqsData = { items: faqs.data.items };

  return <Toggle id={faqs.id} type={faqs.type} data={faqsData} />;
};

export default Faqs;
