import React, { useEffect } from "react";
import styled from "styled-components";
import { MaskedField, MaskType, SelectField } from "components/form";
import { useFormContext } from "react-hook-form";
import { mediaMin } from "utils/theme";
import { INCOME_FREQUENCY_OPTIONS } from "../constants";

const Row = styled.div`
  display: grid;
  gap: 24px;

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  updateValidation: (isValid: boolean) => void;
}

const Income: React.FC<Props> = ({ updateValidation }) => {
  const { watch } = useFormContext();
  const income = watch("income");

  useEffect(() => {
    if (!income) return;
    const incomeAmount = parseInt(income.replace(/[^0-9.]/g, ""), 10);
    updateValidation(incomeAmount > 0);
  }, [income]);

  return (
    <Row>
      <MaskedField
        maskType={MaskType.currency}
        name="income"
        label="Income"
        placeholder="$"
      />

      <SelectField
        name="incomeFrequency"
        label="Frequency"
        options={INCOME_FREQUENCY_OPTIONS}
      />
    </Row>
  );
};

export default Income;
