import { Heading } from "components/ui/Text";
import styled from "styled-components";
import { colors, fontWeights, mediaMax, mediaMin } from "utils/theme";

const BORDER_COLOR = `${colors.midnight}11`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;

  ${mediaMin("md")} {
    padding: 24px 24px 0;
  }

  ${mediaMin("lg")} {
    padding: 0 48px;
  }
`;

export const HeaderMain = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 32px;

  ${mediaMax("md")} {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 24px;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  ${mediaMax("md")} {
    flex-direction: column-reverse;
  }
`;

export const HeaderBalance = styled(HeaderItem)`
  flex: 1 1 auto;
  justify-content: center;
  gap: 2px;

  ${mediaMax("md")} {
    flex: 0 0 auto;
    flex-direction: column;
  }
`;

export const HeaderAmount = styled(Heading.H3)`
  font-weight: ${fontWeights.normal};
`;

export const HeaderNote = styled.div`
  padding-top: 8px;
`;

export const HeaderStats = styled.div`
  display: flex;
  flex: 0 0 auto;
  gap: 12px;

  ${mediaMin("md")} {
    border-left: 1px solid ${BORDER_COLOR};
    flex-direction: column;
    align-items: flex-end;
    padding: 2px 0 2px 32px;

    text-align: right;
  }

  ${mediaMax("md")} {
    gap: 24px;

    ${HeaderItem} {
      flex: 1 1 auto;

      &:not(:first-child) {
        position: relative;

        &::before {
          content: "";

          position: absolute;
          top: 0;
          left: -12px;

          height: 100%;
          width: 1px;

          background: ${BORDER_COLOR};
        }
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaMax("md")} {
    gap: 24px;
  }
`;
