import React from "react";
import styled from "styled-components";
import { colors, fontSizesUI, mediaMax, shadows } from "utils/theme";

const Wrapper = styled.div`
  position: relative;

  display: flex;
  padding: 16px 24px;
  width: 100%;
  gap: 8px;
  align-items: center;
  overflow: hidden;

  border-radius: 6px;
  background: white;
  box-shadow: ${shadows.tile};
  border: 1px solid ${colors.cerise};
  color: ${colors.midnight};

  font-size: ${fontSizesUI.md}px;
  text-decoration: none;

  &::before {
    content: "";

    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;

    width: 4px;

    background: ${colors.cerise};
    border-radius: 2px;
  }

  ${mediaMax("md")} {
    padding: 12px 16px;
  }
`;

const FormMessage = ({ children }) => <Wrapper>{children}</Wrapper>;

export default FormMessage;
