import React from "react";
import { OnboardingTaskName } from "types/onboarding";
import Payroll from "./Payroll";
import Bank from "./Bank";

const Step: React.FC<{ id: OnboardingTaskName }> = ({ id }) => {
  let step;

  switch (id) {
    case OnboardingTaskName.payrollLinking:
      step = <Payroll />;
      break;
    case OnboardingTaskName.bankLinking:
      step = <Bank />;
      break;
    default:
      step = <></>;
      break;
  }

  return step;
};

export default Step;
