export const NET_PAY_RATIO = 0.72;

export const COMPENSATION_AMOUNT = 2425; // cents

export const MERCHANTS = [
  "Starbucks",
  "Target",
  "Amazon",
  "Walmart",
  "Costco",
  "Best Buy",
  "Home Depot",
  "Wendy's",
  "Whole Foods",
  "Shell",
];

export const RECURRING_EXPENSES = [
  {
    description: "AT&T",
    personalFinanceCategoryPrimary: "RENT_AND_UTILITIES",
    personalFinanceCategorySecondary: "RENT_AND_UTILITIES_TELEPHONE",
  },
  {
    description: "Comcast",
    personalFinanceCategoryPrimary: "RENT_AND_UTILITIES",
    personalFinanceCategorySecondary: "RENT_AND_UTILITIES_INTERNET_AND_CABLE",
  },
  {
    description: "National Gas & Electric",
    personalFinanceCategoryPrimary: "RENT_AND_UTILITIES",
    personalFinanceCategorySecondary: "RENT_AND_UTILITIES_GAS_AND_ELECTRICITY",
  },
  {
    description: "State Farm",
    personalFinanceCategoryPrimary: "GENERAL_SERVICES",
    personalFinanceCategorySecondary: "GENERAL_SERVICES_INSURANCE",
  },
  {
    description: "Netflix",
    personalFinanceCategoryPrimary: "ENTERTAINMENT",
    personalFinanceCategorySecondary: "ENTERTAINMENT_TV_AND_MOVIES",
  },
  {
    description: "Chase Autopay",
    personalFinanceCategoryPrimary: "LOAN_PAYMENTS",
    personalFinanceCategorySecondary: "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT",
  },
];
