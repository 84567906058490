import React from "react";
import styled from "styled-components";
import LogoWithText from "components/ui/LogoWithText";
import { ButtonLink, ButtonSize } from "components/ui/Button";
import { colors, mediaMax } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 96px;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;

  ${mediaMax("md")} {
    height: 72px;
    padding: 0 16px;

    background: ${colors.midnight};
  }
`;

const ExitButton = styled(ButtonLink)`
  background: transparent;
  border: 1.5px solid ${colors.ice}40;

  &,
  &:visited,
  &:hover,
  &:active {
    color: white;
  }
`;

const Header = () => (
  <Wrapper>
    <LogoWithText text="Financial Health" reverse />

    <ExitButton href="/" size={ButtonSize.md}>
      Exit
    </ExitButton>
  </Wrapper>
);

export default Header;
