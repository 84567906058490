import React from "react";
import styled from "styled-components";
import { Eyebrow } from "components/ui/Text";
import { colors, fontSizes } from "utils/theme";
import Icon, { IconName } from "components/ui/Icon";

const IDENTITY_MAP: { [key: string]: { title: string; icon: IconName } } = {
  name: {
    title: "Name",
    icon: "userCircle",
  },
  email: {
    title: "Email",
    icon: "email",
  },
  phoneNumber: {
    title: "Phone number",
    icon: "phone",
  },
  dateOfBirth: {
    title: "Date of birth",
    icon: "calendar",
  },
  address: {
    title: "Address",
    icon: "house",
  },
  currentAddress: {
    title: "Address",
    icon: "house",
  },
  ssn: {
    title: "Social security number",
    icon: "lock",
  },
};

const Item = styled.div`
  display: flex;
  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;

  border-radius: 9999px;
  background: ${colors.azure}2A;
`;

const ItemContent = styled.div`
  display: grid;
  gap: 2px;
`;

const ItemHeading = styled(Eyebrow)`
  color: ${colors.violet};
  opacity: 0.5;

  font-size: ${fontSizes.xxs}px;
`;

const ItemBody = styled.div`
  display: grid;
  gap: 4px;
`;

const getDisplayValue = (
  name: string,
  value: string | { [name: string]: any }
) => {
  if (
    ["address", "currentAddress"].includes(name) &&
    typeof value === "object"
  ) {
    return (
      <>
        <div>{value.streetOne}</div>
        {value.streetTwo?.length > 0 ? <div>{value.streetTwo}</div> : null}
        <div>
          {value.city}, {value.stateAbbr} {value.zipCode}
        </div>
      </>
    );
  }
  if (typeof value === "string") {
    return value;
  }
  return <></>;
};

const LineItem = ({ name, value }) => {
  const { title, icon } = IDENTITY_MAP[name] || {};

  return (
    <Item>
      <IconWrapper>
        <Icon name={icon} color={colors.violet} />
      </IconWrapper>
      <ItemContent>
        <ItemHeading>{title}</ItemHeading>
        <ItemBody>{getDisplayValue(name, value)}</ItemBody>
      </ItemContent>
    </Item>
  );
};

export default LineItem;
