import React, { useEffect, useState } from "react";
import UILayout from "components/layout/UILayout";
import Loading from "components/ui/Loading";
import { LinkButton } from "components/ui/Link";
import {
  getPaystubs,
  getEarnings,
  getDashboardOverview,
} from "store/api/apiSlice";
import { isHourlyOrGig } from "utils/api/income";
import Earnings from "../Earnings";
import Header from "../Header";
import * as Styled from "./styled";
import Paystubs from "../Paystubs";

const Income: React.FC = () => {
  const [showPaystubs, setShowPaystubs] = useState(false);
  const { data: earnings, isLoading } = getEarnings();
  const { data: paystubs, isLoading: isPaystubsLoading } = getPaystubs({
    skip: !showPaystubs,
  });
  const { data: dashboard } = getDashboardOverview();

  const { beginningOfPayPeriod, netPayRatio, compensationUnit } =
    earnings || {};

  useEffect(() => {
    if (earnings && !isHourlyOrGig(compensationUnit)) {
      setShowPaystubs(true);
    }
  }, [earnings]);

  if (isLoading) return <Loading />;

  const accruedNetEarnings =
    dashboard?.safeToSpendComponents?.accruedNetEarnings;
  const accruedGrossEarnings = accruedNetEarnings / (netPayRatio || 1);

  return (
    <UILayout>
      <Styled.Wrapper>
        <Header
          accruedNetEarnings={accruedNetEarnings}
          accruedGrossEarnings={accruedGrossEarnings}
          beginningOfPayPeriod={beginningOfPayPeriod}
        />

        {isHourlyOrGig(compensationUnit) && <Earnings {...earnings} />}

        <Styled.Footer>
          <LinkButton onClick={() => setShowPaystubs((s) => !s)}>
            {showPaystubs ? "Hide" : "Show"} Previous Pay Periods
          </LinkButton>
        </Styled.Footer>

        <Paystubs paystubs={paystubs?.data} isLoading={isPaystubsLoading} />
      </Styled.Wrapper>
    </UILayout>
  );
};

export default Income;
