import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { LinkButton } from "components/ui/Link";
import { Body, Heading } from "components/ui/Text";
import { SubmitButton } from "components/form";
import PersonalInfoList, { Info } from "components/ui/PersonalInfoList";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { ApiUserProfile } from "utils/api/user";
import { mediaMin } from "utils/theme";
import * as Styled from "../../styled";

const Footer = styled.div`
  padding-top: 24px;
  width: 100%;

  ${mediaMin("lg")} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
  }
`;

interface Props {
  onClickBack: () => void;
  user: ApiUserProfile;
}

const ReviewInfo: React.FC<Props> = ({
  user: { firstName, lastName },
  onClickBack,
}) => {
  const { watch } = useFormContext();
  const personalInfo = watch() as Info;

  return (
    <>
      <Styled.Header>
        <Heading.H2 tag="h1">Let's review your info.</Heading.H2>
        <Body>
          Please verify that the information below is correct before submitting.
        </Body>
      </Styled.Header>

      <PersonalInfoList info={{ firstName, lastName, ...personalInfo }} />

      <Footer>
        <LinkButton onClick={onClickBack} color="azure">
          I need to make a change
        </LinkButton>
      </Footer>

      <MobileFixedFooter>
        <SubmitButton name="confirmed" value={true} fullWidth>
          Verify Identity
        </SubmitButton>
      </MobileFixedFooter>
    </>
  );
};

export default ReviewInfo;
