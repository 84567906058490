import camelcaseKeys from "camelcase-keys";
import { SignupSelections } from "types/general";

const storeSignupParams = () => {
  const ALLOWED_PARAMS = ["fi_name", "fi_id", "pp_name", "pp_id"];

  const params = new URLSearchParams(window.location.search);
  const filteredParams = ALLOWED_PARAMS.reduce((obj, name) => {
    if (params.has(name)) {
      return { ...obj, [name]: params.get(name) };
    }
    return obj;
  }, {});

  if (Object.keys(filteredParams).length === 0) return [];

  const { fiName, fiId, ppName, ppId } = camelcaseKeys(filteredParams) as {
    [key: string]: string;
  };

  const paramsToStore: SignupSelections = {};
  if (fiName && fiId) {
    paramsToStore.bank = { searchString: fiName, id: fiId };
  }
  if (ppName && ppId) {
    paramsToStore.payroll = {
      searchString: ppName,
      id: ppId,
    };
  }
  sessionStorage.setItem("signupParams", JSON.stringify(paramsToStore));

  return ALLOWED_PARAMS;
};

export default storeSignupParams;
