import { BaseButton } from "components/ui/Button";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import { colors, mediaMax, shadows, transitions, zIndex } from "utils/theme";

export const ToggleButton = styled(BaseButton)`
  position: fixed;
  top: 36px;
  right: 24px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  svg {
    display: block;
  }
`;

export const Menu = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 100%;
  z-index: ${zIndex.modal};

  width: 320px;
  height: 100vh;

  background: white;
  box-shadow: ${shadows.large};

  transition: transform ${transitions.default};
`;

export const MenuList = styled.ul`
  display: grid;
  gap: 12px;
  padding: 48px;
`;

export const MenuItem = styled.li`
  list-style-type: none;
`;

export const MenuLink = styled.a`
  color: ${colors.midnight};

  text-decoration: none;

  &:visited {
    color: ${colors.midnight};
  }
`;

export const Wrapper = styled.div<{ $isOpen: boolean }>`
  height: 18px;
  width: 18px;

  &::before {
    content: "";

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    visibility: hidden;
    opacity: 0;
    background: ${colors.midnight}80;

    pointer-events: none;

    transition: all ${transitions.default};
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${ToggleButton} {
        z-index: ${zIndex.modal};
      }

      &::before {
        visibility: visible;
        opacity: 1;
      }
    `}

  ${mediaMax("md")} {
    ${Menu} {
      width: 100%;
    }

    ${ToggleButton} {
      right: 16px;
    }
  }
`;
