import { useEffect, useRef, useState } from "react";
import environment from "utils/environment";
import { local } from "utils/storage";

export interface MswApi {
  isReady: boolean;
  isEnabled: boolean;
  start: () => Promise<void>;
  stop: () => Promise<void>;
  toggle: (enable?: boolean) => Promise<void>;
}

const useMsw: () => MswApi = () => {
  const [isReady, setIsReady] = useState(false);
  const [isEnabled, setIsEnabled] = useState(local.getItem("mswEnabled"));
  const workerRef = useRef(null);

  const updateIsEnabled = (value) => {
    local.setItem("mswEnabled", value);
    setIsEnabled(value);
  };

  const startMockServiceWorker = async () => {
    updateIsEnabled(true);

    if (workerRef.current) {
      workerRef.current.start();
      return;
    }

    const { worker } = require("../../mocks/browser"); // eslint-disable-line global-require
    await worker.start();
    setIsReady(true);
    workerRef.current = worker;
  };

  const stopMockServiceWorker = async () => {
    updateIsEnabled(false);

    if (!workerRef.current) return;

    workerRef.current.stop();
  };

  const toggleMockServiceWorker = async (enable) => {
    if (enable !== undefined && enable === isEnabled) return;

    if (isEnabled) {
      stopMockServiceWorker();
    } else {
      startMockServiceWorker();
    }
  };

  useEffect(() => {
    if (environment.enableDemoTriggers && local.getItem("mswEnabled")) {
      startMockServiceWorker();
    } else {
      setIsReady(true);
    }
  }, []);

  return {
    isReady,
    isEnabled,
    start: startMockServiceWorker,
    stop: stopMockServiceWorker,
    toggle: toggleMockServiceWorker,
  };
};

export default useMsw;
