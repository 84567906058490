import React from "react";
import styled from "styled-components";
import Radio, { RadioProps } from "../ui/Radio";
import Label from "../ui/Label";
import FieldContainer from "../FieldContainer";

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
`;

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  options: RadioProps[];
}

const RadioField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  ...props
}) => (
  <FieldContainer name={name}>
    {({ inputProps }) => (
      <Wrapper {...props}>
        {label && <Label>{label}</Label>}
        {options.map((option) => (
          <Radio
            key={`${option.label.toString()}-${option.value}`}
            {...inputProps}
            {...option}
            required={required}
          />
        ))}
      </Wrapper>
    )}
  </FieldContainer>
);

export default RadioField;
