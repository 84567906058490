import { formatDate, formatDateString } from "utils/date";
import { TimelineEventStatus } from "./types";

const getDate = (date: Date) => new Date(formatDate(date));
const getDateFromString = (date: string) => new Date(formatDateString(date));

export const getEventStatus = ({ date, prevDate, nextDate, index }) => {
  if (!date) return TimelineEventStatus.future;

  const dates = {
    today: getDate(new Date()),
    prevEvent: getDateFromString(prevDate),
    currentEvent: getDateFromString(date),
    nextEvent: getDateFromString(nextDate),
  };

  let status = TimelineEventStatus.today;
  if (
    (!prevDate || dates.prevEvent < dates.today) &&
    dates.currentEvent > dates.today &&
    index > 0
  ) {
    status = TimelineEventStatus.next;
  } else if (
    dates.currentEvent < dates.today &&
    (!nextDate || dates.nextEvent > dates.today)
  ) {
    status = TimelineEventStatus.current;
  } else if (dates.currentEvent < dates.today) {
    status = TimelineEventStatus.past;
  } else if (dates.currentEvent > dates.today) {
    status = TimelineEventStatus.future;
  }

  return status;
};
