export enum TimelineEventStepStatus {
  incomplete = "INCOMPLETE",
  complete = "COMPLETE",
  pending = "PENDING",
}

interface TimelineEventStepLineItem {
  status?: TimelineEventStepStatus;
  title: React.ReactNode;
  subtitle?: string;
  detail?: React.ReactNode;
}

export type TimelineEventStep = TimelineEventStepLineItem;

export enum TimelineEventStatus {
  future = "FUTURE",
  next = "NEXT",
  today = "TODAY",
  current = "CURRENT", // past but before next event
  past = "PAST",
}

export interface TimelineEvent {
  date?: string;
  data: (status: TimelineEventStatus) => {
    title: React.ReactNode;
    description?: React.ReactNode;
    steps?: TimelineEventStep[];
  };
}
