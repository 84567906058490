import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import styled from "styled-components";
import { colors } from "utils/theme";

const Path = styled.path<{ $isGradient?: boolean; $gradientId: string }>`
  fill: ${(props) =>
    props.$isGradient
      ? `url(#linear-gradient-${props.$gradientId})`
      : colors.ice};
`;

interface Props {
  isGradient?: boolean;
  isMirrored?: boolean;
}

const Divider: React.FC<Props> = ({ isGradient, isMirrored, ...props }) => {
  const gradientId = useRef(uuid());

  const gradientColor = isMirrored
    ? [colors.azure, colors.heliotrope]
    : [colors.heliotrope, colors.azure];

  return (
    <svg viewBox="0 0 2040 200" {...props}>
      <linearGradient
        id={`linear-gradient-${gradientId.current}`}
        x1="0"
        y1="101"
        x2="2040"
        y2="101"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor={gradientColor[0]} />
        <stop offset="1" stopColor={gradientColor[1]} />
      </linearGradient>
      <Path
        $gradientId={gradientId.current}
        $isGradient={isGradient}
        d="M2040,16.94s-188.28,127.74-604.14,127.74S829.61,1.97,515.2,1.97,0,145.13,0,145.13v54.87h2040V16.94Z"
      />
    </svg>
  );
};

export default Divider;
