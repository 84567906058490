import React, { useRef } from "react";
import { AnimatePresence } from "framer-motion";
import MarketingPageContent from "components/shared/MarketingPageContent";
import { BudgetResults } from "../types";
import Header from "../Header";
import MainResults from "./MainResults";
import BudgetComparison from "./BudgetComparison";
import * as Styled from "./styled";

interface Props extends BudgetResults {}

const Results: React.FC<Props> = ({ wants, needs, monthlyIncome }) => {
  const learnMoreRef = useRef(null);
  const expensesAmount = wants + needs;
  const savings = Math.max(monthlyIncome - expensesAmount, 0);
  const isOverBudget = expensesAmount > monthlyIncome;

  const scrollToLearnMore = (e) => {
    e.preventDefault();

    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      window.location.hash = "#learn-more";
    }
  };

  return (
    <div>
      <Header />

      <AnimatePresence initial>
        <Styled.Wrapper>
          <MainResults
            wants={wants}
            needs={needs}
            savings={savings}
            monthlyIncome={monthlyIncome}
            isOverBudget={isOverBudget}
            scrollToLearnMore={scrollToLearnMore}
          />

          <BudgetComparison
            wants={wants}
            needs={needs}
            savings={savings}
            monthlyIncome={monthlyIncome}
          />
        </Styled.Wrapper>
      </AnimatePresence>

      <div ref={learnMoreRef} id="learn-more">
        <MarketingPageContent
          eyebrow=""
          heading="Budgeting on autopilot."
          body="Reset factors in your daily income, bank balance and upcoming bills to create a dynamic spending limit tailored to you."
        />
      </div>
    </div>
  );
};

export default Results;
