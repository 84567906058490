import React, {
  useContext,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import { AnimatePresence } from "framer-motion";
import ModalContext from "components/context/ModalContext";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";

export interface ModalApi {
  show: () => void;
  dismiss: () => void;
}

const Modal = (
  {
    children,
    header,
    footer,
    onClose,
    overrideCloseBehavior,
    ...props
  }: {
    children: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    overrideCloseBehavior?: boolean;
  },
  ref: React.ForwardedRef<unknown>
) => {
  const id = useRef(uuid());
  const modalRef = useRef<HTMLElement | null>(null);
  const modalScrollRef = useRef(null);
  const modalContext = useContext(ModalContext);

  const isBrowser = typeof window !== "undefined";

  useEffect(
    () => () => {
      if (modalContext.id === id.current) modalContext.setId(null);
    },
    [modalContext.id, id.current]
  );

  useEffect(() => {
    if (modalContext.id === id.current) {
      modalContext.toggleCloseBehavior(!overrideCloseBehavior);
    }
  }, [modalContext.id, id.current, overrideCloseBehavior]);

  function show() {
    modalContext.setId(null);
    // allows new modal to render
    setTimeout(() => {
      modalContext.setId(id.current);
    }, 0);
  }

  function dismiss() {
    if (modalContext.id === id.current) {
      modalContext.setId(null);
    }
  }

  function onAnimationStart() {
    setTimeout(() => {
      if (modalScrollRef.current)
        modalScrollRef.current.scrollTo({
          top: 0,
        });
    }, 0);
  }

  useImperativeHandle(ref, () => ({ show, dismiss }));

  if (!isBrowser) return null;
  const isShown = Boolean(
    id.current === modalContext.id && modalContext.portal
  );

  useEffect(() => {
    if (!isShown && onClose) onClose();
  }, [isShown]);

  return isShown
    ? ReactDOM.createPortal(
        <AnimatePresence>
          <Styled.Wrapper
            ref={modalRef}
            initial="hidden"
            animate="shown"
            exit="hidden"
            variants={{
              shown: { y: 0, opacity: 1 },
              hidden: { y: 20, opacity: 0 },
            }}
            transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
            onAnimationStart={onAnimationStart}
            layout
            {...props}
          >
            <Styled.ModalWrapper ref={modalScrollRef}>
              {header}

              <Styled.ModalTile>
                {!overrideCloseBehavior && (
                  <Styled.Close>
                    <Styled.CloseButton
                      onClick={() => dismiss()}
                      className="js-skip-focus"
                    >
                      <Icon name="close" size="md" />
                    </Styled.CloseButton>
                  </Styled.Close>
                )}

                <Styled.Main>{children}</Styled.Main>
              </Styled.ModalTile>

              {footer}
            </Styled.ModalWrapper>
          </Styled.Wrapper>
        </AnimatePresence>,
        modalContext.portal || document.body
      )
    : null;
};

export default forwardRef(Modal);
