import React from "react";
import styled from "styled-components";
import { mediaMax } from "utils/theme";

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 576px;
  padding-left: 24px;
  padding-right: 24px;

  ${mediaMax("lg")} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const CenteredLayout = (props: any) => <Wrapper {...props} />;

export default CenteredLayout;
