import React, { useEffect, useRef, useState } from "react";
import { useFormState } from "react-hook-form";
import Button from "components/ui/Button";
import {
  InputField,
  CheckboxField,
  FormMessage,
  MaskedField,
  MaskType,
  FormSection,
} from "components/form";
import { Heading } from "components/ui/Text";
import EligibilityFooter from "components/shared/EligibilityFooter";
import SidebarInfo from "components/ui/SidebarInfo";
import UILabel from "components/ui/UILabel";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import { ApiDisclosure } from "utils/api/disclosures";
import environment from "utils/environment";
import { openWindow } from "utils/window";
import { SignupSelections } from "types/general";
import { Eligibility } from "types/signup";
import BankQuestionnaire from "./BankQuestionnaire";
import PayrollQuestionnaire from "./PayrollQuestionnaire";
import * as Styled from "./styled";

interface Props {
  isSubmitting: boolean;
  hasError: { bank: boolean; payroll: boolean };
  initialParams: SignupSelections;
  updateEligibility: (key: "bank" | "payroll") => (value: Eligibility) => void;
  updateHasError: (key: "bank" | "payroll") => (value: boolean) => void;
  eligibility: { bank: Eligibility; payroll: Eligibility };
  openDrawer: (body: React.ReactNode) => void;
  closeDrawer: () => void;
  messagingTerms?: ApiDisclosure;
  submissionCount: number;
}

const Fields: React.FC<Props> = ({
  isSubmitting,
  hasError,
  initialParams,
  updateEligibility,
  updateHasError,
  eligibility,
  openDrawer,
  closeDrawer,
  messagingTerms,
  submissionCount,
}) => {
  const { bank, payroll } = eligibility;
  const userInfoRef = useRef(null);
  const [isFilled, setIsFilled] = useState({ bank: false, payroll: false });
  const [showUserFields, setShowUserFields] = useState(true);
  const { errors } = useFormState();
  const hasUserValidationErrors = Object.keys(errors).some((key) =>
    ["user", "phoneNumber", "checked"].includes(key)
  );

  const onClickViewTerms = (e) => {
    e.preventDefault();
    openWindow(e.target.href);
  };

  const openSmsHelp = (e) => {
    e.preventDefault();
    openDrawer(
      <SidebarInfo closeDrawer={closeDrawer}>
        By checking this box, you agree to receive recurring automated text or
        SMS messages, including text or SMS messages sent using automatic
        telephone dialing systems by or on behalf of Reset at the mobile number
        you provided when signing up or registering, or at any other number you
        designate, about Reset&apos;s products and services, including
        personalized offers and promotions. You understand that these messages
        may be from Reset, our agents, representatives, service providers,
        affiliates, or anyone acting on our behalf and that you may receive
        these messages whether or not you complete the sign up or registration
        process. You understand that your consent to receive automated marketing
        text or SMS messages is not a condition of any purchase or service Reset
        offers. Message frequency varies. Message and data rates may apply.{" "}
        <a href={messagingTerms?.path} onClick={onClickViewTerms}>
          View Messaging Terms and Conditions
        </a>
      </SidebarInfo>
    );
  };

  useEffect(() => {
    // This is an intentional one-way door; we don't want to hide fields once they've been revealed
    if (bank !== Eligibility.indeterminate) {
      setIsFilled((prev) => ({ ...prev, bank: true }));
    }

    if (payroll !== Eligibility.indeterminate) {
      setIsFilled((prev) => ({ ...prev, payroll: true }));
    }
  }, [bank, payroll]);

  useEffect(() => {
    if (isFilled.payroll && isFilled.bank && !initialParams?.bank) {
      setTimeout(() => {
        userInfoRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  }, [isFilled.payroll, isFilled.bank]);

  useEffect(() => {
    if (hasUserValidationErrors) {
      setShowUserFields(true);
    }
  }, [hasUserValidationErrors]);

  useEffect(() => {
    if (submissionCount > 0) {
      setShowUserFields(false);
    }
  }, [submissionCount]);

  return (
    <Styled.Wrapper>
      <Styled.FormWrapper>
        <Styled.FormSection>
          <Styled.SectionHeading>
            <Heading.H3 tag="h1">
              Tell us about {submissionCount > 0 ? "another" : "your primary"}{" "}
              source of income.
            </Heading.H3>
          </Styled.SectionHeading>

          {(hasError.payroll || hasError.bank) && (
            <FormMessage>Please fill out all fields</FormMessage>
          )}

          <PayrollQuestionnaire
            initialValue={initialParams?.payroll}
            setEligibility={updateEligibility("payroll")}
            setHasError={updateHasError("payroll")}
          />

          {environment.signupFormDebugMode &&
            process.env.NODE_ENV === "development" && (
              <div>Payroll: {eligibility.payroll}</div>
            )}

          {isFilled.payroll && (
            <BankQuestionnaire
              initialValue={initialParams?.bank}
              setEligibility={updateEligibility("bank")}
              setHasError={updateHasError("bank")}
            />
          )}

          {environment.signupFormDebugMode &&
            process.env.NODE_ENV === "development" && (
              <div>Bank: {eligibility.bank}</div>
            )}
        </Styled.FormSection>

        {isFilled.payroll && isFilled.bank && (
          <>
            {showUserFields && (
              <>
                <FormSection>
                  <Heading.H3 tag="h2">
                    <Styled.UserInfoAnchor>
                      <span ref={userInfoRef} />
                    </Styled.UserInfoAnchor>
                    Tell us about yourself.
                  </Heading.H3>
                  <p className="form-help">
                    Please enter your legal name for ID verification.
                  </p>
                  <InputField
                    name="user.firstName"
                    label="First name"
                    placeholder="Val"
                    required
                  />
                  <InputField
                    label="Last name"
                    name="user.lastName"
                    placeholder="Clark"
                    required
                  />
                  <InputField
                    name="user.email"
                    label="Email"
                    required
                    placeholder="val.clark@example.com"
                    autoComplete="email"
                    type="email"
                  />
                  <MaskedField
                    maskType={MaskType.phone}
                    placeholder="(123) 456-7890"
                    name="phoneNumber"
                    label="Phone number"
                    required
                  />

                  <CheckboxField
                    name="acceptMessaging"
                    value="yes"
                    label={
                      <UILabel.Medium>
                        I agree to receive recurring automated text or SMS
                        messages about Reset's products and services, including
                        personalized offers and promotions. Message frequency
                        varies. Message and data rates may apply. Reply HELP for
                        help and STOP to cancel.{" "}
                        <a href="#" onClick={openSmsHelp}>
                          What am I agreeing to?
                        </a>
                      </UILabel.Medium>
                    }
                  />
                </FormSection>

                <FormSection>
                  <CheckboxField
                    required
                    name="checked"
                    value="yes"
                    label={
                      <span>
                        By checking this box, you agree to our{" "}
                        <a target="_blank" href="/e-sign-consent-agreement">
                          E-Sign Consent Agreement
                        </a>
                        ,{" "}
                        <a target="_blank" href="/terms-of-service">
                          Terms of Service
                        </a>
                        , and{" "}
                        <a target="_blank" href="/privacy-policy">
                          Privacy Policy
                        </a>
                        .
                      </span>
                    }
                  />
                </FormSection>
              </>
            )}

            <MobileFixedFooter>
              <Button
                type="submit"
                name="commit"
                className="button"
                fullWidth
                isLoading={isSubmitting}
                data-test="btn-submit"
              >
                Continue
              </Button>
            </MobileFixedFooter>
          </>
        )}
      </Styled.FormWrapper>

      <EligibilityFooter />
    </Styled.Wrapper>
  );
};

export default Fields;
