import { ButtonLink } from "components/ui/Button";
import CardActivationWidget from "components/ui/CardActivationWidget";
import { Heading } from "components/ui/Text";
import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  gap: 48px;

  text-align: center;
`;

const CardActivation = () => {
  const { data: user, isLoading } = getUserProfile();

  const url = user?.currentCardAccount?.activateUrl;

  return (
    <Wrapper>
      <Heading.H2 tag="h1">Activate Your Card</Heading.H2>
      {url || isLoading ? (
        <CardActivationWidget url={url} isLoading={isLoading} />
      ) : (
        <>
          <Heading.H4 tag="h2">Thanks for activating your card!</Heading.H4>
          <ButtonLink href="/dashboard" data-turbo="false">
            Check Spending Limit
          </ButtonLink>
        </>
      )}
    </Wrapper>
  );
};

export default CardActivation;
