import DisappearingTile from "components/ui/DisappearingTile";
import styled from "styled-components";
import { colors, mediaMin, shadows } from "utils/theme";

export const Wrapper = styled.div`
  background: ${colors.midnight};
`;

export const Tile = styled(DisappearingTile).attrs(() => ({ size: "xl" }))`
  max-width: 640px;
  margin: 0 auto;
  overflow: hidden;

  ${mediaMin("md")} {
    box-shadow: ${shadows.tileSolid};
  }
`;
