const getItem =
  (storage: Storage = localStorage) =>
  (name: string, asString?: boolean) => {
    const item = storage.getItem(name);
    if (item === null) return null;
    return asString ? item : JSON.parse(item);
  };

const setItem =
  (storage: Storage = localStorage) =>
  (name: string, value: any) =>
    storage.setItem(name, JSON.stringify(value));

const removeItem =
  (storage: Storage = localStorage) =>
  (name: string) =>
    storage.removeItem(name);

export const local = {
  getItem: getItem(),
  setItem: setItem(),
  removeItem: removeItem(),
};

export const session = {
  getItem: getItem(sessionStorage),
  setItem: setItem(sessionStorage),
  removeItem: removeItem(sessionStorage),
};
