import React, { useEffect, useState } from "react";
import Loading from "components/ui/Loading";
import { getDashboardOverview, getUserProfile } from "store/api/apiSlice";
import Card from "../Card";
import Header from "../Header";
import DashboardTabs from "../DashboardTabs";
import DslInfo from "../DslInfo";
import * as Styled from "./styled";

const Dashboard: React.FC = () => {
  const [resetCardAmount, setResetCardAmount] = useState(0);
  const { data: userProfile, isLoading: isProfileLoading } = getUserProfile();
  const { data: dashboard, isLoading: isDashboardLoading } =
    getDashboardOverview();

  useEffect(() => {
    if (dashboard?.safeToSpendComponents?.safeToSpend) {
      setResetCardAmount(dashboard.safeToSpendComponents.safeToSpend);
    }
  }, [dashboard?.safeToSpendComponents?.safeToSpend]);

  if (isProfileLoading || isDashboardLoading) return <Loading />;

  const { safeToSpendComponents } = dashboard;
  const { safeToSpend, maxSafeToSpend, nextPayday } = safeToSpendComponents;

  const toggleMaxDslDisplay = (showMax: boolean) => {
    setResetCardAmount(showMax ? maxSafeToSpend : safeToSpend);
  };

  return (
    <Styled.Wrapper>
      {userProfile && <Header userProfile={userProfile} />}

      <Styled.DashboardMain>
        <Styled.CardWrapper>
          <Card amount={resetCardAmount} nextPayday={nextPayday} />
        </Styled.CardWrapper>

        <DslInfo
          userProfile={userProfile}
          toggleMaxDslDisplay={toggleMaxDslDisplay}
        />

        <DashboardTabs userProfile={userProfile} />
      </Styled.DashboardMain>
    </Styled.Wrapper>
  );
};

export default Dashboard;
