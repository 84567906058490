import React from "react";
import * as Styled from "./styled";
import CardActivation from "./components/CardActivation";

const ActivateView: React.FC = () => (
  <Styled.Wrapper>
    <CardActivation />
  </Styled.Wrapper>
);

export default ActivateView;
