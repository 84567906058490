import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { borderRadius, colors, mediaMax, mediaMin, shadows } from "utils/theme";

export const HeaderContent = styled(ContentLayout)`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 80px;
  align-items: center;
`;

export const NotificationTile = styled.div`
  display: grid;
  width: 320px;
  grid-template-columns: 40px 1fr;
  gap: 12px;
  padding: 16px;

  border-radius: ${borderRadius.small}px;
  background: white;
  box-shadow: ${shadows.tileSolid};

  text-align: left;
`;

export const NotificationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background: linear-gradient(45deg, ${colors.heliotrope}, ${colors.azure});
  border-radius: 8px;

  img {
    height: 24px;
    display: block;
  }
`;

export const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const CardWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;

  ${mediaMax("lg")} {
    justify-content: center;

    ${NotificationTile} {
      display: none;
    }
  }

  ${mediaMin("lg")} {
    ${NotificationTile} {
      position: absolute;
      top: 20%;
      right: 120px;
    }
  }
`;

export const CardImage = styled.div`
  position: relative;

  img {
    display: block;
    width: 360px;
  }
`;

export const HeaderText = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMin("lg")} {
    ${NotificationTile} {
      display: none;
    }
  }

  ${mediaMax("lg")} {
    align-items: center;

    ${NotificationTile} {
      margin: 0 auto 24px;
    }
  }
`;

export const Bullets = styled.ul`
  display: grid;
  gap: 6px;
  padding: 12px 0 4px;
  margin: 0;

  list-style-type: none;
  text-align: left;

  ${mediaMax("md")} {
    padding: 12px 12px 4px;
  }

  ${mediaMin("lg")} {
    margin-right: -24px;
  }
`;

export const Bullet = styled.li`
  display: flex;
  gap: 12px;
`;

export const BulletIcon = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  svg {
    display: block;
  }

  ${mediaMax("lg")} {
    height: 26px;
  }
`;

export const Wrapper = styled.header`
  position: relative;

  overflow: hidden;

  background: ${colors.ice};

  ${mediaMax("lg")} {
    background: ${colors.ice};

    ${HeaderContent} {
      display: flex;
      flex-direction: column;
      gap: 48px;
      padding-top: 24px;
      padding-bottom: 48px;

      text-align: center;
    }

    ${CardImage} {
      z-index: 1;

      img {
        width: 240px;

        animation: none;
      }
    }
  }
`;
