import React from "react";
import { PeopleBlock } from "types/cms";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import * as Styled from "./styled";
import Header from "../../Header";

const People: React.FC<PeopleBlock> = ({
  isHero,
  isReverse,
  data: { eyebrow, heading, body, people, textSize },
}) => (
  <Styled.Wrapper>
    {(eyebrow || heading || body) && (
      <Header
        eyebrow={eyebrow}
        heading={heading}
        body={body}
        isHero={isHero}
        textSize={textSize}
        isCentered
        isLight={isReverse}
      />
    )}

    <Styled.CardList>
      {people.map((person) => (
        <Styled.Card key={person.name}>
          {person.image && (
            <Styled.CardImage>
              <img src={person.image} alt={`Photo of ${person.name}`} />
            </Styled.CardImage>
          )}

          <Styled.CardContent>
            <Styled.CardHeading>{person.name}</Styled.CardHeading>

            <Styled.CardBody>{person.title}</Styled.CardBody>

            <Styled.CardLinks>
              {person.links.map((link) => (
                <Styled.CardLink
                  key={link.url}
                  href={link.url}
                  color={colors.azure}
                  target="_blank"
                >
                  {link.icon && <Icon name={link.icon} />}
                  <span>{link.label}</span>
                </Styled.CardLink>
              ))}
            </Styled.CardLinks>
          </Styled.CardContent>
        </Styled.Card>
      ))}
    </Styled.CardList>
  </Styled.Wrapper>
);

export default People;
