import styled from "styled-components";
import { SubmitButton } from "components/form";
import { BodySmall } from "components/ui/Text";
import { breakpoints, colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  padding: 24px 0 108px;
  min-height: 100vh;
  overflow: hidden;

  background: ${colors.midnight};

  ${mediaMin("lg")} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0 200px;
  }
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;
  margin: 0 auto;

  ${mediaMin("md")} {
    padding: 0 24px;
    gap: 36px;
    max-width: ${breakpoints.md}px;
  }

  ${mediaMin("lg")} {
    display: grid;
    max-width: ${breakpoints.lg}px;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 64px;
  }
`;

export const Headline = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  color: white;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const FormHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormBody = styled(BodySmall)`
  line-height: 1.5;
`;

export const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormButton = styled(SubmitButton)`
  width: 100%;
`;

export const Shape = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: top;
  }

  ${mediaMax("lg")} {
    display: none;
  }
`;
