import React, { useState } from "react";
import {
  ApiCardTransaction,
  TransactionCustomerState,
} from "utils/api/cardTransactions";
import UILabel from "components/ui/UILabel";
import { formatTimeString } from "utils/date";
import { colors } from "utils/theme";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { TRANSACTION_STATES_DISPUTABLE } from "utils/cardTransactions";
import LineItem from "components/ui/LineItem";
import { getDetails } from "./utils";
import * as Styled from "./styled";

interface Props {
  transaction: ApiCardTransaction;
  isOpen: boolean;
  toggleTransaction: (id: string) => void;
  disputeTransaction: (transaction: ApiCardTransaction) => Promise<{}>;
  hasDispute: boolean;
  canDispute?: boolean;
}

const ListItem: React.FC<Props> = ({
  transaction,
  isOpen,
  toggleTransaction,
  disputeTransaction,
  hasDispute,
  canDispute,
}) => {
  const [isDisputeLoading, setIsDisputeLoading] = useState(false);
  const {
    id,
    createdAt,
    amount,
    customerState,
    merchantName,
    merchantCategory,
    cardPresent,
    transactionReferenceNumber,
  } = transaction;

  const canBeDisputed =
    canDispute && TRANSACTION_STATES_DISPUTABLE.includes(customerState);

  const onClickDispute = async (e) => {
    if (canBeDisputed) {
      e.stopPropagation();
      if (!isDisputeLoading) {
        setIsDisputeLoading(true);
        await disputeTransaction(transaction);
        setIsDisputeLoading(false);
      }
    }
  };

  const footer =
    canBeDisputed &&
    (hasDispute ? (
      <Styled.DisputeMessage>
        We've received your dispute request
      </Styled.DisputeMessage>
    ) : (
      <Styled.ListItemAction>
        <>
          <UILabel.Small
            color={`color-mix(in srgb, ${colors.midnight} 66%, transparent)`}
          >
            Don't recognize this?
          </UILabel.Small>
          <Button
            size={ButtonSize.sm}
            level={ButtonLevel.ghost}
            onClick={onClickDispute}
            isLoading={isDisputeLoading}
          >
            Dispute This Charge
          </Button>
        </>
      </Styled.ListItemAction>
    ));

  return (
    <LineItem
      onClick={() => toggleTransaction(id)}
      isOpen={isOpen}
      isPending={customerState === TransactionCustomerState.pending}
      isInactive={[
        TransactionCustomerState.declined,
        TransactionCustomerState.expired,
        TransactionCustomerState.manuallyExpired,
      ].includes(customerState)}
      hideDateMobile
      date={createdAt}
      title={
        merchantName || `Transaction at ${formatTimeString(createdAt, "time")}`
      }
      status={customerState}
      amount={amount}
      details={getDetails({
        merchantCategory,
        createdAt,
        cardPresent,
        customerState,
        transactionReferenceNumber,
      })}
      footer={footer}
    />
  );
};
export default ListItem;
