import Modal, { ModalApi } from "components/modal/Modal";
import Button, { ButtonSize } from "components/ui/Button";
import React, { useEffect, useRef, useState } from "react";
import { usePostAdminExportWaitlistDataMutation } from "store/admin/api/apiSlice";
import { getUserProfile } from "store/api/apiSlice";

const ExportWaitlistDataButton = () => {
  const modalRef = useRef<ModalApi>(null);
  const [exportWaitlistData, { isLoading, isSuccess }] =
    usePostAdminExportWaitlistDataMutation();
  const { data: userProfile } = getUserProfile();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      modalRef.current?.show();
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    }
  }, [isSuccess]);

  return (
    <>
      <Button
        size={ButtonSize.md}
        onClick={() => exportWaitlistData()}
        isLoading={isLoading}
        isSuccess={showSuccess}
      >
        Export Waitlist Data
      </Button>

      <Modal ref={modalRef}>
        Waitlist data export sent to {userProfile?.email}
      </Modal>
    </>
  );
};

export default ExportWaitlistDataButton;
