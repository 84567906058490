import React, { createContext } from "react";
import useMsw, { MswApi } from "utils/hooks/useMsw";

const MockApiContext = createContext<{
  msw: MswApi;
}>({
  msw: null,
});

export const MockApiContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const msw = useMsw();

  // wait until service worker is ready or not needed before rendering the app to avoid unhandled requests
  return (
    <MockApiContext.Provider value={{ msw }}>
      {msw.isReady && children}
    </MockApiContext.Provider>
  );
};

export default MockApiContext;
