import React from "react";
import { getScoresByCategory } from "../utils";
import { QuestionnaireCategoryName } from "../types";
import { SURVEY_SCORE } from "../constants";

interface GetStartedContent {
  heading: string;
  body: React.ReactNode;
}

const CONTENT_FOR_SPENDING: GetStartedContent = {
  heading: "Access your income daily.",
  body: (
    <>
      It&apos;s your money, just sooner and without the fees. There&apos;s no
      credit check and no security deposit.
    </>
  ),
};

const HEADING_FOR_SAVING = "Budgeting on autopilot.";

const CONTENT_FOR_SAVING: GetStartedContent = {
  heading: HEADING_FOR_SAVING,
  body: (
    <>
      Reset creates a dynamic spending limit tailored to you. We factor in your
      daily income, bank balance, and upcoming bills to help you better manage
      your budget. And it&apos;s free!
    </>
  ),
};

const CONTENT_FOR_HEALTHY_USER: GetStartedContent = {
  heading: HEADING_FOR_SAVING,
  // this text is slightly different from the SAVING version
  body: (
    <>
      Reset creates a dynamic spending limit tailored to you. We factor in your
      daily income, bank balance, and upcoming bills to help you automate your
      budget. And it&apos;s free!
    </>
  ),
};

const CATEGORIES_WITH_SAVING_MESSAGE = [
  QuestionnaireCategoryName.Saving,
  QuestionnaireCategoryName.Planning,
];

export const getContent = ({
  score,
  subscores,
}: {
  score: number;
  subscores: number[];
}) => {
  const scoresByCategory = getScoresByCategory(subscores);
  const lowestScore = Object.values(scoresByCategory).reduce(
    (acc, subscore) => Math.min(acc, subscore),
    100
  );
  const categories = Object.entries(scoresByCategory)
    .filter((category) => category[1] === lowestScore)
    .map(([categoryName]) => categoryName);

  if (score >= SURVEY_SCORE.healthy) {
    return CONTENT_FOR_HEALTHY_USER;
  }

  if (
    CATEGORIES_WITH_SAVING_MESSAGE.some((category) =>
      categories.includes(category)
    )
  ) {
    return CONTENT_FOR_SAVING;
  }

  return CONTENT_FOR_SPENDING;
};
