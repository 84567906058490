import { round } from "utils/number";

export const zIndex = {
  modal: 9999,
  nav: 8888,
};

// RESPONSIVE

export const BREAKPOINT = {
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
};

export const breakpoints = {
  [BREAKPOINT.sm]: 0,
  [BREAKPOINT.md]: 576,
  [BREAKPOINT.lg]: 1024,
  [BREAKPOINT.xl]: 1280,
};

const breakpointArray = [
  BREAKPOINT.sm,
  BREAKPOINT.md,
  BREAKPOINT.lg,
  BREAKPOINT.xl,
];

export const media = (breakpoint: string) => {
  const index = breakpointArray.indexOf(breakpoint);
  const query = ["@media screen"];
  if (index > 0) {
    query.push(`and (min-width: ${breakpoints[breakpointArray[index]]}px)`);
  }
  if (index < breakpointArray.length - 1) {
    query.push(
      `and (max-width: ${breakpoints[breakpointArray[index + 1]] - 1}px)`
    );
  }
  return query.join(" ");
};

export const mediaMin = (breakpoint: string) =>
  `@media screen and (min-width: ${breakpoints[breakpoint]}px)`;

export const mediaMax = (breakpoint: string) =>
  `@media screen and (max-width: ${breakpoints[breakpoint] - 1}px)`;

export const generateMediaQueries = (styleArray: Array<string>) =>
  breakpointArray
    .map(
      (bp, index) => `${media(bp)} { 
    ${styleArray[index]}
  }`
    )
    .join(" ");

// TYPOGRAPHY

export const fonts = {
  body: '"Lato", "Helvetica", "Arial", sans-serif',
  heading: '"Lato", "Helvetica", "Arial", sans-serif',
};

export const FONT_SIZE_KEYS = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"];
const BASE_FONT_SIZE = 18;
const BASE_LINE_HEIGHT_ADDEND = 0.5;
const BASE_LETTER_SPACING = -0.01;
const FONT_SCALE = 4 / 3;
const BASE_FONT_SIZE_MOBILE = 16;
const FONT_SCALE_MOBILE = 5 / 4;

// generate font properties based on a perfect fourth (large screens) and perfect fifth (small screens) font scales

export const fontSizes: { [key: (typeof FONT_SIZE_KEYS)[number]]: number } =
  FONT_SIZE_KEYS.reduce(
    (acc, key, index) => ({
      ...acc,
      [key]: round(BASE_FONT_SIZE * FONT_SCALE ** (index - 2)),
    }),
    {}
  ); // pixels

export const fontSizesMobile: {
  [key: (typeof FONT_SIZE_KEYS)[number]]: number;
} = FONT_SIZE_KEYS.reduce(
  (acc, key, index) => ({
    ...acc,
    [key]: round(BASE_FONT_SIZE_MOBILE * FONT_SCALE_MOBILE ** (index - 2)),
  }),
  {}
); // pixels

export const fontSizesUI = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
};

export const lineHeights: { [key: (typeof FONT_SIZE_KEYS)[number]]: number } =
  FONT_SIZE_KEYS.reduce(
    (acc, key, index) => ({
      ...acc,
      [key]: round(
        1 + BASE_LINE_HEIGHT_ADDEND * FONT_SCALE ** ((-index + 2) * 1.5),
        2
      ),
    }),
    {}
  ); // unitless

export const letterSpacings: {
  [key: (typeof FONT_SIZE_KEYS)[number]]: number;
} = FONT_SIZE_KEYS.reduce(
  (acc, key, index) => ({
    ...acc,
    [key]: round(BASE_LETTER_SPACING * FONT_SCALE ** (index - 2), 2),
  }),
  {}
); // ems

export const fontWeights = {
  normal: 400,
  bold: 700,
  black: 900,
};

// APPEARANCE

export const colors = {
  midnight: "#1D1933",
  azure: "#0486FF",
  heliotrope: "#BD62FF",
  violet: "#18009C",
  cerise: "#E5345E",
  cosmos: "#FFEE93",
  leaf: "#B3E673",
  white: "#FFFFFF",
  ice: "#F2F9FF",
  flax: "#C7E4FF",
  sky: "#A3D3FF",
  dust: "#8A8A99",
  blackthorn: "#8E70FF",
};

export const borderRadius = {
  small: 12,
  regular: 16,
  large: 24,
};

export const shadows: { [name: string]: string } = {
  regular: `0 2px 16px ${colors.heliotrope}22`,
  large: `0 2px 24px ${colors.heliotrope}22`,
  xlarge: `0 2px 32px ${colors.heliotrope}44`,
  tile: `0 4px ${colors.violet}20, 0 2px 40px ${colors.heliotrope}22`,
  tileSolid: `0 4px #DAD7EA, 0 2px 40px ${colors.heliotrope}22`,
  tileDark: `0 4px ${colors.violet}cc, 0 2px 24px ${colors.heliotrope}22`,
};

// ANIMATION

export const ease = {
  outQuart: "cubic-bezier(0.25, 1, 0.5, 1)",
};

export const transitions = {
  default: `0.3s ${ease.outQuart}`,
  simple: `0.15s ease-out`,
};
