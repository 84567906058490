import React from "react";
import styled from "styled-components";
import LogoMark from "components/ui/LogoMark";
import UILabel from "./UILabel";

const ResetLogo = styled(LogoMark)`
  display: block;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  gap: 12px;
`;

interface Props {
  reverse?: boolean;
  text: string;
}

const LogoWithText: React.FC<Props> = ({ reverse, text, ...props }) => (
    <Wrapper {...props}>
      <ResetLogo />
      <UILabel.Large bold color={reverse ? "ice" : "midnight"}>
        {text}
      </UILabel.Large>
    </Wrapper>
  );

export default LogoWithText;
