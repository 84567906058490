import React from "react";
import styled from "styled-components";
import { fontSizes, fontWeights } from "utils/theme";
import Checkbox, { CheckboxProps } from "../ui/Checkbox";
import FieldContainer from "../FieldContainer";
import Label from "../ui/Label";

const StyledLabel = styled(Label)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
`;

const LabelText = styled.div`
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.sm}px;
`;

interface Props extends CheckboxProps {
  label?: React.ReactNode;
}

const CheckboxField: React.FC<Props> = ({ name, label, ...props }) => (
  <FieldContainer name={name}>
    {({ inputProps, error }) => (
      <StyledLabel htmlFor={name}>
        <Checkbox name={name} hasError={!!error} {...inputProps} {...props} />

        <LabelText>{label}</LabelText>
      </StyledLabel>
    )}
  </FieldContainer>
);

export default CheckboxField;
