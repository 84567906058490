import React from "react";
import App from "App";
import CardApplication from "./CardApplication";

const ApplyView: React.FC = () => (
  <App withAuth>
    <CardApplication />
  </App>
);

export default ApplyView;
