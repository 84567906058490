import React from "react";
import { BodySmall, Eyebrow } from "components/ui/Text";
import styled from "styled-components";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { breakpoints, mediaMax } from "utils/theme";

const Wrapper = styled.div`
  height: 100%;
  max-width: calc(${breakpoints.md}px - 48px);
  margin: 0 auto;

  ${mediaMax("lg")} {
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ButtonWrapper = styled.div`
  padding-top: 24px;
`;

interface Props {
  children: React.ReactNode;
  heading?: React.ReactNode;
  closeDrawer: () => void;
}

const SidebarInfo: React.FC<Props> = ({ children, heading, closeDrawer }) => (
  <Wrapper>
    <Content>
      {heading && <Eyebrow>{heading}</Eyebrow>}
      <BodySmall>{children}</BodySmall>
    </Content>

    <ButtonWrapper>
      <Button
        onClick={closeDrawer}
        fullWidth
        size={ButtonSize.md}
        level={ButtonLevel.secondary}
      >
        I understand
      </Button>
    </ButtonWrapper>
  </Wrapper>
);

export default SidebarInfo;
