import React from "react";
import Entry from "./Entry";

const ContentfulPage = () => {
  const pathParts = window.location.pathname
    .replace(/^\//, "")
    .split("/")
    .slice(0, 2)
    .reverse();
  const params = new URLSearchParams(window.location.search);
  const preview = params.get("preview") === "true";
  const slug = pathParts[0];
  const sectionParam = params.get("section");
  const section =
    preview && sectionParam && sectionParam !== "none"
      ? sectionParam
      : pathParts[1];

  return <Entry slug={slug} section={section} preview={preview} />;
};

export default ContentfulPage;
