import React, { useState } from "react";
import { PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP } from "utils/constants/plaid";
import {
  ApiExpense,
  filterExpensesByNextPayday,
  sortExpensesByNextOccurenceAscending,
} from "utils/api/expenses";
import {
  getActiveRecurringOutflows,
  getDashboardOverview,
} from "store/api/apiSlice";
import UILabel from "components/ui/UILabel";
import { formatCurrency } from "utils/string";
import { LinkButton } from "components/ui/Link";
import * as Styled from "./styled";

const getDaysLabel = (days: number) => {
  if (days === 0) return "Today";
  if (days === 1) return "Tomorrow";
  return `${days} days`;
};

const getExpenseComponents = (expense: ApiExpense) => {
  const category =
    PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP[
      expense.personalFinanceCategoryPrimary
    ] || PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP.OTHER;
  const days = Math.ceil(
    (new Date(expense.nextOccurrenceDate).getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );

  return { days, category };
};

const ExpensesList: React.FC = () => {
  const { data: expenses, isLoading: isLoadingExpenses } =
    getActiveRecurringOutflows();
  const { data: dashboard, isLoading: isLoadingDashboard } =
    getDashboardOverview();
  const [showAll, setShowAll] = useState(false);

  if (isLoadingExpenses || isLoadingDashboard) return null;

  const nextPayday = dashboard?.safeToSpendComponents?.nextPayday;

  const sortedExpenses = sortExpensesByNextOccurenceAscending(expenses);
  const filteredExpenses = filterExpensesByNextPayday(
    sortedExpenses,
    nextPayday
  );

  const toggleExpenses = (e) => {
    setShowAll((s) => !s);
    e.stopPropagation();
    e.preventDefault();
  };

  if (filteredExpenses.length === 0) return null;

  return (
    <Styled.Wrapper>
      <Styled.List>
        {filteredExpenses.slice(0, showAll ? undefined : 2).map((expense) => {
          const { days, category } = getExpenseComponents(expense);
          return (
            <Styled.Item key={expense.id}>
              <Styled.Main>
                <Styled.Left>
                  <UILabel.Small>
                    {expense.merchantName ||
                      expense.description ||
                      category.title}
                  </UILabel.Small>
                  <UILabel.Small color="dust">
                    {getDaysLabel(days)}
                  </UILabel.Small>
                </Styled.Left>

                <Styled.Amount color="violet">
                  -{formatCurrency(expense.averageAmount, true)}
                </Styled.Amount>
              </Styled.Main>
            </Styled.Item>
          );
        })}
      </Styled.List>

      {filteredExpenses.length > 2 && (
        <LinkButton onClick={toggleExpenses}>
          {showAll ? "Show fewer" : "Show more"}
        </LinkButton>
      )}
    </Styled.Wrapper>
  );
};

export default ExpensesList;
