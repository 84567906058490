import dayjs from "dayjs";
import { ordinalize } from "./number";

export const ordinalizeDateString = (dateString: string) => {
  const day = dayjs(dateString).toDate().getDate();
  return ordinalize(day);
};

const DATE_STYLE: { [key: string]: Intl.DateTimeFormatOptions } = {
  numericShorter: { month: "numeric", day: "numeric" },
  numericShort: { year: "2-digit", month: "numeric", day: "numeric" },
  numericShortWithTime: {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  },
  numericLong: {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  },
  text: { year: "numeric", month: "long", day: "numeric" },
  textShortWithWeekday: { month: "short", day: "numeric", weekday: "short" },
  day: { day: "numeric" },
};

export const formatDate = (date: Date, style: string = "numericShort") =>
  date.toLocaleDateString(
    "default",
    DATE_STYLE[style] || DATE_STYLE.numericShort
  );

export const formatDateString = (
  dateString: string,
  style: string = "numericShort"
) => {
  if (style === "ordinalDay") return ordinalizeDateString(dateString);

  const date = dayjs(dateString).toDate();
  return formatDate(date, style);
};

const TIME_STYLE: { [key: string]: Intl.DateTimeFormatOptions } = {
  time: { hour: "numeric", minute: "2-digit", hour12: true },
};

export const formatTimeString = (
  dateString: string,
  style: keyof typeof DATE_STYLE = "numericShort"
) =>
  dayjs(dateString)
    .toDate()
    .toLocaleTimeString("default", TIME_STYLE[style] || TIME_STYLE.default);

export const truncateAndFormatDateString = (
  dateString: string,
  style: string
) => {
  const dateOnlyString = dateString.split("T")[0];
  return formatDateString(dateOnlyString, style);
};

export const currentTime = () => dayjs().format("h:mma");
