import React from "react";
import styled from "styled-components";
import { colors, transitions } from "utils/theme";

const Wrapper = styled.div`
  position: relative;

  display: inline-flex;
  width: max-content;
`;

const Switch = styled.div`
  padding: 3px;
  width: 56px;

  background: white;
  border: 1px solid ${colors.midnight}80;
  border-radius: 9999px;

  transition: all ${transitions.simple};

  pointer-events: none;
`;

const Indicator = styled.div`
  width: 24px;
  height: 24px;

  border-radius: 9999px;
  background: ${colors.midnight}80;

  transition: all ${transitions.simple};
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  margin: 0;

  opacity: 0;
  appearance: none;
  cursor: pointer;

  &:checked + ${Switch} {
    background: ${colors.azure};
    border-color: ${colors.azure};

    ${Indicator} {
      transform: translateX(100%);

      background: white;
    }
  }
`;

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: any;
  hasError?: boolean;
}

const SwitchCheckbox = (
  { name, onChange, ...props }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>
) => (
  <Wrapper>
    <Checkbox
      name={name}
      type="checkbox"
      onChange={onChange}
      ref={ref}
      {...props}
    />

    <Switch>
      <Indicator />
    </Switch>
  </Wrapper>
);

export default React.forwardRef(SwitchCheckbox);
