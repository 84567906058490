import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import Icon from "components/ui/Icon";
import AnimatedCounter from "components/ui/AnimatedCounter";
import LoadingDots from "components/ui/LoadingDots";
import { formatDateString } from "utils/date";
import * as Styled from "./styled";

interface Props {
  amount: number;
  nextPayday?: string;
  isLoadingDetails?: boolean;
  isActive: boolean;
  isUpdating?: boolean;
  showDetails?: () => void;
  showDslZeroModal?: () => void;
}

const CardFront: React.FC<Props> = ({
  amount,
  nextPayday,
  isLoadingDetails,
  isActive,
  isUpdating,
  showDetails,
  showDslZeroModal,
}) => {
  const { data: user } = getUserProfile();

  const lastFour = user?.currentCardAccount?.cards.find(
    (card) => card.form === "VIRTUAL"
  )?.lastFour;

  return (
    <Styled.Wrapper>
      <Styled.Card>
        <Styled.Content>
          <Styled.Info>
            <Styled.Heading>
              <Styled.HeadingText>Spending Limit</Styled.HeadingText>
            </Styled.Heading>

            <Styled.AmountWrapper $isUpdating={isUpdating}>
              <Styled.Amount>
                <Styled.DollarSign>$</Styled.DollarSign>
                {amount < 10 ? (
                  amount.toFixed(2)
                ) : (
                  <AnimatedCounter delay={0.1} value={amount} />
                )}
              </Styled.Amount>

              {isUpdating && (
                <Styled.Loading>
                  <LoadingDots width={12} />
                </Styled.Loading>
              )}
            </Styled.AmountWrapper>

            {isUpdating && <Styled.Subheading>Updating...</Styled.Subheading>}

            {nextPayday && isActive && !isUpdating && (
              <Styled.Subheading>
                Until payday on {formatDateString(nextPayday, "numericShorter")}
              </Styled.Subheading>
            )}

            {!isActive && !isUpdating && (
              <Styled.DslZeroButton onClick={showDslZeroModal}>
                <Icon name="questionCircle" />
                Why is my limit $0?
              </Styled.DslZeroButton>
            )}
          </Styled.Info>

          <Styled.Logo />
        </Styled.Content>

        {lastFour && (
          <Styled.Footer>
            <Styled.CardNumber>
              <span>•••• {lastFour}</span>
              <Styled.DetailsButton onClick={showDetails}>
                {isLoadingDetails ? (
                  <Styled.SpinnerIcon name="spinner" />
                ) : (
                  <Icon name="eye" />
                )}
              </Styled.DetailsButton>
            </Styled.CardNumber>

            <Styled.VisaLogo $isActive={isActive} />
          </Styled.Footer>
        )}
      </Styled.Card>

      <Styled.Spacer />
    </Styled.Wrapper>
  );
};

export default CardFront;
