import React from "react";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import { TransactionCustomerState } from "utils/api/cardTransactions";

const StatusIcon = ({ status }) => {
  switch (status) {
    case TransactionCustomerState.pending:
      return (
        <Icon
          name="statusPending"
          color={colors.violet}
          colorSecondary={colors.flax}
        />
      );
    case TransactionCustomerState.declined:
      return (
        <Icon
          name="statusWarning"
          color={colors.midnight}
          colorSecondary={colors.cosmos}
        />
      );
    case TransactionCustomerState.repaid:
      return (
        <Icon
          name="statusSuccess"
          color={colors.midnight}
          colorSecondary={colors.leaf}
        />
      );
    default:
      return null;
  }
};

export default StatusIcon;
