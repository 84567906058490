import React from "react";
import { createGlobalStyle } from "styled-components";
import {
  colors,
  shadows,
  fonts,
  fontWeights,
  transitions,
  lineHeights,
  letterSpacings,
  fontSizes,
  fontSizesMobile,
} from "utils/theme";
import { camelCaseToKebabCase } from "utils/string";

const colorVars = Object.entries(colors)
  .map(([key, value]) => `--color-${camelCaseToKebabCase(key)}: ${value}`)
  .join(";");

const shadowVars = Object.entries(shadows)
  .map(([key, value]) => `--shadow-${camelCaseToKebabCase(key)}: ${value}`)
  .join(";");

const fontVars = Object.entries(fonts)
  .map(([key, value]) => `--font-${camelCaseToKebabCase(key)}: ${value}`)
  .join(";");

const fontWeightVars = Object.entries(fontWeights)
  .map(([key, value]) => `--font-weight-${camelCaseToKebabCase(key)}: ${value}`)
  .join(";");

const fontSizeVars = Object.entries(fontSizes)
  .map(([key, value]) => `--font-size-${key}: ${value}px`)
  .join(";");

const fontSizeMobileVars = Object.entries(fontSizesMobile)
  .map(([key, value]) => `--font-size-mobile-${key}: ${value}px`)
  .join(";");

const lineHeightVars = Object.entries(lineHeights)
  .map(([key, value]) => `--line-height-${key}: ${value}`)
  .join(";");

const letterSpacingVars = Object.entries(letterSpacings)
  .map(([key, value]) => `--letter-spacing-${key}: ${value}em`)
  .join(";");

const transitionVars = Object.entries(transitions)
  .map(([key, value]) => `--transition-${camelCaseToKebabCase(key)}: ${value}`)
  .join(";");

const GlobalStyle = createGlobalStyle`
  :root {
    ${colorVars};
    ${shadowVars};
    ${fontVars};
    ${fontWeightVars};
    ${fontSizeVars};
    ${fontSizeMobileVars};
    ${lineHeightVars};
    ${letterSpacingVars};
    ${transitionVars};
  }

  html {
    color: ${colors.midnight};
    font-family: ${fonts.body};

    * {
      box-sizing: border-box;
    }
  }

  html, body {
    margin: 0;
  }

  body {
    background: ${colors.azure}08;
  }

  .app-placeholder {
    position: absolute;
    z-index: -9999;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
  }
`;

const Styles = () => <GlobalStyle />;

export default Styles;
