import { TileRadioOption } from "components/form/fields/TileRadioGroupField";
import { SearchInputSelection } from "types/general";
import { IncomeType } from "types/signup";

export const DEFAULT_VALUES = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
  },
  questionnaire: {
    incomeType: null,
    tracksHoursWithPayroll: null,
  },
};

export const INCOME_OPTIONS: TileRadioOption[] = [
  { label: "a salary", value: IncomeType.salary, icon: "briefcase" },
  { label: "hourly wages", value: IncomeType.hourly, icon: "clock" },
  { label: "for gig work", value: IncomeType.gig, icon: "car" },
  { label: "government benefits", value: IncomeType.benefits, icon: "bank" },
];

const SALARY_HOURLY_FAVORITES: SearchInputSelection[] = [
  { searchString: "ADP", id: "5becff90-1e35-450a-8995-13ac411e749b" },
  {
    searchString: "Paychex Flex",
    id: "9a4e213b-aeed-4cb2-aace-696bcd2b1e0d",
  },
  {
    searchString: "UKG Pro (a.k.a. Ultipro)",
    id: "a6512ce4-d9ca-4113-b732-39c363520a83",
  },
  { searchString: "Workday", id: "5965580e-380f-4b86-8a8a-7278c77f73cb" },
  { searchString: "Paycom", id: "3f812c04-ac83-495b-99ca-7ec7d56dc68b" },
  { searchString: "Gusto", id: "4475fef1-018d-43b8-92cb-bd548f8fd278" },
];

export const PAYROLL_FAVORITES: {
  [key in IncomeType]: SearchInputSelection[];
} = {
  [IncomeType.salary]: SALARY_HOURLY_FAVORITES,
  [IncomeType.hourly]: SALARY_HOURLY_FAVORITES,
  [IncomeType.benefits]: [
    {
      searchString: "Social Security (SSDI)",
      id: "0bab321e-64ea-4047-8d8a-44ba283f6cd8",
    },
    {
      searchString: "Supplemental Security (SSI)",
      id: "bc792b12-85f8-4909-8337-36758d56cb91",
    },
    {
      searchString: "Veterans Affairs",
      id: "3f7b6ce9-6371-4bb8-8c2f-051c446dc55c",
    },
  ],
  [IncomeType.gig]: [
    {
      searchString: "DoorDash (Dasher)",
      id: "737d833a-1b68-44f7-92ae-3808374cb459",
    },
    {
      searchString: "Uber (Driver)",
      id: "91063607-2b4a-4c8e-8045-a543f01b8b09",
    },
    {
      searchString: "Uber Eats (Driver)",
      id: "cace440e-43f7-46b4-a937-063f7ed22b74",
    },
    {
      searchString: "Instacart (Full-Service Shopper)",
      id: "9f7ddcaf-cbc5-4bd2-b701-d40c67389eae",
    },
  ],
};
