import React, { useEffect, useRef, useState } from "react";
import Button from "components/ui/Button";
import CenteredLayout from "components/layout/CenteredLayout";
import {
  Field,
  FormMessage,
  FormSection,
  Input,
  PasswordStrength,
} from "components/form";
import { Body, Heading } from "components/ui/Text";
import * as Styled from "./styled";
import PasswordStrengthContainer from "./PasswordStrengthContainer";

interface CurrentUser {
  first_name: string;
  last_name: string;
}

interface Props {
  currentUser: CurrentUser;
  errors: {
    [key: string]: string[];
  };
}

const prettifyMessage = (message: string) => {
  let formattedMessage;
  switch (message) {
    case "doesn't match Password":
      formattedMessage = "Passwords must match";
      break;
    default:
      formattedMessage = `Password ${message}`;
  }
  if (formattedMessage.charAt(formattedMessage.length - 1) !== ".") {
    formattedMessage += ".";
  }
  return formattedMessage;
};

const PasswordFields: React.FC<Props> = ({ currentUser, errors }) => {
  const [hasError, setHasError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const inputRef = useRef(null);
  const [password, setPassword] = useState("");
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const showError = () => {
    setHasError(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const rawErrorMessages = Object.values(errors || {}).flat();
  const errorMessage = rawErrorMessages
    .map((message) => prettifyMessage(message))
    .join(" ");

  const handleFormSubmit = () => {
    setSubmitting(true);
  };

  useEffect(() => {
    // This is a hack to prevent multiple submissions of the form which is defined in the Rails
    // template outside of this React component.
    const form = document.getElementById("edit_user") as HTMLFormElement;
    form?.addEventListener("submit", handleFormSubmit);

    return () => {
      form?.removeEventListener("submit", handleFormSubmit);
    };
  }, []);

  return (
    <CenteredLayout>
      <Styled.Wrapper>
        {errorMessage.length > 0 && <FormMessage>{errorMessage}</FormMessage>}

        <FormSection>
          <Heading.H2 tag="h1">Set your password</Heading.H2>

          <Body>
            Choose a <strong>strong</strong> password to keep your account safe.
          </Body>

          <PasswordStrengthContainer
            password={password}
            blacklist={[currentUser?.first_name, currentUser?.last_name]}
          >
            {({ strength }) => (
              <>
                {hasError && strength.score < 4 && (
                  <FormMessage>
                    <div>
                      You must choose a <strong>strong</strong> password. Please
                      see requirements below.
                    </div>
                  </FormMessage>
                )}
                <Field name="user[password]">
                  <Input
                    name="user[password]"
                    placeholder="Password"
                    type="password"
                    required
                    onChange={onChangePassword}
                    autoComplete="new-password"
                    readOnly={submitting}
                    ref={inputRef}
                  />

                  <PasswordStrength {...strength} />
                </Field>

                <Field name="user[password]">
                  <Input
                    name="user[password_confirmation]"
                    placeholder="Confirm password"
                    type="password"
                    readOnly={submitting}
                    required
                  />
                </Field>
                {strength.score === 4 ? (
                  <Button
                    type="submit"
                    data-test="btn-submit"
                    disabled={submitting}
                  >
                    {submitting ? "Submitting..." : "Set Password"}
                  </Button>
                ) : (
                  <Button type="button" onClick={showError}>
                    Set Password
                  </Button>
                )}
              </>
            )}
          </PasswordStrengthContainer>
        </FormSection>
      </Styled.Wrapper>
    </CenteredLayout>
  );
};

export default PasswordFields;
