import React, { useEffect, useState } from "react";
import { getUserProfile } from "store/api/apiSlice";
import UILayout from "components/layout/UILayout";
import Loading from "components/ui/Loading";
import Notification, { NotificationLevel } from "components/ui/Notification";
import Edit from "./Edit";
import Show from "./Show";
import { Wrapper } from "../styled";

const PersonalInfo = () => {
  const { isLoading, data: userProfile } = getUserProfile();
  const [personalInfo, setPersonalInfo] = useState(userProfile);
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!isLoading && !personalInfo) {
      setPersonalInfo(userProfile);
    }
  }, [isLoading, userProfile]);

  if (isLoading) return <Loading />;
  if (!personalInfo) return null;

  return (
    <UILayout>
      <Wrapper>
        {isSuccess && (
          <Notification level={NotificationLevel.success}>
            We received your update request! Please allow 1-3 business days for
            changes to take effect.
          </Notification>
        )}

        {isEditing ? (
          <Edit
            userProfile={personalInfo}
            setIsEditing={setIsEditing}
            setIsSuccess={setIsSuccess}
            setPersonalInfo={setPersonalInfo}
          />
        ) : (
          <Show
            userProfile={personalInfo}
            setIsEditing={setIsEditing}
            isSuccess={isSuccess}
          />
        )}
      </Wrapper>
    </UILayout>
  );
};

export default PersonalInfo;
