import React, { forwardRef, useImperativeHandle, useRef } from "react";
import confetti from "canvas-confetti";
import { Body, Heading } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import { ButtonLevel, ButtonRouterLink } from "components/ui/Button";
import { colors, zIndex } from "utils/theme";
import { getDashboardPayday } from "store/api/apiSlice";
import AnimatedCounter from "components/ui/AnimatedCounter";
import Modal, { ModalApi } from "../Modal";
import * as Styled from "./styled";

const throwConfetti = () => {
  confetti({
    particleCount: 120,
    shapes: ["circle"],
    colors: [colors.azure, colors.midnight, colors.heliotrope, colors.violet],
    spread: 500,
    origin: { y: 0.4 },
    ticks: 400,
    zIndex: zIndex.modal,
  });
};

const PaydayCelebrationModal = (
  _: {},
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  const { data: payday, isLoading: isLoadingPayday } = getDashboardPayday();

  const show = () => {
    throwConfetti();
    ref.current?.show();
  };

  useImperativeHandle(forwardedRef, () => ({ ...ref.current, show }));

  if (isLoadingPayday) return null;

  const { remainderAmount } = payday || {};

  return (
    <Modal ref={ref}>
      <Styled.Wrapper>
        <Styled.Heading>
          <Heading.H3 tag="h2">You got paid!</Heading.H3>
          <Body>Your outstanding transactions were automatically repaid.</Body>
        </Styled.Heading>

        <Styled.AmountWrapper>
          <UILabel.Small bold wideCaps color="violet">
            Net paycheck
          </UILabel.Small>
          <Heading.H1 tag="h3" color="azure">
            <Styled.Amount>
              <div>{remainderAmount < 0 ? "-" : ""}$</div>
              <AnimatedCounter delay={0.1} value={Math.abs(remainderAmount)} />
            </Styled.Amount>
          </Heading.H1>
        </Styled.AmountWrapper>

        <ButtonRouterLink
          fullWidth
          level={ButtonLevel.cta}
          onClick={() => ref.current?.dismiss()}
          to="/dashboard#payday"
        >
          See details
        </ButtonRouterLink>
      </Styled.Wrapper>
    </Modal>
  );
};

export default forwardRef(PaydayCelebrationModal);
