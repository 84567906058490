import {
  MenuItemProps,
  MenuItemType,
  MenuSection,
} from "components/ui/Menu/types";
import { ApiCardAccount } from "utils/api/cardAccount";
import { ApiUserProfile } from "utils/api/user";

interface Props {
  userProfile: ApiUserProfile;
}

const getSettingsSection = (userProfile: ApiUserProfile): MenuItemProps[] => {
  let section: MenuItemProps[] = [
    {
      type: MenuItemType.routerLink,
      children: "Personal info",
      icon: "userCircle",
      data: {
        to: "/account/personal-info",
      },
    },
  ];

  if (userProfile.phoneNumber) {
    section = [
      ...section,
      {
        type: MenuItemType.routerLink,
        children: "Login & security",
        icon: "shield",
        data: {
          to: "/account/security",
        },
      },
    ];
  }

  return section;
};

const hasPhysicalCard = (cardAccount: ApiCardAccount) =>
  cardAccount.cards.some((card) => card.form === "PHYSICAL");

const getCardSection = (userProfile: ApiUserProfile): MenuItemProps[] => {
  let section: MenuItemProps[] = [];

  if (userProfile.currentCardAccount) {
    section = [
      ...section,
      {
        type: MenuItemType.routerLink,
        children: "Deposit account statements",
        icon: "invoice",
        data: {
          to: "/account/statements",
        },
      },
    ];

    if (hasPhysicalCard(userProfile.currentCardAccount)) {
      section = [
        ...section,
        {
          type: MenuItemType.routerLink,
          children: "Replace card",
          icon: "mailbox",
          data: {
            to: "/account/replace-card",
          },
        },
      ];
    }
  } else {
    section = [
      ...section,
      {
        type: MenuItemType.link,
        children: "Apply now",
        icon: "creditCard",
        isCta: true,
        data: {
          href: "/apply",
        },
      },
    ];
  }

  return section;
};

const getSupportSection = (): MenuItemProps[] => [
  {
    type: MenuItemType.routerLink,
    children: "Documents",
    icon: "files",
    data: {
      to: "/account/documents",
    },
  },
  {
    type: MenuItemType.routerLink,
    children: "FAQs",
    icon: "questionCircle",
    data: {
      to: "/account/user-faqs",
    },
  },
  {
    type: MenuItemType.routerLink,
    children: "Manage account",
    icon: "toggleOn",
    data: {
      to: "/account/manage-account",
    },
  },
];

export const getMenuSections: (props: Props) => MenuSection[] = ({
  userProfile,
}) => {
  const settingsSection = {
    title: "Settings",
    items: getSettingsSection(userProfile),
  };
  const resetCardSection = {
    title: "Reset card",
    items: getCardSection(userProfile),
  };
  const supportSection = {
    title: "Support",
    items: getSupportSection(),
  };

  const sections: MenuSection[] = [settingsSection];
  if (userProfile.eligibleForCard || userProfile.currentCardAccount) {
    sections.push(resetCardSection);
  }
  sections.push(supportSection);

  return sections;
};
