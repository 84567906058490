import React from "react";
import SidebarLayout from "components/layout/SidebarLayout";
import DrawerLayout from "components/layout/DrawerLayout";
import FormProgressSidebar, {
  NavItem,
} from "components/ui/FormProgressSidebar";
import FormProgressHeader, {
  NavItem as MobileNavItem,
} from "components/ui/FormProgressHeader";
import ChatWidget from "components/ui/ChatWidget";
import { ButtonLevel } from "components/ui/Button";
import { PartnerSlug } from "types/general";
import { FormTask, FormTaskStatus } from "types/task";
import { DEFAULT_TASKS, TASK_MAP } from "utils/constants/onboarding";
import { OnboardingTask } from "types/onboarding";
import * as Styled from "./styled";

interface RenderProps {
  openDrawer: (body: React.ReactNode) => void;
  closeDrawer: () => void;
}

interface Props {
  children: React.ReactNode | ((props: RenderProps) => React.ReactNode);
  tasks?: OnboardingTask[];
  partnerSlug?: PartnerSlug;
  sidebarNavigation?: NavItem[];
  mobileNavigation?: MobileNavItem[];
}

const OnboardingFlow: React.FC<Props> = ({
  tasks = [],
  partnerSlug,
  sidebarNavigation,
  mobileNavigation,
  children,
}) => {
  const steps = DEFAULT_TASKS.map((defaultTask) => {
    const task = {
      ...defaultTask,
      ...tasks.find((t) => t.name === defaultTask.name),
    };
    return {
      id: task.name,
      status: task.state,
      ...TASK_MAP[task.name],
    };
  }) as FormTask[];
  const currentStepIndex = steps.findIndex(
    (step) => step.status === FormTaskStatus.ready
  );

  return (
    <SidebarLayout
      sidebar={
        <Styled.SidebarWrapper>
          <FormProgressSidebar
            title="Card Application"
            steps={steps}
            navigation={sidebarNavigation}
            partnerSlug={partnerSlug}
          />
        </Styled.SidebarWrapper>
      }
    >
      <Styled.MobileHeaderWrapper>
        <FormProgressHeader
          currentStepIndex={currentStepIndex}
          steps={steps}
          icon="clipboardListCheck"
          title="Card Application"
          navigation={mobileNavigation}
        />
      </Styled.MobileHeaderWrapper>
      <DrawerLayout>
        {({ openWith, close }) => (
          <Styled.ContentWrapper>
            <Styled.Content>
              {typeof children === "function"
                ? children({ openDrawer: openWith, closeDrawer: close })
                : children}
            </Styled.Content>
          </Styled.ContentWrapper>
        )}
      </DrawerLayout>

      <Styled.ChatWrapper>
        <ChatWidget
          isPrivate
          buttonProps={{ level: ButtonLevel.tertiary }}
          label="Need Help?"
          icon="commentQuestion"
          positionMobileY="top"
        />
      </Styled.ChatWrapper>
    </SidebarLayout>
  );
};

export default OnboardingFlow;
