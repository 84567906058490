import React from "react";
import { CardHero } from "types/cms";
import Publications from "components/shared/Publications";
import HeaderDivider from "./HeaderDivider";
import * as Styled from "./styled";

const Card: React.FC<CardHero> = ({
  data: {
    textSize = "large",
    heading,
    image,
    imageOrientation,
    eyebrow,
    body,
    cta,
    theme = "default",
    includePublications,
    imageAnimation,
  },
}) => (
  <>
    <Styled.Wrapper $imageOrientation={imageOrientation} $theme={theme}>
      <Styled.BackgroundShape>
        <HeaderDivider />
      </Styled.BackgroundShape>

      <Styled.HeaderContent>
        <Styled.CardContainer>
          <Styled.CardImage $imageAnimation={imageAnimation}>
            <img src={image} />

            <Styled.CardShadow />
          </Styled.CardImage>
        </Styled.CardContainer>

        <Styled.Header
          eyebrow={eyebrow}
          heading={heading}
          body={body}
          cta={cta}
          textSize={textSize}
          isLight={theme === "default"}
          isHero
        />
      </Styled.HeaderContent>
    </Styled.Wrapper>

    {includePublications && (
      <Styled.PublicationsWrapper>
        <Publications />
      </Styled.PublicationsWrapper>
    )}
  </>
);

export default Card;
