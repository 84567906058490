import styled from "styled-components";
import { mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  --sidebar-width: 400px;

  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: var(--sidebar-width) 1fr;

  @media screen and (max-width: 1440px) {
    --sidebar-width: 320px;
  }

  ${mediaMax("xl")} {
    --sidebar-width: 280px;
  }

  ${mediaMax("lg")} {
    display: block;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Sidebar = styled.div`
  ${mediaMin("lg")} {
    position: sticky;
    top: 0;

    align-self: self-start;
    height: 100vh;
  }
`;
