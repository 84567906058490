import styled from "styled-components";
import { colors, mediaMax } from "utils/theme";
import { Body, Heading } from "components/ui/Text";
import Link from "components/ui/Link";

export const Header = styled.div`
  display: grid;
  gap: 16px;

  & > * {
    max-width: 768px;
    margin: 0 auto;
  }
`;

export const CardList = styled.div`
  --gap: 24px;

  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);
  row-gap: 50px;
  flex-wrap: wrap;
  justify-content: center;

  text-align: left;
`;

export const CardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 144px;
  overflow: hidden;

  border-radius: 9999px;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
`;

export const CardContent = styled.div`
  display: grid;
  gap: 4px;

  * {
    line-height: 1.33;
  }

  sup {
    color: ${colors.midnight}80;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: calc((100% - 2 * var(--gap)) / 3);
  flex: 0 0 auto;

  text-align: center;

  ${mediaMax("lg")} {
    width: calc((100% - var(--gap)) / 2);
  }

  ${mediaMax("md")} {
    width: 100%;
  }
`;

export const CardHeading = styled(Heading.H4)``;

export const CardBody = styled(Body)``;

export const CardLinks = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  align-items: center;
`;

export const CardLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Wrapper = styled.div`
  position: relative;

  display: grid;
  gap: 48px;
  padding-bottom: 24px;

  text-align: center;
`;
