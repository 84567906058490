import styled from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const Menu = styled.div`
  display: grid;
  gap: 32px;

  ${mediaMax("md")} {
    gap: 16px;
  }
`;

export const MenuSectionItems = styled.div`
  display: grid;

  ${mediaMax("md")} {
    padding-top: 8px;
    padding-bottom: 8px;
    gap: 12px;
  }
`;

export const MenuItemWrapper = styled.div`
  ${mediaMin("md")} {
    &:not(:first-child) {
      border-top: 1px solid ${colors.midnight}11;
    }
  }
`;

export const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${mediaMin("md")} {
    &:not(:last-child) {
      ${MenuItemWrapper} {
        &:last-child {
          border-bottom: 1px solid ${colors.midnight}11;
        }
      }
    }

    &:last-child {
      ${MenuItemWrapper} {
        &:last-child {
          margin-bottom: -24px;
        }
      }
    }
  }
`;
