import { colors } from "utils/theme";
import { BLOCK_ELEMENTS, STYLES } from "./constants";

function getInlineNode(node: Node) {
  // return text, styles and attributes for inline nodes
  let text = node.textContent?.replace(/[\r\n]/g, " ").replace(/\s+/g, " ");
  // .trim();
  if (text.trim().length === 0) return null;
  const parentEl = node.parentElement;
  const parentTagName = parentEl?.tagName;
  if (parentEl.classList.contains("eyebrow")) {
    text = text.toUpperCase();
  }
  const tag = node.nodeName;
  const style = [tag.toLowerCase()];
  if (parentTagName.match(/^H/) || parentTagName === "STRONG") {
    style.push("bold");
  }
  if (parentTagName === "EM") {
    style.push("italics");
  }
  const item: { [key: string]: any } = { text, style };
  if (node.nodeName === "A") {
    const href = (node as Element).getAttribute("href");
    if (href.match(/^#/)) {
      item.linkToDestination = href.replace(/^#/, "");
    } else {
      item.link = href;
    }
  }
  return item;
}

function getInlineNodes(node: Node) {
  // return list of inline nodes
  // appropriate to capture inline elements mixed in with text nodes
  return Array.from(node.childNodes).reduce((acc, child) => {
    let item: any[] = [];
    if (child.childNodes.length > 1) {
      item = getInlineNodes(child);
    } else {
      item = [getInlineNode(child)];
    }
    return [...acc, ...item];
  }, []);
}

function getTableHeaderType(el: Element) {
  // determine style of table header from class name
  if (el.classList.contains("header-table")) {
    return "primary";
  }
  if (el.classList.contains("subheader-table")) {
    return "secondary";
  }
  return "normal";
}

function buildTable(el: Element, ids) {
  // build table from html table element
  const rowLength = el
    .querySelector("tbody tr:last-child")
    .querySelectorAll("td, th").length;
  const widths = new Array(rowLength).fill("auto");
  widths[widths.length - 1] = "*";
  const table: { [key: string]: any } = {
    headerRows: 0,
    widths,
  };
  const headerType = getTableHeaderType(el);
  if (headerType === "normal") {
    table.headerRows = 1;
  }
  table.body = Array.from(el.querySelectorAll("tr")).reduce(
    (acc, tr, rowIndex) => {
      let row = Array.from(tr.querySelectorAll("td, th")).map(
        (td, columnIndex) => {
          const colSpan = parseInt(td.getAttribute("colspan") || "1", 10);
          // eslint-disable-next-line
          const stack = buildItems(td, ids);
          const props: { [key: string]: any } = {};
          if (
            (["primary", "secondary"].includes(headerType) &&
              columnIndex === 0) ||
            (headerType === "normal" && rowIndex === 0)
          ) {
            props.fillColor = colors.midnight;
            props.style = ["th"];
            if (headerType !== "primary") {
              props.fillOpacity = 0.5;
            }
          }
          return { stack, colSpan, ...props };
        }
      );
      if (row.length < rowLength) {
        row = [...row, ...new Array(rowLength - row.length).fill("")];
      }
      return [...acc, row];
    },
    []
  );
  return table;
}

function getList(el: Element) {
  // build list of items for ul or ol
  return Array.from(el.querySelectorAll("li")).map((li) => ({
    text: getInlineNodes(li),
    margin: [0, 4],
  }));
}

export function buildItems(node: Node, ids: string[], parentId?: string) {
  // build content stack for pdfmake
  if (node.nodeType === Node.TEXT_NODE) {
    const text = getInlineNode(node);
    return [text];
  }
  if (node.nodeType === Node.ELEMENT_NODE) {
    const el = node as Element;
    const tag = el.tagName;
    const style = [tag.toLowerCase()];
    const isHeading = tag.match(/^H/);
    if (isHeading) style.push("heading");
    const item: { [key: string]: any } = {
      style,
    };
    // must pass parent id down to innermost element of first child node for internal links to work
    // outermost id will take precedence, so edit original document if this isn't working as expected
    const id = el.id || parentId;

    if (
      Array.from(el.childNodes).some((child) =>
        BLOCK_ELEMENTS.includes(child.nodeName)
      )
    ) {
      // if more than one child node and at least one block element, treat all as block elements
      const stack = Array.from(el.childNodes).reduce((acc, child) => {
        const items = buildItems(child, ids, id);
        return [...acc, ...items];
      }, []);
      return item.style.some((s) => STYLES[s])
        ? [
            {
              ...item,
              stack,
            },
          ]
        : [...stack];
    }

    el.classList.forEach((className) => {
      style.push(className);
    });

    item.id = !ids.includes(id) ? id : null;
    if (id) ids.push(id);
    item.margin = isHeading ? [0, 16, 0, 8] : [0, 8];
    if (tag === "UL") {
      item.ul = getList(el);
    } else if (tag === "OL") {
      item.ol = getList(el);
    } else if (tag === "TABLE") {
      item.table = buildTable(el, ids);
    } else {
      item.text = getInlineNodes(el);
    }
    return [item];
  }
  return [];
}
