import React from "react";
import { Outlet } from "react-router-dom";
import AppLayout from "components/layout/AppLayout";
import { LayoutName } from "components/context/LayoutContext";
import Authenticator from "./Authenticator";

const Root: React.FC = () => (
  <AppLayout layoutName={LayoutName.withSidebar}>
    <Authenticator>
      <Outlet />
    </Authenticator>
  </AppLayout>
);

export default Root;
