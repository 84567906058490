import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputField, SearchInputField } from "components/form";
import InfoBox from "components/ui/InfoBox";
import { Body } from "components/ui/Text";
import { SearchInputSelection } from "types/general";
import { Eligibility } from "types/signup";
import { searchInstitutions } from "utils/api/plaid";
import environment from "utils/environment";
import * as Styled from "./styled";

// NOTE: this list is duplicated in 3 places and needs to be kept in sync:
//   app/models/waitlist.rb
//   app/services/reset_plaid/link_token_creator.rb
//   app/javascript/components/views/Signup/Form/BankQuestionnaire.tsx
const REQUIRED_PRODUCTS = ["auth", "transactions"];

interface Props {
  initialValue?: SearchInputSelection;
  setEligibility: (eligibility: Eligibility) => void;
  setHasError: (hasError: boolean) => void;
}

interface Bank {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

const BankQuestionnaire: React.FC<Props> = ({
  initialValue,
  setEligibility,
  setHasError,
}) => {
  const [bank, setBank] = useState<Bank | undefined>(undefined);

  const { register, setValue } = useFormContext();
  register("bank");

  const onSelectBank = (item, searchValue) => {
    setValue("bank", item);
    setBank(item || undefined);
    if (item.id === "other") {
      setValue("bank.otherName", searchValue);
    }
    if (item) {
      setHasError(false);
    }
  };

  useEffect(() => {
    if (bank) {
      const eligibility = REQUIRED_PRODUCTS.every((product) =>
        bank.data.products.includes(product)
      )
        ? Eligibility.eligible
        : Eligibility.ineligible;
      setEligibility(eligibility);
    } else {
      setEligibility(Eligibility.indeterminate);
    }
  }, [bank]);

  return (
    <>
      <Styled.Field>
        <Body tag="h2">My direct deposit goes to...</Body>
        <SearchInputField
          initialValue={initialValue}
          onSelect={onSelectBank}
          getSearch={searchInstitutions}
          placeholder="Search banks and credit unions"
          errorMessage="Please select an institution from the list"
          name="bankName"
          required
        />

        {bank?.id === "other" && (
          <InputField
            name="bank.otherName"
            label="Other bank or credit union"
          />
        )}
      </Styled.Field>

      {environment.signupFormDebugMode &&
        process.env.NODE_ENV === "development" &&
        bank?.data.products && <div>{bank.data.products.join(", ")}</div>}

      {!bank && (
        <InfoBox title="Important note">
          Select the bank or credit union where you have your primary checking
          account. Selecting a different one may cause problems with verifying
          your direct deposit.
        </InfoBox>
      )}
    </>
  );
};

export default BankQuestionnaire;
