import React from "react";
import Button, { ButtonProps } from "components/ui/Button";
import { useFormContext, useFormState } from "react-hook-form";

interface Props extends ButtonProps {
  name?: string;
  value?: any;
}

const SubmitButton: React.FC<Props> = ({
  name,
  value,
  isLoading,
  ...props
}) => {
  const { register, setValue } = useFormContext();
  const { isSubmitting } = useFormState();
  if (name) register(name);
  return (
    <Button
      type="submit"
      name={name}
      value={value}
      onClick={() => name && setValue(name, value)}
      isLoading={isSubmitting || isLoading}
      {...props}
    />
  );
};

export default SubmitButton;
