import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { motion } from "framer-motion";
import { Body, BodyLarge, Eyebrow, Heading } from "components/ui/Text";
import { ButtonLevel, ButtonLink } from "components/ui/Button";
import cardImage from "assets/images/general/reset-card-angle.png";
import backgroundShape from "assets/images/home/header-bg.svg";
import backgroundShapeMobile from "assets/images/home/header-bg-mobile.svg";
import Icon from "components/ui/Icon";
import Notification from "./Notification";
import * as Styled from "./styled";
import { HEADER } from "../../constants";

interface Props {
  hasInvitation: boolean;
}

const Header: React.FC<Props> = ({ hasInvitation }) => {
  const flags = useFlags();
  const cardImageSrc = cardImage;
  const hasComingSoon = !hasInvitation && !flags["rf-6-card-launch"];

  const { eyebrow, heading, body, bullets } = HEADER;

  return (
    <Styled.Wrapper>
      <Styled.BackgroundShape src={backgroundShape} />
      <Styled.BackgroundShapeMobile src={backgroundShapeMobile} />

      <Styled.HeaderContent>
        <Styled.HeaderText>
          <Notification />
          {hasComingSoon && <Styled.Tag>Currently invite-only!</Styled.Tag>}
          <Eyebrow>{eyebrow}</Eyebrow>
          <Heading.H1 data-test="home-heading">{heading}</Heading.H1>
          <BodyLarge>{body}</BodyLarge>
          <Styled.Bullets>
            {bullets.map((bullet) => (
              <Styled.Bullet key={bullet.icon}>
                <Styled.BulletIcon>
                  <Icon name={bullet.icon} color="violet" size="sm" />
                </Styled.BulletIcon>
                <Body>{bullet.text}</Body>
              </Styled.Bullet>
            ))}
          </Styled.Bullets>
          <Styled.HeaderCta>
            <ButtonLink href="/users/sign_up" level={ButtonLevel.cta}>
              Sign Up
            </ButtonLink>
          </Styled.HeaderCta>
        </Styled.HeaderText>

        <Styled.CardWrapper>
          <Styled.CardImage>
            <img src={cardImageSrc} />

            <Styled.CardShadow />
          </Styled.CardImage>

          <motion.div
            animate={{ y: 0, opacity: 1 }}
            initial={{ y: 10, opacity: 0 }}
            transition={{
              duration: 0.66,
              ease: [0.25, 1, 0.5, 1],
              delay: 0.33,
            }}
          >
            <Notification />
          </motion.div>
        </Styled.CardWrapper>
      </Styled.HeaderContent>
    </Styled.Wrapper>
  );
};

export default Header;
