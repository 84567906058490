import React from "react";
import GetStartedSection from "./GetStartedSection";
import CardSection from "./CardSection";
import FeaturesSection from "./FeaturesSection";
import FooterSection from "./FooterSection";

interface Props {
  eyebrow: string;
  heading: React.ReactNode;
  body: React.ReactNode;
}

const MarketingPageContent: React.FC<Props> = ({ eyebrow, heading, body }) => (
  <>
    <GetStartedSection eyebrow={eyebrow} heading={heading} body={body} />
    <CardSection />
    <FeaturesSection />
    <FooterSection />
  </>
);

export default MarketingPageContent;
