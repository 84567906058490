import React from "react";
import styled from "styled-components";
import LogoWithText from "components/ui/LogoWithText";
import { ButtonLink, ButtonSize } from "components/ui/Button";
import { colors, mediaMax } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  width: 100%;

  background: ${colors.midnight};

  ${mediaMax("lg")} {
    padding: 0 24px;
  }

  ${mediaMax("md")} {
    height: 72px;
    padding: 0 16px;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
`;

const ExitButton = styled(ButtonLink)`
  --color: white;
  background: transparent;
  border: 1.5px solid ${colors.ice}40;

  &,
  &:visited,
  &:hover,
  &:active {
    color: var(--color);
  }
`;

const Header = () => (
  <Wrapper>
    <Inner>
      <LogoWithText text="Budget Calculator" reverse />

      <ExitButton href="/" size={ButtonSize.md}>
        Exit
      </ExitButton>
    </Inner>
  </Wrapper>
);

export default Header;
