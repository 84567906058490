import styled, { css } from "styled-components";
import Tag from "components/ui/Tag";
import { colors, mediaMax } from "utils/theme";

const getStatusTagStyles = ({ $status }: { $status: string }) => {
  switch ($status) {
    case "unregistered":
    case "confirmed_email":
      return css`
        background: ${colors.flax};
      `;
    case "registered":
    case "linked_income_account":
    case "linked_core_financial_institution_account":
      return css`
        background: ${colors.heliotrope};
        color: white;
      `;
    case "onboarded":
      return css`
        background: ${colors.azure};
        color: white;
      `;
    case "verified_identity":
    case "switched_direct_deposit":
      return css`
        background: ${colors.midnight};
        color: white;
      `;
    case "issued_card":
    case "activated_card":
    case "used_card":
      return css`
        background: ${colors.leaf};
      `;
    default:
      return "";
  }
};

export const Wrapper = styled(Tag)<{ $status: string }>`
  width: fit-content;

  color: ${colors.midnight};
  background: ${colors.flax};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
