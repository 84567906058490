import React from "react";
import UILabel from "components/ui/UILabel";
import { Body, BodySmall, Heading } from "components/ui/Text";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";
import { SpendingBucket } from "../types";
import { SPENDING_BUCKETS } from "../constants";
import Chart from "./Chart";
import * as Styled from "./styled";

const toDollars = (amount: number) =>
  `$${Math.round(amount).toLocaleString("en-US")}`;

interface Props {
  needs: number;
  wants: number;
  savings: number;
  monthlyIncome: number;
}

const BudgetComparison: React.FC<Props> = ({
  needs,
  wants,
  savings,
  monthlyIncome,
}) => {
  const budgetItems = [
    { name: SpendingBucket.needs, amount: needs },
    { name: SpendingBucket.wants, amount: wants },
    { name: SpendingBucket.savings, amount: savings },
  ];

  return (
    <Styled.Tile>
      <Styled.ResultsContent>
        <Styled.ResultsHeader>
          <Styled.ChartWrapper>
            <Styled.IdealChart>
              <Chart
                needs={50}
                wants={30}
                savings={20}
                outerRadius={75}
                strokeWidth={24}
              />
            </Styled.IdealChart>

            <Styled.ReferenceChart>
              <Chart
                needs={needs}
                wants={wants}
                savings={savings}
                strokeWidth={8}
              />
            </Styled.ReferenceChart>

            <Styled.ChartCaption>
              <UILabel.Small color="dust">
                Compare your spending (outer circle) to our suggested budget
              </UILabel.Small>
            </Styled.ChartCaption>
          </Styled.ChartWrapper>

          <Styled.ResultsHeaderText>
            <UILabel.Small color="azure" wideCaps bold>
              Budgeting for the Future
            </UILabel.Small>

            <Heading.H3 tag="h2">The 50/30/20 Rule</Heading.H3>

            <BodySmall tag="div">
              <p>
                A good rule of thumb to use when setting a budget is to allocate{" "}
                <strong>half</strong> of your income for needs and 20% for
                savings and paying down debt. That leaves 30% for the extras
                each month.
              </p>

              <p>
                When your needs take up more than half of your budget, try to
                reduce spending on wants before you eliminate savings or paying
                down debt.
              </p>
            </BodySmall>
          </Styled.ResultsHeaderText>
        </Styled.ResultsHeader>
      </Styled.ResultsContent>

      <Styled.Comparison>
        <Body>
          With monthly income of {toDollars(monthlyIncome)}, aim to spend...
        </Body>

        <Styled.ComparisonList>
          {budgetItems.map(({ name, amount }) => {
            const { label, color, icon, ratio } = SPENDING_BUCKETS[name];
            const ideal = monthlyIncome * ratio;
            const difference = amount - ideal;
            return (
              <Styled.ComparisonItem key={label} $color={color}>
                <Styled.ComparisonItemIcon>
                  <Icon name={icon} size="lg" color="violet" />
                </Styled.ComparisonItemIcon>

                <Styled.ComparisonItemInfo>
                  <UILabel.Small bold wideCaps color="midnight">
                    {label}
                  </UILabel.Small>
                  <Styled.ComparisonItemAmount>
                    <Heading.H3>{toDollars(ideal)}</Heading.H3>
                  </Styled.ComparisonItemAmount>

                  <UILabel.Medium color={`${colors.violet}CC`}>
                    {toDollars(Math.abs(difference))}{" "}
                    {difference > 0 ? "less" : "more"}
                  </UILabel.Medium>
                </Styled.ComparisonItemInfo>
              </Styled.ComparisonItem>
            );
          })}
        </Styled.ComparisonList>
      </Styled.Comparison>
    </Styled.Tile>
  );
};

export default BudgetComparison;
