import React from "react";
import { getUserProfile } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import CardBenefits from "./CardBenefits";
import CardTransactions from "./CardTransactions";

const Page: React.FC = () => {
  const { data: userProfile, isLoading } = getUserProfile();

  if (isLoading) return <Loading />;

  return userProfile?.currentCardAccount !== undefined ? (
    <CardTransactions />
  ) : (
    <CardBenefits />
  );
};

export default Page;
