import { TileCheckboxOption } from "components/form/fields/TileCheckboxGroupField";
import { colors } from "utils/theme";
import { IconName } from "components/ui/Icon";
import { IncomeFrequency, SpendingBucket } from "./types";

export const NEEDS_OPTIONS: TileCheckboxOption[] = [
  {
    name: "HOUSING",
    label: "Housing",
    icon: "house",
  },
  {
    name: "TRANSPORTATION",
    label: "Transportation",
    icon: "car",
  },
  {
    name: "HEALTHCARE",
    label: "Healthcare",
    icon: "doctor",
  },
  {
    name: "GROCERIES",
    label: "Food & Essentials",
    icon: "grocery",
  },
  {
    name: "CHILDCARE",
    label: "Childcare",
    icon: "teddyBear",
  },
  {
    name: "PHONE",
    label: "Phone",
    icon: "phone",
  },
  {
    name: "INTERNET",
    label: "Internet",
    icon: "wifi",
  },
  {
    name: "UTILITIES",
    label: "Utilities",
    icon: "utilities",
  },
  {
    name: "MINIMUM_PAYMENTS",
    label: "Minimum Payments",
    icon: "creditCard",
  },
  {
    name: "OTHER_NEEDS",
    label: "Other",
    icon: "cubes",
  },
];

export const WANTS_OPTIONS: TileCheckboxOption[] = [
  {
    name: "DINING",
    label: "Dining Out",
    icon: "restaurant",
  },
  {
    name: "ENTERTAINMENT",
    label: "Entertainment",
    icon: "movieCamera",
  },
  {
    name: "TRAVEL",
    label: "Travel",
    icon: "globe",
  },
  {
    name: "SHOPPING",
    label: "Shopping",
    icon: "shoppingBag",
  },
  {
    name: "SUBSCRIPTIONS",
    label: "Subscriptions",
    icon: "refresh",
  },
  {
    name: "GIFTS",
    label: "Gifts",
    icon: "gift",
  },
  {
    name: "PERSONAL_CARE",
    label: "Personal Care",
    icon: "stars",
  },
  {
    name: "OTHER_WANTS",
    label: "Other",
    icon: "faceLaughBeam",
  },
];

export const INCOME_FREQUENCY_OPTIONS = [
  { name: "Weekly", value: IncomeFrequency.weekly },
  { name: "Biweekly", value: IncomeFrequency.biweekly },
  { name: "Semimonthly", value: IncomeFrequency.semimonthly },
  { name: "Monthly", value: IncomeFrequency.monthly },
  { name: "Annually", value: IncomeFrequency.annually },
];

export const SPENDING_BUCKETS: {
  [name in SpendingBucket]: {
    color: string;
    label: string;
    icon: IconName;
    ratio: number;
  };
} = {
  [SpendingBucket.needs]: {
    color: colors.violet,
    label: "Needs",
    icon: "cubes",
    ratio: 0.5,
  },
  [SpendingBucket.wants]: {
    color: colors.heliotrope,
    label: "Wants",
    icon: "faceLaughBeam",
    ratio: 0.3,
  },
  [SpendingBucket.savings]: {
    color: colors.azure,
    label: "Savings",
    icon: "piggyBank",
    ratio: 0.2,
  },
};
