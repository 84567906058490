export enum NodeType {
  text = "TEXT",
  link = "LINK",
}

export interface BaseNode {
  text: string;
}

export interface TextNode extends BaseNode {
  type: NodeType.text;
}

export interface LinkNode extends BaseNode {
  type: NodeType.link;
  data: {
    href: string;
  };
}

export type LabelNode = TextNode | LinkNode;
