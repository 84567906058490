import React from "react";
import styled from "styled-components";
import { getSessionPartner } from "utils/partners";
import { PARTNERS } from "utils/partners/constants";
import { PartnerSlug } from "types/general";
import UILabel from "../UILabel";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72px;
  padding: 0 16px;

  text-align: center;
`;

interface Props {
  partnerSlug?: PartnerSlug;
}

const PartnerBanner: React.FC<Props> = ({ partnerSlug }) => {
  const { banner } = getSessionPartner() || PARTNERS[partnerSlug] || {};

  if (!banner) return null;

  return (
    <Wrapper style={{ ...banner.theme }}>
      <UILabel.Large bold wideCaps>
        {banner.message}
      </UILabel.Large>
    </Wrapper>
  );
};

export default PartnerBanner;
