import { PARTNERS } from "utils/partners/constants";

const storePartnerParams = () => {
  const params = new URLSearchParams(window.location.search);
  let partner = params.get("partner");

  partner = Object.keys(PARTNERS).find(
    (key) => `/${key}` === window.location.pathname
  );

  if (!partner) return [];

  sessionStorage.setItem("partner", partner);

  return ["partner"];
};

export default storePartnerParams;
