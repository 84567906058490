import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { SpendingBucket } from "../types";
import { SPENDING_BUCKETS } from "../constants";

const DEFAULT_OUTER_RADIUS = 90;
const DEFAULT_STROKE_WIDTH = 32;

const Wrapper = styled.div<{ $outerRadius: number }>`
  position: relative;

  width: ${(props) => props.$outerRadius * 2}px;
  height: ${(props) => props.$outerRadius * 2}px;
  overflow: hidden;

  svg {
    transform: scaleX(-1) rotate(-90deg);

    width: 100%;
    height: 100%;
  }
`;

const Value = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
`;

interface Props {
  needs: number;
  wants: number;
  savings: number;
  isAnimated?: boolean;
  strokeWidth?: number;
  outerRadius?: number;
}

const Chart: React.FC<Props> = ({
  needs,
  wants,
  savings,
  strokeWidth = DEFAULT_STROKE_WIDTH,
  outerRadius = DEFAULT_OUTER_RADIUS,
  isAnimated,
}) => {
  const total = needs + wants + savings;
  const plots = [
    { value: total, name: SpendingBucket.savings },
    { value: wants + needs, name: SpendingBucket.needs },
    { value: wants, name: SpendingBucket.wants },
  ];

  const circumference = 2 * Math.PI * (outerRadius - strokeWidth / 2);

  return (
    <Wrapper $outerRadius={outerRadius}>
      <svg
        width={outerRadius * 2}
        height={outerRadius * 2}
        viewBox={`0 0 ${outerRadius * 2} ${outerRadius * 2}`}
      >
        <circle
          cx={outerRadius}
          cy={outerRadius}
          r={outerRadius - strokeWidth / 2}
          fill="transparent"
          stroke="#e6e6e6"
          strokeWidth={strokeWidth}
        />
      </svg>

      {plots.map(({ value, name }) => (
        <Value key={name}>
          <svg>
            <motion.circle
              cx={outerRadius}
              cy={outerRadius}
              r={outerRadius - strokeWidth / 2}
              fill="transparent"
              stroke={SPENDING_BUCKETS[name].color}
              strokeWidth={strokeWidth}
              strokeDasharray={circumference}
              animate={{
                strokeDashoffset: circumference * (1 - value / total),
              }}
              initial={{ strokeDashoffset: circumference }}
              transition={
                isAnimated
                  ? {
                      duration: 1,
                      delay: 0.5,
                      type: "spring",
                      damping: 15,
                    }
                  : { duration: 0 }
              }
            />
          </svg>
        </Value>
      ))}
    </Wrapper>
  );
};

export default Chart;
