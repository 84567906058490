import React from "react";
import styled, { css } from "styled-components";
import Icon from "components/ui/Icon";
import { camelCaseToTitleCase } from "utils/string";
import { colors } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Item = styled.div`
  display: flex;
  gap: 6px;
`;

const IconWrapper = styled.div<{ $eligible: boolean }>`
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  ${({ $eligible }) =>
    $eligible
      ? css`
          background: ${colors.leaf};
        `
      : css`
          background: ${colors.cerise};
          color: white;
        `}

  svg {
    display: block;

    * {
      stroke-width: 40px;
      stroke: currentColor;
    }
  }
`;

interface Props {
  criteria: { [key: string]: boolean };
}

const EligibilityCriteria: React.FC<Props> = ({ criteria }) => (
  <Wrapper>
    {Object.entries(criteria || {}).map(([key, value]) => (
      <Item key={key}>
        <IconWrapper $eligible={value}>
          <Icon name={value ? "checkmark" : "close"} height={12} />
        </IconWrapper>

        {camelCaseToTitleCase(key.replace(/\?/g, ""))}
      </Item>
    ))}
  </Wrapper>
);

export default EligibilityCriteria;
