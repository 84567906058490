import React from "react";

const Disclosure = () => (
  <>
    <p>
      <strong>
        In Case of Errors or Questions About Your Electronic Transfers:
      </strong>
    </p>
    <p>
      Write us at hello@getreset.co or call us at (415) 702-2027 as soon as you
      can, if you think your statement or receipt is wrong or if you need more
      information about a transfer on the statement or receipt. We must hear
      from you no later than 60 days after we sent you the FIRST statement on
      which the error or problem appeared.
    </p>
    <ol>
      <li>Tell us your name and account number (if any). </li>
      <li>
        Describe the error or the transfer you are unsure about, and explain as
        clearly as you can why you believe it is an error or why you need more
        information.{" "}
      </li>
      <li>Tell us the dollar amount of the suspected error.</li>{" "}
    </ol>
    <p>
      We will investigate your complaint and will correct any error promptly. If
      we take more than 10 business days to do this, we will credit your account
      for the amount you think is in error, so that you will have the use of the
      money during the time it takes us to complete our investigation.
    </p>
  </>
);

export default Disclosure;
