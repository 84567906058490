import React from "react";
import waveImage from "assets/images/general/wave-shape.svg";
import { BodyLarge, Heading } from "components/ui/Text";
import LogoWithText from "components/ui/LogoWithText";
import Tile from "components/ui/Tile";
import * as Styled from "./styled";
import SignupSuccess from "./SignupSuccess";
import SignupForm from "./SignupForm";

interface Props {
  postBudgetResults: any;
}

const Signup: React.FC<Props> = ({ postBudgetResults }) => {
  const email = postBudgetResults?.isSuccess
    ? postBudgetResults.originalArgs?.user?.email
    : null;
  const isSubmitted = !!email;

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <Styled.Headline>
          <LogoWithText text="Budget Calculator" reverse />

          <Heading.H1>
            We&apos;re building a financial health movement.
          </Heading.H1>

          <BodyLarge color="ice">
            At Reset, our goal is to provide you with access to tools that help
            you survive and thrive, wherever you are on your
            financial&nbsp;journey.
          </BodyLarge>
        </Styled.Headline>

        <Tile size="xl">
          <Styled.Form>
            <Styled.FormHeading>
              <Heading.H4>Unlock your financial future.</Heading.H4>

              <Styled.FormBody>
                Get your personalized budget emailed to you, and receive updates
                on how your finances stacks up with peers. We'll also tell you
                about new tools to help get you to, and keep you in, your
                financial happy place.
              </Styled.FormBody>
            </Styled.FormHeading>

            {isSubmitted ? (
              <SignupSuccess
                email={email}
                showSignupForm={postBudgetResults.reset}
              />
            ) : (
              <SignupForm />
            )}
          </Styled.Form>
        </Tile>
      </Styled.Content>

      <Styled.Shape>
        <img src={waveImage} />
      </Styled.Shape>
    </Styled.Wrapper>
  );
};

export default Signup;
