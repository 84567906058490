import React from "react";
import styled from "styled-components";
import LoadingDots from "components/ui/LoadingDots";
import { borderRadius, shadows } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 24px 9px;

  border-radius: ${borderRadius.large}px;
  box-shadow: ${shadows.tile};
  background: white;
`;

const Iframe = styled.iframe`
  width: 320px;
  height: 320px;
  max-width: 100%;
  margin: 0;

  border: 0;
`;

interface Props {
  isLoading?: boolean;
  url?: string;
}

const CardActivationWidget: React.FC<Props> = ({ isLoading, url }) => (
    <Wrapper>{isLoading ? <LoadingDots /> : <Iframe src={url} />}</Wrapper>
  );

export default CardActivationWidget;
