import styled from "styled-components";
import DisappearingTile from "components/ui/DisappearingTile";
import Detail from "components/ui/Detail";
import { colors, mediaMax, mediaMin } from "utils/theme";
import Button, { ButtonLevel } from "components/ui/Button";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaMax("lg")} {
    padding: 16px;
  }
`;

export const SectionGroup = styled.div`
  display: grid;
  gap: 48px;

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SectionTile = styled(DisappearingTile).attrs(() => ({
  size: "xl",
}))`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Info = styled.div`
  display: grid;
  gap: 48px;

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);
    gap: 144px;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InfoItem = styled(Detail).attrs(() => ({ size: "xl" }))``;

export const ButtonWrapper = styled.div`
  padding-top: 24px;

  button {
    width: 100%;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 4px;
`;

export const TileActions = styled.div`
  display: grid;
  gap: 48px;
  padding-top: 24px;

  hr {
    width: 100%;
    height: 1px;
    margin: 0;
  }

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);

    hr {
      grid-column: 1 / span 2;
    }
  }
`;

export const DangerGhostButton = styled(Button).attrs(() => ({
  level: ButtonLevel.ghost,
}))`
  border-color: ${colors.cerise};
`;
