import Icon, { IconName } from "components/ui/Icon";
import DisappearingTile from "components/ui/DisappearingTile";
import UILabel from "components/ui/UILabel";
import React from "react";
import styled from "styled-components";
import { colors, mediaMin } from "utils/theme";

const Wrapper = styled.div`
  position: relative;

  ${mediaMin("md")} {
    margin-top: -108px;
    padding: 0 36px;
  }
`;

const ListTile = styled(DisappearingTile)`
  padding: 0 16px;

  ${mediaMin("md")} {
    padding: 36px;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 0;

  list-style-type: none;
`;

const ListItem = styled.div`
  display: flex;
  gap: 16px;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;

  border-radius: 9999px;
  background: ${colors.flax};
  color: ${colors.violet};
`;

const ITEMS: { icon: IconName; text: React.ReactNode }[] = [
  {
    icon: "dollar",
    text: "The spending limit you see here is the same one you will have on the Reset card.",
  },
  {
    icon: "dollarChart",
    text: "Reset updates your card spending limit daily based on income, expenses, and other factors.",
  },
  {
    icon: "creditCard",
    text: "Use your Reset card anywhere in the U.S. that Visa cards are accepted.",
  },
];

const BasicExplanation = () => (
  <Wrapper>
    <ListTile size="xl">
      <List>
        {ITEMS.map((item, index) => (
          <li key={index}>
            <ListItem>
              <IconWrapper>
                <Icon name={item.icon} />
              </IconWrapper>

              <UILabel.Large>{item.text}</UILabel.Large>
            </ListItem>
          </li>
        ))}
      </List>
    </ListTile>
  </Wrapper>
);

export default BasicExplanation;
