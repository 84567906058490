import { FormSection } from "components/form";
import styled from "styled-components";

export const Tile = styled(FormSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding-bottom: 48px;

  * {
    text-align: center;
  }
`;

export const Header = styled.div`
  display: grid;
  gap: 12px;
`;
