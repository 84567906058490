import Loading from "components/ui/Loading";
import React, { useEffect, useState } from "react";
import { UIMatch, useMatches, useNavigate } from "react-router-dom";
import { getUserProfile } from "store/api/apiSlice";
import { ApiUserProfile } from "utils/api/user";

const CARDHOLDER_ROUTES = ["/account/replace-card", "/account/statements"];

const PHONE_NUMBER_ROUTES = ["/account/security"];

const routeMustBeAuthorized = (routes: string[], matches: UIMatch[]) =>
  matches.some((match) => routes.some((route) => match.pathname === route));

const authorizeRoute = (
  routes: string[],
  matches: UIMatch[],
  condition: boolean
) => !routeMustBeAuthorized(routes, matches) || condition;

const getAuthorization = (matches: UIMatch[], userProfile?: ApiUserProfile) =>
  authorizeRoute(
    CARDHOLDER_ROUTES,
    matches,
    !!userProfile?.currentCardAccount
  ) && authorizeRoute(PHONE_NUMBER_ROUTES, matches, !!userProfile?.phoneNumber);

interface Props {
  children: React.ReactNode;
}

const Authenticator: React.FC<Props> = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const { data: userProfile, isLoading } = getUserProfile();
  const matches = useMatches();
  const navigate = useNavigate();

  useEffect(() => {
    setIsReady(false);
    const isAuthorized = getAuthorization(matches, userProfile);
    if (!isAuthorized) {
      navigate("/dashboard", { replace: true });
    } else {
      setIsReady(true);
    }
  }, [matches, userProfile]);

  if (isLoading || !isReady) return <Loading />;

  return children;
};

export default Authenticator;
