import React from "react";
import { NEEDS_OPTIONS } from "../constants";
import Amounts from "./Amounts";

interface Props {
  updateValidation: (isValid: boolean) => void;
}

const NeedsAmounts: React.FC<Props> = ({ updateValidation }) => (
  <Amounts options={NEEDS_OPTIONS} updateValidation={updateValidation} />
);

export default NeedsAmounts;
