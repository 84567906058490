import React, { useEffect, useState } from "react";
import { Heading } from "components/ui/Text";
import { getAdminUserAnnualizedIncome } from "store/admin/api/apiSlice";
import Button from "components/ui/Button";
import { ApiAdminUser } from "utils/api/admin/user";
import { formatCurrency } from "utils/string";
import UILabel from "components/ui/UILabel";
import * as Styled from "./styled";

interface Props {
  user: ApiAdminUser;
}

const AnnualizedIncome: React.FC<Props> = ({ user }) => {
  const [showAnnualizedIncome, setShowAnnualizedIncome] = useState(false);
  const {
    data: adminUserAnnualizedIncome,
    isLoading: isLoadingAnnualizedIncome,
  } = getAdminUserAnnualizedIncome(user.id);

  useEffect(() => {
    setShowAnnualizedIncome(false);
  }, [user]);

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Calculated Annualized Income</Heading.H4>
      These are calculated based on the user's pay frequency and net pay
      amounts. Use this information to determine a reasonable income override.
      {showAnnualizedIncome && (
        <>
          <Styled.Info>
            <Styled.InfoSection>
              <Styled.InfoItem
                label="Annualized Pay with Minimum Net Pay and Ratio"
                value={
                  formatCurrency(
                    (adminUserAnnualizedIncome.annualizedPayWithMin || 0) /
                      100.0,
                    true
                  ) || "n/a"
                }
              />
              <Styled.InfoItem
                label="Annualized Pay with Maximum Net Pay and Ratio"
                value={
                  formatCurrency(
                    (adminUserAnnualizedIncome.annualizedPayWithMax || 0) /
                      100.0,
                    true
                  ) || "n/a"
                }
              />
              <Styled.InfoItem
                label="Annualized Pay with Average Net Pay and Ratio"
                value={
                  formatCurrency(
                    (adminUserAnnualizedIncome.annualizedPayWithAvg || 0) /
                      100.0,
                    true
                  ) || "n/a"
                }
              />
            </Styled.InfoSection>
          </Styled.Info>

          <Styled.Info>
            <Styled.InfoSection>
              <Styled.InfoItem
                label="Sorted Net Pay Amounts"
                value={adminUserAnnualizedIncome.sortedNetPayAmounts.map(
                  (amount) => (
                    <UILabel.Medium>
                      {formatCurrency(amount / 100, true)}
                    </UILabel.Medium>
                  )
                )}
              />
            </Styled.InfoSection>
            <Styled.InfoSection>
              <Styled.InfoItem
                label="Sorted Net Pay Ratios"
                value={adminUserAnnualizedIncome.sortedNetPayRatios.map(
                  (amount) => (
                    <UILabel.Medium>{amount}</UILabel.Medium>
                  )
                )}
              />
            </Styled.InfoSection>
          </Styled.Info>
        </>
      )}
      {!showAnnualizedIncome && (
        <Styled.ButtonWrapper>
          <Button
            isLoading={isLoadingAnnualizedIncome}
            onClick={() => setShowAnnualizedIncome(true)}
          >
            Show Annualized Income
          </Button>
        </Styled.ButtonWrapper>
      )}
    </Styled.SectionTile>
  );
};

export default AnnualizedIncome;
