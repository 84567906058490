import React from "react";
import { BodySmall } from "components/ui/Text";
import * as Styled from "./styled";

const Footnotes: React.FC<{ footnotes: React.ReactNode[] }> = ({
  footnotes,
}) => (
  <Styled.Wrapper>
    {footnotes.map((footnote, index) => (
      <BodySmall key={index}>{footnote}</BodySmall>
    ))}
  </Styled.Wrapper>
);

export default Footnotes;
