import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";
import LoadingDots from "components/ui/LoadingDots";
import { Input } from "components/form";
import Icon from "components/ui/Icon";
import { SearchInputSelection } from "types/general";
import * as Styled from "./styled";

interface SearchResult {
  name: string;
  id: string;
  data?: any;
}

export interface SearchInputProps {
  initialValue?: SearchInputSelection;
  onSelect?: (item: SearchResult, value: string) => void;
  getSearch: (str: string) => Promise<SearchResult[]>;
  placeholder?: string;
  name?: string;
  errorMessage?: string;
  hasError?: boolean;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  required?: boolean;
  transformSearchString?: (str: string) => string;
}

const SearchInput = (
  {
    initialValue,
    onSelect,
    getSearch,
    name = "search",
    required,
    placeholder = "Start typing to search",
    errorMessage = "Please select an item from the list",
    hasError,
    setError = () => null,
    clearErrors = () => null,
    transformSearchString = (str) => str,
  }: SearchInputProps,
  ref: React.RefObject<HTMLInputElement>
) => {
  const [search, setSearch] = useState<SearchResult[] | null>(null);
  const [value, setValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInitial, setIsLoadingInitial] = useState(false);
  const [selection, setSelection] = useState<SearchResult | null>(null);

  const dropdownRef = useRef(null);

  function updateError(e) {
    if (e) {
      setError(name, { type: "custom", message: errorMessage });
    } else {
      clearErrors(name);
    }
  }

  // search

  const updateSearch = async (userInput) => {
    setSearch([]);
    setIsLoading(true);
    const searchString = transformSearchString(userInput.trim());
    if (searchString.length >= 2) {
      const data = await getSearch(searchString);
      setSearch(data);
    } else {
      setSearch(null);
    }
    setIsLoading(false);
  };

  const debouncedSearch = useCallback(debounce(updateSearch, 300), []);

  // dropdown listeners

  function onSelectItem(e, item) {
    e?.target.focus();
    e?.target.blur();

    setSelection(item);
    updateError(required && !item);

    if (item) {
      setValue(item.name);
    }
    if (onSelect) {
      onSelect(item, value);
    }
  }

  function onKeyDownDropdown(e, index) {
    let el;
    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        if (index === 0) {
          el = ref.current;
        } else {
          el = e.target.parentNode.previousSibling.querySelector("button");
        }
        break;
      case "ArrowDown":
        e.preventDefault();
        if (index < search.length - 1) {
          el = e.target.parentNode.nextSibling.querySelector("button");
        }
        break;
      default:
    }
    if (el) el.focus();
  }

  // setup

  const setInitial = async ({ searchString, id }) => {
    setValue(searchString);
    setIsLoadingInitial(true);
    const results = await getSearch(initialValue.searchString);
    const resultById = results.find((result) => result.id === id);
    if (resultById) {
      onSelectItem(null, resultById);
    }
    setIsLoadingInitial(false);
  };

  useEffect(() => {
    if (initialValue) {
      setInitial(initialValue);
    }
  }, [initialValue]);

  // input listeners

  function onChangeInput(e) {
    const v = e.target.value;
    setValue(v);
    debouncedSearch(v);
  }

  function onFocusInput(e) {
    if (!search) {
      updateSearch(e.target.value);
    }
  }

  function onKeyDownInput(e) {
    switch (e.key) {
      case "Tab":
        break;
      default:
    }
  }

  function onKeyUpInput(e) {
    let el;
    if (search) {
      switch (e.key) {
        case "Escape":
          setSearch(null);
          break;
        case "ArrowDown":
          el = dropdownRef.current?.querySelector("button");
          break;
        default:
      }
    }
    if (el) el.focus();
  }

  function onBlurInput() {
    updateError(required && !selection);
  }

  const hasTag = !!selection || isLoadingInitial;

  return (
    <Styled.InputWrapper
      $hasTag={hasTag}
      $isLoadingInitial={isLoadingInitial}
      className="notranslate"
    >
      <Input
        name={name}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        onKeyUp={onKeyUpInput}
        onKeyDown={onKeyDownInput}
        onFocus={onFocusInput}
        placeholder={placeholder}
        value={value}
        autoComplete="off"
        hasError={hasError}
        ref={ref}
      />

      <Styled.SearchIcon>
        <Icon name="search" size="sm" />
      </Styled.SearchIcon>

      {hasTag && (
        <Styled.Tag>
          {selection?.name || <>&hellip;</>}
          <Icon name="close" size="xs" />
        </Styled.Tag>
      )}

      {search && (
        <Styled.Dropdown ref={dropdownRef}>
          {isLoading ? (
            <Styled.Loading>
              <LoadingDots />
            </Styled.Loading>
          ) : (
            search.map((item, index) => (
              <Styled.DropdownItem key={item.id}>
                <button
                  type="button"
                  onClick={(e) => onSelectItem(e, item)}
                  onMouseDown={(e) => e.preventDefault()}
                  onKeyDown={(e) => onKeyDownDropdown(e, index)}
                >
                  {item.name}
                </button>
              </Styled.DropdownItem>
            ))
          )}
        </Styled.Dropdown>
      )}
    </Styled.InputWrapper>
  );
};

export default React.forwardRef(SearchInput);
