import styled, { css } from "styled-components";
import * as Text from "components/ui/Text";
import {
  colors,
  fontSizes,
  fontSizesMobile,
  fontWeights,
  mediaMax,
  mediaMin,
} from "utils/theme";
import { ImageOrientation } from "types/cms";

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.div`
  position: relative;

  padding-bottom: 100%;
  width: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h2,
  h3 {
    sup {
      font-size: ${fontSizes.xs}px;

      ${mediaMax("lg")} {
        font-size: ${fontSizesMobile.xs}px;
      }
    }
  }
`;

export const Body = styled(Text.Body)`
  strong,
  em,
  b,
  i {
    color: ${colors.midnight};

    font-weight: ${fontWeights.bold};
  }
`;

export const HeaderContent = styled.div<{
  $imageOrientation: ImageOrientation;
}>`
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;
  margin: 0 auto;
  align-items: center;

  sup {
    color: ${colors.midnight}80;

    line-height: 1;
    font-size: 0.66em;
  }

  --image-padding: 0;

  ${mediaMin("lg")} {
    --image-padding: 24px;
  }

  ${mediaMin("xl")} {
    --image-padding: 36px;
  }

  ${(props) =>
    props.$imageOrientation === "right"
      ? css`
          ${ImageWrapper} {
            padding-left: var(--image-padding);
          }

          ${mediaMin("lg")} {
            ${HeaderText} {
              order: -1;
            }
          }
        `
      : css`
          ${ImageWrapper} {
            padding-right: var(--image-padding);
          }
        `}
`;

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  ${mediaMax("lg")} {
    ${HeaderContent} {
      display: flex;
      flex-direction: column;
      gap: 36px;

      text-align: center;
    }

    ${ImageWrapper} {
      padding: 0;
      width: 100%;
    }

    ${Image} {
      padding-bottom: 66%;

      img {
        width: 100%;
        height: 100%;

        border-radius: 8px;
      }
    }
  }
`;
