import styled, { css } from "styled-components";
import {
  borderRadius,
  colors,
  fontSizesUI,
  fontWeights,
  mediaMax,
  shadows,
} from "utils/theme";

const Table = styled.table<{ $noCollapse?: boolean }>`
  width: 100%;

  border-collapse: collapse;

  tr {
    &:nth-child(odd) {
      td {
        background: ${colors.ice};
      }
    }
  }

  td,
  th {
    padding: 12px;

    * {
      width: fit-content;
    }

    font-size: ${fontSizesUI.md}px;
  }

  th {
    background: ${colors.midnight};
    color: white;

    font-weight: ${fontWeights.bold};

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  td {
    a {
      color: ${colors.azure};

      font-weight: ${fontWeights.bold};
      text-decoration: underline;
    }
  }

  ${(props) =>
    !props.$noCollapse &&
    css`
      ${mediaMax("lg")} {
        display: block;

        thead {
          display: none;
        }

        tbody {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
        }

        tr {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 24px;

          background: white;
          border-radius: ${borderRadius.small}px;
          box-shadow: ${shadows.tile};

          &:nth-child(odd) {
            td {
              background: transparent;
            }
          }
        }

        td {
          padding: 0;

          font-size: ${fontSizesUI.xl}px;

          &:empty {
            display: none;
          }

          &::before {
            content: attr(data-heading);

            display: block;
            margin-bottom: 2px;

            font-size: ${fontSizesUI.xs}px;
            letter-spacing: 0.11em;
            text-transform: uppercase;
          }
        }
      }

      ${mediaMax("md")} {
        tbody {
          grid-template-columns: 1fr;
        }
      }
    `}
`;

export default Table;
