import DisappearingTile from "components/ui/DisappearingTile";
import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const LineItemsWrapper = styled(DisappearingTile).attrs(() => ({
  size: "xl",
}))`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  ${mediaMax("md")} {
    width: 100%;
    overflow: visible;

    box-shadow: none;
    background: transparent;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;
