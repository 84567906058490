import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Tile from "components/cms/components/block/Tile";
import { FEATURES } from "components/views/Home/constants";
import * as Styled from "./styled";

const FeaturesSection = () => (
  <Styled.Wrapper>
    <ContentLayout>
      <Tile {...FEATURES} />
    </ContentLayout>
  </Styled.Wrapper>
);

export default FeaturesSection;
