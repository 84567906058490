import React from "react";
import FormContainer from "components/form";
import { BudgetResults, BudgetStep, IncomeFrequency } from "./types";
import Intro from "./Intro";
import Questionnaire from "./Questionnaire";
import Signup from "./Signup";
import Results from "./Results";

interface Props {
  currentStep: BudgetStep;
  budgetResults: BudgetResults | null;
  getStarted: () => void;
  onSubmitQuestionnaire: (values: any) => void;
  onSubmitSignup: (values: any) => void;
  postBudgetResults: any; // TODO: type this
}

const Step: React.FC<Props> = ({
  currentStep,
  budgetResults,
  getStarted,
  onSubmitQuestionnaire,
  onSubmitSignup,
  postBudgetResults,
}) => {
  switch (currentStep) {
    case BudgetStep.Signup:
      return (
        <FormContainer onSubmit={onSubmitSignup}>
          <Signup postBudgetResults={postBudgetResults} />
        </FormContainer>
      );
    case BudgetStep.Results:
      return budgetResults ? <Results {...budgetResults} /> : null;
    case BudgetStep.Questionnaire:
      return (
        <FormContainer
          onSubmit={onSubmitQuestionnaire}
          defaultValues={{
            incomeFrequency: IncomeFrequency.monthly,
            isValid: [true, true],
          }}
        >
          <Questionnaire />
        </FormContainer>
      );
    default:
      return <Intro getStarted={getStarted} />;
  }
};

export default Step;
