import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icon, { IconName } from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${colors.violet};
`;

const Progress = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: 12px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 9999px;
  background: ${colors.heliotrope};
  opacity: 0.5;
`;

const Completed = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  width: 12px;
  height: 12px;

  border-radius: 9999px;
  background: ${colors.heliotrope};
`;

interface Props {
  icon: IconName;
  title: string;
  stepCount: number;
  currentStep: number;
}

const SurveyProgressHeader: React.FC<Props> = ({
  icon,
  title,
  stepCount,
  currentStep,
}) => (
  <Wrapper>
    <Title>
      <Icon name={icon} size="md" />

      <UILabel.Small bold wideCaps>
        {title}
      </UILabel.Small>
    </Title>

    <Progress>
      {new Array(stepCount).fill(0).map((_, stepIndex) => (
        <Dot key={stepIndex} />
      ))}

      <Completed
        animate={{
          width: 12 * ((currentStep + 1) * 2 - 1),
        }}
        initial={{ width: 12 }}
        transition={{
          duration: 0.15,
          ease: [0.25, 1, 0.5, 1],
        }}
      />
    </Progress>
  </Wrapper>
);

export default SurveyProgressHeader;
