import React from "react";
import { Heading, Body, BodyLarge } from "components/ui/Text";
import { Cta } from "types/cms";
import * as Styled from "./styled";
import CtaButton from "../CtaButton";

interface Props {
  eyebrow?: string;
  heading?: React.ReactNode;
  body?: React.ReactNode;
  cta?: Cta;
  isCentered?: boolean;
  isHero?: boolean;
  textSize?: "large" | "small";
  isLight?: boolean;
}

const Header: React.FC<Props> = ({
  heading,
  eyebrow,
  body,
  cta,
  isCentered,
  isHero,
  textSize = "small",
  isLight,
  ...props
}) => {
  if (!(heading || eyebrow || body || cta)) return null;

  const HeadingText = textSize === "large" ? Heading.H1 : Heading.H2;
  const BodyText = textSize === "large" ? BodyLarge : Body;

  return (
    <Styled.Wrapper $isCentered={isCentered} $isLight={isLight} {...props}>
      {eyebrow && <Styled.Eyebrow>{eyebrow}</Styled.Eyebrow>}
      {heading && (
        <HeadingText tag={isHero ? "h1" : "h2"}>{heading}</HeadingText>
      )}
      {body && (
        <Styled.BodyWrapper>
          <BodyText tag="div">{body}</BodyText>
        </Styled.BodyWrapper>
      )}
      {cta && (
        <Styled.CtaWrapper>
          <CtaButton {...cta} />
        </Styled.CtaWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default Header;
