import React from "react";
import styled, { css } from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { Eyebrow as BaseEyebrow } from "components/ui/Text";
import motleyFoolLogo from "assets/images/publications/motley-fool.svg";
import axiosLogo from "assets/images/publications/axios.svg";
import americanBankerLogo from "assets/images/publications/american-banker.svg";
import motleyFoolLogoReverse from "assets/images/publications/motley-fool-reverse.svg";
import axiosLogoReverse from "assets/images/publications/axios-reverse.svg";
import americanBankerLogoReverse from "assets/images/publications/american-banker-reverse.svg";
import { mediaMax } from "utils/theme";

const getLogos = (reverse?: boolean) => [
  {
    logo: reverse ? americanBankerLogoReverse : americanBankerLogo,
    title: "American Banker",
    href: "https://www.americanbanker.com/news/how-a-minnesota-bank-is-jumping-into-the-earned-wage-access-market",
    height: 40,
  },
  {
    logo: reverse ? axiosLogoReverse : axiosLogo,
    title: "Axios",
    href: "https://www.axios.com/pro/fintech-deals/2024/02/29/earned-wage-access-reset-2-million",
    height: 44,
  },
  {
    logo: reverse ? motleyFoolLogoReverse : motleyFoolLogo,
    title: "The Motley Fool",
    href: "https://www.fool.com/the-ascent/credit-cards/articles/this-unique-fintech-could-help-working-families-improve-their-personal-finances/",
    height: 76,
  },
];

const Eyebrow = styled(BaseEyebrow)`
  opacity: 0.7;
  color: currentColor;
`;

const Wrapper = styled.div<{ $reverse?: boolean }>`
  ${(props) =>
    props.$reverse &&
    css`
      color: white;
    `}

  ${Eyebrow} {
  }
`;

const Inner = styled(ContentLayout)`
  display: flex;
  align-items: center;
  gap: 96px;

  * {
    flex: 0 0 auto;
  }

  ${mediaMax("xl")} {
    gap: 64px;
  }

  ${mediaMax("lg")} {
    flex-direction: column;
    gap: 36px;
  }
`;

const Logos = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaMax("md")} {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px 36px;
  }
`;

const LogoImage = styled.img<{ $height: number }>`
  ${(props) => css`
    height: ${props.$height}px;

    ${mediaMax("lg")} {
      height: ${props.$height / 1.5}px;
    }
  `}
`;

interface Props {
  reverse?: boolean;
}

const Publications: React.FC<Props> = ({ reverse }) => {
  const logos = getLogos(reverse);

  return (
    <Wrapper $reverse={reverse}>
      <Inner>
        <Eyebrow>As seen in</Eyebrow>
        <Logos>
          {logos.map((logo) => (
            <a
              key={logo.title}
              href={logo.href}
              target="_blank"
              rel="noopener"
              data-turbo="false"
            >
              <LogoImage
                src={logo.logo}
                alt={logo.title}
                $height={logo.height}
              />
            </a>
          ))}
        </Logos>
      </Inner>
    </Wrapper>
  );
};

export default Publications;
