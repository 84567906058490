const cleanupQueryString = (paramsToRemove) => {
  const params = new URLSearchParams(window.location.search);
  paramsToRemove.forEach((name) => {
    params.delete(name);
  });
  const { hash } = window.location;
  const queryString = params.toString();
  let cleanedUrl = window.location.pathname;
  if (queryString) {
    cleanedUrl += `?${queryString}`;
  }
  cleanedUrl += hash;
  window.history.replaceState({}, "", cleanedUrl);
};

export default cleanupQueryString;
