import React from "react";
import { useSearchParams } from "react-router-dom";
import { getAdminFinancialHealthSurveyResults } from "store/admin/api/apiSlice";
import Loading from "components/ui/Loading";
import Pagination from "components/ui/Pagination";
import { ApiAdminFinancialHealthSurveyResult } from "utils/api/admin/financialHealth";
import { formatDateString } from "utils/date";
import { SURVEY_SCORE } from "components/shared/FinancialHealthSurvey/constants";
import Table from "../../components/Table";
import * as Styled from "./styled";

const HEADINGS = ["Name", "Email", "Score", "Label", "Created At"];

const getScoreFromSubscores = (subscores: number[]) =>
  Math.round(
    subscores.reduce((sum, score) => sum + score, 0) / subscores.length
  );

const getLabelFromScore = (score: number) => {
  if (score >= SURVEY_SCORE.healthy) return "Healthy";
  if (score >= SURVEY_SCORE.coping) return "Coping";
  return "Vulnerable";
};

const getRow = (result: ApiAdminFinancialHealthSurveyResult) => {
  const score = getScoreFromSubscores(result.subscores);
  const label = getLabelFromScore(score);

  return [
    result.name,
    result.email,
    score,
    <Styled.StatusTag $label={label}>{label}</Styled.StatusTag>,
    formatDateString(result.createdAt, "numericShortWithTime"),
  ];
};

const FinancialHealthSurvey = () => {
  const [params] = useSearchParams();
  const page = parseInt(params.get("page"), 10) || 1;
  const { data, isLoading } = getAdminFinancialHealthSurveyResults(page);

  if (isLoading) <Loading />;

  const { surveyResults, totalPages } = data || {};

  if (!surveyResults) return null;

  return (
    <Styled.Wrapper>
      <Table>
        <thead>
          <tr>
            {HEADINGS.map((heading) => (
              <th key={heading}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {surveyResults.map((result) => (
            <tr key={result.id}>
              {getRow(result).map((cell, index) => (
                <td key={index} data-heading={HEADINGS[index]}>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      {totalPages > 1 && <Pagination current={page} total={totalPages} />}
    </Styled.Wrapper>
  );
};

export default FinancialHealthSurvey;
