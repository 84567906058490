import { TagLevel as MenuItemLevel } from "components/ui/Tag";
import { IconName } from "components/ui/Icon";

export enum MenuItemType {
  link = "LINK",
  routerLink = "ROUTER_LINK",
  button = "BUTTON",
}

export { MenuItemLevel };

interface BaseProps {
  children: React.ReactNode;
  icon?: IconName;
  isDisabled?: boolean;
  tag?: {
    level: MenuItemLevel;
    text: string;
  };
  isCta?: boolean;
}

interface LinkProps extends BaseProps {
  type: MenuItemType.link;
  data: {
    href: string;
    title?: string;
    disabled?: boolean;
  };
}

interface RouterLinkProps extends BaseProps {
  type: MenuItemType.routerLink;
  data: {
    to: string;
    title?: string;
    disabled?: boolean;
  };
}

interface ButtonProps extends BaseProps {
  type: MenuItemType.button;
  data: {
    onClick: () => void;
    disabled?: boolean;
  };
}

export type MenuItemProps = LinkProps | ButtonProps | RouterLinkProps;

export interface MenuSection {
  title?: string;
  items: MenuItemProps[];
}
