import { OnboardingTask, OnboardingTaskName } from "types/onboarding";
import { FormTask, FormTaskStatus } from "types/task";
import { DEFAULT_TASKS, TASK_MAP } from "utils/constants/onboarding";

export const mapTasksToSteps = (cardApplicationTasks: OnboardingTask[]) => {
  const cardApplicationStatus = cardApplicationTasks.reduce(
    (acc, task) => ({
      ...acc,
      [task.name]: task.state,
    }),
    {}
  );

  const onboardingSteps = DEFAULT_TASKS.map((task) => {
    let status = FormTaskStatus.complete;
    if (
      task.name === OnboardingTaskName.identityVerification &&
      [
        cardApplicationStatus[OnboardingTaskName.termsAcceptance],
        cardApplicationStatus[OnboardingTaskName.identityVerification],
      ].includes(FormTaskStatus.ready)
    ) {
      // In the UI terms acceptance and IDV will appear as one step
      // Repayment won't appear in the sidebar at all
      status = FormTaskStatus.ready;
    }

    return {
      id: task.name,
      status,
      ...TASK_MAP[task.name],
    };
  }) as FormTask[];

  return onboardingSteps;
};
