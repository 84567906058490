import React, { useState } from "react";
import { usePostInternalMessageMutation } from "store/api/apiSlice";
import UILayout from "components/layout/UILayout";
import Notification, { NotificationLevel } from "components/ui/Notification";
import FormContainer from "components/form";
import { capitalize } from "utils/string";
import { CardAction } from "./types";
import Form from "./Form";
import { Wrapper } from "../styled";

const getCardType = (action: CardAction) => {
  switch (action) {
    case CardAction.replace:
      return "replacement";
    case CardAction.reissue:
      return "new";
    default:
      return action;
  }
};

const getCardReason = (action: CardAction) => {
  switch (action) {
    case CardAction.replace:
      return "lost or stolen";
    case CardAction.reissue:
      return "damaged";
    default:
      return action;
  }
};

const ReplaceCard = () => {
  const [action, setAction] = useState<CardAction | null>(null);
  const [postInternalMessage] = usePostInternalMessageMutation();

  const onSubmit = async (values) => {
    await postInternalMessage({
      type: `${capitalize(values.action)} Reset Card`,
      body: `My card is ${getCardReason(values.action)}. Please ${
        values.action
      } my card.`,
    });

    setAction(values.action);
  };

  return (
    <UILayout>
      <Wrapper>
        {action ? (
          <Notification level={NotificationLevel.success}>
            We received your request. You {getCardType(action)} card should
            arrive in 10-14 business days.
          </Notification>
        ) : (
          <FormContainer onSubmit={onSubmit} defaultValues={{ action: null }}>
            <Form />
          </FormContainer>
        )}
      </Wrapper>
    </UILayout>
  );
};

export default ReplaceCard;
