import React from "react";
import { Heading, Body } from "components/ui/Text";
import LoadingDots from "components/ui/LoadingDots";
import * as Styled from "./styled";

const VerificationMessage: React.FC = () => (
  <Styled.Tile>
    <Styled.Header>
      <Heading.H2 tag="h1">Please wait.</Heading.H2>

      <Body>Your account is being set up.</Body>
    </Styled.Header>

    <LoadingDots />
  </Styled.Tile>
);

export default VerificationMessage;
