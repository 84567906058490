import styled from "styled-components";
import { ButtonLink } from "components/ui/Button";
import { colors, mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  position: relative;

  padding: 48px 0 120px;

  * {
    color: white;
  }

  ${mediaMax("lg")} {
    padding: 0 0 80px;

    background: linear-gradient(
      to bottom,
      ${colors.azure},
      ${colors.blackthorn}
    );
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  * {
    color: ${colors.ice};
  }
`;

export const IncomeTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const IncomeTypeList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  gap: 8px;

  list-style-type: none;

  ${mediaMax("lg")} {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  ${mediaMax("md")} {
    max-width: 260px;
    margin: 0 auto;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const IncomeTypeItem = styled.li`
  padding: 0 !important;

  &::before {
    content: none !important;
  }
`;

export const IncomeTypeLink = styled(ButtonLink)`
  position: relative;

  padding: 0 24px 0 22px;

  background: ${colors.flax};

  &:hover {
    background: white;
  }

  ${mediaMax("lg")} {
    height: auto;
    padding-bottom: 100%;

    border-radius: 8px;
  }
`;

export const IncomeTypeLinkContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  * {
    color: ${colors.violet} !important;
  }

  ${mediaMax("lg")} {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 60px 60px;
    justify-items: center;
    gap: 6px;

    font-size: 14px;

    svg {
      align-self: flex-end;
      height: 32px;
    }
  }
`;
