import React from "react";
import styled, { css } from "styled-components";
import {
  Link as BaseRouterLink,
  LinkProps as BaseRouterLinkProps,
} from "react-router-dom";
import { colors, fontSizesUI, fontWeights, fonts } from "utils/theme";

interface StyleProps {
  $size: "lg" | "md" | "sm";
  $color: string;
}

const linkStyles = css<StyleProps>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${(props) => (props.$size === "sm" ? 6 : 8)}px;
  width: fit-content;
  max-width: 100%;
  padding: 0;

  cursor: pointer;
  appearance: none;
  border: 0;
  background: none;
  color: ${(props) => colors[props.$color] || props.$color};

  font-family: ${fonts.body};
  font-weight: ${fontWeights.bold};
  font-size: ${(props) => fontSizesUI[props.$size]}px;
  text-decoration: underline;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:visited {
    color: ${(props) => colors[props.$color] || props.$color};
  }

  @media (hover: hover) {
    &:hover:not([disabled]) {
      color: ${colors.violet};
    }
  }
`;

const StyledLink = styled.a<StyleProps>`
  ${linkStyles};
`;

const StyledRouterLink = styled(BaseRouterLink)<StyleProps>`
  ${linkStyles};
`;

interface BaseProps {
  as?: React.ElementType;
  size?: "md" | "sm" | "lg";
  color?: string;
}

interface Props
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    BaseProps {}

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseProps {}

interface RouterLinkProps extends BaseRouterLinkProps, BaseProps {}

const Link: React.FC<Props> = ({
  children,
  size = "md",
  color = "midnight",
  ...props
}) => (
  <StyledLink $size={size} data-turbo="false" $color={color} {...props}>
    {children}
  </StyledLink>
);

export const LinkButton: React.FC<ButtonProps> = ({
  children,
  size = "md",
  disabled,
  type = "button",
  color = "midnight",
  ...props
}) => (
  <StyledLink
    as="button"
    $size={size}
    disabled={disabled}
    type={type}
    $color={color}
    {...props}
  >
    {children}
  </StyledLink>
);

export const RouterLink: React.FC<RouterLinkProps> = ({
  children,
  size = "md",
  color = "midnight",
  ...props
}) => (
  <StyledRouterLink $size={size} data-turbo="false" $color={color} {...props}>
    {children}
  </StyledRouterLink>
);

export default Link;
