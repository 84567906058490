import styled from "styled-components";
import ContentLayout from "components/layout/ContentLayout";
import { colors, mediaMax } from "utils/theme";

export const Wrapper = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 120px;
  overflow: hidden;
  padding: 120px 0 64px;
  margin-top: -1px;

  background: ${colors.ice};

  ${mediaMax("lg")} {
    padding: 80px 0 48px;
    gap: 80px;
  }
`;

export const Main = styled(ContentLayout)`
  display: flex;
  width: 100%;
  gap: 48px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    width: 320px;
    max-width: 100%;

    ${mediaMax("md")} {
      width: 100%;
    }
  }
`;

export const Footnotes = styled(ContentLayout)`
  position: relative;

  sup {
    margin-left: -0.9em;

    color: ${colors.midnight}80;
  }
`;
