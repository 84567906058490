import styled, { css } from "styled-components";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import { colors } from "utils/theme";
import UILabel from "components/ui/UILabel";
import BaseModal from "../Modal";

export const Modal = styled(BaseModal)`
  & > * {
    width: 480px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const LinkButtonWrapper = styled.div`
  padding: 0 8px;
`;

export const UserList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const UserButton = styled(Button).attrs(() => ({
  level: ButtonLevel.ghost,
  size: ButtonSize.md,
}))<{ $isActive?: boolean }>`
  ${(props) =>
    props.$isActive
      ? css`
          background: linear-gradient(
            75deg,
            ${colors.azure},
            ${colors.heliotrope}
          );
          border: none;
          color: white;
        `
      : css`
          border-color: ${colors.violet}40;
        `}
`;

export const ActionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

export const ActionButton = styled(Button).attrs(() => ({
  level: ButtonLevel.ghost,
  size: ButtonSize.md,
}))`
  border-color: ${colors.violet}40;
`;

export const ShortcutList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
  margin-top: 24px;
`;

export const Shortcut = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ShortcutKey = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px 4px;
  min-width: 1.5em;

  border-radius: 3px;
  background: ${colors.flax}80;
  color: ${colors.violet};

  font-size: 12px;
`;

export const ShortcutLabel = styled(UILabel.Small).attrs(() => ({
  color: "dust",
}))``;
