// Plaid Link
// https://plaid.com/docs/link/web/#installation
// TODO: remove Plaid/LinkController & CDN later on

export const getLinkToken = async () => {
  const res = await fetch("/api/v1/plaid/link_token", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const data = await res.json();

  return data;
};

export const exchangePublicTokenToAccessToken = async (public_token) => {
  const res = await fetch(
    `/api/v1/plaid/create_access_token?public_token=${public_token}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const data = await res.json();

  return data;
};

export interface ApiBankInstitutions {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

export const searchInstitutions = async (str: string) => {
  const res = await fetch(`/api/v1/plaid/search_institutions?name=${str}`, {
    credentials: "include",
  });
  const data = await res.json();
  const institutions = data.institutions.map((item) => ({
    name: item.name,
    id: item.id,
    data: {
      products: item.products.split(", "),
    },
  }));

  return [
    ...institutions,
    { name: "Other", id: "other", data: { products: [] } },
  ] as Array<ApiBankInstitutions>;
};
