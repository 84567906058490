import React, { useRef } from "react";
import DslZeroModal from "components/modal/DslZeroModal";
import { ModalApi } from "components/modal/Modal";
import ResetCard from "components/shared/ResetCard";
import useMarqeta from "utils/hooks/useMarqeta";

interface Props {
  amount: number;
  nextPayday?: string;
}

const Card: React.FC<Props> = ({ amount, nextPayday }) => {
  const dslZeroModal = useRef<ModalApi>(null);
  const { hide, show, isLoading, isShown, ids } = useMarqeta({});

  return (
    <>
      <ResetCard
        amount={amount}
        nextPayday={nextPayday}
        isLoadingDetails={isLoading}
        isShownDetails={isShown}
        hideDetails={hide}
        showDetails={show}
        cardElementIds={ids}
        showDslZeroModal={dslZeroModal.current?.show}
      />

      <DslZeroModal ref={dslZeroModal} />
    </>
  );
};

export default Card;
