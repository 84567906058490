import React from "react";
import styled from "styled-components";
import { colors } from "utils/theme";

const Path = styled.path`
  fill: ${colors.ice};
`;

const HeaderDivider = (props) => (
  <svg viewBox="0 0 2244 634" {...props}>
    <Path d="M1600.5,560.2c-430.3,0-625.4-128-1007.3-128S0.2,602.4,0.2,602.4v243.3H2244V422.3C2244,422.3,2030.8,560.2,1600.5,560.2z" />
  </svg>
);

export default HeaderDivider;
