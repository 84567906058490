import styled, { css } from "styled-components";
import { colors } from "utils/theme";
import UILabel from "../UILabel";
import { TimelineEventStatus, TimelineEventStepStatus } from "./types";

export const Wrapper = styled.ol`
  margin: 0;
  padding: 0 16px 0 0;

  list-style-type: none;
`;

const getEventStepLineColor = (status?: TimelineEventStepStatus) =>
  status === TimelineEventStepStatus.incomplete ? colors.sky : colors.azure;

export const EventMain = styled.div<{
  $hasSteps?: boolean;
  $stepStatus?: TimelineEventStepStatus;
}>`
  position: relative;

  display: flex;
  gap: 12px;

  padding-bottom: 24px;

  ${(props) =>
    props.$hasSteps &&
    css`
      &::before {
        content: "";

        position: absolute;
        left: 9px;
        top: 10px;

        background: ${getEventStepLineColor(props.$stepStatus)};

        width: 2px;
        height: 100%;
      }
    `}
`;

export const EventNode = styled.div`
  position: relative;
  z-index: 1;

  width: 20px;
  height: 20px;
  flex: 0 0 auto;

  border-radius: 9999px;
  box-shadow: 0 0 0 6px var(--point-color) inset;
  background: white;
`;

export const EventText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;
`;

export const EventDate = styled(UILabel.Small)`
  padding-bottom: 2px;
`;

export const EventDescription = styled(UILabel.Medium)`
  &:empty {
    display: none;
  }
`;

export const EventConnector = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translateX(-50%);

  height: 100%;

  svg {
    height: 100%;
    width: 6px;

    line {
      stroke: var(--line-color);
    }
  }
`;

export const EventSteps = styled.ol`
  margin: 0;
  padding: 0 0 0 32px;

  list-style-type: none;
`;

export const EventStep = styled.li<{
  $status: TimelineEventStepStatus;
  $nextStatus?: TimelineEventStepStatus;
}>`
  position: relative;

  padding-bottom: 24px;

  &::before,
  &::after {
    content: "";

    position: absolute;
    left: -23px;
    top: 10px;
  }

  &::before {
    width: 2px;
    height: 100%;

    background: ${(props) => getEventStepLineColor(props.$nextStatus)};
  }

  &::after {
    width: 18px;
    height: 2px;

    background: ${(props) => getEventStepLineColor(props.$status)};
  }

  &:last-child {
    &::before {
      content: none;
    }
  }
`;

export const EventStepMain = styled.div`
  display: flex;
  gap: 8px;
`;

export const EventStepNode = styled.div`
  width: 20px;
  height: 20px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
`;

export const EventStepText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 2px;
  padding: 2px 0;
`;

export const EventStepDetail = styled.div`
  flex: 0 0 auto;
  padding: 2px 0;
`;

const getNodeColor = ({ $status }: { $status: TimelineEventStatus }) => {
  if (
    [TimelineEventStatus.future, TimelineEventStatus.next].includes($status)
  ) {
    return colors.sky;
  }
  return colors.azure;
};

const getLineColor = ({ $status }: { $status: TimelineEventStatus }) => {
  if (
    [TimelineEventStatus.current, TimelineEventStatus.past].includes($status)
  ) {
    return colors.azure;
  }
  return colors.sky;
};

export const Event = styled.li<{ $status: TimelineEventStatus }>`
  position: relative;

  --point-color: ${getNodeColor};
  --line-color: ${getLineColor};

  ${(props) =>
    props.$status === TimelineEventStatus.next &&
    css`
      ${EventNode} {
        box-shadow:
          0 0 0 2px ${colors.azure} inset,
          0 0 0 6px var(--point-color) inset;
      }
    `}
`;
