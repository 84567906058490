import React from "react";
import { Heading } from "components/ui/Text";
import {
  MaskedField,
  MaskType,
  FormSection,
  AddressField,
  HelpMessage,
  SubmitButton,
  Input,
  Field,
} from "components/form";
import { LinkButton } from "components/ui/Link";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import ToggleBlock from "components/cms/components/block/Toggle";
import { BlockType } from "types/cms";
import { PARTNERS } from "utils/partners/constants";
import { ApiUserProfile } from "utils/api/user";
import * as Styled from "../../styled";
import { InfoType } from "../../types";

interface Props {
  user: ApiUserProfile | undefined;
  showInfo: (infoType: InfoType) => () => void;
  showName: boolean;
  isLoading: boolean;
}

const Form: React.FC<Props> = ({ user, showInfo, showName, isLoading }) => {
  const partnerFaqs = PARTNERS[user?.partnerSlug]?.faqs?.identityVerification;

  return (
    <>
      <Styled.Header>
        <Heading.H2 tag="h1">Next we'll verify your&nbsp;identity.</Heading.H2>

        {partnerFaqs && (
          <ToggleBlock
            type={BlockType.toggle}
            id="partner-faq"
            data={{
              items: partnerFaqs,
            }}
          />
        )}
      </Styled.Header>

      {showName && (
        <FormSection>
          <Field label={<Styled.Label>Your name</Styled.Label>} name="name">
            <Input
              id="name"
              name="name"
              value={`${user?.firstName} ${user?.lastName}`}
              disabled
            />
          </Field>

          <HelpMessage>
            To update your name, please{" "}
            <a href="mailto:hello@getreset.co?subject=Update my name">
              contact us
            </a>
            .
          </HelpMessage>
        </FormSection>
      )}

      <FormSection>
        <MaskedField
          maskType={MaskType.phone}
          placeholder="(123) 456-7890"
          name="phoneNumber"
          label={<Styled.Label>What's your phone number?</Styled.Label>}
          required
        />

        <LinkButton onClick={showInfo(InfoType.phone)} color="azure">
          I don't have a US phone number
        </LinkButton>
      </FormSection>

      <FormSection>
        <MaskedField
          maskType={MaskType.date}
          placeholder="mm/dd/yyyy"
          name="dateOfBirth"
          label={<Styled.Label>What's your date of birth?</Styled.Label>}
          required
        />

        <HelpMessage>
          Reset cardholders must be 18 years of age or older.
        </HelpMessage>
      </FormSection>

      <FormSection>
        <AddressField
          name="address"
          label={<Styled.Label>What's your home address?</Styled.Label>}
          required
        />

        <HelpMessage>
          We need this to mail you your Reset Card. Residential addresses only,
          no PO boxes.
        </HelpMessage>

        <LinkButton onClick={showInfo(InfoType.address)} color="azure">
          I don't have a US home address
        </LinkButton>
      </FormSection>

      <FormSection>
        <MaskedField
          maskType={MaskType.ssn}
          placeholder="012-34-5678"
          name="ssn"
          label={
            <Styled.Label>What's your social security number?</Styled.Label>
          }
          required
        />

        <HelpMessage>We need this to verify your identity.</HelpMessage>
      </FormSection>

      <MobileFixedFooter>
        <SubmitButton
          isLoading={isLoading}
          name="confirmed"
          value={false}
          fullWidth
        >
          Continue
        </SubmitButton>
      </MobileFixedFooter>
    </>
  );
};

export default Form;
