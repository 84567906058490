import Navigation from "components/ui/Navigation";
import React from "react";
import { useMatches } from "react-router-dom";
import {
  getUserProfile,
  usePostAdminUserStopImpersonatingMutation,
} from "store/api/apiSlice";

const AppNavigation = () => {
  const matches = useMatches();
  const { data: userProfile } = getUserProfile();
  const [postStopImpersonatingUser] =
    usePostAdminUserStopImpersonatingMutation();

  const stopImpersonatingUser = async () => {
    await postStopImpersonatingUser();
    window.location.replace(`/admin/users/${userProfile.id}`);
  };

  if (!userProfile) return null;

  return (
    <Navigation
      userProfile={userProfile}
      matches={matches}
      stopImpersonatingUser={stopImpersonatingUser}
    />
  );
};

export default AppNavigation;
