import React from "react";
import { formatCurrency } from "utils/string";
import successIcon from "assets/images/icon/success.svg";
import warningIcon from "assets/images/icon/warning.svg";
import Icon from "components/ui/Icon";
import * as Styled from "./styled";

type Status = "ok" | "warning";

const getStatusIconSrc = (status: Status) => {
  switch (status) {
    case "ok":
      return successIcon;
    case "warning":
      return warningIcon;
    default:
      return null;
  }
};

interface Props {
  title: string;
  amount: number;
  isDebit?: boolean;
  status: Status;
  href?: string;
}

const SubItem: React.FC<Props> = ({
  title,
  amount = 0,
  isDebit,
  status,
  href,
}) => {
  const showAsDebit = (isDebit && amount >= 0) || (!isDebit && amount < 0);

  return (
    <Styled.Wrapper $isInteractive={!!href}>
      {href && (
        <Styled.LineItemLink to={href} data-turbo="false"></Styled.LineItemLink>
      )}

      <Styled.IconWrapper $isDebit={isDebit}>
        <img src={getStatusIconSrc(status)} />
      </Styled.IconWrapper>

      <Styled.Main>
        <Styled.Left>
          <Styled.Title>
            <span>{title}</span>

            {href && (
              <Styled.TitleIcon>
                <Icon name="arrowRight" size="xs" />
              </Styled.TitleIcon>
            )}
          </Styled.Title>
        </Styled.Left>

        <Styled.Right>
          <Styled.Amount $isDebit={isDebit}>
            {showAsDebit && "-"}${formatCurrency(amount)}
          </Styled.Amount>
        </Styled.Right>
      </Styled.Main>
    </Styled.Wrapper>
  );
};

export default SubItem;
