import React from "react";
import Icon, { IconName } from "components/ui/Icon";
import UILabel from "components/ui/UILabel";
import PartnerLogoLockup from "components/ui/PartnerLogoLockup";
import { FormTask, FormTaskStatus } from "types/task";
import { PartnerSlug } from "types/general";
import * as Styled from "./styled";

export interface NavItem {
  text: string;
  icon: IconName;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
}

interface Props {
  title: string;
  steps: FormTask[];
  navigation: NavItem[];
  partnerSlug?: PartnerSlug;
}

const FormProgressSidebar: React.FC<Props> = ({
  title,
  steps,
  navigation,
  partnerSlug,
}) => (
  <Styled.Wrapper>
    <Styled.HomeLink href="/">
      <PartnerLogoLockup partnerSlug={partnerSlug} reverse />
    </Styled.HomeLink>

    <Styled.Heading>{title}</Styled.Heading>

    <Styled.StepList>
      {steps.map((step) => (
        <Styled.Step key={step.id}>
          <Styled.IconWrapper $status={step.status}>
            <Icon name="checkmark" color="white" size="xs" />
          </Styled.IconWrapper>
          <UILabel.Medium>
            <Styled.Title $status={step.status}>{step.title}</Styled.Title>
            {step.description && step.status === FormTaskStatus.ready && (
              <Styled.Description $status={step.status}>
                {step.description}
              </Styled.Description>
            )}
          </UILabel.Medium>
        </Styled.Step>
      ))}
    </Styled.StepList>

    <Styled.Footer>
      <Styled.NavItems>
        {navigation?.map((navItem) => (
          <Styled.NavItem
            key={navItem.text}
            href={navItem.href || "#"}
            onClick={(e) => {
              if (navItem.onClick) {
                e.preventDefault();
                navItem.onClick(e);
              }
            }}
            data-turbo="false"
          >
            <Icon name={navItem.icon} />
            <UILabel.Medium bold>{navItem.text}</UILabel.Medium>
          </Styled.NavItem>
        ))}
      </Styled.NavItems>
    </Styled.Footer>
  </Styled.Wrapper>
);

export default FormProgressSidebar;
