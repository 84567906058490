import React from "react";
import Loading from "components/ui/Loading";
import Icon from "components/ui/Icon";
import { getActiveRecurringOutflows } from "store/api/apiSlice";
import { ApiExpense, sortExpensesByDayAscending } from "utils/api/expenses";
import { colors } from "utils/theme";
import {
  PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP,
  PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP,
} from "utils/constants/plaid";
import UILabel from "components/ui/UILabel";
import { formatDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import { getOrdinal } from "utils/number";
import * as Styled from "./styled";

const getExpenseComponents = (expense: ApiExpense) => {
  const subcategory =
    PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP[
      expense.personalFinanceCategorySecondary
    ] || PLAID_PERSONAL_FINANCE_CATEGORIES_DETAILED_MAP.OTHER_OTHER;
  const category =
    PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP[
      expense.personalFinanceCategoryPrimary
    ] || PLAID_PERSONAL_FINANCE_CATEGORIES_PRIMARY_MAP.OTHER;
  return { category, subcategory };
};

const ExpensesList: React.FC = () => {
  const { data: expenses, isLoading } = getActiveRecurringOutflows();

  if (isLoading) return <Loading />;

  const sortedExpenses = sortExpensesByDayAscending(expenses);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.DescriptionLabel>Description</Styled.DescriptionLabel>
        <Styled.DayLabel>Day</Styled.DayLabel>
        <Styled.AmountLabel>Avg. Amount</Styled.AmountLabel>
      </Styled.Header>

      <Styled.LineItems>
        {sortedExpenses.map((item) => {
          const { category, subcategory } = getExpenseComponents(item);

          return (
            <Styled.LineItem
              key={`${item.lastOccurrenceDate}+${item.description}`}
            >
              <Styled.ItemMain>
                <Styled.IconWrapper>
                  <Icon
                    color={colors.violet}
                    name={subcategory.icon || category.icon}
                  />
                </Styled.IconWrapper>

                <Styled.ItemText>
                  <UILabel.Large>
                    {item.merchantName || item.description}
                  </UILabel.Large>
                  <UILabel.Micro bold caps color={`${colors.midnight}80`}>
                    {category.title}
                  </UILabel.Micro>
                </Styled.ItemText>
              </Styled.ItemMain>

              <Styled.ItemDay>
                {formatDateString(item.lastOccurrenceDate, "day")}
                <sup>
                  <UILabel.Micro bold>
                    {getOrdinal(
                      formatDateString(item.lastOccurrenceDate, "day")
                    )}
                  </UILabel.Micro>
                </sup>
              </Styled.ItemDay>

              <Styled.ItemAmount>
                {formatCurrency(item.averageAmount, true)}
              </Styled.ItemAmount>
            </Styled.LineItem>
          );
        })}
      </Styled.LineItems>
    </Styled.Wrapper>
  );
};

export default ExpensesList;
