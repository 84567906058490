import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AnimatedCounter from "components/ui/AnimatedCounter";
import { fontWeights } from "utils/theme";

const Wrapper = styled.div`
  display: flex;

  font-weight: ${fontWeights.bold};
  letter-spacing: -0.025em;
  line-height: 1;
`;

const Amount: React.FC<{ amounts: number[] }> = ({ amounts }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((c) => c + 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Wrapper>
      <AnimatedCounter value={amounts[count % amounts.length]} />
    </Wrapper>
  );
};

export default Amount;
