import React from "react";
import styled, { css } from "styled-components";
import {
  Link as RouterLink,
  LinkProps as BaseRouterLinkProps,
} from "react-router-dom";
import UILabel from "components/ui/UILabel";
import { colors } from "utils/theme";

export enum TagLevel {
  info = "INFO",
  error = "ERROR",
  warning = "WARNING",
  success = "SUCCESS",
  pending = "PENDING",
  disabled = "DISABLED",
}

interface TagProps extends BaseProps {
  $level?: TagLevel;
}

const baseStyles = css`
  flex: 0 0 auto;
  padding: 4px 8px;

  border-radius: 4px;

  white-space: nowrap;
`;

const getLevelStyles = ({ $level = TagLevel.info }: TagProps) => {
  switch ($level) {
    case TagLevel.error:
      return `
      background: ${colors.cerise};
      color: ${colors.white};
    `;
    case TagLevel.success:
      return `
      background: ${colors.leaf};
      color: ${colors.midnight};
    `;
    case TagLevel.pending:
      return `
      background: ${colors.flax};
      color: ${colors.violet};
    `;
    case TagLevel.warning:
      return `
      background: ${colors.cosmos};
      color: ${colors.midnight};
    `;
    case TagLevel.disabled:
      return `
      background: ${colors.dust};
      color: ${colors.white};
    `;
    case TagLevel.info:
    default:
      return `
      background: ${colors.azure};
      color: ${colors.white};
    `;
  }
};

const StyledTag = styled.div<TagProps>`
  ${baseStyles};
  ${getLevelStyles};
`;

const StyledTagRouterLink = styled(RouterLink)<TagProps>`
  ${baseStyles};
  ${getLevelStyles};

  text-decoration: none;
`;

interface BaseProps {
  level?: TagLevel;
  children?: React.ReactNode;
}

interface Props extends BaseProps {}

const Tag: React.FC<Props> = ({ children, level, ...props }) => (
  <StyledTag $level={level} {...props}>
    <UILabel.Micro bold caps>
      {children}
    </UILabel.Micro>
  </StyledTag>
);

interface RouterLinkProps extends BaseRouterLinkProps, BaseProps {}

export const TagRouterLink: React.FC<RouterLinkProps> = ({
  children,
  level,
  ...props
}) => (
  <StyledTagRouterLink $level={level} {...props}>
    <UILabel.Micro bold caps>
      {children}
    </UILabel.Micro>
  </StyledTagRouterLink>
);

export default Tag;
