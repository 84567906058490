export enum Eligibility {
  indeterminate = "INDETERMINATE",
  eligible = "ELIGIBLE",
  ineligible = "INELIGIBLE",
}

export enum IncomeType {
  hourly = "hourly",
  gig = "gig",
  salary = "salary",
  benefits = "benefits",
}

export enum PinwheelProduct {
  income = "income",
  employment = "employment",
  shifts = "shifts",
  dds = "direct_deposit_switch",
  paystubs = "paystubs",
}
