import React from "react";
import styled from "styled-components";
import { capitalize, formatCurrency } from "utils/string";
import { truncateAndFormatDateString } from "utils/date";
import { colors, fontWeights, mediaMin } from "utils/theme";
import { ApiStatementTransaction, getMemoFromType } from "utils/api/statements";

const DATE_STYLE = "numericLong";

export const Table = styled.table`
  width: 100%;
  padding: 0;
  overflow: hidden;

  border-collapse: collapse;
  border-radius: 4px;

  font-size: inherit;

  thead {
    tr {
      background: ${colors.midnight};
      color: white;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background: ${colors.azure}11;
      }

      &:first-child,
      &:last-child {
        background: ${colors.azure}33;

        font-weight: ${fontWeights.bold};
      }
    }
  }

  th {
    padding: 6px 8px;

    ${mediaMin("lg")} {
      padding: 8px 12px;
    }
  }

  td {
    padding: 4px 8px;

    ${mediaMin("lg")} {
      padding: 6px 12px;
    }
  }

  td,
  th {
    &:not(:first-child) {
      padding-left: 12px;
    }

    &:not(:last-child) {
      padding-right: 12px;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }
`;

const Amount = styled.span<{ $isCredit?: boolean }>`
  ${(props) => props.$isCredit && `color: ${colors.azure};`}
`;

const TransactionRow: React.FC<{
  transaction: ApiStatementTransaction;
  accountNumber: string;
}> = ({ transaction, accountNumber }) => {
  const line = transaction.data.lines.find(
    (l) => l.accountNo === accountNumber
  );
  if (!line) return null;

  const memo =
    transaction.data.memo.length > 0
      ? transaction.data.memo
      : getMemoFromType(transaction.type, transaction.subtype);

  return (
    <tr>
      <td>{truncateAndFormatDateString(transaction.postedDate, DATE_STYLE)}</td>
      <td>{memo}</td>
      <td>
        <Amount $isCredit={line.dcSign === "credit"}>
          {formatCurrency(line.amount / 100)}
        </Amount>
      </td>
      <td>{capitalize(line.dcSign)}</td>
    </tr>
  );
};

interface Props {
  transactions: ApiStatementTransaction[];
  accountNumber: string;
  startDate: string;
  endDate: string;
  openingBalance: number;
  closingBalance: number;
}

const TransactionTable: React.FC<Props> = ({
  transactions,
  startDate,
  endDate,
  openingBalance,
  closingBalance,
  accountNumber,
}) => (
  <Table>
    <thead>
      <tr>
        <th>Date</th>
        <th>Description</th>
        <th>Amount ($)</th>
        <th>Debit/Credit</th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <td>{truncateAndFormatDateString(startDate, DATE_STYLE)}</td>
        <td>Beginning Balance</td>
        <td>{formatCurrency(openingBalance / 100)}</td>
        <td></td>
      </tr>

      {transactions.map((transaction) => (
        <TransactionRow
          key={transaction.uuid}
          accountNumber={accountNumber}
          transaction={transaction}
        />
      ))}

      <tr>
        <td>{truncateAndFormatDateString(endDate, DATE_STYLE)}</td>
        <td>Closing Balance</td>
        <td>{formatCurrency(closingBalance / 100)}</td>
        <td></td>
      </tr>
    </tbody>
  </Table>
);

export default TransactionTable;
