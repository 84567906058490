import React from "react";
import styled from "styled-components";
import { Body, Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import { ButtonLink } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import Loading from "components/ui/Loading";
import { HelpMessage } from "components/form";

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  gap: 24px;
`;

interface Props {
  isDashboardReady: boolean;
}

const SuccessMessage: React.FC<Props> = ({ isDashboardReady }) =>
  isDashboardReady ? (
    <Wrapper size="xl">
      <Header>
        <Heading.H2 tag="h1">Your Reset card is here!</Heading.H2>
      </Header>

      <Main>
        <Body>
          You can start using your virtual card right away. Access your card
          details from your Dashboard at any time. You can also add your card to{" "}
          <strong>Apple&nbsp;Pay</strong> or&nbsp;
          <strong>Google&nbsp;Pay</strong>.
        </Body>
      </Main>

      <Footer>
        <ButtonLink href="/dashboard">Go to Dashboard</ButtonLink>
      </Footer>
    </Wrapper>
  ) : (
    <Wrapper size="xl">
      <Header>
        <Heading.H2 tag="h1">Your Reset card is almost&nbsp;ready!</Heading.H2>
      </Header>

      <Main>
        <Body>
          Please wait while we issue your virtual card and update your
          Dashboard.
        </Body>
      </Main>

      <Footer>
        <Loading />
        <HelpMessage>
          <UILabel.Medium>Please do not refresh this page.</UILabel.Medium>
        </HelpMessage>
      </Footer>
    </Wrapper>
  );

export default SuccessMessage;
