import React from "react";
import { Heading } from "components/ui/Text";
import { ApiAdminUserExpense } from "utils/api/admin/user";
import { ordinalizeDateString } from "utils/date";
import { formatCurrency } from "utils/string";
import * as Styled from "./styled";
import Table from "../../components/Table";

interface Props {
  expenses: ApiAdminUserExpense[];
}

const Expenses: React.FC<Props> = ({ expenses }) => {
  const sortedExpenses = expenses
    ? [...expenses].sort(
        (a, b) =>
          new Date(a.lastOccurrenceDate).getDate() -
          new Date(b.lastOccurrenceDate).getDate()
      )
    : [];

  return (
    <Styled.SectionTile>
      <Heading.H4 tag="h2">Recurring Expenses</Heading.H4>
      <Table $noCollapse>
        <thead>
          <tr>
            <th>Day of month</th>
            <th>Description</th>
            <th>Average Amount</th>
          </tr>
        </thead>

        <tbody>
          {sortedExpenses.map((expense) => (
            <tr key={expense.id}>
              <td>{ordinalizeDateString(expense.lastOccurrenceDate)}</td>
              <td>{expense.merchantName || expense.description}</td>
              <td>{formatCurrency(expense.averageAmountInt / 100, true)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Styled.SectionTile>
  );
};

export default Expenses;
