import React from "react";
import { Provider as StoreProvider } from "react-redux";
import store from "store/admin";
import FeatureFlagContextProvider from "components/context/FeatureFlagContext";
import { ModalContextProvider } from "components/context/ModalContext";
import AdminNavigation from "components/ui/AdminNavigation";
import * as Styled from "./styled";

interface Props {
  children: React.ReactNode;
}

const AdminLayout: React.FC<Props> = ({ children }) => (
  <StoreProvider store={store}>
    <FeatureFlagContextProvider withAuth>
      <ModalContextProvider>
        <Styled.AppWrapper>
          <Styled.NavWrapper>
            <AdminNavigation />
          </Styled.NavWrapper>

          <Styled.MainWrapper>
            <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
          </Styled.MainWrapper>
        </Styled.AppWrapper>
      </ModalContextProvider>
    </FeatureFlagContextProvider>
  </StoreProvider>
);

export default AdminLayout;
