import React from "react";
import UILabel from "components/ui/UILabel";
import { formatCurrency } from "utils/string";
import { formatDateString } from "utils/date";
import { HelpMessage } from "components/form";
import * as Styled from "./styled";

interface Props {
  beginningOfPayPeriod?: string;
  accruedNetEarnings?: number;
  accruedGrossEarnings?: number;
}

const Header: React.FC<Props> = ({
  beginningOfPayPeriod,
  accruedNetEarnings,
  accruedGrossEarnings,
}) => (
  <Styled.Header>
    <Styled.HeaderMain>
      <Styled.HeaderBalance>
        <UILabel.Medium>Accrued net earnings</UILabel.Medium>
        <Styled.HeaderAmount color="violet" tag="div">
          {formatCurrency(accruedNetEarnings || 0, true)}
        </Styled.HeaderAmount>
        <Styled.HeaderNote>
          <HelpMessage>
            A portion of this factors into your Reset card spending limit
          </HelpMessage>
        </Styled.HeaderNote>
      </Styled.HeaderBalance>

      <Styled.HeaderStats>
        <Styled.HeaderItem>
          <UILabel.Medium>Beginning of pay period</UILabel.Medium>
          {beginningOfPayPeriod && (
            <UILabel.Large bold color="violet">
              {formatDateString(beginningOfPayPeriod, "numericShort")}
            </UILabel.Large>
          )}
        </Styled.HeaderItem>

        <Styled.HeaderItem>
          <UILabel.Medium>Accrued gross earnings</UILabel.Medium>
          <UILabel.Large bold color="violet">
            {formatCurrency(accruedGrossEarnings || 0, true)}
          </UILabel.Large>
        </Styled.HeaderItem>
      </Styled.HeaderStats>
    </Styled.HeaderMain>
  </Styled.Header>
);
export default Header;
