import styled from "styled-components";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Heading } from "components/ui/Text";
import IconList from "components/ui/IconList";
import { LIST_ITEMS } from "./constants";
import Modal, { ModalApi } from "../Modal";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  text-align: left;
`;

const SignupPayrollHelpModal = (
  _: {},
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  useImperativeHandle(forwardedRef, () => ref.current);

  return (
    <Modal ref={ref}>
      <Wrapper>
        <Heading.H3 tag="h2">How do I find my payroll provider?</Heading.H3>

        <IconList items={LIST_ITEMS} />
      </Wrapper>
    </Modal>
  );
};

export default forwardRef(SignupPayrollHelpModal);
