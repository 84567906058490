import React from "react";
import { BodyLarge, Heading } from "components/ui/Text";
import { ButtonLink } from "components/ui/Button";
import ContentLayout from "components/layout/ContentLayout";
import { FINANCIAL_INSTITUTIONS } from "../../constants";
import * as Styled from "./styled";

const FinancialInstitutions = () => {
  const { heading, body } = FINANCIAL_INSTITUTIONS;

  return (
    <Styled.Wrapper>
      <ContentLayout>
        <Styled.Content>
          <Heading.H1 tag="h2">{heading}</Heading.H1>
          <BodyLarge color="rgba(255,255,255,0.8)">{body}</BodyLarge>

          <Styled.Cta>
            <ButtonLink href="/partners/financial-institutions">
              Learn More
            </ButtonLink>
          </Styled.Cta>
        </Styled.Content>
      </ContentLayout>
    </Styled.Wrapper>
  );
};

export default FinancialInstitutions;
