interface StringMap {
  [key: string]: string;
}

export const substituteInString = (str: string, stringMap: StringMap) => {
  let newStr = str;
  Object.entries(stringMap).forEach(([key, value]) => {
    const regex = new RegExp(`{{${key}}}`, "g");
    newStr = newStr.replace(regex, value);
  });
  return newStr;
};

export const substituteIn = (
  item: string | { [key: string]: any } | Array<any>,
  stringMap
) => {
  if (typeof item === "string") {
    return substituteInString(item, stringMap);
  }
  if (item instanceof Array) {
    return item.map((arrayItem) => substituteIn(arrayItem, stringMap));
  }
  if (item instanceof Object) {
    return Object.entries(item).reduce((acc, [key, value]) => {
      acc[key] = substituteIn(value, stringMap);
      return acc;
    }, {});
  }
  return item;
};
