import React from "react";
import styled from "styled-components";
import msufcuLines from "assets/images/partners/msufcu-lines.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 2em;
`;

const Emoji = styled.span`
  transform: translateY(-0.1em);

  display: inline-block;
`;

const Icon = styled.img`
  display: block;
  width: 0.8em;
  margin-right: 1.2em;
`;

const MsufcuBannerMessage = () => (
  <Wrapper>
    <Icon src={msufcuLines} />
    <span>
      Welcome MSUFCU Members! <Emoji>🙌</Emoji>
    </span>
  </Wrapper>
);

export default MsufcuBannerMessage;
