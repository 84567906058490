import React, { useContext, useRef, useState } from "react";
import Icon from "components/ui/Icon";
import { ModalApi } from "components/modal/Modal";
import DemoTriggersModal from "components/modal/DemoTriggersModal";
import MockApiContext from "components/context/MockApiContext";
import environment from "utils/environment";
import DemoCreateUserModal from "components/modal/DemoCreateUserModal";
import * as Styled from "./styled";

const DemoControlPanel: React.FC = () => {
  const { msw } = useContext(MockApiContext);
  const modal = useRef<ModalApi>(null);
  const createUserModal = useRef<ModalApi>(null);
  const [isVisible, setIsVisible] = useState(true);

  if (!environment.enableDemoTriggers) return null;

  const openModal = () => {
    modal.current?.show();
  };

  const openCreateUserModal = () => {
    createUserModal.current?.show();
  };

  const onClick = () => {
    openModal();
  };

  const toggleControlPanel = async () => {
    await setIsVisible((v) => !v);
  };

  return (
    <Styled.Wrapper>
      <Styled.Button
        onClick={onClick}
        $isEnabled={msw.isEnabled}
        $isVisible={isVisible}
      >
        <Icon name={msw.isEnabled ? "astronaut" : "rocketLaunch"} size="md" />
      </Styled.Button>

      <DemoTriggersModal
        ref={modal}
        msw={msw}
        openCreateUserModal={openCreateUserModal}
        toggleControlPanel={toggleControlPanel}
      />

      <DemoCreateUserModal ref={createUserModal} />
    </Styled.Wrapper>
  );
};

export default DemoControlPanel;
