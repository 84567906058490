import React from "react";
import { Body } from "components/ui/Text";
import {
  QuestionnaireCategory,
  QuestionnaireCategoryName,
  QuestionnaireQuestion,
} from "./types";

export const SURVEY_SCORE = {
  healthy: 80,
  coping: 40,
  vulnerable: 0,
};

export const CATEGORIES: {
  [value in QuestionnaireCategoryName]: QuestionnaireCategory;
} = {
  [QuestionnaireCategoryName.Spending]: {
    title: "Spending",
    icon: "wallet",
  },
  [QuestionnaireCategoryName.Saving]: {
    title: "Saving",
    icon: "piggyBank",
  },
  [QuestionnaireCategoryName.Borrowing]: {
    title: "Borrowing",
    icon: "loan",
  },
  [QuestionnaireCategoryName.Planning]: {
    title: "Planning",
    icon: "clipboardListCheck",
  },
};

export const RESULTS_CATEGORIES = [
  QuestionnaireCategoryName.Spending,
  QuestionnaireCategoryName.Saving,
  QuestionnaireCategoryName.Borrowing,
  QuestionnaireCategoryName.Planning,
];

export const QUESTIONS: QuestionnaireQuestion[] = [
  {
    categoryName: QuestionnaireCategoryName.Spending,
    text: (
      <>
        How did your household&apos;s total spending compare to total income
        over the last 12 months?
      </>
    ),
    options: [
      {
        label: (
          <>
            Spending was <strong>much less</strong> than income
          </>
        ),
        value: 100,
      },
      {
        label: (
          <>
            Spending was <strong>a little less</strong> than income
          </>
        ),
        value: 75,
      },
      {
        label: (
          <>
            Spending was <strong>about equal</strong> to income
          </>
        ),
        value: 50,
      },
      {
        label: (
          <>
            Spending was <strong>a little more</strong> than income
          </>
        ),
        value: 25,
      },
      {
        label: (
          <>
            Spending was <strong>much more</strong> than income
          </>
        ),
        value: 0,
      },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Spending,
    text: (
      <>Over the last 12 months, my household has been financially able to:</>
    ),
    options: [
      {
        label: (
          <>
            Pay <strong>all</strong> our bills on time
          </>
        ),
        value: 100,
      },
      {
        label: (
          <>
            Pay <strong>nearly all</strong> our bills on time
          </>
        ),
        value: 60,
      },
      {
        label: (
          <>
            Pay <strong>most</strong> of our bills on time
          </>
        ),
        value: 40,
      },
      {
        label: (
          <>
            Pay <strong>some</strong> of our bills on time
          </>
        ),
        value: 20,
      },
      {
        label: (
          <>
            Pay <strong>very few</strong> of our bills on time
          </>
        ),
        value: 0,
      },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Saving,
    text: (
      <>
        <p>
          At your current level of spending, how long could your household
          afford to cover expenses with only the money you have readily
          available?
        </p>
        <Body>
          Consider only money you have without withdrawing from retirement
          accounts or borrowing.
        </Body>
      </>
    ),
    options: [
      { label: "6 months or more", value: 100 },
      { label: "3-5 months", value: 75 },
      { label: "1-2 months", value: 50 },
      { label: "1-3 weeks", value: 25 },
      { label: "Less than 1 week ", value: 0 },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Saving,
    text: (
      <>
        <p>
          How confident are you that your household is currently doing what is
          needed to meet your longer-term goals?
        </p>
        <Body>
          These include things like saving for a vacation, starting a business,
          buying or paying off a home, saving up for education, putting money
          away for retirement, and making retirement funds last.
        </Body>
      </>
    ),
    options: [
      {
        label: (
          <>
            <strong>Very</strong> confident
          </>
        ),
        value: 100,
      },
      {
        label: (
          <>
            <strong>Moderately</strong> confident
          </>
        ),
        value: 75,
      },
      {
        label: (
          <>
            <strong>Somewhat</strong> confident
          </>
        ),
        value: 50,
      },
      {
        label: (
          <>
            <strong>Slightly</strong> confident
          </>
        ),
        value: 25,
      },
      {
        label: (
          <>
            <strong>Not at all</strong> confident
          </>
        ),
        value: 0,
      },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Borrowing,
    text: (
      <>
        <p>
          As of today, which of the following statements describes how
          manageable your household debt is?
        </p>
        <Body>
          Think about all of your household&apos;s current debts, including
          mortgages, bank loans, student loans, money owed to people, medical
          debt, past-due bills, and credit card balances that are carried over
          from prior months.
        </Body>
      </>
    ),
    options: [
      { label: "Do not have any debt", value: 100 },
      { label: "Have a manageable amount of debt", value: 85 },
      { label: "Have a bit more debt than is manageable", value: 40 },
      { label: "Have far more debt than is manageable", value: 0 },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Borrowing,
    text: (
      <>
        <p>How would you rate your credit score?</p>
        <Body>
          Your credit score is a number that tells lenders how risky or safe you
          are as a borrower.
        </Body>
      </>
    ),
    options: [
      { label: "Excellent", value: 100 },
      { label: "Very good", value: 80 },
      { label: "Good", value: 60 },
      { label: "Fair", value: 40 },
      { label: "Poor", value: 0 },
      { label: "I don't know", value: 0 },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Planning,
    text: (
      <>
        <p>
          How confident are you that your insurance policies will provide enough
          support in case of an emergency?
        </p>
        <Body>
          Think about all of the types of insurance you and others in your
          household currently might have, including health insurance, vehicle
          insurance, home or rental insurance, life insurance, and disability
          insurance.
        </Body>
      </>
    ),
    options: [
      {
        label: (
          <>
            <strong>Very</strong> confident
          </>
        ),
        value: 100,
      },
      {
        label: (
          <>
            <strong>Moderately</strong> confident
          </>
        ),
        value: 75,
      },
      {
        label: (
          <>
            <strong>Somewhat</strong> confident
          </>
        ),
        value: 50,
      },
      {
        label: (
          <>
            <strong>Slightly</strong> confident
          </>
        ),
        value: 25,
      },
      {
        label: (
          <>
            <strong>Not at all</strong> confident
          </>
        ),
        value: 10,
      },
      { label: "No one in my household has any insurance", value: 0 },
    ],
  },
  {
    categoryName: QuestionnaireCategoryName.Planning,
    text: (
      <>
        <Body>
          To what extent do you agree or disagree with the following statement:
        </Body>
        <p>&ldquo;My household plans ahead financially.&rdquo;</p>
      </>
    ),
    options: [
      { label: "Agree strongly", value: 100 },
      { label: "Agree somewhat", value: 65 },
      { label: "Neither agree nor disagree", value: 35 },
      { label: "Disagree somewhat", value: 15 },
      { label: "Disagree strongly", value: 0 },
    ],
  },
];
