import React from "react";
import Select, { SelectProps } from "../ui/Select";
import FieldContainer from "../FieldContainer";

interface Props extends SelectProps {
  label?: React.ReactNode;
}

const SelectField: React.FC<Props> = ({ name, label, ...props }) => (
  <FieldContainer name={name} label={label}>
    {({ inputProps, error }) => (
      <Select name={name} hasError={!!error} {...inputProps} {...props} />
    )}
  </FieldContainer>
);

export default SelectField;
