import React from "react";
import styled from "styled-components";
import Tile, { TileSize } from "components/ui/Tile";
import { mediaMax, mediaMin, BREAKPOINT } from "utils/theme";

const StyledTile = styled(Tile)<{ $mediaQuery: string }>`
  ${(props) => props.$mediaQuery} {
    padding: 0;

    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
`;

interface Props {
  size?: TileSize;
  children: React.ReactNode;
  useMin?: boolean;
  breakpoint?: keyof typeof BREAKPOINT;
}

const DisappearingTile: React.FC<Props> = ({
  children,
  useMin = false,
  breakpoint = "md",
  ...props
}) => {
  const media = useMin ? mediaMin : mediaMax;
  const mediaQuery = media(breakpoint);
  return (
    <StyledTile $mediaQuery={mediaQuery} {...props}>
      {children}
    </StyledTile>
  );
};

export default DisappearingTile;
