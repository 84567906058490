import {
  ApiStatement,
  getAccountType,
  getMemoFromType,
  sortTransactionsAscending,
} from "utils/api/statements";
import { truncateAndFormatDateString } from "utils/date";
import { getResetAddress, getResetConstant } from "utils/reset";
import { colors } from "utils/theme";
import { capitalize, formatCurrency } from "utils/string";
import { Address } from "utils/api/address";
import { ApiUserProfile } from "utils/api/user";
import { LOGO } from "./constants";

const REGULAR_MARGIN = [0, 0];
const HEADING_MARGIN = [0, 0, 0, 16];
const SECTION_MARGIN = [0, 0, 0, 8];
const COLUMN_GAP = 16;
const DATE_STYLE = "numericLong";

const getLine = (text: string, style?: string[]) => ({
  text,
  margin: REGULAR_MARGIN,
  style,
});

const buildHeader = (statement: ApiStatement, address: Address) => {
  const resetAddress = getResetAddress();
  const { primaryAccountHolderPersonal, accountSummary, startDate, endDate } =
    statement;

  return {
    stack: [
      {
        svg: LOGO,
        width: 80,
        margin: [0, 0, 0, 16],
      },
      {
        columnGap: COLUMN_GAP,
        columns: [
          {
            width: "33%",
            stack: [
              getLine("Reset Financial Technologies, Inc.", ["strong"]),
              getLine(resetAddress.street_one),
              getLine(resetAddress.street_two),
              getLine(
                `${resetAddress.city}, ${resetAddress.state_abbr} ${resetAddress.zip_code}`
              ),
            ],
          },
          [
            {
              text: "Account Statement",
              style: ["heading"],
              margin: HEADING_MARGIN,
            },
            {
              widths: ["*"],
              layout: "header",
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    {
                      border: [false, false, false, false],
                      fillColor: "#E6F3FF",
                      stack: [
                        getLine(
                          `${primaryAccountHolderPersonal.firstName} ${primaryAccountHolderPersonal.lastName}`,
                          ["strong"]
                        ),
                        getLine(address?.streetOne),
                        address?.streetTwo
                          ? getLine(address?.streetTwo)
                          : undefined,
                        getLine(
                          `${address?.city}, ${address?.stateAbbr} ${address?.zipCode}`
                        ),
                        getLine("United States"),
                      ],
                    },
                    {
                      fillColor: "#E6F3FF",
                      border: [false, false, false, false],
                      stack: [
                        {
                          margin: SECTION_MARGIN,
                          stack: [
                            getLine("Statement Period", ["strong"]),
                            getLine(
                              `${truncateAndFormatDateString(
                                startDate,
                                "text"
                              )} - ${truncateAndFormatDateString(
                                endDate,
                                "text"
                              )}`
                            ),
                          ],
                        },
                        {
                          margin: SECTION_MARGIN,
                          stack: [
                            getLine("Account Type", ["strong"]),
                            getLine(getAccountType(accountSummary.accountType)),
                          ],
                        },
                        {
                          margin: SECTION_MARGIN,
                          stack: [
                            getLine("Account Number", ["strong"]),
                            getLine(accountSummary.accountNumber),
                          ],
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        ],
      },
    ],
  };
};

const getTableRow = (columns: string[], style?: string[]) =>
  columns.map((text, index) => ({
    text,
    style,
    alignment: index === 2 ? "right" : "left",
    border: [false, false, false, false],
  }));

const getTableRowFromTransaction = ({ transaction, accountNumber, style }) => {
  const line = transaction.data.lines.find(
    (l) => l.accountNo === accountNumber
  );
  if (!line) return null;

  const memo =
    transaction.data.memo.length > 0
      ? transaction.data.memo
      : getMemoFromType(transaction.type, transaction.subtype);

  return getTableRow(
    [
      truncateAndFormatDateString(transaction.postedDate, DATE_STYLE),
      memo,
      {
        text: formatCurrency(line.amount / 100),
        color: line.dcSign === "credit" ? colors.azure : colors.midnight,
      },
      capitalize(line.dcSign),
    ],
    style
  );
};

const buildTable = (statement: ApiStatement) => ({
  margin: [0, 36],
  stack: [
    {
      text: "Transaction Details",
      style: ["heading"],
      margin: HEADING_MARGIN,
    },
    {
      table: {
        headerRows: 1,
        widths: ["auto", "*", "auto", "auto"],
        body: [
          getTableRow(
            ["Date", "Description", "Amount ($)", "Credit/Debit"],
            ["tableHeader"]
          ),
          getTableRow(
            [
              truncateAndFormatDateString(statement.startDate, DATE_STYLE),
              "Beginning Balance",
              formatCurrency(statement.openingBalance / 100),
              null,
            ],
            ["tableHighlight"]
          ),
          ...sortTransactionsAscending(statement.transactions).map(
            (transaction, index) =>
              getTableRowFromTransaction({
                transaction,
                accountNumber: statement.accountSummary.accountNumber,
                style: index % 2 === 0 ? ["tableEvenRow"] : undefined,
              })
          ),
          getTableRow(
            [
              truncateAndFormatDateString(statement.endDate, DATE_STYLE),
              "Closing Balance",
              formatCurrency(statement.closingBalance / 100),
              null,
            ],
            ["tableHighlight"]
          ),
        ],
      },
    },
  ],
});

const buildFooter = () => [
  {
    text: "In Case of Errors or Questions About Your Electronic Transfers:",
    style: ["strong"],
    margin: SECTION_MARGIN,
  },
  {
    text: "Write us at hello@getreset.co or call us at (415) 702-2027 as soon as you can, if you think your statement or receipt is wrong or if you need more information about a transfer on the statement or receipt. We must hear from you no later than 60 days after we sent you the FIRST statement on which the error or problem appeared.",
    margin: SECTION_MARGIN,
  },
  {
    ol: [
      "Tell us your name and account number (if any).",

      "Describe the error or the transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information.",
      "Tell us the dollar amount of the suspected error.",
    ],
    margin: SECTION_MARGIN,
  },
  {
    text: "We will investigate your complaint and will correct any error promptly. If we take more than 10 business days to do this, we will credit your account for the amount you think is in error, so that you will have the use of the money during the time it takes us to complete our investigation.",
    margin: SECTION_MARGIN,
  },
  {
    text: getResetConstant("disclosure"),
    margin: [0, 24, 0, 0],
  },
];

export const buildStatement = ({
  statement,
  userProfile,
}: {
  statement: ApiStatement;
  userProfile: ApiUserProfile;
}) => [
  buildHeader(statement, userProfile?.currentAddress),
  buildTable(statement),
  buildFooter(),
];
