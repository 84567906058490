import styled, { css } from "styled-components";
import { colors, fontSizes, fontWeights, shadows } from "utils/theme";

export const Dropdown = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 2;

  width: 100%;
  padding: 8px;
  margin: 0;
  overflow-y: auto;
  max-height: 160px;

  border-radius: 8px;
  box-shadow:
    ${shadows.tile},
    0 4px 8px ${colors.violet}11;
  background: white;
  visibility: hidden;
  opacity: 0;

  pointer-events: none;

  list-style-type: none;
`;

export const DropdownItem = styled.li`
  button {
    width: 100%;
    padding: 8px 16px;

    appearance: none;
    border: 0;
    background: none;

    font-size: ${fontSizes.xs}px;
    text-align: left;

    pointer-events: none;

    &:hover,
    &:focus {
      background: ${colors.azure}22;
    }
  }
`;

export const Loading = styled.li`
  padding: 16px;
  display: flex;
  justify-content: center;
`;

export const Tag = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 6px;

  border-radius: 4px;
  background: ${colors.azure};
  color: white;

  font-size: ${fontSizes.xs}px;
  font-weight: ${fontWeights.bold};

  pointer-events: none;
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-45%);

  display: none;

  color: ${colors.violet};

  pointer-events: none;

  * {
    stroke: currentColor;
    stroke-width: 12px;
  }
`;

export const InputWrapper = styled.div<{
  $hasTag: boolean;
  $isLoadingInitial: boolean;
}>`
  position: relative;

  &:focus-within {
    ${Dropdown} {
      visibility: visible;
      opacity: 1;

      pointer-events: all;
    }

    ${DropdownItem} {
      button {
        pointer-events: all;
      }
    }

    ${Tag} {
      display: none;
    }

    input {
      padding-left: 40px;

      & + ${SearchIcon} {
        display: block;
      }
    }
  }

  ${(props) =>
    props.$hasTag
      ? css`
          input:not(:focus) {
            color: white;

            transition: none;

            &::placeholder {
              color: white;
            }
          }
        `
      : css`
          input {
            padding-left: 40px;

            & + ${SearchIcon} {
              display: block;
            }
          }
        `}

  ${(props) =>
    props.$isLoadingInitial &&
    css`
      input {
        color: transparent;

        transition: none;
      }
    `}
`;

export const ErrorMessage = styled.div`
  color: ${colors.cerise};

  font-size: ${fontSizes.xs}px;
`;
