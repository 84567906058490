import { NavItem } from "components/ui/FormProgressSidebar";

export const getSidebarNavigation = ({
  isImpersonating,
  stopImpersonatingUser,
  logout,
}: {
  isImpersonating?: boolean;
  stopImpersonatingUser: () => void;
  logout: () => void;
}) => {
  const navigation: NavItem[] = [];

  if (isImpersonating) {
    navigation.push({
      text: "Stop Viewing as User",
      icon: "userCircle",
      onClick: stopImpersonatingUser,
    });
  }

  navigation.push({
    text: "Log Out",
    icon: "doorOpen",
    onClick: logout,
  });

  return navigation;
};
