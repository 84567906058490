export function getPropValue(
  sourceObject: { [key: string]: any },
  dotNotationPath: string
) {
  let returnData = sourceObject;

  dotNotationPath.split(".").forEach((subPath) => {
    returnData = returnData?.[subPath] || undefined;
  });

  return returnData;
}

export const hasValue = (obj: { [key: string]: any }, value: any) =>
  Object.values(obj).includes(value);

export const isDefined = (value) => value !== undefined && value !== null;
