import styled, { css } from "styled-components";
import UILabel from "components/ui/UILabel";
import { colors, mediaMax, transitions } from "utils/theme";
import { BaseButton } from "components/ui/Button";
import { motion } from "framer-motion";
import Icon from "components/ui/Icon";

export const LineItemDate = styled(UILabel.Large)`
  flex: 0 0 auto;
  width: 72px;
`;

export const LineItemInfo = styled(UILabel.Large).attrs(() => ({ bold: true }))`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1 1 auto;
`;

export const LineItemStatus = styled.div`
  flex: 0 0 auto;

  &:empty {
    display: none;
  }
`;

interface AmountProps {
  $isCredit?: boolean;
  $isInactive?: boolean;
}

const getAmountColor = (props: AmountProps) => {
  if (props.$isInactive) return colors.dust;
  if (props.$isCredit) return colors.azure;
  return colors.violet;
};

export const LineItemAmount = styled(UILabel.Large)<AmountProps>`
  flex: 0 0 auto;
  min-width: 72px;

  text-align: right;

  color: ${getAmountColor};
`;

export const LineItemArrow = styled(Icon)`
  width: 12px;
  display: none;
  justify-content: center;
  align-items: center;

  @media (pointer: coarse) {
    display: flex;
  }
`;

export const LineItemDetailsWrapper = styled(motion.div)`
  width: 100%;
`;

export const LineItemDetails = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 24px 0 0 88px;

  ${mediaMax("md")} {
    display: flex;
    flex-direction: column;
    padding-left: 0;
  }

  & > * {
    &:empty {
      display: none;
    }
  }
`;

const mainStyles = css`
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;

  text-align: left;
`;

export const LineItemMainButton = styled(BaseButton)`
  ${mainStyles}
`;

export const LineItemMain = styled.div`
  ${mainStyles}
`;

const getInteractiveStyles = (props) => {
  if (!props.onClick) return "";

  const baseStyles = css`
    cursor: pointer;
  `;

  const interactiveStyles = props.$isOpen
    ? css`
        z-index: 1;

        ${LineItemArrow} {
          transform: rotate(90deg);
        }
      `
    : css`
        @media (pointer: fine) {
          &:hover {
            background: color-mix(in srgb, ${colors.azure} 5%, white);

            &::before {
              opacity: 0;
            }
          }
        }
      `;
  return css`
    ${baseStyles}
    ${interactiveStyles}
  `;
};

export const LineItemFooter = styled.div`
  grid-column: span 2;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding-top: 16px;

  ${mediaMax("md")} {
    display: flex;
  }
`;

export const LineItem = styled.div<{
  $isOpen?: boolean;
  $isPending: boolean;
  $hideDateMobile: boolean;
}>`
  position: relative;

  display: flex;
  flex-direction: column;
  padding: 24px 8px;
  width: 100%;

  transition: background ${transitions.simple};

  * {
    position: relative;
    z-index: 1;
  }

  ${(props) =>
    props.$isPending &&
    css`
      ${LineItemDate}, ${LineItemInfo}, ${LineItemAmount} {
        opacity: 0.5;
      }
    `}

  ${getInteractiveStyles}

  ${(props) =>
    props.$hideDateMobile &&
    css`
      ${mediaMax("md")} {
        ${LineItemDate} {
          display: none;
        }
      }
    `}
`;
