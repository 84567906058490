import styled from "styled-components";
import Tile from "components/ui/Tile";
import { colors, fontSizes, mediaMax, mediaMin } from "utils/theme";
import { Body, Heading } from "components/ui/Text";

export const Header = styled.div`
  display: grid;
  gap: 16px;

  & > * {
    max-width: 768px;
    margin: 0 auto;
  }
`;

export const CardList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  row-gap: 50px;

  text-align: left;
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;

  svg {
    * {
      fill: white;
    }
  }
`;

export const CardImage = styled.div`
  overflow: hidden;

  background: ${colors.azure}22;
  border-radius: 9999px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const CardContent = styled.div`
  display: grid;
  gap: 4px;

  * {
    line-height: 1.33;
  }

  sup {
    color: ${colors.midnight}80;

    line-height: 0;
  }
`;

export const Card = styled(Tile)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 36px;

  color: ${colors.violet};

  text-align: center;
`;

export const CardHeading = styled(Heading.H4)``;

export const CardBody = styled(Body).attrs(() => ({ tag: "div" }))`
  * {
    margin: 0;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;

  a,
  button {
    width: auto;
    min-width: 320px;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  display: grid;
  gap: 48px;

  text-align: center;

  ${Card} {
    &:nth-child(n + 1) {
      ${CardIcon} {
        background: linear-gradient(
          -45deg,
          ${colors.heliotrope} 0,
          ${colors.azure} 200%
        );
      }
    }
    &:nth-child(n + 2) {
      ${CardIcon} {
        background: linear-gradient(
          0deg,
          ${colors.heliotrope} -50%,
          ${colors.azure} 150%
        );
      }
    }
    &:nth-child(n + 3) {
      ${CardIcon} {
        background: linear-gradient(
          45deg,
          ${colors.heliotrope} -100%,
          ${colors.azure} 100%
        );
      }
    }
  }

  ${mediaMin("lg")} {
    ${CardImage}, ${CardIcon} {
      width: 96px;
      height: 96px;
    }
  }

  ${mediaMax("lg")} {
    ${CardList} {
      grid-template-columns: 1fr;
      gap: 12px;
    }

    ${Card} {
      flex-direction: row;
      align-items: flex-start;
      gap: 24px;
    }

    ${CardContent} {
      display: flex;
      flex-direction: column;
      gap: 4px;
      flex: 1 1 auto;
      min-height: 100%;
      justify-content: center;

      text-align: left;
    }

    ${CardImage}, ${CardIcon} {
      width: 64px;
      height: 64px;
    }

    ${CardIcon} {
      flex: 0 0 auto;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  ${mediaMax("md")} {
    ${Card} {
      gap: 16px;
      align-items: stretch;
      flex-direction: column;
    }

    ${CardContent} {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }

    ${CardImage}, ${CardIcon} {
      width: 48px;
      height: 48px;
    }

    ${CardIcon} {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    ${CardHeading} {
      font-size: ${fontSizes.sm}px;
    }

    ${CardBody} {
      font-size: ${fontSizes.xs}px;
    }
  }
`;
