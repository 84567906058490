import React from "react";
import { ApiCardTransaction } from "utils/api/cardTransactions";
import ListItem from "./ListItem";
import * as Styled from "./styled";

interface Props {
  date: string;
  transactions: ApiCardTransaction[];
  toggleTransaction: (id: string) => void;
  openTransactions: Set<string>;
  disputes: Set<string>;
  disputeTransaction: (transaction: ApiCardTransaction) => Promise<{}>;
  canDispute?: boolean;
}

const ListSection: React.FC<Props> = ({
  date,
  transactions,
  toggleTransaction,
  openTransactions,
  disputes,
  disputeTransaction,
  canDispute,
}) => (
  <Styled.Section>
    <Styled.SectionHeading>{date}</Styled.SectionHeading>

    <Styled.List>
      {transactions.map((transaction) => (
        <Styled.ListItemWrapper key={transaction.id}>
          <ListItem
            transaction={transaction}
            toggleTransaction={toggleTransaction}
            isOpen={openTransactions.has(transaction.id)}
            hasDispute={disputes.has(transaction.id)}
            disputeTransaction={disputeTransaction}
            canDispute={canDispute}
          />
        </Styled.ListItemWrapper>
      ))}
    </Styled.List>
  </Styled.Section>
);

export default ListSection;
