import React from "react";
import styled, { keyframes } from "styled-components";

const aLoading = keyframes`
  0% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(75%);
  }
`;

const LoaderDot = styled.div``;

const LoaderContainer = styled.div<{ $width: number }>`
  --width: ${(props) => props.$width}px;

  position: relative;

  width: var(--width);
  height: var(--width);

  ${LoaderDot},
  &::before,
  &::after {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    background: currentColor;
    border-radius: 9999px;

    animation: ${aLoading} 0.66s ease-in alternate forwards infinite;
  }

  ${LoaderDot} {
    left: 0;

    animation-delay: -0.22s;
  }

  &::before,
  &::after {
    content: "";
  }

  &::before {
    right: calc(100% + var(--width));
  }

  &::after {
    left: calc(100% + var(--width));

    animation-delay: -0.44s;
  }
`;

const LoadingDots = ({ width = 8, ...props }) => (
  <LoaderContainer $width={width} {...props}>
    <LoaderDot />
  </LoaderContainer>
);

export default LoadingDots;
