export const camelCaseToKebabCase = (str: string) =>
  str && typeof str === "string"
    ? str.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
      )
    : "";

export const camelCaseToSnakeCase = (str: string) =>
  str && typeof str === "string"
    ? str.replace(
        /[A-Z]+(?![a-z])|[A-Z]/g,
        ($, ofs) => (ofs ? "_" : "") + $.toLowerCase()
      )
    : "";

export const sentenceCaseToKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");

export const formatCurrency = (value: number, includeSymbol?: boolean) => {
  const amount = value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (includeSymbol) {
    return amount;
  }
  return amount.replace("$", "");
};

export const formatCurrencyString = (str: string) =>
  formatCurrency(parseFloat(str));

export const currencyStringToNumber = (str: string) =>
  parseFloat(str.replace(/[^\d.]/g, ""));

export const capitalize = (str: string) =>
  str.toLowerCase().replace(/^([a-z])/, (l) => l.toUpperCase());

const LOWER_CASE_WORDS = [
  "a",
  "an",
  "the",
  "at",
  "by",
  "for",
  "in",
  "of",
  "on",
  "to",
  "up",
  "and",
  "as",
  "but",
  "or",
  "nor",
];

const capitalizeForTitle = (str: string) => {
  if (LOWER_CASE_WORDS.includes(str)) {
    return str;
  }
  return capitalize(str);
};

export const toTitleCase = (str: string) => {
  const words = str.split(" ");
  return words
    .map((word, index) =>
      index === 0 || index === words.length
        ? capitalize(word)
        : capitalizeForTitle(word)
    )
    .join(" ");
};

export const snakeCaseToTitleCase = (str: string) => {
  const sentence = str.replace(/_/g, " ");
  return toTitleCase(sentence);
};

export const camelCaseToTitleCase = (str: string) => {
  const snake = camelCaseToSnakeCase(str);
  return snakeCaseToTitleCase(snake);
};

export const toHash = async (str: string) => {
  const utf8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

const hexToRgbArray = (hex) => hex.replace(/#/g, "").match(/.{1,2}/g);

export const interpolateColor = (hex1, hex2, ratio) => {
  const rgb1 = hexToRgbArray(hex1).map(
    (oct) => parseInt(oct, 16) * (1 - ratio)
  );
  const rgb2 = hexToRgbArray(hex2).map((oct) => parseInt(oct, 16) * ratio);

  const rgb = [0, 1, 2].map((i) =>
    Math.min(Math.round(rgb1[i] + rgb2[i]), 255)
  );
  const hex = rgb
    .reduce((a, v) => (a << 8) + v, 0) // eslint-disable-line no-bitwise
    .toString(16)
    .padStart(6, "0");
  return `#${hex}`;
};

export const formatPhoneNumber = (str: string) =>
  str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
