import React from "react";
import App from "App";
import Flow from "./Flow";

const BudgetCalculator = () => (
  <App>
    <Flow />
  </App>
);

export default BudgetCalculator;
