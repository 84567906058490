import styled, { css } from "styled-components";
import { colors, mediaMax, mediaMin, zIndex } from "utils/theme";

export const NavWrapper = styled.div`
  ${mediaMin("lg")} {
    position: sticky;
    z-index: 1;
    top: 0;
    height: 100vh;
  }

  ${mediaMax("lg")} {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.nav};

    width: 100%;
    height: calc(60px + env(safe-area-inset-bottom, 0));
    min-height: 72px;
    padding: 4px 0 env(safe-area-inset-bottom, 0);
    display: flex;
    align-items: center;

    background: white;
    filter: drop-shadow(0 -2px 24px ${colors.heliotrope}22);
  }
`;

export const AppWrapper = styled.div<{ $hideNavigation?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 0 72px;

  background: color-mix(in srgb, ${colors.azure} 3%, white);

  ${mediaMin("lg")} {
    padding: 0;

    ${(props) =>
      props.$hideNavigation
        ? ""
        : css`
            display: grid;
            grid-template-columns: 284px 1fr;
          `};
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  flex: 1 1 auto;
  overflow: hidden;

  ${mediaMin("lg")} {
    padding: 48px;
    gap: 96px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Footer = styled.footer`
  --padding-x: 16px;

  position: relative;
  padding: 0 var(--padding-x);
  max-width: 576px;
  margin: 0 auto;

  ${mediaMax("lg")} {
    padding: 24px var(--padding-x);
  }
`;
