import { IconName } from "components/ui/Icon";
import { logout } from "utils/api/user";

const onClickLogout = (e) => {
  e.preventDefault();
  logout();
};

interface DropdownItem {
  title: string;
  icon: IconName;
  href: string;
  adminOnly?: boolean;
  onClick?: (e) => void;
}

export const DROPDOWN_SECTIONS: DropdownItem[][] = [
  [
    {
      title: "Dashboard",
      icon: "dashboard",
      href: "/dashboard",
    },
    {
      title: "Account",
      icon: "account",
      href: "/account",
    },
    {
      title: "Admin",
      icon: "lock",
      href: "/admin/users",
      adminOnly: true,
    },
  ],
  [
    {
      title: "Log Out",
      icon: "doorOpen",
      href: "/users/sign_out",
      onClick: onClickLogout,
    },
  ],
];
