import React from "react";
import styled from "styled-components";
import {
  borderRadius,
  colors,
  fontSizes,
  fontWeights,
  fonts,
} from "utils/theme";

const Dot = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;

  display: none;
  width: 16px;
  height: 16px;

  pointer-events: none;

  border-radius: 9999px;
  background: ${colors.azure};
`;

const Label = styled.div`
  position: relative;

  flex: 1 1 auto;

  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.sm}px;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: none;
  width: 100%;
  height: 100%;

  background: color-mix(in srgb, ${colors.azure} 10%, transparent);
  border: 1px solid ${colors.azure};
  border-radius: ${borderRadius.small}px;
`;

const StyledRadio = styled.input`
  position: relative;
  z-index: 1;

  display: block;
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  flex: 0 0 auto;

  background: white;
  border: 1px solid ${colors.midnight}40;
  border-radius: 9999px;
  outline: 0;
  appearance: none;

  font-family: ${fonts.body};
  font-size: ${fontSizes.sm}px;

  &:focus {
    border-color: ${colors.azure};
  }

  &:checked {
    border-color: ${colors.azure};

    & ~ ${Dot} {
      display: block;
    }

    & ~ ${Background} {
      display: block;
    }
  }
`;

const Wrapper = styled.label`
  position: relative;

  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  gap: 12px;

  border-radius: ${borderRadius.small}px;
  box-shadow: 0 0 0 1px color-mix(in srgb, ${colors.midnight} 10%, transparent)
    inset;
`;

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value: any;
  label: React.ReactNode;
}

const Radio = (
  { label, ...props }: RadioProps,
  ref: React.Ref<HTMLInputElement>
) => (
  <Wrapper>
    <StyledRadio {...props} type="radio" ref={ref} />
    <Dot />
    <Background />
    <Label>{label}</Label>
  </Wrapper>
);

export default React.forwardRef(Radio);
