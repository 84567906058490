import {
  ApiDisclosures,
  getDisclosurePathsByType,
} from "utils/api/disclosures";
import { ResetDocument, ResetDocumentType } from "./types";

export const getBasicDisclosures = (
  disclosuresData: ApiDisclosures
): ResetDocument[] => {
  if (!disclosuresData) {
    return [];
  }

  const disclosurePathsByType = getDisclosurePathsByType(disclosuresData);

  return [
    {
      title: "E-Sign Consent Agreement",
      data: { url: disclosurePathsByType.e_sign },
      type: ResetDocumentType.Web,
    },
    {
      title: "Terms of Service",
      data: { url: disclosurePathsByType.terms_of_service },
      type: ResetDocumentType.Web,
    },
    {
      title: "Privacy Policy",
      data: { url: disclosurePathsByType.privacy_policy },
      type: ResetDocumentType.Web,
    },
  ];
};

export const getCardHolderDisclosures = (
  disclosuresData: ApiDisclosures
): ResetDocument[] => {
  if (!disclosuresData) {
    return [];
  }

  const disclosurePathsByType = getDisclosurePathsByType(disclosuresData);

  return [
    {
      title: "Card Agreement",
      data: { url: disclosurePathsByType.card_agreement },
      type: ResetDocumentType.File,
    },
    {
      title: "Deposit Account Agreement",
      data: { url: disclosurePathsByType.deposit_account_agreement },
      type: ResetDocumentType.File,
    },
    {
      title: "Stearns Bank's Privacy Policy",
      data: { url: disclosurePathsByType.bank_privacy_policy },
      type: ResetDocumentType.File,
    },
    {
      title: "EWA Terms of Service",
      data: { url: disclosurePathsByType.ewa_terms_of_service },
      type: ResetDocumentType.File,
    },
    {
      title: "ACH Authorization Agreement",
      data: { url: disclosurePathsByType.ach_authorization_agreement },
      type: ResetDocumentType.File,
    },
  ];
};
