import { BaseButton } from "components/ui/Button";
import UILabel from "components/ui/UILabel";
import styled, { css } from "styled-components";
import { borderRadius, colors, fontSizes, mediaMax } from "utils/theme";

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 68%;
  padding: 30px 30px 24px;
  gap: 12px;

  ${mediaMax("md")} {
    gap: 2px;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  gap: 12px;

  font-family: monospace;
  font-size: ${fontSizes.sm}px;

  button {
    flex: 1 1 auto;
    text-align: right;
  }

  ${mediaMax("md")} {
    transform-origin: left center;
    transform: scale(calc(14 / 18));
  }
`;

export const DetailsHeading = styled(UILabel.Small)`
  margin-bottom: 8px;

  opacity: 0.8;
`;

export const DetailsItem = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`;

export const ItemLabel = styled.div`
  opacity: 0.8;

  font-size: ${fontSizes.xxs}px;
  text-transform: uppercase;
`;

const CardItem = styled.div`
  height: 20px;
`;

export const CardPan = styled(CardItem)`
  width: 206px;
`;

export const CardExp = styled(CardItem)`
  width: 54px;
`;

export const CardCvv = styled(CardItem)`
  width: 32px;
`;

export const BackButton = styled(BaseButton)`
  position: absolute;
  z-index: 1;

  top: 24%;
  left: 30px;
  transform: translateY(-50%);
`;

export const Wrapper = styled.div<{ $isActive: boolean }>`
  position: relative;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  gap: 12px;
  overflow: hidden;

  border-radius: ${borderRadius.regular}px;
  background: linear-gradient(45deg, var(--bg-color-1) 30%, var(--bg-color-2));

  &::after {
    content: "";

    position: absolute;
    top: 12%;
    left: 0;

    height: 24%;
    width: 100%;

    opacity: 16.66%;
    background: ${colors.midnight};
  }

  iframe {
    width: 100%;
  }

  ${(props) =>
    !props.$isActive &&
    css`
      ${CardItem} {
        filter: invert();
      }
    `};
`;
