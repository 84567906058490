import styled from "styled-components";
import { breakpoints, mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  max-width: ${breakpoints.md}px;
  margin: 0 auto;
  gap: 36px;
  padding: 0 16px;

  ${mediaMax("lg")} {
    gap: 24px;
    padding: 16px;
  }

  ${mediaMax("md")} {
    padding: 12px;
  }
`;

export const CardWrapper = styled.div`
  position: relative;
  z-index: 1;

  flex: 0 0 auto;

  & > * {
    margin: 0 auto;
    padding: 0 36px;
  }

  ${mediaMax("md")} {
    & > * {
      padding: 0;
    }
  }
`;

export const DashboardMain = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;
