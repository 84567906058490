import React from "react";
import App from "App";
import View from "./View";

const SignupView: React.FC = () => (
    <App>
      <View />
    </App>
  );

export default SignupView;
