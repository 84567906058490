import React from "react";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import { InputField } from "components/form";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

const SignupForm = () => (
  <>
    <Styled.FormFields>
      <InputField label="First name" name="name" required />

      <InputField label="Email" name="email" type="email" required />
    </Styled.FormFields>

    <MobileFixedFooter>
      <Styled.FormButton level={ButtonLevel.cta} size={ButtonSize.xl}>
        Get My Results
      </Styled.FormButton>
    </MobileFixedFooter>
  </>
);

export default SignupForm;
