// interface

export enum ApiNotificationErrorType {
  monitoring_status_unavailable = "monitoring_status_unavailable",
  direct_deposit_switched_away_from_reset = "direct_deposit_switched_away_from_reset",
}

export enum ApiNotificationLevel {
  success = "SUCCESS",
  info = "INFO",
  warn = "WARN",
  error = "ERROR",
}

export enum ApiNotificationSource {
  pinwheel = "pinwheel",
  plaid = "plaid",
}

export enum ApiNotificationType {
  integrationError = "IntegrationErrorNotifier::Notification",
  dslUpdating = "DynamicSpendingLimits::UpdatingNotifier::Notification",
  dslUpdated = "DynamicSpendingLimits::UpdatedNotifier::Notification",
}

export interface ApiNotification {
  id: string;
  title: string | null;
  type: ApiNotificationType;
  actedAt: string | null;
  readAt: string | null;
  requireAction: boolean;
  level: ApiNotificationLevel;
  source: ApiNotificationSource | null;
  errorType: ApiNotificationErrorType | null;
}

// maps

const ERROR_TYPE_MAP = {
  [ApiNotificationErrorType.monitoring_status_unavailable]: {
    [ApiNotificationSource.pinwheel]: {
      title: "Please relink your payroll account",
      level: ApiNotificationLevel.error,
    },
    [ApiNotificationSource.plaid]: {
      title: "Please relink your checking account",
      level: ApiNotificationLevel.error,
    },
  },
};

export const NOTIFICATION_MAP = ERROR_TYPE_MAP;

// helpers

interface RawNotification {
  id: string;
  type: ApiNotificationType;
  actedAt: string | null;
  readAt: string | null;
  requireAction: boolean;
  params: { errorType: string; source: string };
}

type NotificationTransformer = (
  notifications: RawNotification[]
) => ApiNotification[];

export const notificationTransformer: NotificationTransformer = (
  notifications
) =>
  notifications.map(({ id, type, actedAt, readAt, requireAction, params }) => {
    let transformed = {
      id,
      type,
      actedAt,
      readAt,
      requireAction,
      level: ApiNotificationLevel.info,
      title: null,
      source: null,
      errorType: null,
    };

    if (type === ApiNotificationType.integrationError) {
      transformed = {
        ...transformed,
        errorType: params.errorType,
        source: params.source,
        ...NOTIFICATION_MAP[params.errorType][params.source],
      };
    }

    return transformed;
  });

export const getIntegrationErrors = (notifications: ApiNotification[]) =>
  notifications.filter(
    (notification) =>
      [ApiNotificationSource.pinwheel, ApiNotificationSource.plaid].includes(
        notification.source
      ) && notification.type === ApiNotificationType.integrationError
  );

export const hasNotification = (
  type: ApiNotificationType,
  notifications: ApiNotification[] = []
) => notifications.some((notification) => notification.type === type);

export const hasDslUpdatingNotification = (
  notifications: ApiNotification[] = []
) => hasNotification(ApiNotificationType.dslUpdating, notifications);

export const hasDslUpdatedNotification = (
  notifications: ApiNotification[] = []
) => hasNotification(ApiNotificationType.dslUpdated, notifications);
