import React, { useEffect, useState } from "react";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import {
  FormSection,
  HelpMessage,
  InputField,
  SubmitButton,
} from "components/form";
import { LinkButton } from "components/ui/Link";
import UILabel from "components/ui/UILabel";
import {
  usePostSendCodeMutation,
  usePostVerifyCodeMutation,
} from "store/api/apiSlice";
import { useFormContext } from "react-hook-form";
import { Wrapper } from "../styled";
import * as Styled from "./styled";

const maskPhoneNumber = (phoneNumber: string) => {
  const mask = `(•••) •••-${phoneNumber.slice(-4)}`;
  return mask;
};

interface Props {
  isMfaEnabled?: boolean;
  userId?: string;
  phoneNumber?: string;
  isLoading: boolean;
  onClickCancel: () => void;
}

const Verify: React.FC<Props> = ({
  isMfaEnabled,
  userId,
  phoneNumber,
  isLoading,
  onClickCancel,
}) => {
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(60);
  const [sendCode] = usePostSendCodeMutation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { data: verificationResult, error: verificationError }] =
    usePostVerifyCodeMutation({
      fixedCacheKey: "verifyCode",
    });
  const { setError } = useFormContext();
  const canResend = timer === 60;

  const onClickResend = () => {
    if (canResend) {
      sendCode(userId);
      setCount((c) => c + 1);
    }
  };

  useEffect(() => {
    let interval;

    if (count > 0) {
      setTimer(59);
      interval = setInterval(() => {
        if (timer === 0) {
          clearInterval(interval);
          setTimer(60);
          return;
        }
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    sendCode(userId);
  }, []);

  useEffect(() => {
    if (
      verificationError ||
      (verificationResult && !verificationResult.success)
    )
      setError(
        "otpAttempt",
        { type: "custom", message: "Invalid code" },
        { shouldFocus: true }
      );
  }, [verificationError, verificationResult]);

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">
          {isMfaEnabled ? "Disable" : "Enable"} 2-factor authentication
        </Heading.H3>

        <FormSection>
          <HelpMessage>
            We just sent a code to {maskPhoneNumber(phoneNumber)}
          </HelpMessage>

          <InputField name="otpAttempt" label="Verification code" type="text" />

          <SubmitButton isLoading={isLoading}>Submit</SubmitButton>

          <Styled.LinkContainer>
            {canResend ? (
              <LinkButton onClick={onClickResend} disabled={!canResend}>
                Resend code
              </LinkButton>
            ) : (
              <UILabel.Medium light>
                New code sent. Please wait {timer} seconds to try again.
              </UILabel.Medium>
            )}

            <LinkButton onClick={onClickCancel}>Cancel</LinkButton>
          </Styled.LinkContainer>
        </FormSection>
      </Wrapper>
    </UILayout>
  );
};

export default Verify;
