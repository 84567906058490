import styled, { css } from "styled-components";
import Tag from "components/ui/Tag";
import { colors, mediaMax } from "utils/theme";

const getStatusTagStyles = ({ $status }: { $status: string }) => {
  switch ($status) {
    case "red":
      return css`
        background: ${colors.cerise};
        color: white;
      `;
    case "green":
      return css`
        background: ${colors.leaf};
      `;
    case "yellow":
    default:
      return css`
        background: ${colors.cosmos};
      `;
  }
};

export const Wrapper = styled(Tag)<{ $status: string }>`
  width: fit-content;

  color: ${colors.midnight};

  ${getStatusTagStyles}

  ${mediaMax("lg")} {
    margin-top: 6px;
  }
`;
