import styled, { keyframes } from "styled-components";
import DisappearingTile from "components/ui/DisappearingTile";
import { colors, fontWeights, mediaMax, mediaMin, shadows } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 48px;

  background: white;

  ${mediaMin("md")} {
    padding: 0 24px 64px;
    background: ${colors.midnight};
    gap: 24px;
  }

  ${mediaMin("lg")} {
    padding: 24px 24px 84px;
    gap: 48px;
  }
`;

export const Tile = styled(DisappearingTile).attrs(() => ({ size: "xl" }))`
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  ${mediaMin("md")} {
    box-shadow: ${shadows.tileSolid};
  }
`;

export const ResultsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaMax("md")} {
    padding: 24px 16px;
    gap: 24px;

    text-align: center;
  }
`;

export const ResultsHeader = styled.div`
  display: flex;
  gap: 36px;

  ${mediaMax("md")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const ChartWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;

  flex: 0 0 auto;

  text-align: center;
`;

export const ChartCaption = styled.div`
  max-width: 180px;
`;

export const IdealChart = styled.div`
  position: absolute;
  top: 90px;
  left: 90px;
  transform: translate(-50%, -50%);
`;

export const ReferenceChart = styled.div`
  opacity: 0.33;
`;

const aFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const SavingsAmount = styled.div`
  position: absolute;
  top: 90px;
  left: 90px;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  font-size: 28px;
  font-weight: ${fontWeights.black};
  letter-spacing: -0.02em;

  opacity: 0;
  color: ${colors.azure};

  animation: ${aFadeIn} 0.15s 0.35s ease-in forwards;
`;

export const Amount = styled.span`
  display: flex;
  align-items: center;
`;

export const ResultsHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Comparison = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  padding-top: 24px;

  border-top: 1px solid ${colors.midnight}20;

  ${mediaMax("md")} {
    text-align: center;
  }
`;

export const ComparisonList = styled.div`
  display: grid;
  gap: 48px;

  text-align: left;

  ${mediaMin("md")} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${mediaMax("md")} {
    max-width: 240px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const ComparisonItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 64px;
  height: 64px;

  border-radius: 9999px;
`;

export const ComparisonItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ComparisonItemAmount = styled.div`
  line-height: 1;
`;

export const ComparisonItem = styled.div<{ $color: string }>`
  position: relative;

  display: flex;
  gap: 16px;
  padding: 0 16px;

  &:not(:first-child) {
    &::before {
      content: "";

      position: absolute;
      top: -24px;
      left: 0;

      height: 1px;
      width: 100%;

      background: ${colors.midnight}20;
    }
  }

  --color: ${(props) => props.$color};

  ${ComparisonItemIcon} {
    background: color-mix(in srgb, var(--color) 10%, white);
  }

  ${ComparisonItemAmount} {
    color: var(--color);
  }

  ${mediaMin("md")} {
    flex-direction: column;
    padding: 0;

    &:not(:first-child) {
      &::before {
        top: 0%;
        left: -24px;

        height: 100%;
        width: 1px;
      }
    }
  }
`;
