import { FormSection } from "components/form";
import styled from "styled-components";

export const Tile = styled(FormSection)`
  gap: 24px;

  * {
    text-align: center;
  }
`;

export const Ctas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;

  text-align: center;
`;
