import React from "react";
import { SPENDING_BUCKETS } from "../constants";
import { SpendingBucket } from "../types";

const MARGIN_OF_ERROR = 0.075; // margin for determining high/low spending as a percentage of monthly income

const isLow = (name, { spending, monthlyIncome }) => {
  const { current, ideal } = spending[name];
  return current < ideal - MARGIN_OF_ERROR * monthlyIncome;
};

const isHigh = (name, { spending, monthlyIncome }) => {
  const { current, ideal } = spending[name];
  return current > ideal + MARGIN_OF_ERROR * monthlyIncome;
};

export const getBudgetProfile = ({
  needs,
  wants,
  savings,
  monthlyIncome,
  isOverBudget,
}: {
  needs: number;
  wants: number;
  savings: number;
  monthlyIncome: number;
  isOverBudget: boolean;
}) => {
  let heading = "Budget Hero";
  let body = (
    <>
      Maybe we should be asking your for budget advice! You&apos;re doing a
      great job balancing spending and saving. Keeping on top of your budget can
      be hard work, but Reset can help lighten the load.
    </>
  );

  const budget = {
    monthlyIncome,
    spending: {
      [SpendingBucket.needs]: {
        current: needs,
        ideal: monthlyIncome * SPENDING_BUCKETS[SpendingBucket.needs].ratio,
      },
      [SpendingBucket.wants]: {
        current: wants,
        ideal: monthlyIncome * SPENDING_BUCKETS[SpendingBucket.wants].ratio,
      },
      [SpendingBucket.savings]: {
        current: savings,
        ideal: monthlyIncome * SPENDING_BUCKETS[SpendingBucket.savings].ratio,
      },
    },
  };

  if (isLow(SpendingBucket.savings, budget)) {
    heading = "Aspiring Saver";

    if (isOverBudget) {
      body = (
        <>
          You may not be able to save any of your income at the moment, but
          that&apos;s okay! Regardless of income level, everyone goes through
          cycles of higher spending. Reset can help you budget better for the
          future.
        </>
      );
    } else {
      body = (
        <>
          You&apos;re not saving a large portion of your income at the moment,
          but that&apos;s okay! Regardless of income level, everyone goes
          through cycles of higher spending. Reset can help you budget better
          for the future.
        </>
      );
    }
  } else if (isHigh(SpendingBucket.savings, budget)) {
    heading = "Savvy Saver";
    body = (
      <>
        Whether you&apos;re paying down high-interest debt or topping up your
        rainy day fund, you&apos;re doing a great job saving! You may be able to
        afford to spend a bit more on needs or wants, but what matters most is
        how you feel about your finances. Reset can help you balance your income
        and spending even better.
      </>
    );
  } else if (isHigh(SpendingBucket.needs, budget)) {
    heading = "Practical Spender";
    body = (
      <>
        <p>
          You&apos;re spending a fair amount of your income on necessities—and
          that&apos;s okay! It can be a challenge to reduce this kind of
          spending, but it&apos;s not impossible. If you still have career
          growth ahead of you, try to keep your spending stable while your
          income grows.
        </p>
        Balancing your budget can be hard work, but Reset can help.
      </>
    );
  } else if (isHigh(SpendingBucket.wants, budget)) {
    heading = "Lifestyle Lover";
    body = (
      <>
        You&apos;re spending a fair amount of your income on “wants”—and
        that&apos;s okay! Whether you already have a healthy rainy-day fund or
        you&apos;re just choosing to enjoy what life has to offer, everyone goes
        through periods of higher discretionary spending. Just keep an eye on
        your savings and debt. Future you will appreciate it! Reset can help you
        get started.
      </>
    );
  }

  // if no budget item is "high" and savings isn't "low", we can consider the budget to be relatively balanced

  return {
    heading,
    body,
  };
};
