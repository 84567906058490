import React from "react";
import { CenteredHero } from "types/cms";
import HeaderDivider from "./HeaderDivider";
import * as Styled from "./styled";
import Header from "../../Header";

const Centered: React.FC<CenteredHero> = ({
  data: { heading, eyebrow, body, cta, theme = "default" },
}) => (
  <Styled.Wrapper $theme={theme}>
    <Styled.BackgroundShape>
      <HeaderDivider />
    </Styled.BackgroundShape>

    <Styled.Content>
      <Header
        heading={heading}
        eyebrow={eyebrow}
        body={body}
        cta={cta}
        isCentered
        isHero
        isLight={theme === "default"}
        textSize="large"
      />
    </Styled.Content>
  </Styled.Wrapper>
);

export default Centered;
