import React from "react";
import { WANTS_OPTIONS } from "../constants";
import Amounts from "./Amounts";

interface Props {
  updateValidation: (isValid: boolean) => void;
}

const WantsAmounts: React.FC<Props> = ({ updateValidation }) => (
  <Amounts options={WANTS_OPTIONS} updateValidation={updateValidation} />
);

export default WantsAmounts;
