import React, { useEffect, useState } from "react";
import { usePostFinancialHealthSurveyMutation } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import SlideLayout from "components/layout/SlideLayout";
import Step from "./Step";
import * as Styled from "./styled";
import { SurveyResults, SurveyStep } from "./types";
import { getInitialResults, getResults } from "./utils";

const FinancialHealthSurvey = () => {
  const [currentStep, setCurrentStep] = useState<SurveyStep | null>(null);
  const [results, setResults] = useState<SurveyResults | null>(null);
  const [postSurvey, postSurveyResults] =
    usePostFinancialHealthSurveyMutation();

  const getStarted = async () => {
    setCurrentStep(SurveyStep.Questionnaire);
  };

  const onSubmitQuestionnaire = (values) => {
    const questionnaireResults = getResults(
      values.questions.map((value) => parseInt(value, 10))
    );

    setResults(questionnaireResults);
    setCurrentStep(SurveyStep.Signup);
  };

  const onSubmitSignup = async (user) => {
    await postSurvey({ user, subscores: results.subscores });
  };

  useEffect(() => {
    const initialResults = getInitialResults();
    setResults(initialResults);
    setCurrentStep(initialResults ? SurveyStep.Results : SurveyStep.Intro);
  }, []);

  if (!currentStep)
    return (
      <Styled.Wrapper>
        <Loading />
      </Styled.Wrapper>
    );

  return (
    <Styled.Wrapper>
      <SlideLayout currentId={currentStep}>
        <Step
          currentStep={currentStep}
          results={results}
          getStarted={getStarted}
          onSubmitQuestionnaire={onSubmitQuestionnaire}
          onSubmitSignup={onSubmitSignup}
          postSurveyResults={postSurveyResults}
        />
      </SlideLayout>
    </Styled.Wrapper>
  );
};

export default FinancialHealthSurvey;
