import { Input } from "components/form";
import React, { useRef, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "utils/theme";
import { BaseButton } from "./Button";
import Icon from "./Icon";

const Wrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 100%;
  padding-right: 64px;
`;

const CopyButton = styled(BaseButton)<{ $isSuccess: boolean }>`
  position: absolute;
  right: 0;
  top: 0;

  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  border-radius: 0 8px 8px 0;
  background: ${colors.midnight};

  ${(props) =>
    props.$isSuccess &&
    css`
      background: ${colors.azure};
    `}
`;

interface Props {
  children: string;
}

const ClipboardInput: React.FC<Props> = ({ children }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const copy = () => {
    if (inputRef?.current) {
      const input = inputRef.current;
      input.select();
      input.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(input.value);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  };

  return (
    <Wrapper>
      <StyledInput value={children} readOnly ref={inputRef} />

      <CopyButton onClick={copy} disabled={isSuccess} $isSuccess={isSuccess}>
        <Icon name={isSuccess ? "checkmark" : "clipboard"} />
      </CopyButton>
    </Wrapper>
  );
};

export default ClipboardInput;
