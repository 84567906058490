import React, { createContext } from "react";

export enum LayoutName {
  fullWidth = "FULL_WIDTH",
  withSidebar = "WITH_SIDEBAR",
}

const LayoutContext = createContext<{
  name: LayoutName;
}>({
  name: LayoutName.fullWidth,
});

export const LayoutContextProvider = ({
  name = LayoutName.fullWidth,
  children,
}: {
  name?: LayoutName;
  children: React.ReactNode;
}) => (
    <LayoutContext.Provider value={{ name }}>{children}</LayoutContext.Provider>
  );

export default LayoutContext;
