import React from "react";
import { Body, Heading } from "components/ui/Text";
import { VerificationStatus } from "types/verification";
import Button, { ButtonProps } from "components/ui/Button";
import MobileFixedFooter from "components/ui/MobileFixedFooter";
import * as Styled from "./styled";

interface Content {
  heading: string;
  body: React.ReactNode;
  cta?: ButtonProps;
}

const REVIEW_MESSAGE = {
  heading: "Thank you for applying!",
  body: "We're reviewing your application and will reach out when there's an update. It's safe to log out or return to the Dashboard.",
};

const VERIFIED_MESSAGE = {
  heading: "You're approved!",
  body: "Only one more step to set up your Reset card.",
  cta: {
    children: "Continue",
    onClick: () => {
      window.location.reload();
    },
  },
};

const CONTENT: {
  [status in VerificationStatus]?: Content;
} = {
  [VerificationStatus.submitted]: {
    heading: "Thank you for applying!",
    body: "Please wait while we verify your identity.",
    cta: {
      children: "Continue",
      isLoading: true,
    },
  },
  [VerificationStatus.flaggedForReview]: REVIEW_MESSAGE,
  [VerificationStatus.flaggedForReviewWithDocument]: REVIEW_MESSAGE,
  [VerificationStatus.flaggedForInternalReview]: REVIEW_MESSAGE,
  [VerificationStatus.verifiedAutomatically]: VERIFIED_MESSAGE,
  [VerificationStatus.verifiedManually]: VERIFIED_MESSAGE,
  [VerificationStatus.rejected]: {
    heading: "Your identity could not be verified.",
    body: "We are unable to verify your identity and thus cannot complete your Reset card signup process at this time.",
  },
};

interface Props {
  status?: VerificationStatus;
}

const Message: React.FC<Props> = ({ status }) => {
  const content = CONTENT[status] || CONTENT[VerificationStatus.submitted];

  return (
    <>
      <Styled.Tile>
        <Styled.Header>
          <Heading.H2 tag="h1">{content.heading}</Heading.H2>
        </Styled.Header>

        <Body>{content.body}</Body>
      </Styled.Tile>

      {content.cta && (
        <MobileFixedFooter>
          <Button {...content.cta} />
        </MobileFixedFooter>
      )}
    </>
  );
};

export default Message;
