import React from "react";
import Button, { ButtonLink, ButtonSize } from "../Button";

interface Props {
  isAdmin: boolean;
  isImpersonating: boolean;
  stopImpersonatingUser: () => void;
  closeMobileDrawer?: () => void;
}

const FooterLinks: React.FC<Props> = ({
  isAdmin,
  isImpersonating,
  stopImpersonatingUser,
}) => (
  <>
    {isImpersonating && (
      <Button size={ButtonSize.md} onClick={stopImpersonatingUser}>
        Stop Viewing as User
      </Button>
    )}

    {isAdmin && (
      <ButtonLink href="/admin" size={ButtonSize.md}>
        Admin
      </ButtonLink>
    )}
  </>
);

export default FooterLinks;
