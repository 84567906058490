import ContentLayout from "components/layout/ContentLayout";
import { Heading } from "components/ui/Text";
import styled from "styled-components";
import { colors } from "utils/theme";

export const Wrapper = styled(ContentLayout)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
`;

export const ErrorCode = styled(Heading.H2)`
  color: ${colors.violet}40;

  font-size: 108px;
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding-top: 24px;
`;
