import React from "react";
import styled from "styled-components";
import { Body, Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import Icon from "components/ui/Icon";
import { ButtonLevel, ButtonLink, ButtonSize } from "components/ui/Button";
import { colors } from "utils/theme";

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  strong {
    @supports (background-clip: text) {
      background-image: linear-gradient(
        75deg,
        ${colors.heliotrope},
        ${colors.azure}
      );
      color: transparent;
      background-clip: text;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
`;

const IneligibleMessage = () => (
  <Wrapper size="xl">
    <Header>
      <Heading.H2 tag="h1">Thanks for linking your accounts!</Heading.H2>
    </Header>

    <Main>
      <Body>
        We need to verify your account data before you can proceed with the{" "}
        <strong>Reset card</strong> application. This typically takes
        less&nbsp;than an&nbsp;hour.
      </Body>
      <Body>
        In the meantime you&apos;re free to explore your Dashboard, which allows
        you to track your earned income, checking account balance, and upcoming
        expenses.
      </Body>
    </Main>

    <Footer>
      <ButtonLink
        href="/dashboard"
        size={ButtonSize.lg}
        level={ButtonLevel.secondary}
      >
        Explore My Dashboard
        <Icon name="arrowRight" />
      </ButtonLink>
    </Footer>
  </Wrapper>
);

export default IneligibleMessage;
