import { capitalize, snakeCaseToTitleCase } from "utils/string";

export interface ApiStatementMini {
  id: string;
  issueDate: string;
}

interface ApiStatementTransactionLine {
  amount: number;
  dcSign: "debit" | "credit";
  accountNo: string;
}

interface ApiStatementAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
}

export interface ApiStatementTransaction {
  uuid: string;
  postedDate: string;
  type: string;
  subtype: string;
  data: {
    memo: string;
    lines: ApiStatementTransactionLine[];
  };
}

export interface ApiStatement extends ApiStatementMini {
  startDate: string;
  endDate: string;
  openingBalance: number;
  closingBalance: number;
  primaryAccountHolderPersonal: {
    firstName: string;
    lastName: string;
  };
  customerServiceDetails: {
    address: ApiStatementAddress;
  };
  accountSummary: {
    id: string;
    accountType: string;
    accountNumber: string;
  };
  transactions: ApiStatementTransaction[];
}

export const getMemoFromType = (type: string, subtype: string) => {
  let memo = "";

  if (type === "ach") {
    memo += "ACH";
  } else if (type) {
    memo += snakeCaseToTitleCase(type);
  }

  memo += ` ${snakeCaseToTitleCase(subtype)}`;

  return memo;
};

export const getAccountType = (type: string) => {
  switch (type) {
    case "CHECKING":
      return "Deposit";
    default:
      return capitalize(type);
  }
};

export const sortTransactionsAscending = (
  transactions: ApiStatementTransaction[]
) =>
  [...transactions].sort((a, b) => {
    const aDate = new Date(a.postedDate);
    const bDate = new Date(b.postedDate);
    if (aDate > bDate) return 1;
    if (aDate < bDate) return -1;
    return 0;
  });
