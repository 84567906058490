import styled from "styled-components";
import { colors, mediaMin } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const ExpenseList = styled.div`
  display: grid;
  gap: 24px;

  ${mediaMin("md")} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddRowButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  button {
    border-color: ${colors.violet}40;
    background: white;
    color: ${colors.midnight};
  }
`;
