import { TransactionCustomerState } from "./api/cardTransactions";

export const TRANSACTION_STATES_DISPUTABLE = [
  TransactionCustomerState.posted,
  TransactionCustomerState.repaid,
  TransactionCustomerState.partially_repaid
];

export const TRANSACTION_STATES_CURRENT = [
  TransactionCustomerState.pending,
  TransactionCustomerState.posted,
  TransactionCustomerState.partially_repaid
];
