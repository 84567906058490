import React from "react";
import { ApiUserProfile } from "utils/api/user";
import DslInfoTile from "./DslInfoTile";

interface Props {
  userProfile?: ApiUserProfile;
  toggleMaxDslDisplay: (showMax: boolean) => void;
}

const DslInfo: React.FC<Props> = ({ userProfile, toggleMaxDslDisplay }) => {
  if (userProfile?.currentCardAccount) return null;

  return (
    <DslInfoTile
      userProfile={userProfile}
      toggleMaxDslDisplay={toggleMaxDslDisplay}
    />
  );
};

export default DslInfo;
