import React from "react";
import styled from "styled-components";
import { IconName } from "components/ui/Icon";
import { mediaMax } from "utils/theme";
import TileRadio from "../ui/TileRadio";
import FieldContainer from "../FieldContainer";

const Wrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);

  ${mediaMax("md")} {
    grid-template-columns: 1fr;
  }
`;

export interface TileRadioOption {
  value: string;
  label: string;
  icon: IconName;
}

interface Props {
  name: string;
  options: TileRadioOption[];
}

const TileRadioGroupField: React.FC<Props> = ({ name, options, ...props }) => (
  <Wrapper {...props}>
    {options.map((option) => (
      <FieldContainer name={name} key={option.value}>
        {({ inputProps }) => <TileRadio {...option} {...inputProps} />}
      </FieldContainer>
    ))}
  </Wrapper>
);

export default TileRadioGroupField;
