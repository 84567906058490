export const SITE_NAVIGATION: Array<{
  title: string;
  path: string;
}> = [
  {
    title: "FAQs",
    path: "faqs",
  },
  {
    title: "Partner With Us",
    path: "partners/financial-institutions",
  },
  {
    title: "Our Story",
    path: "about",
  },
];
