import { PARTNERS } from "./constants";
import { Partner } from "./types";

export const getPartner: (id: string) => Partner | undefined = (id) =>
  PARTNERS[id];

export const getSessionPartner: () => Partner | undefined = () => {
  const id = sessionStorage.getItem("partner");
  return getPartner(id);
};
