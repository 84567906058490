export interface LinkResult {
  accountId: string;
  platformId: string;
}

export const getToken = async (isDds: boolean) => {
  const res = await fetch(`/api/v1/pinwheel/${isDds ? "dds_" : ""}link_token`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = await res.json();
  return data;
};

export const storeIdentifiers = async (
  accountId: string,
  platformId: string
) => {
  const res = await fetch(
    `/api/v1/pinwheel/store_data?account_id=${accountId}&platform_id=${platformId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await res.json();
  return data;
};

interface ApiPayrollInstitutions {
  name: string;
  id: string;
  data: {
    products: string[];
  };
}

type Platform = "payroll" | "time_and_attendance";

export const getSearch =
  (platform: Platform = "payroll") =>
  async (str: string) => {
    const res = await fetch(
      `/api/v1/pinwheel/search_institutions?name=${str}&platform_type=${platform}`,
      {
        credentials: "include",
      }
    );
    const data = await res.json();

    const institutions = data.institutions.map((item) => ({
      name: item.name,
      id: item.id,
      data: {
        products: item.products.split(", "),
      },
    }));

    return [
      ...institutions,
      { name: "Other", id: "other", data: { products: [] } },
    ] as Array<ApiPayrollInstitutions>;
  };

export const searchInstitutions = getSearch();

export const searchTimeAndAttendance = getSearch("time_and_attendance");
