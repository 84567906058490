import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { ToggleBlock } from "types/cms";
import { sentenceCaseToKebabCase } from "utils/string";
import * as Styled from "./styled";
import Header from "../../Header";
import IconImage from "../../IconImage";

const Toggle: React.FC<ToggleBlock> = ({
  isHero,
  isReverse,
  data: { eyebrow, heading, body, items, textSize },
}) => {
  const anchor = window.location.hash.replace("#", "");
  const initiallyOpenIndex = items.findIndex(
    (item) => sentenceCaseToKebabCase(item.title) === anchor
  );
  const [open, setOpen] = useState<number | undefined>(initiallyOpenIndex);

  const onClickItem = (index) => {
    setOpen((current) => (current === index ? undefined : index));
  };

  useEffect(() => {
    if (initiallyOpenIndex > -1) {
      const item = items[initiallyOpenIndex];
      const id = sentenceCaseToKebabCase(item.title);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [initiallyOpenIndex]);

  return (
    <Styled.Wrapper>
      <Header
        eyebrow={eyebrow}
        heading={heading}
        body={body}
        isCentered
        isHero={isHero}
        textSize={textSize}
        isLight={isReverse}
      />

      <Styled.List>
        {items.map((item, index) => {
          const isOpen = index === open;

          return (
            <Styled.ListItem
              key={item.title}
              id={sentenceCaseToKebabCase(item.title)}
              $isOpen={isOpen}
            >
              <Styled.ListItemTile>
                <Styled.ListItemHeader onClick={() => onClickItem(index)}>
                  <Styled.IconWrapper>
                    <IconImage {...item} />
                  </Styled.IconWrapper>

                  <Styled.ListItemHeading bold>
                    {item.title}
                  </Styled.ListItemHeading>

                  <Styled.ListItemToggleIcon
                    name={isOpen ? "minus" : "plus"}
                    height={14}
                  />
                </Styled.ListItemHeader>

                <AnimatePresence>
                  {isOpen ? (
                    <Styled.ListItemBody
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 },
                      }}
                      transition={{ duration: 0.33, ease: [0.25, 1, 0.5, 1] }}
                    >
                      <Styled.ListItemText>{item.body}</Styled.ListItemText>
                    </Styled.ListItemBody>
                  ) : null}
                </AnimatePresence>
              </Styled.ListItemTile>
            </Styled.ListItem>
          );
        })}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default Toggle;
