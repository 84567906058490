import React, { useState } from "react";
import FormContainer from "components/form";
import {
  getIdentityVerificationStatus,
  getUserProfile,
  usePostPersonalInfosMutation,
} from "store/api/apiSlice";
import { ApiPersonalInfosRequest } from "utils/api/user";
import { InfoType } from "../../types";
import ReviewInfo from "./ReviewInfo";
import Form from "./Form";

interface Props {
  showInfo: (infoType: InfoType) => () => void;
}

const IdentityForm: React.FC<Props> = ({ showInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showName, setShowName] = useState(false);
  const { data: user } = getUserProfile();
  const { refetch: refetchVerificationStatus } =
    getIdentityVerificationStatus();
  const [postPersonalInfos] = usePostPersonalInfosMutation();

  const submitToReview = () => {
    setShowReview(true);
  };

  const verifyIdentity = async ({ phoneNumber, dateOfBirth, ssn, address }) => {
    const dob = new Date(dateOfBirth).toISOString().replace(/T.+/, "");
    const data = {
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ""),
      dateOfBirth: dob,
      ssn,
      address,
    } as ApiPersonalInfosRequest;

    await postPersonalInfos(data);

    await refetchVerificationStatus();
  };

  const onSubmit = (values) => {
    setIsLoading(true);

    if (values.confirmed) {
      verifyIdentity(values);
    } else {
      submitToReview();
    }

    setIsLoading(false);
  };

  const onClickBack = () => {
    setShowReview(false);
    setShowName(true);
  };

  return (
    <FormContainer onSubmit={onSubmit}>
      {showReview ? (
        <ReviewInfo onClickBack={onClickBack} user={user} />
      ) : (
        <Form
          showInfo={showInfo}
          user={user}
          showName={showName}
          isLoading={isLoading}
        />
      )}
    </FormContainer>
  );
};

export default IdentityForm;
