import Icon from "components/ui/Icon";
import React from "react";
import styled from "styled-components";
import { colors, fontSizes, fonts, shadows } from "utils/theme";

const Checkmark = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;

  pointer-events: none;

  svg {
    display: block;

    * {
      stroke-width: 40px;
      stroke: white;
    }
  }
`;

const StyledCheckbox = styled.input<{ $hasError?: boolean }>`
  display: block;
  height: 24px;
  width: 24px;
  margin: 0;
  padding: 0;
  flex: 0 0 auto;

  background: white;
  border: 1px solid ${colors.midnight}40;
  border-radius: 4px;
  outline: 0;
  appearance: none;

  font-family: ${fonts.body};
  font-size: ${fontSizes.sm}px;

  &:focus {
    border-color: ${colors.midnight};
    box-shadow: ${shadows.regular};
  }

  &:checked {
    background: ${colors.azure};

    & + ${Checkmark} {
      display: block;
    }
  }

  ${(props) =>
    props.$hasError
      ? `
  border-color: ${colors.cerise};
  `
      : ""}
`;

const Wrapper = styled.div`
  position: relative;

  display: inline-block;
`;

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: any;
  hasError?: boolean;
}

const Checkbox = (
  { name, onChange, hasError, ...props }: CheckboxProps,
  ref: React.Ref<HTMLInputElement>
) => (
  <Wrapper>
    <StyledCheckbox
      name={name}
      type="checkbox"
      onChange={onChange}
      ref={ref}
      $hasError={hasError}
      {...props}
    />

    <Checkmark>
      <Icon name="checkmark" size="xs" color="white" />
    </Checkmark>
  </Wrapper>
);

export default React.forwardRef(Checkbox);
