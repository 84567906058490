import { FormSection } from "components/form";
import styled from "styled-components";
import { fontSizes, fontWeights } from "utils/theme";

export const Tile = styled(FormSection)`
  gap: 24px;

  * {
    text-align: center;
  }
`;

export const Header = styled.div`
  display: grid;
  gap: 12px;
`;

export const Link = styled.button`
  appearance: none;
  border: 0;
  background: none;

  text-decoration: underline;
  font-size: ${fontSizes.sm}px;
  font-weight: ${fontWeights.bold};
`;
