export enum TransactionCustomerState {
  pending = "pending",
  declined = "declined",
  posted = "posted",
  repaid = "repaid",
  partially_repaid = "partially_repaid",
  expired = "expired",
  manuallyExpired = "manually_expired",
}

export interface ApiCardTransaction {
  id: string;
  createdAt: string;
  amount: number;
  customerState: TransactionCustomerState;
  merchantName: string;
  merchantCategory: string;
  cardPresent: boolean;
  transactionReferenceNumber: string;
}
