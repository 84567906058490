import { BlockType, CardType } from "types/cms";
import {
  buildCta,
  getBody,
  getCommonBlockData,
  mapImagePositionToType,
} from "./common";

const mapContentTypeToBlockType = (contentType) => {
  switch (contentType) {
    case "TileBlock":
      return BlockType.tile;
    case "SplitBlock":
      return BlockType.split;
    case "RichTextBlock":
      return BlockType.wysiwyg;
    case "PeopleBlock":
      return BlockType.people;
    case "ToggleBlock":
      return BlockType.toggle;
    case "LogoBlock":
      return BlockType.logo;
    default:
      return contentType;
  }
};

const getBaseBlock = (block) => ({
  id: block.sys.id,
  type: mapContentTypeToBlockType(block.typename),
});

const mapContentTypeToTileType = (contentType) => {
  switch (contentType) {
    case "TileBlockItemIcon":
      return CardType.icon;
    case "TileBlockItemImage":
      return CardType.image;
    default:
      return contentType;
  }
};

const getBaseTile = (tile, pagesById) => ({
  title: tile.title,
  body: getBody(tile.body, pagesById),
  type: mapContentTypeToTileType(tile.typename),
});

const buildTileBlock = (block, pagesById) => ({
  ...getBaseBlock(block),
  data: {
    ...getCommonBlockData(block, pagesById),
    cta: buildCta(block.cta),
    tiles: block.tilesCollection.items.map((tile) => {
      switch (tile.typename) {
        case "TileBlockItemIcon":
          return {
            ...getBaseTile(tile, pagesById),
            icon: tile.icon?.url,
          };
        case "TileBlockItemImage":
          return {
            ...getBaseTile(tile, pagesById),
            image: tile.image?.url,
          };
        default:
          return tile;
      }
    }),
  },
});

const buildSplitBlock = (block, pagesById) => ({
  ...getBaseBlock(block),
  data: {
    ...getCommonBlockData(block, pagesById),
    cta: buildCta(block.cta),
    image: block.image?.url,
    imageOrientation: mapImagePositionToType(block.imagePosition),
  },
});

const buildRichTextBlock = (block, pagesById) => ({
  ...getBaseBlock(block),
  data: {
    ...getCommonBlockData(block, pagesById),
    cta: buildCta(block.cta),
    isCentered: true,
  },
});

const buildPeopleBlock = (block, pagesById) => ({
  ...getBaseBlock(block),
  data: {
    ...getCommonBlockData(block, pagesById),
    people: block.peopleCollection.items.map((person) => ({
      name: person.name,
      title: person.title,
      image: person.image?.url,
      links: person.linkedInUrl
        ? [{ icon: "linkedin", label: "LinkedIn", url: person.linkedInUrl }]
        : undefined,
    })),
  },
});

const buildToggleBlock = (block, pagesById) => ({
  ...getBaseBlock(block),
  data: {
    ...getCommonBlockData(block, pagesById),
    items: block.itemsCollection.items.map((item) => ({
      icon: item.icon?.url,
      title: item.title,
      body: getBody(item.body, pagesById),
    })),
  },
});

const buildLogoBlock = (block) => ({
  ...getBaseBlock(block),
  data: {
    images: block.imagesCollection.items.map((image) => ({
      url: image.url,
      title: image.title,
    })),
  },
});

export const buildBlockByType = (block, pagesById = {}) => {
  switch (block.typename) {
    case "TileBlock":
      return buildTileBlock(block, pagesById);
    case "SplitBlock":
      return buildSplitBlock(block, pagesById);
    case "RichTextBlock":
      return buildRichTextBlock(block, pagesById);
    case "PeopleBlock":
      return buildPeopleBlock(block, pagesById);
    case "ToggleBlock":
      return buildToggleBlock(block, pagesById);
    case "LogoBlock":
      return buildLogoBlock(block);
    default:
      return undefined;
  }
};

export const buildBlocks = (blocks, pagesById) =>
  blocks.map((block) => buildBlockByType(block, pagesById));
