import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  padding: 120px 0;

  color: white;

  ${mediaMax("lg")} {
    padding: 80px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  text-align: center;

  & > * {
    max-width: 768px;
  }
`;

export const Cta = styled.div`
  padding-top: 24px;
  width: 320px;
  max-width: 100%;

  a {
    width: 100%;
  }

  ${mediaMax("md")} {
    width: 100%;
  }
`;
