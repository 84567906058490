import Icon from "components/ui/Icon";
import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { getStatement, getUserProfile } from "store/api/apiSlice";
import { createStatementPdf } from "utils/pdf";
import styled, { css } from "styled-components";
import { StatementDocument } from "./types";
import { getUrl } from "./utils";

const Button = styled.a<{ $isDisabled: boolean }>`
  cursor: pointer;

  ${(props) =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

interface Props {
  document: StatementDocument;
}

const DownloadStatementButton: React.FC<Props> = ({ document }) => {
  const [skip, setSkip] = useState(true);
  const [isCreatingPdf, setIsCreatingPdf] = useState(false);
  const [requestId, setRequestId] = useState<string>(uuid());
  const { data, isLoading } = getStatement(document.data.id, {
    skip,
  });
  const { data: userProfile, isLoading: isUserProfileLoading } =
    getUserProfile();

  const onClick = (e) => {
    e.preventDefault();
    if (!data) {
      setSkip(false);
    } else {
      setRequestId(uuid());
    }
  };

  const createPdf = async () => {
    setIsCreatingPdf(true);
    await createStatementPdf({ statement: data.statement, userProfile });
    setIsCreatingPdf(false);
  };

  useEffect(() => {
    if (data?.statement && userProfile && requestId) {
      createPdf();
    }
  }, [requestId, userProfile, data]);

  const isDisabled = isLoading || isUserProfileLoading || isCreatingPdf;

  return (
    <Button
      href={getUrl(document)}
      target="_blank"
      onClick={onClick}
      $isDisabled={isDisabled}
    >
      <Icon name="download" color={isDisabled ? "#777777" : "azure"} />
    </Button>
  );
};

export default DownloadStatementButton;
