import { IconListItem } from "components/ui/IconList";

export const LIST_ITEMS: IconListItem[] = [
  {
    icon: "usersRectangle",
    title: "Employer HR portal",
    body: "The name of the portal you log into typically includes the name of your payroll provider.",
  },
  {
    icon: "paycheck",
    title: "Paystub",
    body: "If you see a name on your paystub that isn't your employer, that is likely your payroll provider.",
  },
  {
    icon: "commentsQuestion",
    title: "HR team",
    body: "If you're not sure, you can always reach out to your HR team to confirm the name.",
  },
];
