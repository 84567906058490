import React from "react";
import Loading from "components/ui/Loading";
import Logo from "components/ui/Logo";
import { getStatement, getUserProfile } from "store/api/apiSlice";
import { truncateAndFormatDateString } from "utils/date";
import { getResetAddress } from "utils/reset";
import {
  getAccountType,
  sortTransactionsAscending,
} from "utils/api/statements";
import { useParams } from "react-router-dom";
import * as Styled from "./styled";
import TransactionTable from "./TransactionTable";
import Disclosure from "./Disclosure";

const DATE_STYLE = "numericLong";

const Statement = () => {
  const { id } = useParams();
  const { data, isLoading } = getStatement(id);
  const { data: userProfile } = getUserProfile();

  if (isLoading) return <Loading />;
  if (!data) return null;

  const { statement } = data || {};
  const resetAddress = getResetAddress();
  const {
    primaryAccountHolderPersonal,
    accountSummary,
    startDate,
    endDate,
    openingBalance,
    closingBalance,
    transactions,
  } = statement;

  const sortedTransactions = transactions
    ? sortTransactionsAscending(transactions)
    : null;

  const address = userProfile?.currentAddress;

  return (
    statement && (
      <Styled.Wrapper>
        <Styled.Header>
          <Styled.InfoList>
            <Styled.LogoWrapper>
              <Logo />
            </Styled.LogoWrapper>

            <Styled.Info>
              <Styled.SectionHeading>
                Reset Financial Technologies, Inc.
              </Styled.SectionHeading>
              {resetAddress && (
                <>
                  <div>{resetAddress.street_one}</div>
                  <div>{resetAddress.street_two}</div>
                  <div>
                    {resetAddress.city}, {resetAddress.state_abbr}{" "}
                    {resetAddress.zip_code}
                  </div>
                </>
              )}
            </Styled.Info>
          </Styled.InfoList>

          <Styled.StatementInfo>
            <Styled.InfoHeading>Account Statement</Styled.InfoHeading>

            <Styled.Info>
              <Styled.SectionHeading>
                {primaryAccountHolderPersonal.firstName}{" "}
                {primaryAccountHolderPersonal.lastName}
              </Styled.SectionHeading>
              {address && (
                <>
                  <div>{address.streetOne}</div>
                  {address.streetTwo?.length > 0 && (
                    <div>{address.streetTwo}</div>
                  )}
                  <div>
                    {address.city}, {address.stateAbbr} {address.zipCode}
                  </div>
                  <div>United States</div>
                </>
              )}
            </Styled.Info>

            <Styled.InfoList>
              <Styled.Info>
                <Styled.SectionHeading>Statement Period</Styled.SectionHeading>
                <div>
                  {truncateAndFormatDateString(startDate, DATE_STYLE)} &ndash;{" "}
                  {truncateAndFormatDateString(endDate, DATE_STYLE)}
                </div>
              </Styled.Info>
              <Styled.Info>
                <Styled.SectionHeading>Account Type</Styled.SectionHeading>
                <div>{getAccountType(accountSummary.accountType)}</div>
              </Styled.Info>
              <Styled.Info>
                <Styled.SectionHeading>Account Number</Styled.SectionHeading>
                <div>{accountSummary.accountNumber}</div>
              </Styled.Info>
            </Styled.InfoList>
          </Styled.StatementInfo>
        </Styled.Header>

        <Styled.Details>
          <Styled.InfoHeading>Transaction Details</Styled.InfoHeading>

          <TransactionTable
            transactions={sortedTransactions}
            openingBalance={openingBalance}
            closingBalance={closingBalance}
            startDate={startDate}
            endDate={endDate}
            accountNumber={accountSummary.accountNumber}
          />
        </Styled.Details>

        <Styled.Disclosure>
          <Disclosure />
        </Styled.Disclosure>
      </Styled.Wrapper>
    )
  );
};

export default Statement;
