import React, { useRef } from "react";
import { v4 as uuid } from "uuid";
import * as Styled from "./styled";

export interface Tab {
  id: string;
  label: React.ReactNode;
}

interface Props {
  tabs: Tab[];
  activeId?: string;
  onClick: (id: string) => void;
  tabWidth?: number;
  disabled?: boolean;
}

const Tabs: React.FC<Props> = ({
  tabs,
  activeId,
  onClick,
  tabWidth,
  disabled,
}) => {
  const componentIdRef = useRef(uuid());

  return (
    <Styled.Wrapper>
      {tabs.map((tab) => (
        <Styled.Tab
          $isActive={activeId === tab.id}
          $width={tabWidth}
          key={tab.id}
          onClick={() => onClick(tab.id)}
          disabled={disabled}
        >
          {activeId === tab.id && (
            <Styled.Background
              layoutId={`tab-switcher-bg-${componentIdRef.current}`}
              transition={{ type: "spring", duration: 0.2 }}
            />
          )}
          <Styled.Label>{tab.label}</Styled.Label>
        </Styled.Tab>
      ))}
    </Styled.Wrapper>
  );
};

export default Tabs;
