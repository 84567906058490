const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  new Array(len).fill(0).forEach((_, i) => {
    binary += String.fromCharCode(bytes[i]);
  });
  return window.btoa(binary);
};

export const toBase64 = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer();
  const str = arrayBufferToBase64(arrayBuffer);
  return str;
};
