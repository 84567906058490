import React from "react";
import { getDisclosures, getUserProfile } from "store/api/apiSlice";
import Loading from "components/ui/Loading";
import DocumentList from "components/shared/DocumentList";
import UILayout from "components/layout/UILayout";
import { Heading } from "components/ui/Text";
import { ResetDocument } from "components/shared/DocumentList/types";
import {
  getBasicDisclosures,
  getCardHolderDisclosures,
} from "components/shared/DocumentList/constants";
import { Wrapper } from "../styled";

const Documents: React.FC = () => {
  const { data: userProfile, isLoading } = getUserProfile();
  const { data: disclosuresData, isLoading: isLoadingDisclosures } =
    getDisclosures();

  if (isLoading || isLoadingDisclosures) return <Loading />;

  const documents = [
    ...getBasicDisclosures(disclosuresData),
    ...(userProfile?.currentCardAccount
      ? getCardHolderDisclosures(disclosuresData)
      : []),
  ] as ResetDocument[];

  return (
    <UILayout>
      <Wrapper>
        <Heading.H3 tag="h1">Documents</Heading.H3>

        <DocumentList documents={documents} />
      </Wrapper>
    </UILayout>
  );
};

export default Documents;
