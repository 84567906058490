import React from "react";
import { LineItemDetail } from "components/ui/LineItem";
import { formatDateString } from "utils/date";
import StatusTag from "components/ui/StatusTag";

export const getDetails = ({
  merchantCategory,
  createdAt,
  cardPresent,
  customerState,
  transactionReferenceNumber,
}) => {
  const details: LineItemDetail[] = [];
  if (merchantCategory) {
    details.push({ label: "Category", value: merchantCategory });
  }
  const baseDetails = [
    {
      label: "Date",
      value: formatDateString(createdAt),
      isMobile: true,
    },
    {
      label: "Method",
      value: cardPresent ? "In person" : "Online, mail or phone",
    },
    { label: "Status", value: <StatusTag status={customerState} /> },
  ];
  details.push(...baseDetails);
  if (transactionReferenceNumber) {
    details.push({
      label: "Reference number",
      value: transactionReferenceNumber,
    });
  }
  return details;
};
