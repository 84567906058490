import styled from "styled-components";
import DisappearingTile from "components/ui/DisappearingTile";
import { mediaMin } from "utils/theme";

const DashboardTile = styled(DisappearingTile).attrs(() => ({
  size: "xl",
}))`
  width: 100%;

  ${mediaMin("md")} {
    padding: 152px 48px 48px;
    margin: -152px auto 0;
  }
`;

export default DashboardTile;
