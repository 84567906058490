import { currencyStringToNumber } from "utils/string";
import { NEEDS_OPTIONS } from "./constants";
import {
  BudgetData,
  BudgetExpense,
  BudgetFormData,
  IncomeFrequency,
} from "./types";

const getAnnualIncomeByFrequency = (income, incomeFrequency) => {
  const incomeAmount = currencyStringToNumber(income || "0");
  switch (incomeFrequency) {
    case IncomeFrequency.weekly:
      return (incomeAmount / 7) * 365;
    case IncomeFrequency.biweekly:
      return (incomeAmount / 14) * 365;
    case IncomeFrequency.semimonthly:
      return incomeAmount * 24;
    case IncomeFrequency.monthly:
      return incomeAmount * 12;
    case IncomeFrequency.annually:
    default:
      return incomeAmount;
  }
};

export const getAmountsBySpendingBucket = (expenses: BudgetExpense[]) => {
  const buckets = expenses.reduce(
    (acc, { name, amount }) => {
      if (NEEDS_OPTIONS.some((option) => option.name === name)) {
        acc.needs += amount;
      } else {
        acc.wants += amount;
      }
      return acc;
    },
    { needs: 0, wants: 0 }
  );

  return buckets;
};

export const getBudget = ({
  income,
  incomeFrequency,
  expenses,
}: BudgetFormData) => {
  const annualIncome = getAnnualIncomeByFrequency(income, incomeFrequency);
  const expenseArray = Object.entries(expenses).map(([name, amount]) => ({
    name,
    amount: currencyStringToNumber(amount || "0"),
  }));
  const { needs, wants } = getAmountsBySpendingBucket(expenseArray);

  return {
    version: 1,
    income: annualIncome,
    expenses: expenseArray,
    needs,
    wants,
  };
};

export const getResults = ({ income, expenses }: BudgetData) => {
  const monthlyIncome = income / 12;
  const { needs, wants } = getAmountsBySpendingBucket(expenses);
  return { monthlyIncome, needs, wants };
};

export const getInitialResults = () => {
  const params = new URLSearchParams(window.location.search);
  const budgetString = params.get("b") || "";
  const budgetHexes = budgetString.split(/-/g);

  if (budgetHexes?.length !== 3) return null;

  const [monthlyIncome, needs, wants] = budgetHexes.map((hex) =>
    parseInt(hex, 16)
  );
  return { monthlyIncome, needs, wants };
};

export const isBudgetFormValid = (budgetFormData: BudgetFormData) => {
  const { isValid = [] } = budgetFormData;

  return new Array(5).fill(0).every((_, i) => !!isValid[i]);
};
