import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import {
  borderRadius,
  colors,
  fontWeights,
  mediaMax,
  shadows,
  transitions,
} from "utils/theme";
import Icon from "components/ui/Icon";
import { BaseButton } from "components/ui/Button";
import UILabel from "components/ui/UILabel";

export const Wrapper = styled.div``;

export const List = styled.ul`
  display: grid;
  gap: 16px;
  max-width: 576px;
  margin: 64px auto 0;
  padding: 0;

  list-style-type: none;
  text-align: left;

  ${mediaMax("lg")} {
    grid-template-columns: 1fr;
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const ListItemTile = styled.div`
  --padding: 24px;

  overflow: hidden;
`;

export const ListItemHeader = styled(BaseButton).attrs(() => ({
  type: "button",
}))`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: var(--padding);
  margin: 0;
  gap: 12px;

  text-align: left;
`;

export const IconWrapper = styled.div`
  position: relative;

  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
`;

export const ListItemToggleIcon = styled(Icon)`
  margin-top: 5px;

  transition: all 0.3s ease-out;

  * {
    stroke: currentColor;
    stroke-width: 16px;
  }
`;

export const ListItemHeading = styled(UILabel.Large)`
  flex: 1 1 auto;
  padding: 2px 0;
`;

export const ListItemBody = styled(motion.div)`
  transform: translateY(-12px);
  padding: 0 var(--padding) 0 calc(var(--padding) + 36px);

  color: ${colors.midnight}AA;

  a {
    font-weight: ${fontWeights.bold};
    text-decoration: underline;
  }

  ${mediaMax("md")} {
    padding-left: var(--padding);
  }
`;

export const ListItemText = styled(UILabel.Large)`
  padding-bottom: calc(var(--padding) - 12px);

  * {
    margin: 0;
  }

  p,
  div,
  ul,
  ol {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  a {
    color: ${colors.azure};
  }
`;

export const ListItem = styled.li<{ $isOpen: boolean }>`
  scroll-margin-top: 80px;

  border-radius: ${borderRadius.small}px;

  transition: all ${transitions.default};

  ${(props) =>
    props.$isOpen
      ? css`
          box-shadow: ${shadows.tile};
          background: white;

          ${ListItemToggleIcon} {
            transform: rotate(0);

            color: ${colors.azure}80;
          }
        `
      : css`
          box-shadow: 0 0 0 1px ${colors.midnight}1A;

          ${ListItemToggleIcon} {
            transform: rotate(-180deg);

            color: ${colors.midnight}80;
          }
        `};
`;
