import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import UILabel from "components/ui/UILabel";
import { ButtonLevel, ButtonSize } from "components/ui/Button";
import { IncomeType } from "types/signup";
import { session } from "utils/storage";
import ssaLogo from "assets/images/home/ssa-logo.png";
import * as Styled from "./styled";

const SIGNUP_URL = `/users/sign_up?incomeType=${IncomeType.benefits}`;

const SocialSecurity = () => {
  const onClickSignUp = (e) => {
    e.preventDefault();

    session.setItem("signupParams", {
      payroll: {
        id: "0bab321e-64ea-4047-8d8a-44ba283f6cd8",
        searchString: "Social Security",
      },
    });

    window.location.href = SIGNUP_URL;
  };

  return (
    <ContentLayout>
      <Styled.Callout>
        <Styled.CalloutMain>
          <Styled.CalloutContent>
            <Styled.CalloutIcon>
              <img src={ssaLogo} />
            </Styled.CalloutIcon>

            <UILabel.XLarge>
              <strong>Social Security recipients</strong>: access your income
              daily with Reset!{" "}
              <Styled.CalloutLinkMobile href="/social-security">
                Learn More
              </Styled.CalloutLinkMobile>
            </UILabel.XLarge>
          </Styled.CalloutContent>

          <Styled.CalloutLinks>
            <Styled.CalloutLink href="/social-security" size="lg">
              Learn More
            </Styled.CalloutLink>

            <Styled.CalloutButton
              level={ButtonLevel.primary}
              size={ButtonSize.md}
              onClick={onClickSignUp}
              href={SIGNUP_URL}
            >
              Sign Up
            </Styled.CalloutButton>
          </Styled.CalloutLinks>
        </Styled.CalloutMain>
      </Styled.Callout>
    </ContentLayout>
  );
};

export default SocialSecurity;
