import { FormTaskStatus } from "./task";

export enum OnboardingTaskName {
  signup = "signup",
  payrollLinking = "payroll_linking",
  bankLinking = "bank_linking",
  termsAcceptance = "terms_acceptance",
  identityVerification = "identity_verification",
  depositSwitching = "deposit_switching",
  repaymentAccountAcknowledgement = "repayment_account_acknowledgement",
}

export interface OnboardingTask {
  name: OnboardingTaskName;
  state: FormTaskStatus;
}
