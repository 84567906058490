import { sortByDateDescending } from "utils/api/array";
import { ApiCardTransaction } from "utils/api/cardTransactions";
import { TRANSACTION_STATES_CURRENT } from "utils/cardTransactions";
import { formatDateString } from "utils/date";

const dateReducer = (acc, transaction) => {
  const date = formatDateString(transaction.createdAt, "textShortWithWeekday");
  if (acc.length > 0 && date === acc[acc.length - 1]?.date) {
    acc[acc.length - 1].transactions.push(transaction);
  } else {
    acc.push({
      date,
      transactions: [transaction],
    });
  }
  return acc;
};

const statusReducer = (acc, transaction) => {
  if (TRANSACTION_STATES_CURRENT.includes(transaction.customerState)) {
    acc.current.push(transaction);
  } else {
    acc.past.push(transaction);
  }
  return acc;
};

interface TransactionsByDate {
  date: string;
  transactions: ApiCardTransaction[];
}

export const getTransactionsByDateAndStatus = (
  transactions: ApiCardTransaction[]
) => {
  if (!transactions) return { current: [], past: [] };
  const transactionsByStatus = transactions.reduce(statusReducer, {
    current: [],
    past: [],
  }) as { current: ApiCardTransaction[]; past: ApiCardTransaction[] };
  const sortedTransactions = Object.entries(transactionsByStatus).reduce(
    (acc, [group, groupTransactions]) => {
      acc[group] = sortByDateDescending(groupTransactions, "createdAt").reduce(
        dateReducer,
        []
      );
      return acc;
    },
    {}
  ) as { current: TransactionsByDate[]; past: TransactionsByDate[] };
  return sortedTransactions;
};
