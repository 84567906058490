import styled from "styled-components";
import { colors, fontSizes } from "utils/theme";

const ErrorMessage = styled.div`
  color: ${colors.cerise};

  font-size: ${fontSizes.xs}px;
`;

export default ErrorMessage;
